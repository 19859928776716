import { useEffect, useState } from 'react';

import { SECOND, MINUTE, HOUR, DAY } from '../../config';
import { GatherlyEventServerStatus } from '../../enums';
import {
  c,
  getEventServerStatus,
  getMillisLeft,
  isOnTheMinute,
} from '../../utils';

import { CountdownClock } from './CountdownClock';
import { CountdownTooltip } from './CountdownTooltip';
import { EventOver } from './EventOver';
import { InProgress } from './InProgress';
import { StopEvent } from './StopEvent';
import { TimeLeft } from './TimeLeft';
import classes from './EventCountdown.module.scss';

type Props = {
  className?: string;
  eventId: string;
  startTime: number;
  stopTime: number;
  onCountdown?: (serverStatus?: GatherlyEventServerStatus) => void;
};

function getUpdateInterval(
  millisToStart: number,
  millisToStop: number,
  serverStatus?: GatherlyEventServerStatus,
) {
  switch (serverStatus) {
    case GatherlyEventServerStatus.PRELAUNCH:
      if (millisToStart < DAY) return SECOND;
      break;
    case GatherlyEventServerStatus.LAUNCH:
      return SECOND;
    case GatherlyEventServerStatus.LIVE:
      if (millisToStop < 2 * HOUR) return SECOND;
      break;
  }
  return MINUTE;
}

export function EventCountdown({
  className,
  eventId,
  startTime,
  stopTime,
  onCountdown,
}: Props) {
  const [millisToStart, setMillisToStart] = useState<number>(
    getMillisLeft(startTime),
  );
  const [millisToStop, setMillisToStop] = useState<number>(
    getMillisLeft(stopTime),
  );
  const [serverStatus, setServerStatus] = useState(
    getEventServerStatus(startTime, stopTime),
  );
  const [updateInterval, setUpdateInterval] = useState(
    getUpdateInterval(millisToStart, millisToStop, serverStatus),
  );

  useEffect(() => {
    setMillisToStart(getMillisLeft(startTime));
    setMillisToStop(getMillisLeft(startTime));
    const interval = setInterval(() => {
      const _millisToStart = getMillisLeft(startTime);
      const _millisToStop = getMillisLeft(startTime);
      const _serverStatus = getEventServerStatus(startTime, stopTime);
      const _updateInterval = getUpdateInterval(
        _millisToStart,
        _millisToStop,
        _serverStatus,
      );
      setMillisToStart(_millisToStart);
      setMillisToStop(_millisToStop);
      setServerStatus(_serverStatus);
      setUpdateInterval(_updateInterval);

      if (onCountdown) {
        if (
          serverStatus !== GatherlyEventServerStatus.PRELAUNCH ||
          isOnTheMinute(_millisToStart)
        )
          onCountdown(_serverStatus);
      }
    }, updateInterval);
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [startTime, updateInterval, stopTime, serverStatus, onCountdown]);

  switch (serverStatus) {
    case GatherlyEventServerStatus.LIVE: {
      return (
        <div className="gap-_5 flex flex-row">
          <InProgress />
          <StopEvent eventId={eventId} />
        </div>
      );
    }
    case GatherlyEventServerStatus.OVER: {
      return <EventOver />;
    }
    case GatherlyEventServerStatus.PRELAUNCH:
    case GatherlyEventServerStatus.LAUNCH: {
      return (
        <div
          className={c(
            className,
            classes.EventCountdown,
            'flex flex-col br_25 color-shade-10 bg-color-blue-delight px_75 pl2 subheading relative',
          )}
        >
          <div className="flex flex-1 gap-_5 items-center">
            {millisToStart < DAY ? (
              <CountdownClock millisLeft={millisToStart} />
            ) : (
              <TimeLeft millisLeft={millisToStart} />
            )}
          </div>
          <CountdownTooltip />
        </div>
      );
    }
    default:
      return null;
  }
}
