import React from 'react';

import {
  APP_LABEL,
  APP_VERSION,
  COMMIT_HASH,
  COMMIT_DATE,
  COMMIT_SUBJECT,
} from '../../config';
import { c } from '../../utils';
import { Clickable, ClickableVariant } from '../Clickable';

type Props = {
  className?: string;
};

const year = new Date().getFullYear();

export function Footer({ className = 'color-shade-80' }: Props) {
  return (
    <div className={c('flex justify-center', className)}>
      <h6 className="small mt1">
        {COMMIT_HASH ? (
          <Clickable
            variant={ClickableVariant.LINK}
            to={`https://github.com/Gatherly/event-manager/commit/${COMMIT_HASH}`}
            label={COMMIT_HASH}
            title={`${COMMIT_DATE} - ${COMMIT_SUBJECT}`}
          />
        ) : (
          <span className="mr_25">{APP_LABEL || `v${APP_VERSION}`}</span>
        )}
        <span>&copy; Gatherly {year}</span>
      </h6>
    </div>
  );
}
