import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { isMobile } from 'react-device-detect';

import { c } from '../../utils';
import classes from './Tooltip.module.scss';

export enum TooltipDirection {
  top = 'top',
  right = 'right',
  bottom = 'bottom',
  left = 'left',
}

interface Props {
  children: ReactNode | string;
  className?: string;
  content: ReactNode | string;
  position?: TooltipDirection;
  isDisabled?: boolean;
  followCursor?: boolean;
}

export function Tooltip({
  children,
  className,
  content,
  position = TooltipDirection.bottom,
  isDisabled,
  followCursor = false,
}: Props) {
  let timeout: ReturnType<typeof setTimeout>;
  const [active, setActive] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const tooltipWrapRef = useRef<HTMLDivElement>(null);

  const showToolTip = useCallback(() => {
    if (isDisabled) return;
    setActive(true);
  }, [isDisabled]);

  const hideToolTip = () => {
    setActive(false);
  };

  const updateTooltipPosition: EventListener = useCallback(
    (elem: any): void => {
      const tooltip = tooltipRef.current;
      const tooltipWrap = tooltipWrapRef.current;
      if (tooltip && tooltipWrap) {
        const { offsetLeft, offsetTop } = tooltipWrap;
        const { offsetHeight } = tooltip;

        // @ts-ignore
        tooltip.style.left = elem.pageX - offsetLeft + 'px';
        // @ts-ignore
        tooltip.style.top = elem.pageY - offsetTop - offsetHeight + 'px';
      }
    },
    [tooltipRef],
  );

  useEffect(() => {
    const tooltipWrap = tooltipWrapRef.current;
    if (followCursor && !isMobile && tooltipWrap) {
      tooltipWrap.addEventListener('mousemove', updateTooltipPosition);
    }
    return () => {
      clearInterval(timeout);
      if (tooltipWrap) {
        tooltipWrap.removeEventListener('mousemove', updateTooltipPosition);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [followCursor, active, isDisabled, updateTooltipPosition]);

  return (
    <span className="relative" onMouseEnter={showToolTip} ref={tooltipWrapRef}>
      {children}
      {active && (
        <div
          className={c(
            classes.content,
            className,
            active && classes.active,
            'z-4 p_5 flex items-center nowrap animation-fade-in delay-medium',
            classes[`position--${position}`],
            followCursor && classes.followCursor,
          )}
          ref={tooltipRef}
          onMouseLeave={hideToolTip}
        >
          {content}
        </div>
      )}
      <span className="sr-only">{content}</span>
    </span>
  );
}

export default Tooltip;
