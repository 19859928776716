import { PromiseStatus } from '../enums';
import {
  StatusState,
  makeSetStatus,
  makeSetStatusForList,
} from '../store/statusReducer';

export function setIdle(dispatch, title: keyof StatusState, key?: string) {
  if (key) {
    dispatch(makeSetStatusForList(title, PromiseStatus.IDLE, key));
  } else {
    dispatch(makeSetStatus(title, PromiseStatus.IDLE));
  }
}
export function setPending(dispatch, title: keyof StatusState, key?: string) {
  if (key) {
    dispatch(makeSetStatusForList(title, PromiseStatus.PENDING, key));
  } else {
    dispatch(makeSetStatus(title, PromiseStatus.PENDING));
  }
}
export function setFulfilled(dispatch, title: keyof StatusState, key?: string) {
  if (key) {
    dispatch(makeSetStatusForList(title, PromiseStatus.FULFILLED, key));
  } else {
    dispatch(makeSetStatus(title, PromiseStatus.FULFILLED));
  }
}
export function setRejected(dispatch, title: keyof StatusState, key?: string) {
  if (key) {
    dispatch(makeSetStatusForList(title, PromiseStatus.REJECTED, key));
  } else {
    dispatch(makeSetStatus(title, PromiseStatus.REJECTED));
  }
}
