import React, { useCallback, useMemo, SyntheticEvent } from 'react';

import { setOverlay, OverlayType } from '../../../actions';
import { Clickable, ClickableVariant } from '../../../components/Clickable';
import { useAppDispatch } from '../../../hooks';
import { c, asyncNoop } from '../../../utils';

import classes from './EventFormFooter.module.scss';
import {
  trackPreviewEvent,
  trackPreviewLandingPage,
} from '../../../libs/trackingLib';

type Props = {
  eventId: string;
  hasChanges: boolean;
  isDisabled: boolean;
  isMakingRequest: boolean;
  onSubmit?: (evt?: SyntheticEvent) => Promise<void>;
  previewType?: 'event' | 'landing-page';
};

export function EventFormFooter({
  eventId,
  hasChanges,
  isDisabled,
  isMakingRequest,
  onSubmit = asyncNoop,
  previewType = 'event',
}: Props) {
  const dispatch = useAppDispatch();
  const onClickPreview = useCallback(
    async (evt?: SyntheticEvent) => {
      if (evt) evt.preventDefault();
      await onSubmit();

      if (previewType === 'event') {
        trackPreviewEvent(hasChanges);
      } else {
        trackPreviewLandingPage(hasChanges);
      }

      setOverlay(dispatch, OverlayType.PREVIEW_EVENT, {
        eventId,
        type: previewType,
      });
    },
    [eventId, hasChanges, previewType, onSubmit],
  );

  const buttonLabel = useMemo(() => {
    if (isMakingRequest) return 'Loading...';
    if (hasChanges)
      return `Save and preview ${
        previewType === 'event' ? 'event' : 'landing page'
      }`;
    return `Preview ${previewType === 'event' ? 'event' : 'landing page'}`;
  }, [hasChanges, isMakingRequest, previewType]);
  return (
    <div
      className={c(
        classes.EventFormFooter,
        'bg-color-white border-t1 border-color-shade-20 fixed z1 b0 r0',
        'flex items-center justify-center md:justify-start md:gap-1 px1 md:px2',
      )}
    >
      <Clickable
        data-cy="save-event-changes"
        isDisabled={!hasChanges || isDisabled || isMakingRequest}
        label="Save"
        onClick={onSubmit}
        variant={ClickableVariant.BUTTON_SECONDARY}
        className="bg-color-gradient"
      >
        {isMakingRequest ? 'Saving...' : hasChanges ? 'Save Changes' : 'Saved'}
      </Clickable>
      <Clickable
        isDisabled={isDisabled || isMakingRequest}
        label="Save and preview event"
        onClick={onClickPreview}
        variant={ClickableVariant.BUTTON_TERTIARY}
        className="none md:flex md:visible"
      >
        {buttonLabel}
      </Clickable>
    </div>
  );
}
