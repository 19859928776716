import React, { ReactNode } from 'react';
import { c } from '../../utils';
import classes from './Status.module.scss';

type Props = {
  className?: string;
  message?: string | ReactNode;
  withLoader?: boolean;
};

export function Status({ className, message = 'Loading', withLoader }: Props) {
  return (
    <div
      className={c(
        'flex flex-col items-center justify-center gap-1',
        classes.Status,
        className,
      )}
    >
      <div
        className={c(classes.loaderContainer, withLoader && classes.loader)}
      />
      <div className="body text-center">{message}</div>
    </div>
  );
}
