import { ReactNode } from 'react';
import { classnames } from '@gatherly/lib';
import { Tooltip, TooltipDirection } from '../Tooltip';
import { Images } from '../../images';
import { Image } from '../Image';

type BrandingTooltipProps = {
  children: ReactNode;
  className?: string;
};

export function BrandingTooltip({ children, className }: BrandingTooltipProps) {
  const content = (
    <div className={classnames(className, 'flex flex-col')}>
      <p className="detail wrap">
        Located in the top left of your Gatherly space. We recommend a landscape
        orientation logo.
      </p>
      <Image {...Images.LOGO_TOOLTIP} />
    </div>
  );
  return (
    <Tooltip
      content={content}
      position={TooltipDirection.right}
      className="inline-block"
    >
      {children}
    </Tooltip>
  );
}

export default BrandingTooltip;
