import { user, event, file, permission, billing, test, tracking } from './http';

export { http, removeHttpAuthToken, setHttpAuthToken } from './http';

export const Api = {
  ...user,
  ...event,
  ...file,
  ...permission,
  ...billing,
  ...test,
  ...tracking,
};

export default Api;
