import { loadStripe } from '@stripe/stripe-js';

export { isMobile } from 'react-device-detect';
const {
  NODE_ENV,
  REACT_APP_STAGE = NODE_ENV,
  REACT_APP_IS_OFFLINE,
  REACT_APP_APP_LABEL,
  REACT_APP_AWS_REGION,
  REACT_APP_USER_POOL_ID,
  REACT_APP_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_OFFLINE_URL,
  REACT_APP_API_GATEWAY_URL,
  REACT_APP_STRIPE_PUBLISHABLE_KEY,
  REACT_APP_SENTRY_DSN,
  REACT_APP_SMARTLOOK_PROJECT_ID,
  REACT_APP_IS_SMARTLOOK_ENABLED,
  REACT_APP_SEGMENT_PROJECT_ID,
  REACT_APP_IS_SEGMENT_ENABLED,
  REACT_APP_GIT_LOG,
  REACT_APP_IDENTITY_POOL_ID,
  REACT_APP_COGNITO_DOMAIN_NAME,
} = process.env;

export const isProd = NODE_ENV === 'production';
export const isDev = NODE_ENV === 'development';
export const isOffline = REACT_APP_IS_OFFLINE === 'true';

export const [COMMIT_HASH, COMMIT_DATE, COMMIT_SUBJECT] =
  REACT_APP_GIT_LOG?.split('|') || '';

const betaAppLabel = REACT_APP_STAGE === 'beta' && COMMIT_HASH;
export const STAGE = REACT_APP_STAGE || 'beta';
export const APP_LABEL = REACT_APP_APP_LABEL || betaAppLabel || '';
export const AWS_REGION = REACT_APP_AWS_REGION || 'us-east-2';
export const USER_POOL_ID = REACT_APP_USER_POOL_ID || '';
export const USER_POOL_WEB_CLIENT_ID = REACT_APP_USER_POOL_WEB_CLIENT_ID;
export const IDENTITY_POOL_ID = REACT_APP_IDENTITY_POOL_ID;
export const REDIRECT_SIGNIN_URL = `${window.location.origin}/callback`;
export const REDIRECT_SIGNOUT_URL = `${window.location.origin}/`;
export const COGNITO_DOMAIN_NAME = REACT_APP_COGNITO_DOMAIN_NAME;

const API_VERSION = 'v1';

const OFFLINE_URL = REACT_APP_OFFLINE_URL;
const API_GATEWAY_URL = REACT_APP_API_GATEWAY_URL || OFFLINE_URL;

export const API_BASEURL =
  isDev && isOffline
    ? `${OFFLINE_URL}/${API_VERSION}`
    : `${API_GATEWAY_URL}/${API_VERSION}`;

export const stripePromise = loadStripe(
  REACT_APP_STRIPE_PUBLISHABLE_KEY as string,
);

export const SENTRY_DSN = REACT_APP_SENTRY_DSN;

export const SMARTLOOK_PROJECT_ID = REACT_APP_SMARTLOOK_PROJECT_ID || '';
export const IS_SMARTLOOK_ENABLED =
  isProd && REACT_APP_IS_SMARTLOOK_ENABLED !== 'false';
export const SEGMENT_PROJECT_ID = REACT_APP_SEGMENT_PROJECT_ID || '';
export const IS_SEGMENT_ENABLED =
  isProd && REACT_APP_IS_SEGMENT_ENABLED !== 'false';
