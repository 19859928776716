import React, { useCallback, SyntheticEvent } from 'react';

import { Clickable, ClickableVariant } from '../../Clickable';

import { EventLinkList } from '../../EventLinkList';
import { setOverlay, OverlayType } from '../../../actions';
import { useAppDispatch } from '../../../hooks';
import { c } from '../../../utils';
import { EventFormHeader } from './EventFormHeader';
import { EventFormFooter } from './EventFormFooter';
import classes from './EventInfoForm.module.scss';
import { IGatherlyEvent } from '@gatherly/types';

type Props = {
  event: IGatherlyEvent;
  isDisabled: boolean;
};

export function EventLinksForm({ event, isDisabled }: Props) {
  const { eventId } = event;
  const dispatch = useAppDispatch();

  // Click Handlers
  const onClickNewLink = useCallback(
    (evt?: SyntheticEvent) => {
      if (evt) evt.preventDefault();
      setOverlay(dispatch, OverlayType.NEW_LINK, { eventId });
    },
    [eventId, dispatch],
  );

  return (
    <div className={c('flex flex-1 flex-col gap-2')}>
      <div className="md:pl2">
        <EventFormHeader
          eventId={eventId}
          isDisabled={isDisabled}
          title="Access Links"
        >
          <div className="flex flex-col gap-1">
            <p className="color-shade-80 body">
              Custom links enable hosts to customize the permissions and
              appearance of attendees on Gatherly.
              <Clickable
                className="ml_5"
                to={`https://help.gatherly.io/en/articles/4743366-how-to-create-custom-links-for-your-gatherly-event`}
                variant={ClickableVariant.LINK}
              >
                Learn More
              </Clickable>
            </p>
          </div>
        </EventFormHeader>
      </div>
      <div className={c(classes.mainColumn, 'flex flex-col gap-1 md:px2')}>
        <EventLinkList
          eventId={eventId}
          isDisabled={isDisabled}
          onClickNewLink={onClickNewLink}
        />
      </div>
      <EventFormFooter
        eventId={eventId}
        isDisabled={isDisabled}
        hasChanges={false}
        isMakingRequest={false}
      />
    </div>
  );
}
