import React, { ReactNode } from 'react';

import { Clickable, ClickableVariant } from '../Clickable';
import { Image } from '../Image';
import { c } from '../../utils';
import classes from './MessageWithIllustration.module.scss';

type Props = {
  children?: ReactNode[] | ReactNode;
  className?: string;
  ctaLabel?: string;
  ctaOnClick?: () => void;
  ctaTo?: string;
  heading: string;
  imgAlt?: string;
  imgSrc?: string;
  message?: string;
};

export function MessageWithIllustration({
  children,
  className,
  ctaLabel = 'Click here',
  ctaOnClick,
  ctaTo,
  heading,
  imgAlt,
  imgSrc,
  message = '',
}: Props) {
  return (
    <div
      className={c(
        classes.MessageWithIllustration,
        className,
        'flex flex-1 justify-center items-center',
      )}
    >
      <div className="flex flex-col lg:col-10 xl:col-8 my4 items-center text-center gap-1">
        <div className={c(classes.imageWrapper, 'mb_75')}>
          {imgSrc && <Image src={imgSrc} alt={imgAlt ?? ''} />}
        </div>
        <h2 className="subheading bold color-shade-80">{heading}</h2>
        {children || <p className="body color-shade-70">{message}</p>}
        {(ctaOnClick || ctaTo) && (
          <Clickable
            className="mt1"
            to={ctaTo}
            onClick={ctaOnClick}
            variant={ClickableVariant.BUTTON_PRIMARY}
          >
            <span className="color-white">{ctaLabel}</span>
          </Clickable>
        )}
      </div>
    </div>
  );
}
