import React, { useCallback, useMemo } from 'react';
import { AclPermissionsRole } from '@eventmanager/types';
import { isPlanUpgrade } from '@gatherly/lib';
import { setOverlay, OverlayType } from '../../actions';
import { Clickable, ClickableVariant } from '../Clickable';
import { useAppDispatch } from '../../hooks';

interface Props {
  className?: string;
  monthlyFee: number;
  userPlanName: AclPermissionsRole;
  planName: AclPermissionsRole;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export function AccountPlanCta({
  className,
  planName,
  onClick,
  userPlanName,
}: Props) {
  const dispatch = useAppDispatch();

  const isUpgrade = useMemo(
    () => isPlanUpgrade(userPlanName, planName),
    [userPlanName, planName],
  );

  const buttonLabel = useMemo(() => {
    if (planName === userPlanName) {
      return 'Current Plan';
    }
    if (planName === 'starter' && isUpgrade) {
      return 'Buy Tickets';
    }
    if (planName === 'enterprise') {
      return 'Upgrade';
    }
    return isUpgrade ? 'Upgrade' : 'Downgrade';
  }, [userPlanName, planName, isUpgrade]);

  const handleCtaClick = useCallback(
    (evt: React.MouseEvent<HTMLButtonElement>) => {
      if (planName === 'starter' && isUpgrade) {
        setOverlay(dispatch, OverlayType.PURCHASE_TICKETS, {
          planName: 'starter',
        });
        return;
      }
      return onClick(evt);
    },
    [isUpgrade, planName, onClick, dispatch],
  );

  return (
    <Clickable
      className={className}
      onClick={handleCtaClick}
      isDisabled={planName === userPlanName}
      label={buttonLabel}
      variant={ClickableVariant.BUTTON_SECONDARY}
    />
  );
}

export default AccountPlanCta;
