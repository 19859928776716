import {
  EventEmailSetting,
  IGatherlyEvent,
  IGatherlyEventConfig,
  PhotoBoothOption,
  SessionModeType,
  WaitingRoomOption,
} from '@gatherly/types';
import { difference, getVal } from '../../../utils';

type EventInfoFields = {
  name: string;
  eventType: string;
  capacity: number;
  password: string;
  startTime: number;
  stopTime: number;
  emailSettings: EventEmailSetting;
  description: string;
  elevatorEnabled: boolean;
  waitingRoomOption: WaitingRoomOption;
  photoBoothOption: PhotoBoothOption;
  startMode: SessionModeType;
};

export function getChanges(
  event: IGatherlyEvent,
  {
    name,
    eventType,
    capacity,
    password,
    startTime,
    stopTime,
    emailSettings,
    description,
    elevatorEnabled,
    waitingRoomOption,
    photoBoothOption,
    startMode,
  }: EventInfoFields,
) {
  const fieldsChanged = difference(event, {
    name,
    eventType,
    capacity,
    startTime,
    stopTime,
  });
  const configFieldsChanged = difference<IGatherlyEventConfig>(event.config, {
    emailSettings,
    password,
    elevatorEnabled,
    waitingRoomOption,
    photoBoothOption,
    startMode,
  });
  const eventFieldsDidUpdate = Object.keys(fieldsChanged).length > 0;
  const configFieldsDidUpdate = Object.keys(configFieldsChanged).length > 0;
  const eventDescriptionDidUpdate =
    event.config.landingPage.description !== description;

  if (eventDescriptionDidUpdate) {
    configFieldsChanged.landingPage = {
      ...event.config.landingPage,
      description,
    };
  }

  return {
    fieldsChanged,
    configFieldsChanged,
    eventFieldsDidUpdate,
    configFieldsDidUpdate,
    eventDescriptionDidUpdate,
  };
}

const eventFields = [
  'name',
  'eventType',
  'capacity',
  'password',
  'startTime',
  'stopTime',
  'config.password',
  'config.emailSettings',
  'config.elevatorEnabled',
  'config.photoBoothOption',
  'config.waitingRoomOption',
  'config.startMode',
];

export const getFormFields = event => {
  return eventFields.reduce(
    (acc, cur) => {
      const path = cur.split('.');
      const [key] = path.slice(-1);
      acc[key] = getVal(path, event);
      return acc;
    },
    {
      name: '',
      eventType: '',
      capacity: 0,
      password: '',
      startTime: 0,
      stopTime: 0,
      photoBoothOption: 'selfies-and-group' as PhotoBoothOption,
      waitingRoomOption: 'auto-join' as WaitingRoomOption,
      elevatorEnabled: true,
      emailSettings: 'optional' as EventEmailSetting,
      startMode: 'map' as SessionModeType,
    },
  );
};
