import React, { useEffect } from 'react';

import { getEventBroadcastRecordings } from '../../actions';
import { BROADCAST_RECORDINGS_HELP_URL, SALES_CONTACT_URL } from '../../config';
import { Status } from '../../components/Status';
import { Clickable, ClickableVariant } from '../../components/Clickable';
import { Pill } from '../../components/Pill';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  currentUserPermissions,
  eventBroadcastRecordings,
  floorsListMap,
  getStatusOfGetEventBroadcastRecordings,
} from '../../selectors';
import { isPending, isRejected } from '../../utils';

import { BroadcastRecordingsTable } from './BroadcastRecordingsTable';

type Props = {
  eventId: string;
};

export function BroadcastRecordings({ eventId }: Props) {
  const dispatch = useAppDispatch();
  const status = useAppSelector(
    getStatusOfGetEventBroadcastRecordings(eventId),
  );
  const broadcastRecordingsMap = useAppSelector(
    eventBroadcastRecordings(eventId),
  );
  const floorsMap = useAppSelector(floorsListMap(eventId));
  const { permissions } = useAppSelector(currentUserPermissions);
  useEffect(() => {
    getEventBroadcastRecordings(dispatch, eventId);
  }, [eventId, dispatch]);

  const hasPermission = !!permissions.broadcastRecordings;
  if (isPending(status)) return <Status message="Loading..." />;
  if (isRejected(status))
    return (
      <Status message="Sorry, there was a problem loading your recordings. Please try again." />
    );

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col mb2 body">
        <h2 className="heading flex items-start mb1">
          Broadcast Recordings
          <Pill className="color-logo-blue bg-color-shade-10 ml1">beta</Pill>
        </h2>
        {hasPermission ? (
          <p>View, download and share recorded broadcasts from this event.</p>
        ) : (
          <p>
            To unlock download and sharing permissions,{' '}
            <Clickable to={SALES_CONTACT_URL} variant={ClickableVariant.LINK}>
              contact sales
            </Clickable>
            .
          </p>
        )}
        <Clickable
          to={BROADCAST_RECORDINGS_HELP_URL}
          variant={ClickableVariant.LINK}
        >
          Learn More
        </Clickable>
      </div>
      <BroadcastRecordingsTable
        broadcastRecordingsMap={broadcastRecordingsMap}
        eventId={eventId}
        floorsMap={floorsMap}
        hasPermission={hasPermission}
      />
    </div>
  );
}
