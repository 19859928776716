import * as Sentry from '@sentry/react';
import { Extras } from '@sentry/types';
import { Integrations } from '@sentry/tracing';
import { APP_VERSION, isDev, SENTRY_DSN } from '../config';

export function initSentry(): void {
  if (isDev) {
    return;
  }

  const browserOptions: Sentry.BrowserOptions = {
    dsn: SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    release: APP_VERSION,
  };
  Sentry.init(browserOptions);
}

export function logError(error: any, errorInfo: Extras): void {
  if (isDev) {
    return;
  }

  Sentry.withScope((scope: Sentry.Scope) => {
    errorInfo && scope.setExtras(errorInfo);
    Sentry.captureException(error);
  });
}
