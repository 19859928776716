import React, {
  useCallback,
  useEffect,
  useState,
  SyntheticEvent,
  useMemo,
} from 'react';

import { Clickable, ClickableVariant } from '../Clickable';
import { Input } from '../Input';
import { verifyUserEmail, setIdle } from '../../actions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getStatusOfVerifyEmail, currentUser } from '../../selectors';
import {
  getFormField,
  getOnChangeFormField,
  isFulfilled,
  isPending,
  isRejected,
  validateString,
} from '../../utils';
import { Form } from './Form';

type Props = {
  onClickResend: () => void;
};

export function VerificationForm({ onClickResend }: Props) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    setIdle(dispatch, 'verifyEmail');
  }, []);

  const user = useAppSelector(currentUser);
  const status = useAppSelector(getStatusOfVerifyEmail);
  const isVerified = isFulfilled(status);
  const isMakingRequest = isPending(status);
  const hasError = isRejected(status);
  const [formError, setFormError] = useState('');

  const [email, setEmail] = useState(getFormField(user?.email || ''));
  const [verificationCode, setVerificationCode] = useState(getFormField(''));

  const onChangeEmail = useCallback(
    getOnChangeFormField(value => validateString(value, 'Email'), setEmail),
    [],
  );

  const onChangeVerificationCode = useCallback(
    getOnChangeFormField(
      value => validateString(value, 'Code'),
      setVerificationCode,
    ),
    [],
  );

  const label = useMemo(() => {
    if (isVerified) return 'REDIRECTING...';

    return isMakingRequest ? 'VERIFYING...' : 'VERIFY ACCOUNT';
  }, [isMakingRequest, isVerified]);

  const onSubmit = useCallback(
    async (evt: SyntheticEvent) => {
      evt.preventDefault();
      setFormError('');

      // validate
      const _email = onChangeEmail(email.value);
      const _verificationCode = onChangeVerificationCode(
        verificationCode.value,
      );

      if (_verificationCode.error || _email.error) return;

      const response = await verifyUserEmail(
        dispatch,
        _email.value,
        _verificationCode.value,
      );
      if (response && response.error) {
        setFormError(response.error);
      }
    },
    [email.value, verificationCode.value],
  );

  return (
    <Form className="flex flex-col gap-1" onSubmit={onSubmit}>
      <Input
        label="Email Address"
        name="email"
        onChange={evt => onChangeEmail(evt.target.value)}
        value={email.value}
        error={email.error}
        isDisabled={!!user?.email}
        isRequired
      />
      <Input
        label="Verification Code"
        name="verificationCode"
        onChange={evt => onChangeVerificationCode(evt.target.value)}
        value={verificationCode.value}
        error={verificationCode.error}
        isRequired
      />
      <div className="flex justify-center">
        <Clickable
          className="mt_75"
          isSubmit
          isDisabled={isMakingRequest || isVerified}
          label={label}
          variant={ClickableVariant.BUTTON_PRIMARY}
        />
      </div>
      {(formError || hasError) && (
        <p className="color-red small text-center">
          {formError || 'Something went wrong, please try again'}
          <Clickable
            className="mt_5"
            onClick={onClickResend}
            variant={ClickableVariant.LINK}
          >
            Request a new code.
          </Clickable>
        </p>
      )}
      <Clickable variant={ClickableVariant.LINK} to="/">
        Back to Sign In
      </Clickable>
    </Form>
  );
}
