import React, { ReactNode } from 'react';
import Dropzone, { FileRejection, DropEvent, Accept } from 'react-dropzone';
import { c } from '../../utils';
import { EmptyState } from './EmptyState';
import { FilledState } from './FilledState';
import classes from './DroppableUploader.module.scss';

type Props = {
  className?: string;
  emptyClassName?: string;
  error?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  label: string;
  maxFiles?: number;
  mimeType?: Accept;
  onDrop?: (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => void;
  onRemove?: () => void;
  previewClassName?: string;
  previewImageClassName?: string;
  renderPreview?: boolean;
  wrapperClassName?: string;
  value?: string | string[];
  emptyTitle?: string;
  emptySubtitle?: string | ReactNode;
  emptyPreview?: ReactNode;
};

export function DroppableUploader({
  className,
  emptyClassName,
  error,
  isDisabled,
  isLoading,
  label,
  maxFiles,
  mimeType = { 'image/*': [] },
  onDrop,
  onRemove,
  previewClassName,
  previewImageClassName,
  renderPreview,
  value = '',
  emptyTitle,
  emptySubtitle,
  wrapperClassName,
  emptyPreview,
}: Props) {
  const isFilled = Array.isArray(value) ? value.length > 0 : !!value;
  const isMultiple = !!maxFiles && maxFiles > 1;
  const showEmptyState = !isMultiple || maxFiles !== value.length;

  return (
    <Dropzone
      onDrop={onDrop}
      accept={mimeType}
      disabled={isDisabled}
      maxFiles={maxFiles}
      multiple={isMultiple}
    >
      {({ getRootProps, getInputProps, isDragActive }) => {
        return (
          <div
            className={c(
              classes.DroppableUploader,
              'flex flex-col gap-1 relative overflow-hidden',
              isDisabled ? '' : 'cursor-pointer',
              wrapperClassName,
            )}
          >
            <div className="flex flex-col gap-_5" {...getRootProps()}>
              {showEmptyState && (
                <EmptyState
                  className={emptyClassName}
                  error={error}
                  isDisabled={isDisabled}
                  isDragActive={isDragActive}
                  isLoading={isLoading}
                  title={emptyTitle}
                  subtitle={emptySubtitle}
                />
              )}
              <div className={className}>
                <input disabled={isDisabled} {...getInputProps()} />
                {isFilled &&
                  (Array.isArray(value) ? (
                    value.map((v: string, index: number) => (
                      <FilledState
                        className={previewClassName}
                        error={error}
                        isLoading={isLoading}
                        mimeType={mimeType}
                        label={label}
                        key={`droppable-fllled-state-${index}`}
                        onRemove={onRemove}
                        renderPreview={renderPreview}
                        value={v}
                      />
                    ))
                  ) : (
                    <FilledState
                      className={previewClassName}
                      imageClassName={previewImageClassName}
                      error={error}
                      isLoading={isLoading}
                      mimeType={mimeType}
                      label={label}
                      onRemove={onRemove}
                      renderPreview={renderPreview}
                      value={value}
                    />
                  ))}
                {!isFilled && renderPreview && emptyPreview}
              </div>
            </div>
          </div>
        );
      }}
    </Dropzone>
  );
}
