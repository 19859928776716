import React from 'react';
import { Toolbar } from '../../components/Toolbar';
import { ResourceCard, ResourceCardProps } from './ResourceCard';

const PAGE_TITLE = 'Resources';

const resources: Array<ResourceCardProps> = [
  {
    type: '', //'Video',
    title: 'Overview Video',
    image:
      'https://poormanvr.s3.us-east-2.amazonaws.com/Gatherly_Overview_Video.gif',
    description:
      'Watch our overview video to understand how Gatherly might help you with your next online event.',
    ctaLabel: 'Watch Video',
    ctaLocation:
      'https://www.youtube.com/watch?v=aJcbsGpmPF0&list=PLRL7DgvBi2Un7CcUk9NYJ6Gf1JfLTIrAZ',
  },
  {
    type: '', //'Guide',
    title: 'Map Customization',
    image:
      'https://poormanvr.s3.us-east-2.amazonaws.com/Gatherly_Edit_Floors.gif',
    description:
      'Create your own custom maps with our easy-to-use template! It includes a tutorial video to show you how!',
    ctaLabel: 'Get started',
    ctaLocation:
      'https://gatherlyio-website-assets.s3.us-east-2.amazonaws.com/betterDMV/downloads/Event+Production+Guide.pptx',
  },
  /*
  {
    type: 'Template',
    title: 'Brochure Customization',
    image:
      'https://gatherlyio-website-assets.s3.us-east-2.amazonaws.com/betterDMV/customBrochure.svg',
    description:
      'Not sure how to communicate your event info? Use our template as a starting point.',
    ctaLabel: 'Download Template',
    ctaLocation:
      'https://gatherlyio-website-assets.s3.us-east-2.amazonaws.com/betterDMV/downloads/Gatherly+Brochure+Customization.pptx',
  },
  */
  {
    type: '', //'Support',
    title: 'Help Center',
    image:
      'https://poormanvr.s3.us-east-2.amazonaws.com/Gatherly_Help_Center.gif',
    description:
      'Learn all about Gatherly in our comprehensive guides designed for event hosts like you.',
    ctaLabel: 'View Guides',
    ctaLocation: 'https://www.gatherly.io/our-help-center',
  },
  {
    type: '', //'Videos',
    title: 'Tutorial Playlist',
    image:
      'https://poormanvr.s3.us-east-2.amazonaws.com/Gatherly_Video_Playlist.gif',
    description:
      'This playlist covers all the important features and is great prep for your guests.',
    ctaLabel: 'View Playlist',
    ctaLocation:
      'https://www.youtube.com/watch?v=tPGlIkB6OZE&list=PLRL7DgvBi2Ulg5F9Y-VEklKQssdWq7Rmh',
  },
  /*
  {
    type: 'Tech',
    title: 'Check Compatibility',
    image:
      'https://poormanvr.s3.us-east-2.amazonaws.com/tutorial-user-katara.gif',
    description:
      'Send guests here to check their device and network compatibility with Gatherly.',
    ctaLabel: 'Visit Hub',
    ctaLocation: 'https://check.gatherly.io/',
  },
  */
];

export function ResourcesPage() {
  return (
    <div className="page-inner">
      <Toolbar title={PAGE_TITLE} classNames="bg-frosted" />
      <div className="bg-color-gradient-faded page-inner__content flex-1">
        <div className="flex flex-row flex-wrap gap-2 pt1 pb8">
          {resources.map((resource, index) => (
            <ResourceCard {...resource} key={`resource-${index}`} />
          ))}
        </div>
      </div>
    </div>
  );
}
