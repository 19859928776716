import React, { ReactNode } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

type Props = {
  className?: string;
  children: ReactNode[];
  hasMore: boolean;
  onLoad: (page: number) => void;
  loader?: ReactNode;
};

export function InfiniteScroller({
  className,
  children,
  hasMore,
  loader,
  onLoad,
}: Props) {
  return (
    <InfiniteScroll
      className={className}
      pageStart={0}
      loadMore={onLoad}
      hasMore={hasMore}
      loader={loader}
    >
      {children}
    </InfiniteScroll>
  );
}
