import React from 'react';
import { Clickable, ClickableVariant } from '../Clickable';

export const NewVersionToast = ({
  waiting,
}: {
  waiting: ServiceWorker | null;
}) => {
  const handleLoadNewVersion = () => {
    waiting?.postMessage({ type: 'SKIP_WAITING' });
    window.location.reload();
  };
  return (
    <>
      An new version is available!
      <Clickable
        variant={ClickableVariant.BUTTON_SMALL}
        onClick={handleLoadNewVersion}
        className="color-white bg-color-logo-green"
      >
        Update
      </Clickable>
    </>
  );
};
