import { useEffect } from 'react';
import { getEventTracking } from '../../actions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { eventChatLogs, getStatusOfGetEventTracking } from '../../selectors';
import {
  downloadAsCSV,
  isFulfilled,
  isIdle,
  isPending,
  isRejected,
} from '../../utils';
import { Clickable, ClickableVariant } from '../Clickable';
import { Status } from '../Status';

type Props = {
  eventId: string;
  eventName: string;
};

export const PublicChatReport = ({ eventId, eventName }: Props) => {
  const chatLogs = useAppSelector(eventChatLogs(eventId));
  const status = useAppSelector(getStatusOfGetEventTracking(eventId));

  const dispatch = useAppDispatch();
  useEffect(() => {
    getEventTracking(dispatch, eventId);
  }, [eventId]);

  const loaded = isFulfilled(status);
  const isLoading =
    chatLogs.length === 0 && (isPending(status) || isIdle(status));
  const failed = chatLogs.length === 0 && isRejected(status);

  if (isLoading) {
    return (
      <div>
        <Status message="Loading..." />
      </div>
    );
  }

  if (failed) {
    return <Status message="Unable to load attendees' chat." />;
  }

  return (
    <>
      {loaded && (
        <div className="heading bold color-shade-80 flex justify-between gap-1">
          {chatLogs.length}
          <Clickable
            label="Download chat messages"
            variant={ClickableVariant.BUTTON_TERTIARY}
            onClick={() =>
              downloadAsCSV(`${eventName || ''} chat logs`, chatLogs)
            }
          />
        </div>
      )}
    </>
  );
};
