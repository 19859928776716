import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  SyntheticEvent,
} from 'react';
import { LinkConfig } from '@gatherly/types';

import { Avatar, Chip, classnames, Switch } from '@gatherly/lib';
import { setIdle, createEventLink, updateEventLink } from '../../actions';
import {
  AvatarColors,
  DEFAULT_AVATAR_COLOR,
  DEFAULT_LINK_NAMES,
} from '../../config';
import { Clickable, ClickableVariant } from '../Clickable';
import { Input } from '../Input';
import { Multiselect } from '../Multiselect';
import { Select } from '../Select';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { eventById, getStatusOfEditEventLink } from '../../selectors';
import {
  getFormField,
  getOnChangeFormField,
  hasValidationError,
  isPending,
  isRejected,
  isFulfilled,
  noValidate,
  validateUniqueString,
} from '../../utils';
import { AdminTooltip } from '../EventLinkList/AdminTooltip';
import { DEFAULT_AVATAR } from '../../images';
import { Form } from './Form';

import classes from './EditLinkForm.module.scss';
import { trackLinkCreated } from '../../libs/trackingLib';

const getAvatarColorOptions = (linkName: string) => {
  return AvatarColors.map(({ label, ...colorOption }, index) => {
    return {
      ...colorOption,
      label: (
        <div
          className={classnames(
            classes.colorOption,
            'flex justify-between',
            !index && classes.firstColorOption,
          )}
        >
          {label}
          <div className="flex items-center">
            <Avatar
              avatarUrl={DEFAULT_AVATAR.src}
              name={DEFAULT_AVATAR.alt}
              borderColor={colorOption.value}
              size={22}
            />
            <Chip className="ml1" color={colorOption.value}>
              {linkName}
            </Chip>
          </div>
        </div>
      ),
    };
  });
};

type Props = {
  eventId: string;
  linkConfig?: LinkConfig;
  onCancel: () => void;
};

export function EventLinkForm({ eventId, onCancel, linkConfig }: Props) {
  const dispatch = useAppDispatch();
  const event = useAppSelector(eventById(eventId));
  const status = useAppSelector(getStatusOfEditEventLink);
  const isMakingRequest = isPending(status);
  const hasError = isRejected(status);
  const [formError, setFormError] = useState('');

  useEffect(() => {
    setIdle(dispatch, 'editEventLink');
  }, []);

  useEffect(() => {
    if (isFulfilled(status)) onCancel();
  }, [status]);

  const floorOptionsArray = useMemo(() => {
    const floors = event?.config?.floors;
    if (!Array.isArray(floors)) return [];
    return floors.map((floor, index) => ({
      id: floor.id,
      label: floor.name,
      value: index,
    }));
  }, [event?.config?.floors]);
  const floorOptionsLabels = useMemo(() => {
    const floors = event?.config?.floors;
    if (!Array.isArray(floors)) return [];
    return floors.reduce((memo, floor, index) => {
      return {
        ...memo,
        [index]: floor.name,
      };
    }, {});
  }, [event?.config?.floors]);
  const linkNameBlacklist = useMemo(() => {
    if (!event?.links) return DEFAULT_LINK_NAMES;
    const linkNames = [...DEFAULT_LINK_NAMES, ...Object.keys(event.links)];
    return linkNames.filter(
      linkName => linkName && linkName !== linkConfig?.name,
    );
  }, [event?.links, linkConfig?.name]);

  const [linkName, setLinkName] = useState(
    getFormField(linkConfig?.name || ''),
  );
  const [color, setColor] = useState(
    getFormField(linkConfig?.color || DEFAULT_AVATAR_COLOR),
  );
  const [spawnFloor, setSpawnFloor] = useState(
    getFormField(linkConfig?.spawnFloor || 0),
  );
  const [allowedFloors, setAllowedFloors] = useState(
    getFormField(linkConfig?.allowedFloors || ([] as number[])),
  );
  const [broadcast, setBroadcast] = useState(!!linkConfig?.broadcast);

  const onChangeLinkName = useCallback(
    getOnChangeFormField(
      value => validateUniqueString(value, 'Link name', linkNameBlacklist),
      setLinkName,
    ),
    [linkNameBlacklist],
  );
  const onChangeColor = useCallback(
    getOnChangeFormField(value => noValidate(value), setColor),
    [],
  );
  const onChangeSpawnFloor = useCallback(
    getOnChangeFormField(value => noValidate(value), setSpawnFloor),
    [],
  );
  const onChangeAllowedFloors = useCallback(
    getOnChangeFormField(value => noValidate(value), setAllowedFloors),
    [],
  );

  const handleSubmit = useCallback(
    async (evt: SyntheticEvent) => {
      evt.preventDefault();
      const _linkName = onChangeLinkName(linkName.value);

      if (hasValidationError(_linkName)) {
        setFormError('Please check all form fields to continue');
        return;
      }

      if (!event) {
        setFormError('Error: Event not found. Please refresh and try again.');
        return;
      }
      const numberOfFloors = event.config.floors.length;
      const link = {
        allowedFloors:
          allowedFloors.value.length > 0 &&
          allowedFloors.value.length < numberOfFloors
            ? allowedFloors.value
            : null,
        broadcast,
        color: color.value,
        encryptedUuid: linkConfig?.encryptedUuid || '',
        name: _linkName.value,
        spawnFloor: spawnFloor.value,
        maIM: '',
      };
      if (linkConfig) {
        updateEventLink(dispatch, event, linkConfig.name, link);
      } else {
        createEventLink(dispatch, event, link).then(() =>
          trackLinkCreated({
            color: link.color,
            ...(link.allowedFloors && {
              customAllowedFloors: link.allowedFloors,
            }),
            ...(link.spawnFloor !== 0 && {
              customStartingFloor: link.spawnFloor,
            }),
            admin: link.broadcast,
          }),
        );
      }
    },
    [
      linkConfig?.name,
      linkConfig?.encryptedUuid,
      eventId,
      event?.links,
      event?.config.floors,
      linkName.value,
      color.value,
      broadcast,
      allowedFloors.value,
      spawnFloor.value,
    ],
  );
  return (
    <Form className="flex flex-col gap-1" onSubmit={handleSubmit}>
      <div className="flex flex-col md:flex-row gap-1">
        <Input
          className="md:col-6"
          label="Link Name"
          name="linkName"
          onChange={evt => onChangeLinkName(evt.target.value)}
          value={linkName.value}
          error={linkName.error}
          isDisabled={isMakingRequest}
          isRequired
        />
        <Select
          className={classnames(classes.colorOptions, 'md:col-6')}
          label="Avatar Border and Label Color*"
          name="color"
          onChange={evt => onChangeColor(evt.target.value)}
          options={getAvatarColorOptions(linkName.value || 'Link name')}
          error={color.error}
          value={color.value}
          isDisabled={isMakingRequest}
          isRequired
        />
      </div>
      <div className="flex flex-row justify-between items-center">
        <p className="subbody color-shade-80">Admin and broadcast privileges</p>

        <Switch
          onChange={setBroadcast}
          options={[
            {
              id: 'enable-broadcast',
              label: 'Yes',
              value: true,
            },
            {
              id: 'disable-broadcast',
              label: 'No',
              value: false,
            },
          ]}
          value={broadcast}
        />
      </div>
      <AdminTooltip />
      <p className="detail bold">Customize Access</p>
      <div className="flex flex-col md:flex-row gap-1">
        <Select
          className="md:col-6"
          label="Starting Floor"
          name="spawnFloor"
          onChange={evt => onChangeSpawnFloor(+evt.target.value)}
          options={floorOptionsArray}
          error={spawnFloor.error}
          value={spawnFloor.value}
          isDisabled={isMakingRequest}
          isRequired
        />
      </div>
      <Multiselect
        label="Allowed Floors"
        placeholder="Select allowed floors"
        name="allowedFloors"
        onChange={evt => onChangeAllowedFloors(evt.target.value as number[])}
        options={floorOptionsArray}
        optionLabels={floorOptionsLabels}
        value={allowedFloors.value}
        error={allowedFloors.error}
        helpText="Event attendees who join using this URL will be limited to only the floors that you select. They cannot get access to other floors unless given a new link. Only seeing one floor? Add more in the Floors tab."
      />

      <div className="flex flex-row flex-1 justify-center items-center gap-1 mt2">
        <Clickable
          isSubmit
          isDisabled={isMakingRequest}
          variant={ClickableVariant.BUTTON_SECONDARY}
          label={isMakingRequest ? 'Saving...' : 'Save'}
        />
        <Clickable
          onClick={onCancel}
          variant={ClickableVariant.BUTTON_TERTIARY}
        >
          Cancel
        </Clickable>
      </div>
      {(hasError || formError) && (
        <p className="detail text-center color-red">
          {formError ||
            `Sorry, we were unable to save your link. Please check all fields and try again.`}
        </p>
      )}
    </Form>
  );
}
