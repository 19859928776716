import { useCallback, useEffect, useState } from 'react';
import { downloadAsCSV, pluralize } from '../../utils';
import { Clickable, ClickableVariant } from '../Clickable';
import { Input } from '../Input';
import { PaginatedList } from '../PaginatedList';
import { Status } from '../Status';

type Props = {
  failedToLoad: boolean;
  loading: boolean;
  loaded: boolean;
  data: any;
  filterField: string;
  eventName?: string;
  columns: {
    key: string;
    label: string;
  }[];
  title: string;
};

export const TrackingTable = ({
  failedToLoad,
  columns,
  loading,
  loaded,
  data,
  eventName,
  title,
  filterField = 'name',
}: Props) => {
  const [query, setQuery] = useState('');
  const [filteredResults, setFilteredResults] = useState<Array<any>>(data);
  const onChangeQuery = useCallback(
    evt => {
      const value = evt.target?.value || '';
      setQuery(value);
    },
    [data, filterField],
  );

  useEffect(() => {
    const _filteredResults = data.filter(element => {
      return element[filterField]?.toLowerCase().includes(query.toLowerCase());
    });
    setFilteredResults(_filteredResults);
  }, [data, query]);

  const onClearQuery = useCallback(() => {
    setQuery('');
    setFilteredResults(data);
  }, [data]);

  return (
    <div className="flex flex-col gap-1">
      <h2 className="heading bold mb1">{title}</h2>
      {failedToLoad && <Status message="Unable to load attendee tracking." />}
      {loading && <Status message="Loading..." />}
      {loaded && (
        <>
          <div className="flex flex-col md:flex-row gap-1">
            <Input
              label="Search by name"
              name="query"
              onChange={onChangeQuery}
              placeholder="Type an attendee's name to find them"
              className="flex-1"
              value={query}
            />
            <Clickable
              label="Clear Search"
              variant={ClickableVariant.BUTTON_TERTIARY}
              onClick={onClearQuery}
            />
            <Clickable
              label="Download CSV"
              variant={ClickableVariant.BUTTON_TERTIARY}
              onClick={() =>
                downloadAsCSV(`${eventName || ''} attendees`, filteredResults)
              }
            />
          </div>
          <div className="scroll-y">
            <p className="color-shade-70 detail mb2">
              {pluralize(filteredResults.length, 'result')}
            </p>
            <PaginatedList
              columns={columns}
              items={filteredResults}
              perPage={30}
            />
          </div>
        </>
      )}
    </div>
  );
};
