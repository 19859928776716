import React, { useEffect } from 'react';

import { getEventTracking } from '../../actions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  eventById,
  eventTracking,
  getStatusOfGetEventTracking,
} from '../../selectors';
import { isPending, isFulfilled, isRejected } from '../../utils';
import { TrackingTable } from './TrackingTable';

type Props = {
  eventId: string;
};

const BASE_COLUMNS = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'title',
    label: 'Job Title',
  },
  {
    key: 'link',
    label: 'Link',
  },
  {
    key: 'timeSpent',
    label: 'Duration',
  },
  {
    key: 'numHuddles',
    label: 'Conversations',
  },
  {
    key: 'numPeopleTalkedTo',
    label: 'People Met',
  },
];
const EMAIL_ENABLED_COLUMNS = BASE_COLUMNS.slice(0, 2)
  .concat({
    key: 'email',
    label: 'Email',
  })
  .concat(BASE_COLUMNS.slice(2, BASE_COLUMNS.length));

export function TrackingReport({ eventId }: Props) {
  const event = useAppSelector(eventById(eventId));
  const tracking = useAppSelector(eventTracking(eventId));
  const status = useAppSelector(getStatusOfGetEventTracking(eventId));

  const dispatch = useAppDispatch();
  useEffect(() => {
    getEventTracking(dispatch, eventId);
  }, [eventId]);

  const emailEnabled = event?.config.emailSettings !== 'disabled';

  return (
    <TrackingTable
      columns={emailEnabled ? EMAIL_ENABLED_COLUMNS : BASE_COLUMNS}
      failedToLoad={!tracking.length && isRejected(status)}
      filterField={'name'}
      loaded={isFulfilled(status)}
      eventName={event?.name}
      loading={!tracking.length && isPending(status)}
      data={tracking}
      title={'Attendee Tracking'}
    />
  );
}
