import React from 'react';
import { Provider } from 'react-redux';
import { Elements as StripeElements } from '@stripe/react-stripe-js';

import { ThemeProvider } from '@material-ui/core';
import { ToastProvider } from './components/Toast';
import { store } from './store';
import { stripePromise } from './config';
import { materialUITheme } from './styles';

function AppProvider({ children }) {
  return (
    <Provider store={store}>
      <StripeElements stripe={stripePromise}>
        <ThemeProvider theme={materialUITheme}>
          <ToastProvider>{children}</ToastProvider>
        </ThemeProvider>
      </StripeElements>
    </Provider>
  );
}

export default AppProvider;
