import React from 'react';
import { c } from '../../utils';
import { Clickable, ClickableVariant } from '../Clickable';

type Props = {
  editLabel: string;
  enableEditing: () => void;
  isEditing: boolean;
  onCancel: () => void;
  onDelete: () => void;
  selectedCount: number;
};
export function BulkEdit({
  editLabel = 'Bulk Edit',
  enableEditing,
  isEditing,
  onCancel,
  onDelete,
  selectedCount,
}: Props) {
  const isDisabled = !selectedCount;

  return (
    <div className="none md:flex flex-row items-center gap-1">
      {isEditing ? (
        <>
          <span>{selectedCount} Selected</span>
          <Clickable
            onClick={onCancel}
            variant={ClickableVariant.BUTTON_TERTIARY}
            className="detail"
          >
            Cancel
          </Clickable>
          <Clickable
            isDisabled={isDisabled}
            onClick={onDelete}
            variant={ClickableVariant.BUTTON_TERTIARY}
            className={c('detail', !isDisabled && 'color-red border-color-red')}
          >
            Delete
          </Clickable>
        </>
      ) : (
        <Clickable
          onClick={enableEditing}
          variant={ClickableVariant.BUTTON_TERTIARY}
          className="detail"
        >
          {editLabel}
        </Clickable>
      )}
    </div>
  );
}
