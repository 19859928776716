import humanizeDuration from 'humanize-duration';
import { MessageWithIllustration } from '../MessageWithIllustration';
import { FOUR_ATTENDEES, INACTIVE_ANALYTICS } from '../../images';

type ReportNotReadyProps = {
  inProgress: boolean;
  readyTime: number;
};

const messages = {
  inProgress: () => ({
    heading: 'Event in Progress',
    message:
      'We are still collecting data from your event. Once your event ends, you can view attendance and engagement metrics here.',
    imgSrc: FOUR_ATTENDEES.src,
    imgAlt: FOUR_ATTENDEES.alt,
  }),
  stillProcessing: (readyTime: number) => ({
    heading: 'Still Processing...',
    message: `We are still processing data collected from your event. Check back here in ${humanizeDuration(
      Date.now() - readyTime,
      {
        round: true,
        units: ['h', 'm'],
      },
    )} to view attendance and engagement metrics here.`,
    imgSrc: INACTIVE_ANALYTICS.src,
    imgAlt: 'Still processing event data',
  }),
};

export function ReportNotReady({ inProgress, readyTime }: ReportNotReadyProps) {
  const messageProps = inProgress
    ? messages.inProgress()
    : messages.stillProcessing(readyTime);
  return <MessageWithIllustration {...messageProps} />;
}
