import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import { SECOND } from '../../config';
import { c } from '../../utils';
import { ClickableVariant } from '../Clickable';
import { Tooltip } from '../Tooltip';

import classes from '../Clickable/Clickable.module.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  value?: string;
  children: ReactNode;
  variant?: ClickableVariant;
  copiedContent?: ReactNode;
}

export function Copyable(props: Props) {
  const [didCopy, setDidCopy] = useState(false);

  const onClick = useCallback(() => {
    if (!props.value) {
      console.warn('unable to copy empty value');
      return;
    }
    copy(props.value);
    setDidCopy(true);
  }, [props.value]);
  const onMouseEnter = useCallback(() => {
    setDidCopy(false);
  }, [setDidCopy]);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (didCopy) {
      timer = setTimeout(() => {
        setDidCopy(false);
      }, SECOND);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [didCopy]);

  const { children, className, copiedContent, variant } = props;

  const content = (
    <div
      className={c(
        'cursor-pointer',
        className,
        variant === ClickableVariant.BUTTON_PRIMARY && classes.buttonPrimary,
        variant === ClickableVariant.BUTTON_SECONDARY &&
          classes.buttonSecondary,
        variant === ClickableVariant.BUTTON_TERTIARY && classes.buttonTertiary,
        variant === ClickableVariant.BUTTON_SMALL && classes.buttonSmall,
        variant === ClickableVariant.BUTTON_GRID && classes.buttonGrid,
        variant === ClickableVariant.BUTTON_GRID_SM && classes.buttonGridSmall,
        variant === ClickableVariant.LINK && classes.link,
      )}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      {didCopy && copiedContent ? copiedContent : children}
    </div>
  );

  return copiedContent ? (
    content
  ) : (
    <Tooltip content={didCopy ? 'copied!' : 'copy'}>{content}</Tooltip>
  );
}
