import { classnames } from '@gatherly/lib';
import { Pie } from 'react-chartjs-2';
import { LOGO_BLUE, LOGO_GREEN } from '../../config';

type Props = {
  className?: string;
  data: number[];
  label?: string;
  labels?: string[];
  title?: string;
};

export function PieChart({ className, title, labels, label, data }: Props) {
  return (
    <div className={classnames(className, 'flex flex-1 flex-col gap-1')}>
      {title && <p className="subheading">{title}</p>}
      <Pie
        data={{
          labels: labels,
          datasets: [
            {
              label,
              backgroundColor: [LOGO_BLUE, LOGO_GREEN],
              borderColor: [LOGO_BLUE, LOGO_GREEN],
              borderWidth: 1,
              data,
            },
          ],
        }}
      />
    </div>
  );
}
