import { useAppSelector } from '../../hooks';
import { eventAnalytics, eventById } from '../../selectors';

import { classnames } from '@gatherly/lib';
import classes from './AnalyticsReport.module.scss';
import { AnalyticsOverview } from './AnalyticsOverview';
import { AnalyticsEngagement } from './AnalyticsEngagement';

type Props = {
  eventId: string;
};

export function AnalyticsReport({ eventId }: Props) {
  const event = useAppSelector(eventById(eventId));

  const analytics = useAppSelector(eventAnalytics(eventId));

  return (
    <div className={classnames(classes.AnalyticsReport, 'flex flex-col gap-2')}>
      {event && (
        <>
          <AnalyticsOverview
            analytics={analytics}
            eventStartTime={event.startTime}
            eventStopTime={event.stopTime}
          />
          <AnalyticsEngagement
            analytics={analytics}
            eventName={event.name}
            eventId={event.eventId}
          />
        </>
      )}
    </div>
  );
}
