import { Backdrop, classnames } from '@gatherly/lib';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useEffect, useState } from 'react';

import classes from './NewEventForm.module.scss';

const ESTIMATED_DURATION = 45;

interface Props {
  isManualSetup: boolean;
  isMakingRequest: boolean;
}

export const NewAIEventProgressIndicator = ({
  isMakingRequest,
  isManualSetup,
}: Props) => {
  const [progressTimer, setProgressTimer] = useState<NodeJS.Timer>();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (isManualSetup || !isMakingRequest) {
      clearInterval(progressTimer);
      setProgressTimer(undefined);
      setProgress(0);
      return;
    }

    if (progressTimer) return;

    const timer = setInterval(() => {
      setProgress(prevProgress =>
        prevProgress >= 95 ? 95 : prevProgress + (1 / ESTIMATED_DURATION) * 100,
      );
    }, 1000);
    setProgressTimer(timer);

    return () => {
      clearInterval(progressTimer);
    };
  }, [isMakingRequest, isManualSetup, progressTimer]);

  if (!isMakingRequest || isManualSetup) return null;

  return (
    <Backdrop
      className={classnames(
        'flex flex-col items-center justify-center',
        classes.backdrop,
      )}
    >
      <LinearProgress
        className={classes.progressIndicator}
        classes={{
          bar1Determinate: classes.progressIndicatorBar,
        }}
        variant="determinate"
        value={progress}
      />
      <p className="bold body">Loading...</p>
      <p>Please do not close or refresh this tab;</p>
      <p>the AI is hard at work! 🤖</p>
    </Backdrop>
  );
};
