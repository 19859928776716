type Handler<T, A> = (state: T, action: A) => T;
export function createReducer<T, A, AT extends string>(
  initialState: T,
  handlers: Record<AT, Handler<T, A>>,
) {
  return function reducer(state = initialState, action: any): T {
    if (handlers.hasOwnProperty(action.type)) {
      const type: keyof typeof handlers = action.type;
      return handlers[type](state, action);
    } else {
      return state;
    }
  };
}
