import { classnames } from '@gatherly/lib';
import { useCallback } from 'react';
import { Delete } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { IGatherlyEvent } from '@gatherly/types';
import { uploadLogo, removeLogo, handleUploadError } from '../../../actions';
import { DroppableUploader } from '../../DroppableUploader';
import { useAppDispatch } from '../../../hooks';
import { Image } from '../../Image';
import { BrandingTooltip } from '../../BrandingTooltip';
import classes from './EventLogoForm.module.scss';

type EventLogoFormProps = {
  event: IGatherlyEvent;
  logoUrl: string;
  isDisabled: boolean;
};
export function EventLogoForm({
  logoUrl,
  isDisabled,
  event,
}: EventLogoFormProps) {
  const dispatch = useAppDispatch();

  const handleChangeLogo = useCallback(
    async acceptedFiles => {
      const file = acceptedFiles[0];
      const response = await uploadLogo(dispatch, event, file);
      handleUploadError(response, 'logo', 'upload');
    },
    [event],
  );
  const handleRemoveLogo = useCallback(async () => {
    const response = await removeLogo(dispatch, event);
    handleUploadError(response, 'logo', 'delete');
  }, [event]);

  return (
    <>
      <div>
        <div className="mt1 body bold relative">
          <BrandingTooltip className={classes.brandingTooltip}>
            Event Logo
          </BrandingTooltip>
        </div>

        <p className="mt_25">
          <span className="bold">Recommended size:</span> Logos should be 32px
          high and no larger than 160px wide
        </p>
      </div>

      <div className="flex flex-col m d:flex-row gap-1 relative">
        <DroppableUploader
          wrapperClassName={classes.logoUploadContainer}
          emptyClassName={classnames(classes.logoEmpty)}
          isLoading={false}
          label="Branding"
          onDrop={handleChangeLogo}
          previewClassName={classnames(
            classes.logoUploadContainer,
            'flex flex-col justify-center',
          )}
          value={''}
          isDisabled={isDisabled}
          renderPreview
        />
        {!!logoUrl && (
          <div className="flex">
            <Image
              src={logoUrl}
              alt={'event logo'}
              skipPreload={true}
              className={classes.logoDisplay}
            />
            <IconButton
              disabled={isDisabled}
              aria-label="delete"
              onClick={handleRemoveLogo}
            >
              <Delete className="shade-70" />
            </IconButton>
          </div>
        )}
      </div>
    </>
  );
}
