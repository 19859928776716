import { classnames } from '@gatherly/lib';
import { signInWithGoogle } from '../../utils/auth';
import { Clickable, ClickableVariant } from '../Clickable';
import { GoogleLogo } from './GoogleLogo';

interface Props {
  className?: string;
  label?: string;
}

export const GoogleSignIn = ({ className, label }: Props) => {
  return (
    <Clickable
      variant={ClickableVariant.BUTTON_SECONDARY}
      className={classnames(
        'bg-color-white color-shade-70 border-1',
        className,
      )}
      onClick={() => signInWithGoogle()}
    >
      <GoogleLogo className="mr1" />
      {label || 'Continue with Google'}
    </Clickable>
  );
};
