import axios from 'axios';
import { getS3UrlForFile } from '..';
import { apiRoutes } from '../../config';

import http from './client';

export async function uploadFile(
  file: File,
  fileName: string,
): Promise<string> {
  const presignedUrlResponse = await http.get(
    `${apiRoutes.upload}?name=${fileName}`,
  );
  const { url, uniqueFilename } = presignedUrlResponse.data;
  if (!url || !uniqueFilename)
    throw new Error('Failed to get signed upload url');

  await axios.put(url, file, {
    headers: {
      'Content-Type': file.type,
    },
  });

  return getS3UrlForFile(uniqueFilename);
}
export async function deleteFile(key: string): Promise<void> {
  const params = { key };
  return http.delete(apiRoutes.upload, { params });
}

export async function downloadFile(
  url: string,
  fileName: string,
): Promise<any> {
  const response = await axios.get(url, { responseType: 'blob' });
  const href = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName); //or any other extension
  document.body.appendChild(link);
  link.click();
}
