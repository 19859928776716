import React, { useEffect } from 'react';
import classes from './StripePricingTable.module.scss';

const StripePricingTable: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Clean up the script on unmount
    };
  }, []);

  return (
    <div className={classes.StripePricingTable}>
      <h3 className="color-shade-80 subheading bold">Pricing Options</h3>
      <div id="stripe-pricing-table-container">
        <stripe-pricing-table
          pricing-table-id="prctbl_1QHz0ECHRbgfmXnmBeHFfZ2j"
          publishable-key="pk_live_51PBjIzCHRbgfmXnmc3K4w61sD2gADY9D40BM64j8G3gqunf0HxJJQwMIBpvQzOD9K67wIT48JXGC3mjgQuisdYAo00nrJACdKa"
        ></stripe-pricing-table>
      </div>
    </div>
  );
};

export default StripePricingTable;
