import { PromiseStatus } from '../enums';

export function isIdle(status: PromiseStatus) {
  return status === PromiseStatus.IDLE;
}
export function isPending(status: PromiseStatus) {
  return status === PromiseStatus.PENDING;
}
export function isFulfilled(status: PromiseStatus) {
  return status === PromiseStatus.FULFILLED;
}
export function isRejected(status: PromiseStatus) {
  return status === PromiseStatus.REJECTED;
}
