import { PromiseStatus } from '../enums';
import { createReducer } from './createReducer';

export interface StatusState {
  createEvent: PromiseStatus;
  createFloor: PromiseStatus;
  deleteEvent: PromiseStatus;
  deleteFloor: Record<string, PromiseStatus>;
  downloadBroadcastRecording: Record<string, PromiseStatus>;
  editEventLink: PromiseStatus;
  getEvent: Record<string, PromiseStatus>;
  getEventBroadcastRecordings: Record<string, PromiseStatus>;
  getEventTracking: Record<string, PromiseStatus>;
  getPermissions: PromiseStatus;
  getUpcomingEvents: PromiseStatus;
  getUser: PromiseStatus;
  requestResetPassword: PromiseStatus;
  resendVerification: PromiseStatus;
  resetPassword: PromiseStatus;
  signIn: PromiseStatus;
  signUp: PromiseStatus;
  updateEvent: Record<string, PromiseStatus>;
  updateFloor: Record<string, PromiseStatus>;
  updateUser: PromiseStatus;
  uploadBanner: PromiseStatus;
  uploadBrochure: Record<string, PromiseStatus>;
  uploadBoothLogo: PromiseStatus;
  uploadLogo: PromiseStatus;
  uploadMap: Record<string, PromiseStatus>;
  verifyEmail: PromiseStatus;
}

enum StatusActionType {
  SET_STATUS = '@status/SET_STATUS',
  SET_STATUS_FOR_LIST = '@status/SET_STATUS_FOR_LIST',
}

type StatusAction = {
  type: StatusActionType;
  title: keyof StatusState;
  status: PromiseStatus;
};
type SetStatusForListAction = {
  type: StatusActionType.SET_STATUS_FOR_LIST;
  title:
    | 'deleteFloor'
    | 'downloadBroadcastRecording'
    | 'getEvent'
    | 'getEventBroadcastRecordings'
    | 'getEventTracking'
    | 'updateEvent'
    | 'updateFloor'
    | 'uploadBrochure'
    | 'uploadMap';
  status: PromiseStatus;
  key: string;
};

const initialState: StatusState = {
  createEvent: PromiseStatus.IDLE,
  createFloor: PromiseStatus.IDLE,
  deleteEvent: PromiseStatus.IDLE,
  downloadBroadcastRecording: {},
  deleteFloor: {},
  editEventLink: PromiseStatus.IDLE,
  getEvent: {},
  getEventBroadcastRecordings: {},
  getEventTracking: {},
  getPermissions: PromiseStatus.IDLE,
  getUpcomingEvents: PromiseStatus.IDLE,
  getUser: PromiseStatus.IDLE,
  requestResetPassword: PromiseStatus.IDLE,
  resendVerification: PromiseStatus.IDLE,
  resetPassword: PromiseStatus.IDLE,
  signIn: PromiseStatus.IDLE,
  signUp: PromiseStatus.IDLE,
  updateEvent: {},
  updateFloor: {},
  updateUser: PromiseStatus.IDLE,
  uploadBanner: PromiseStatus.IDLE,
  uploadBrochure: {},
  uploadBoothLogo: PromiseStatus.IDLE,
  uploadLogo: PromiseStatus.IDLE,
  uploadMap: {},
  verifyEmail: PromiseStatus.IDLE,
};

const setStatus = (state: StatusState, action: StatusAction): StatusState => {
  const title = action.title;
  return {
    ...state,
    [title]: action.status,
  };
};
const setStatusForList = (
  state: StatusState,
  action: SetStatusForListAction,
): StatusState => {
  const title = action.title;
  const list = state[title] || {};
  return {
    ...state,
    [title]: {
      ...list,
      [action.key]: action.status,
    },
  };
};
export const statusReducer = createReducer<StatusState, any, StatusActionType>(
  initialState,
  {
    [StatusActionType.SET_STATUS]: setStatus,
    [StatusActionType.SET_STATUS_FOR_LIST]: setStatusForList,
  },
);

// action creators
export function makeSetStatus(title: keyof StatusState, status: PromiseStatus) {
  return {
    type: StatusActionType.SET_STATUS,
    title,
    status,
  };
}

export function makeSetStatusForList(
  title: string,
  status: PromiseStatus,
  key: string,
) {
  return {
    type: StatusActionType.SET_STATUS_FOR_LIST,
    title,
    status,
    key,
  };
}
