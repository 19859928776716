import { createSelector } from '@reduxjs/toolkit';
import { toListMap } from '../utils';
import { eventById } from './eventSelectors';

export const getEventFloors = (eventId = '') =>
  createSelector(eventById(eventId), event => {
    return event?.config?.floors || [];
  });

export const floorsListMap = (eventId = '') =>
  createSelector(getEventFloors(eventId), floors => toListMap(floors, 'id'));

export const getFloorById = (eventId = '', floorId = '') =>
  createSelector(
    getEventFloors(eventId),
    floors => floors.find(floor => floor.id === floorId) || null,
  );

export const getFloorBrochureUrl = (eventId: string, floorId: string) =>
  createSelector(
    getFloorById(eventId, floorId),
    floor => floor?.brochureUrl || '',
  );

export const numberOfFloors = (eventId: string) =>
  createSelector(getEventFloors(eventId), floors => floors.length);
