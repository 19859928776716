import { createReducer } from './createReducer';

export enum OverlayType {
  BANNER_PICKER = 'BANNER_PICKER',
  BACKGROUND_PICKER = 'BACKGROUND_PICKER',
  MEETING_AREA_EDITOR = 'MEETING_AREA_EDITOR',
  BROADCAST_RECORDING_PLAYER = 'BROADCAST_RECORDING_PLAYER',
  BROWSE_PLANS = 'BROWSE_PLANS',
  DELETE_EVENT = 'DELETE_EVENT',
  DELETE_FLOOR = 'DELETE_FLOOR',
  DELETE_LINK = 'DELETE_LINK',
  DUPLICATE_FLOOR = 'DUPLICATE_FLOOR',
  EDIT_FLOOR = 'EDIT_FLOOR',
  EDIT_LINK = 'EDIT_LINK',
  MAP_PICKER = 'MAP_PICKER',
  NEW_EVENT = 'NEW_EVENT',
  NEW_FLOOR = 'NEW_FLOOR',
  NEW_LINK = 'NEW_LINK',
  NOTIFICATION = 'NOTIFICATION',
  ONBOARDING = 'ONBOARDING',
  PURCHASE_TICKETS = 'PURCHASE_TICKETS',
  PREVIEW_EVENT = 'PREVIEW_EVENT',
  REMOVE_LOGOS = 'REMOVE_LOGOS',
  SHARE_LINK = 'SHARE_LINK',
  USER_DETAILS = 'USER_DETAILS',
  AI_EVENT_CREATED_CONFIRMATION = 'AI_EVENT_CREATED_CONFIRMATION',
  ADD_SESSION = 'ADD_SESSION',
  EDIT_BOOTH = 'EDIT_BOOTH',
  CONFIRM_SWITCH_TAB = 'CONFIRM_SWITCH_TAB',
}

export type OverlayConfig = {
  id: string;
  overlayType: OverlayType;
  data: any;
};

interface UIState {
  overlays: OverlayConfig[];
  isActiveBulkEdit: boolean;
}

enum UIActionType {
  CLEAR_OVERLAY = '@ui/CLEAR_OVERLAY',
  SET_OVERLAY = '@ui/SET_OVERLAY',
  SET_BULK_EDIT = '@ui/SET_BULK_EDIT',
}

type UIClearOverlayAction = {
  type: UIActionType.CLEAR_OVERLAY;
  payload: string;
};

type UISetOverlayAction = {
  type: UIActionType.SET_OVERLAY;
  payload: OverlayConfig;
};

type UISetBulkEditAction = {
  type: UIActionType.SET_BULK_EDIT;
  payload: boolean;
};

type UIAction = UISetOverlayAction & UISetBulkEditAction;

const initialState: UIState = {
  overlays: [],
  isActiveBulkEdit: false,
};

export const uiReducer = createReducer<UIState, UIAction, UIActionType>(
  initialState,
  {
    [UIActionType.CLEAR_OVERLAY]: (
      state: UIState,
      action: UIClearOverlayAction,
    ) => {
      return {
        ...state,
        overlays: state.overlays.filter(
          overlay => overlay.id !== action.payload,
        ),
      };
    },
    [UIActionType.SET_OVERLAY]: (
      state: UIState,
      action: UISetOverlayAction,
    ) => {
      return {
        ...state,
        overlays: [...state.overlays, action.payload],
      };
    },
    [UIActionType.SET_BULK_EDIT]: (
      state: UIState,
      action: UISetBulkEditAction,
    ) => {
      return {
        ...state,
        isActiveBulkEdit: action.payload,
      };
    },
  },
);

// action creators
export function makeClearOverlayAction(payload: string) {
  return {
    type: UIActionType.CLEAR_OVERLAY,
    payload,
  };
}
export function makeSetOverlayAction(payload: OverlayConfig) {
  return {
    type: UIActionType.SET_OVERLAY,
    payload,
  };
}
export function makeSetBulkEditAction(payload: boolean) {
  return {
    type: UIActionType.SET_BULK_EDIT,
    payload,
  };
}
