import React from 'react';
import { AclPermissionsRole } from '@eventmanager/types';

import { currency } from '../../utils';

interface Props {
  className?: string;
  monthlyFee: number;
  planName: AclPermissionsRole;
  ticketCost: number;
}

function getPrice({ monthlyFee, ticketCost, planName }) {
  if (planName === 'free') {
    return 'Free';
  }
  if (planName === 'enterprise') {
    return 'Custom';
  }

  const priceUnit = !!monthlyFee ? 'monthly' : 'ticket';

  return `${currency(monthlyFee || ticketCost)}/${priceUnit}`;
}

export function PlanPriceLabel({
  className,
  ticketCost,
  monthlyFee,
  planName,
}: Props) {
  return (
    <p className={className}>
      {getPrice({ monthlyFee, ticketCost, planName })}
    </p>
  );
}

export default PlanPriceLabel;
