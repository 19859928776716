import React from 'react';
import { Security as SecurityIcon } from '@material-ui/icons';

import { LinkConfig } from '@gatherly/types';

import { classnames, Avatar, Chip } from '@gatherly/lib';

import { Clickable, ClickableVariant } from '../Clickable';

import { DEFAULT_AVATAR } from '../../images';
import classes from './EventLinkList.module.scss';
import AdminTooltip from './AdminTooltip';
import { ActionLinks } from './ActionLinks';

type Props = {
  eventId: string;
  isEditable?: boolean;
  isDefault?: boolean;
  linkConfig: LinkConfig;
  className?: string;
};

export function EventLinkListItem({
  eventId,
  isEditable = false,
  linkConfig,
  className,
}: Props) {
  return (
    <li
      className={classnames(
        className,
        'flex flex-col',
        'border-1 border-color-shade-10',
      )}
    >
      <div
        className={classnames(
          classes.accessLinkDetails,
          'flex items-center justify-between',
          'px_5 py_75 border-b1 border-color-shade-10',
        )}
      >
        <div className={classnames(classes.accessLinkTitle, classes.col)}>
          <Clickable
            className="mr1 hover:underline color-shade-70"
            to={linkConfig.url}
          >
            {linkConfig.name}{' '}
          </Clickable>
          {linkConfig.broadcast && (
            <>
              <Chip className={classes.adminChip}>
                <SecurityIcon fontSize="small" /> Admin
              </Chip>
              <AdminTooltip />
            </>
          )}
        </div>
        <div className={classnames(classes.col)}>
          <Avatar
            avatarUrl={DEFAULT_AVATAR.src}
            name={DEFAULT_AVATAR.alt}
            borderColor={linkConfig.color}
            size={22}
          />
          <Chip className="ml1" color={linkConfig.color}>
            {linkConfig.name}
          </Chip>
        </div>
        <ActionLinks
          isEditable={isEditable}
          linkConfig={linkConfig}
          eventId={eventId}
        />
      </div>
      <div className="bg-color-silver p_5">
        {linkConfig.url ? (
          <Clickable
            to={linkConfig.url}
            title={linkConfig.url}
            variant={ClickableVariant.LINK}
          >
            {linkConfig.url}
          </Clickable>
        ) : (
          <em className="color-shade-50">This url is missing :(</em>
        )}
      </div>
    </li>
  );
}
