import {
  GetStripeConfigResponse,
  NewPaymentIntentParams,
  PaymentIntent,
} from '@eventmanager/types';
import { Stripe, StripeCardElement } from '@stripe/stripe-js';

import { apiRoutes } from '../../config';
import http from './client';

export async function getStripeConfig(): Promise<GetStripeConfigResponse> {
  const {
    data: { publishableKey },
  } = await http.get(apiRoutes.billing);

  return publishableKey;
}
export async function newPaymentIntent(
  paymentIntent: NewPaymentIntentParams,
): Promise<PaymentIntent> {
  const response = await http.post(apiRoutes.billing, paymentIntent);
  return response.data;
}

interface IConfirmCardPayment {
  cardElement: StripeCardElement;
  clientSecret: string;
  fullName: string;
  stripe: Stripe;
}
export function confirmCardPayment({
  cardElement,
  clientSecret,
  fullName,
  stripe,
}: IConfirmCardPayment) {
  return stripe.confirmCardPayment(clientSecret, {
    payment_method: {
      card: cardElement,
      billing_details: {
        name: fullName,
      },
    },
  });
}
