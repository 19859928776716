import {
  DeleteFloorResponse,
  DuplicateFloorParams,
  GetBroadcastRecordingsResponse,
  GetEventResponse,
  GetEventsResponse,
  NewEventParams,
  NewEventResponse,
  NewFloorParams,
  UpdateEventParams,
  UpdateEventResponse,
  UpdateFloorResponse,
} from '@eventmanager/types';
import { IFloorConfig } from '@gatherly/types';

import { apiRoutes } from '../../config';
import http from './client';

export async function createEvent(
  data: NewEventParams,
): Promise<NewEventResponse> {
  const response = await http.post(apiRoutes.events, data, {
    timeout: 60000,
  });
  return response.data || null;
}
export async function updateEvent(
  eventId: string,
  data: UpdateEventParams,
): Promise<UpdateEventResponse> {
  const response = await http.put(`${apiRoutes.events}/${eventId}`, data);
  return response.data || null;
}
export async function createFloor(
  eventId: string,
  data: NewFloorParams | DuplicateFloorParams,
): Promise<UpdateEventResponse> {
  const response = await http.post(
    `${apiRoutes.events}/${eventId}/floors`,
    data,
  );
  return response.data;
}
export async function deleteEvent(
  eventId: string,
): Promise<UpdateEventResponse> {
  const response = await http.delete(`${apiRoutes.events}/${eventId}`);
  return response.data;
}
export async function deleteFloor(
  eventId: string,
  floorId: string,
): Promise<DeleteFloorResponse> {
  const response = await http.delete(
    `${apiRoutes.events}/${eventId}/floors/${floorId}`,
  );
  return response.data;
}
export async function getEventById(id: string): Promise<GetEventResponse> {
  const response = await http.get(`${apiRoutes.events}/${id}`);
  return response.data;
}
export async function getUpcomingEvents(
  lastEventId?: string,
  lastStartTime?: number,
): Promise<GetEventsResponse> {
  const paginationParams =
    lastEventId && lastStartTime
      ? `?lastEventId=${lastEventId}&lastStartTime=${lastStartTime}`
      : '';
  const response = await http.get(`${apiRoutes.events}${paginationParams}`);
  return response.data;
}
export async function updateFloor(
  eventId: string,
  floorId: string,
  data: Partial<IFloorConfig>,
): Promise<UpdateFloorResponse> {
  const response = await http.patch(
    `${apiRoutes.events}/${eventId}/floors/${floorId}`,
    data,
  );
  return response.data;
}
export async function getBroadcastRecordingsByEventId(
  eventId: string,
): Promise<GetBroadcastRecordingsResponse> {
  const response = await http.get(
    `${apiRoutes.events}/${eventId}/broadcast-recordings`,
  );
  return response.data;
}
