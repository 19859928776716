import { classnames } from '@gatherly/lib';
import { useMemo } from 'react';
import {
  DEFAULT_BOOTH_BACKGROUND_URL,
  DEFAULT_GAME_BACKGROUND_URL,
  MAX_BOOTHS_PER_FLOOR,
  MAX_GAMES_PER_FLOOR,
} from '../../../config';
import { useAppSelector } from '../../../hooks';
import { getStatusOfUploadMap } from '../../../selectors';
import { FloorType, FormField, isPending } from '../../../utils';
import { Clickable, ClickableVariant } from '../../Clickable';
import { FieldWrapper } from '../../FieldWrapper';

import classes from './EditFloorForm.module.scss';

interface Props {
  onOpenMeetingAreaEditor: () => Promise<void>;
  createMap: () => void;
  mapUrl: FormField<string>;
  floorId: string;
  isMakingRequest: boolean;
  floorType: FloorType;
}

const MeetingAreaSetup = ({
  floorId,
  mapUrl,
  isMakingRequest,
  onOpenMeetingAreaEditor,
  createMap,
  floorType,
}: Props) => {
  const mapUploadStatus = useAppSelector(getStatusOfUploadMap(floorId));
  const isDisabled = isPending(mapUploadStatus) || isMakingRequest;
  const src = useMemo(() => {
    if (mapUrl.value) {
      return mapUrl.value;
    }

    return floorType === 'booth'
      ? DEFAULT_BOOTH_BACKGROUND_URL
      : DEFAULT_GAME_BACKGROUND_URL;
  }, [mapUrl.value, floorType]);

  return (
    <div data-cy={`${floorType}-setup`} className="flex flex-row mb2">
      <FieldWrapper
        title={`${floorType.charAt(0).toUpperCase()}${floorType.slice(
          1,
        )} setup`}
      >
        <div className="flex flex-col md:flex-row gap-2">
          <div className="flex flex-col flex-1 gap-1">
            <div className="flex flex-col gap-_5">
              <p>
                Upload logos and add company names to setup the {floorType} for
                this floor.{' '}
                <span className="bold">
                  You can add up to{' '}
                  {floorType === 'booth'
                    ? MAX_BOOTHS_PER_FLOOR
                    : MAX_GAMES_PER_FLOOR}{' '}
                  {floorType}s per floor.
                </span>
              </p>
            </div>
            <div className="flex flex-col gap-1 mt_25">
              <div className="flex flex-col flex-wrap items-start gap-_5">
                <Clickable
                  className="border-1 border-color-shade-30"
                  isDisabled={isDisabled}
                  onClick={onOpenMeetingAreaEditor}
                  variant={ClickableVariant.BUTTON_SMALL}
                  data-cy={`edit-${floorType}s`}
                >
                  Edit, add, or delete {floorType}s
                </Clickable>

                {mapUrl.error && !isDisabled && (
                  <div className="flex flex-col items-start mt1">
                    <Clickable
                      className="border-1 border-color-shade-30 mb_25"
                      variant={ClickableVariant.BUTTON_SMALL}
                      onClick={createMap}
                    >
                      Retry
                    </Clickable>
                    <div className={classes.error}>{mapUrl.error}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={classnames(classes.mapUploadWrapper)}>
            <div
              className={classnames(
                classes.meetingAreaBackground,
                !isMakingRequest && 'relative',
              )}
            >
              {isMakingRequest && (
                <div className={classes.loadingWrapper}>
                  <div className={classes.loading}></div>
                </div>
              )}
              {!isMakingRequest && (
                <div className={classes.mapPreview}>
                  <img
                    className={'absolute-center'}
                    data-cy={`${floorType}-image`}
                    src={src}
                    alt={`${floorType} map`}
                  ></img>
                </div>
              )}
            </div>
          </div>
        </div>
      </FieldWrapper>
    </div>
  );
};

export default MeetingAreaSetup;
