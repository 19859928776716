import React, { useState } from 'react';
import { DescriptionRounded } from '@material-ui/icons';

import { Clickable, ClickableVariant } from '../Clickable';
import { Image } from '../Image';
import { c } from '../../utils';

import classes from './DroppableUploader.module.scss';
import { Accept } from 'react-dropzone';

type Props = {
  className?: string;
  error?: string;
  imageClassName?: string;
  isLoading?: boolean;
  label: string;
  mimeType: Accept;
  onRemove?: () => void;
  renderPreview?: boolean;
  value: string;
};

const mimeTypeIsPDF = (mimeType: Accept) =>
  Object.keys(mimeType).some(type => type.includes('pdf'));

export function FilledState({
  className,
  error,
  imageClassName,
  isLoading,
  label,
  mimeType,
  onRemove,
  renderPreview,
  value,
}: Props) {
  const isPDF = mimeTypeIsPDF(mimeType);
  const [isLoadingImage, setIsLoadingImage] = useState(isPDF ? false : true);
  function onLoadPreviewImage() {
    setIsLoadingImage(false);
  }
  return (
    <>
      {renderPreview ? (
        <div
          className={c(
            (isLoading || isLoadingImage) && classes.loading,
            className,
            'border-1 border-color-shade-30 br_25 overflow-hidden relative',
          )}
        >
          {isPDF ? (
            <iframe
              src={value}
              title="Preview"
              className={classes.pdfPreview}
            />
          ) : (
            <Image
              src={value}
              alt={label}
              onError={onLoadPreviewImage}
              onLoad={onLoadPreviewImage}
              className={imageClassName}
            />
          )}
        </div>
      ) : (
        <div
          className={c(
            className,
            isLoading && classes.loading,
            'flex flex-col justify-center items-center text-center',
            'border-1 border-color-shade-20 p1',
          )}
        >
          <DescriptionRounded />
          <p className="color-shade-80 small mt_5">{value}</p>
          {error && <p className="italic color-red small">{error}</p>}
        </div>
      )}
      {error && <p className="italic color-red small mt1">{error}</p>}
      {onRemove && (
        <div className="flex mt_25">
          <Clickable
            className="bg-color-blush"
            onClick={onRemove}
            variant={ClickableVariant.BUTTON_SMALL}
          >
            Remove File
          </Clickable>
        </div>
      )}
    </>
  );
}
