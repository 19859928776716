import { classnames } from '@gatherly/lib';
import { useMemo } from 'react';
import {
  DEFAULT_BOOTH_BACKGROUND_URL,
  DEFAULT_GAME_BACKGROUND_URL,
  SALES_CONTACT_URL,
} from '../../../config';
import { useAppSelector } from '../../../hooks';
import { getPermissionValue, getStatusOfUploadMap } from '../../../selectors';
import { FloorType, FormField, isPending } from '../../../utils';
import { Clickable, ClickableVariant } from '../../Clickable';
import { DroppableUploader } from '../../DroppableUploader';
import { FieldWrapper } from '../../FieldWrapper';

import classes from './EditFloorForm.module.scss';

interface Props {
  floorId: string;
  floorType: FloorType;
  onOpenBackgroundPicker: () => Promise<void>;
  onRemoveBackground: () => Promise<void>;
  onUploadBackground: (
    acceptedFiles: File[],
    floorType: FloorType,
  ) => Promise<void>;
  backgroundUrl: FormField<string>;
  isMakingRequest: boolean;
}

const BackgroundStyle = ({
  floorId,
  floorType,
  backgroundUrl,
  isMakingRequest,
  onOpenBackgroundPicker,
  onRemoveBackground,
  onUploadBackground,
}: Props) => {
  const mapUploadStatus = useAppSelector(getStatusOfUploadMap(floorId));

  const hasCustomMapsPermission = useAppSelector(
    getPermissionValue('customMaps'),
  );

  const src = useMemo(() => {
    if (backgroundUrl.value) {
      return backgroundUrl.value;
    }

    return floorType === 'booth'
      ? DEFAULT_BOOTH_BACKGROUND_URL
      : DEFAULT_GAME_BACKGROUND_URL;
  }, [backgroundUrl.value, floorType]);

  const isCustomBackground = useMemo(() => {
    if (!backgroundUrl.value) return;

    return (
      backgroundUrl.value !== DEFAULT_BOOTH_BACKGROUND_URL &&
      backgroundUrl.value !== DEFAULT_GAME_BACKGROUND_URL
    );
  }, [backgroundUrl.value, floorType]);

  const isDisabled = isPending(mapUploadStatus) || isMakingRequest;

  return (
    <div className="flex flex-row mb2">
      <FieldWrapper title="Background style">
        <div className="flex flex-col md:flex-row gap-2">
          <div className="flex flex-col flex-1 gap-1">
            <div className="flex flex-col gap-_5">
              <p>
                Select the background style for this {floorType} floor
                {hasCustomMapsPermission && ', or upload your own'}! There is no
                difference in functionality based on style chosen: it is purely
                visual.
              </p>

              {!hasCustomMapsPermission && (
                <p>
                  To unlock the custom background permission,{' '}
                  <Clickable
                    variant={ClickableVariant.LINK}
                    to={SALES_CONTACT_URL}
                  >
                    contact sales
                  </Clickable>
                  .
                </p>
              )}
            </div>
            <div className="flex flex-col gap-1 mt_25">
              <div className="flex flex-col md:flex-row gap-_5">
                <Clickable
                  data-cy={`change-${floorType}-background`}
                  className="border-1 border-color-shade-30"
                  isDisabled={isDisabled}
                  onClick={onOpenBackgroundPicker}
                  variant={ClickableVariant.BUTTON_SMALL}
                >
                  Open Background Style Gallery
                </Clickable>
                {isCustomBackground && (
                  <Clickable
                    className="bg-color-blush"
                    isDisabled={isDisabled}
                    onClick={onRemoveBackground}
                    variant={ClickableVariant.BUTTON_SMALL}
                  >
                    Remove Background
                  </Clickable>
                )}
              </div>
            </div>
          </div>
          <DroppableUploader
            label="Background"
            emptyTitle={'Upload new background'}
            onDrop={files => onUploadBackground(files, floorType)}
            value={src}
            error={backgroundUrl.error}
            isLoading={isPending(mapUploadStatus) || isMakingRequest}
            isDisabled={!hasCustomMapsPermission}
            emptyClassName={classnames(classes.mapEmpty, 'absolute-center z1')}
            previewClassName={classnames(classes.meetingAreaBackground)}
            previewImageClassName="absolute-center"
            className={classes.mapUploader}
            wrapperClassName={classes.mapUploadWrapper}
            renderPreview
          />
        </div>
      </FieldWrapper>
    </div>
  );
};

export default BackgroundStyle;
