import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  SyntheticEvent,
} from 'react';
import { IFloorConfig, IGatherlyEvent } from '@gatherly/types';
import isEqual from 'lodash.isequal';
import { generate as shortUuid } from 'short-uuid';

import {
  updateEventConfig,
  setOverlay,
  OverlayType,
} from '../../../../actions';

import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  getStatusOfUpdateEvent,
  maxFloorsPerEvent,
  getEventFloors,
  getFloorById,
} from '../../../../selectors';
import { isPending, isFulfilled, toListMap } from '../../../../utils';
import { DraggableList, getDraggableItems } from '../../../DraggableList';
import { FloorCard } from '../FloorCard';
import { EventFormFooter } from '../EventFormFooter';
import { EventFloorsFormFooter } from './Footer';
import { EventFloorsFormHeader } from './Header';

type Props = {
  event: IGatherlyEvent;
  isDisabled: boolean;
  setHasChanges: (hasChanges: boolean) => void;
};

function getChanges(floorsOrder: string[], floors: IFloorConfig[]) {
  const prevFloorsOrder = floors.map(floor => floor.id);
  const floorsMap = toListMap(floors, 'id');
  const didUpdate = !isEqual(prevFloorsOrder, floorsOrder);
  return {
    floors: floorsOrder.map(id => floorsMap[id]),
    didUpdate,
  };
}

export function EventFloorsForm({ event, isDisabled, setHasChanges }: Props) {
  const {
    eventId,
    config: {
      landingPage: { description },
    },
  } = event;
  const dispatch = useAppDispatch();

  const floors = useAppSelector(getEventFloors(eventId));

  const maxFloors = useAppSelector(maxFloorsPerEvent);
  const unlimitedMaxFloors = maxFloors === -1;
  const status = useAppSelector(getStatusOfUpdateEvent(eventId));
  const [refreshKey, setRefreshKey] = useState(shortUuid());

  const [floorsOrder, setFloorsOrder] = useState(floors.map(floor => floor.id));
  const isMaxFloors =
    isDisabled || (!unlimitedMaxFloors && floors.length >= maxFloors);

  useEffect(() => {
    if (isFulfilled(status)) {
      setFloorsOrder(floors.map(floor => floor.id));
    }
  }, [status]);
  useEffect(() => {
    setFloorsOrder(floors.map(floor => floor.id));
  }, [floors.length]);

  // onChange Handlers
  const hasChanges = useMemo(() => {
    const { didUpdate } = getChanges(floorsOrder, floors);
    setHasChanges(didUpdate);
    return didUpdate;
  }, [floorsOrder, setHasChanges, floors]);

  // Click Handlers
  const onSubmit = useCallback(
    async (evt?: SyntheticEvent) => {
      if (evt) evt.preventDefault();
      const { floors: updatedFloors, didUpdate } = getChanges(
        floorsOrder,
        floors,
      );

      if (didUpdate) {
        await updateEventConfig(dispatch, event, {
          floors: updatedFloors,
        });
      }
    },
    [floorsOrder, floors],
  );

  const onDelete = useCallback(
    (floorId: string) => {
      if (floors.length === 1) return;
      setOverlay(dispatch, OverlayType.DELETE_FLOOR, {
        eventId,
        floorId,
        numFloors: floors.length,
      });
    },
    [eventId, floors.length],
  );

  const onEdit = useCallback(
    (floorId: string) => {
      setOverlay(dispatch, OverlayType.EDIT_FLOOR, {
        eventId,
        floorId,
      });
    },
    [eventId],
  );

  const onClickDuplicate = useCallback(
    (floorId: string) => {
      if (!unlimitedMaxFloors && floors.length >= maxFloors) {
        setOverlay(dispatch, OverlayType.NOTIFICATION, {
          title: 'Max Floors Reached',
          message: `Your current plan allows a maximum of ${maxFloors} per event. To upgrade, change your plan or contact sales.`,
        });
      } else {
        setOverlay(dispatch, OverlayType.DUPLICATE_FLOOR, {
          eventId,
          sourceFloorId: floorId,
        });
      }
    },
    [eventId, floors.length, maxFloors],
  );

  const [floorCards, setFloorCards] = useState(
    getDraggableItems<string>(floorsOrder),
  );

  useEffect(() => {
    setFloorCards(getDraggableItems(floorsOrder));
  }, [floorsOrder]);

  const onReorder = useCallback(
    items => {
      const _floorsOrder: string[] = items.map(item => item.id);
      setFloorsOrder(_floorsOrder);
      setRefreshKey(shortUuid());
    },
    [setFloorsOrder],
  );

  const renderFloorCard = useCallback(
    (provided, snapshot, floorId, index) => {
      const floor = useAppSelector(getFloorById(eventId, floorId));
      return (
        <div
          className="pb1"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <FloorCard
            floor={floor}
            eventDescription={description}
            onDelete={() => onDelete(floorId)}
            canDelete={floors.length > 1}
            onDuplicate={() => onClickDuplicate(floorId)}
            onEdit={() => onEdit(floorId)}
            index={index}
            isDisabled={isDisabled}
          />
        </div>
      );
    },
    [eventId, isDisabled, floors.length, maxFloors],
  );

  return (
    <div className="flex flex-1 flex-col gap-1">
      <EventFloorsFormHeader eventId={eventId} isDisabled={isDisabled} />
      <div className="flex flex-col gap-1 body md:pl2">
        <DraggableList
          id={refreshKey}
          items={floorCards}
          renderItem={renderFloorCard}
          onReorder={onReorder}
          isDisabled={isDisabled}
        />
        <EventFloorsFormFooter
          eventId={eventId}
          maxFloors={maxFloors}
          isDisabled={isMaxFloors}
        />
        <EventFormFooter
          eventId={eventId}
          isDisabled={isDisabled}
          hasChanges={hasChanges}
          isMakingRequest={isPending(status)}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
}
