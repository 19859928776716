import {
  AccountsUser,
  AclPermissions,
  AclPermissionsRole,
  Permissions,
} from '@eventmanager/types';
import { DEFAULT_ACL_ROLE, ACTIVE_ROLES } from '../config';
import { UserAuthState } from '../enums';
import { RootState } from '../store';

export const emptyPermissions: AclPermissions = {
  role: DEFAULT_ACL_ROLE,
  advancedLinkBuilder: false,
  advancedSettings: false,
  attendeeTracking: false,
  broadcastRecordings: false,
  customMaps: true,
  dataAnalytics: false,
  isActive: true,
  aiGeneratedEvents: 0,
  logoCustomization: false,
  maxEventDuration: 0.67, // hours
  maxFloors: 2,
  monthlyFee: 0,
  subscriptionId: '',
  ticketCost: 8,
  ticketsIncluded: 25,
  ticketsPerMonth: 0,
};

export function currentUser(state: RootState): AccountsUser | null {
  return state.account.user;
}

export function currentUserAuthState(state: RootState): UserAuthState {
  return state.account.authState;
}

export function currentUserPermissions(state: RootState): {
  permissions: AclPermissions;
  overridden: string[];
} {
  const user = currentUser(state);
  const role = user?.role || DEFAULT_ACL_ROLE;
  const planPermissions: AclPermissions =
    getPermission(role)(state) || emptyPermissions;
  const permissionOverrides = user?.permissionOverrides || {};
  return Object.keys(permissionOverrides).reduce(
    (memo, keyName) => {
      if (permissionOverrides[keyName as keyof Permissions]) {
        return {
          permissions: {
            ...memo.permissions,
            [keyName]: permissionOverrides[keyName as keyof Permissions],
          },
          overridden: [...memo.overridden, keyName],
        };
      }
      return memo;
    },
    {
      permissions: planPermissions,
      overridden: [] as string[],
    },
  );
}

export function availableUpgradePlans(state: RootState): AclPermissions[] {
  return ACTIVE_ROLES.map(
    planName => state.account.permissions[planName as AclPermissionsRole],
  ).filter(v => !!v && v.role !== DEFAULT_ACL_ROLE && v.role !== 'pro');
}

export function permissionsMap(state: RootState) {
  return state.account.permissions;
}

export function getPermission(
  role: AclPermissionsRole,
): (state: RootState) => AclPermissions | null {
  return (state: RootState) => state.account?.permissions[role] || null;
}

export const getPermissionValue =
  (permissionName: keyof AclPermissions) => (state: RootState) => {
    const { permissions } = currentUserPermissions(state);
    return permissions[permissionName];
  };

export function maxEventDurationHours(state: RootState): number {
  return currentUserPermissions(state)?.permissions.maxEventDuration || 0;
}

export function maxFloorsPerEvent(state: RootState): number {
  return currentUserPermissions(state)?.permissions.maxFloors || 0;
}
