import Cookies from 'js-cookie';

import { COOKIE_NAMES, makeCookieName } from '../config';

export interface SessionData {
  userId: string;
  userEmail: string;
  idToken: string;
}

interface CookiesShape {
  readonly sessionData?: SessionData;
  readonly idToken?: string;
}

export const clearCookies = (cookieNames: string[] = COOKIE_NAMES) =>
  cookieNames.forEach((cookieName: string) => Cookies.remove(cookieName));

export const setCookies = (cookies: CookiesShape) =>
  Object.entries(cookies).forEach(([cookieName, cookieVal]: string[]) =>
    Cookies.set(makeCookieName(cookieName), cookieVal),
  );
