import React from 'react';

import Ticket from './Ticket';

type TicketCounts = {
  available: number;
  reserved: number;
};

interface Props {
  ticketCounts: TicketCounts;
}

export function Tickets({ ticketCounts }: Props) {
  return (
    <div className="flex flex-col md:flex-row md:justify-around lg:flex-col gap-2 md:gap-4 pb2">
      {Object.entries(ticketCounts).map(([type, count], index) => (
        <Ticket
          ticketType={type}
          ticketCount={count}
          key={`ticket-${type}-${index}`}
        />
      ))}
    </div>
  );
}

export default Tickets;
