import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRoute } from 'wouter';

import { getEvent } from '../../actions';
import { GatherlyEventServerStatus } from '../../enums';
import { Clickable, ClickableVariant } from '../../components/Clickable';
import { EventCountdown } from '../../components/EventCountdown';
import { Footer } from '../../components/Footer';
import { Tabs } from '../../components/Tabs';
import { Toolbar } from '../../components/Toolbar';
import { Status } from '../../components/Status';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { eventById, getStatusOfGetEvent } from '../../selectors';
import {
  c,
  eventIsEditable,
  isPending,
  isRejected,
  scrollTo,
} from '../../utils';
import classes from './EventDetailPage.module.scss';

import {
  EventDetailPageContent,
  EventTab,
  tabIds,
  getTabs,
} from './EventDetailPageContent';

export function EventDetailPage() {
  const [, params] = useRoute('/events/:id/:tab*');

  const eventId = useMemo(() => params?.id || '', [params?.id]);
  const activeTabId = useMemo(() => {
    const [tab] = (params?.tab || '').split('/');
    return tabIds.includes(tab as EventTab) ? tab : tabIds[0];
  }, [params?.tab]);
  const tabs = useMemo(() => getTabs(eventId), [eventId]);

  const event = useAppSelector(eventById(eventId));
  const getEventStatus = useAppSelector(getStatusOfGetEvent(eventId));

  const isMakingGetEventRequest = isPending(getEventStatus);
  const hasGetEventsError = isRejected(getEventStatus);

  const dispatch = useAppDispatch();

  useEffect(() => {
    getEvent(dispatch, eventId);
  }, [eventId]);

  useEffect(() => {
    scrollTo(0);
  }, [params?.tab]);

  const [isEditable, setIsEditable] = useState<boolean>(eventIsEditable(event));
  useEffect(() => {
    setIsEditable(eventIsEditable(event));
  }, [event]);
  const onCountdown = useCallback(
    (serverStatus?: GatherlyEventServerStatus) => {
      setIsEditable(serverStatus === GatherlyEventServerStatus.PRELAUNCH);
    },
    [],
  );

  return (
    <div className={c(classes.EventDetailPage, 'flex flex-1 bg-color-white')}>
      <Toolbar
        data-cy="event-toolbar"
        title={event?.name}
        classNames="bg-color-white border-b1 border-color-shade-20"
      >
        <div className="flex flex-1 justify-end">
          {event?.startTime && (
            <div className="none md:flex items-center">
              <EventCountdown
                eventId={event.eventId}
                startTime={event.startTime}
                stopTime={event.stopTime}
                onCountdown={onCountdown}
              />
            </div>
          )}
        </div>
      </Toolbar>
      <div className="flex-1 page-inner__content bg-color-white">
        <div className="flex flex-col flex-1 mt_5">
          {event ? (
            <>
              <div className="flex justify-between items-center border-b1 border-color-shade-30">
                <Tabs tabs={tabs} activeTabId={activeTabId} />
                <Clickable
                  data-cy="dashboard-return"
                  to="/events"
                  className="none md:flex"
                  variant={ClickableVariant.LINK}
                >
                  {'<'} back to dashboard
                </Clickable>
              </div>
              <div
                className={c(
                  classes.content,
                  'flex flex-col border-l1 border-r1 border-b1 border-color-shade-40 pb4',
                )}
              >
                <EventDetailPageContent
                  eventId={eventId}
                  isDisabled={!isEditable}
                  tabId={activeTabId}
                />
              </div>
            </>
          ) : (
            <div
              className={c(
                classes.content,
                'flex flex-1 flex-col items-center justify-center',
              )}
            >
              {isMakingGetEventRequest ? (
                <Status message="Loading..." />
              ) : (
                <Status message="Event not found." />
              )}
              {hasGetEventsError && (
                <Status message="Error retrieving event." />
              )}
            </div>
          )}
        </div>
        <Footer className="mt1" />
      </div>
    </div>
  );
}
