import { Switch } from '@gatherly/lib';
import { BOOTHS_HELP_URL } from '../../../config';
import { Clickable, ClickableVariant } from '../../Clickable';
import { FieldWrapper } from '../../FieldWrapper';
import { FloorType } from '../../../utils';

interface Props {
  floorType: FloorType;
  onChangeFloorType: (floorType: FloorType) => void;
}

const FloorTypeSwitch = ({ floorType, onChangeFloorType }: Props) => {
  return (
    <div className="flex flex-row mb2">
      <FieldWrapper title="Type">
        <div className="flex flex-col md:flex-row gap-1 flex-1">
          <div className="flex-1">
            <p>
              Choose the type of floor! Booths are perfect for poster fair-style
              events.{' '}
              {floorType !== 'game' &&
                `Games will let you embed activities into your Gatherly
              floor`}
              {floorType === 'game' &&
                `Just looking for an unstructured open area for mingling? Go for no booths`}
              . Learn about the difference{' '}
              <Clickable to={BOOTHS_HELP_URL} variant={ClickableVariant.LINK}>
                here
              </Clickable>
              .
            </p>
          </div>
          <div>
            <Switch<FloorType>
              onChange={onChangeFloorType}
              value={floorType}
              options={[
                {
                  id: 'normal',
                  label: 'No booths',
                  value: 'normal',
                },
                {
                  id: 'booths',
                  label: 'Booths',
                  value: 'booth',
                },
                {
                  id: 'games',
                  value: 'game',
                  label: 'Games',
                },
              ]}
            />
          </div>
        </div>
      </FieldWrapper>
    </div>
  );
};

export default FloorTypeSwitch;
