import React from 'react';

import { c } from '../../utils';
import { Clickable } from '../Clickable';
import { SelectOption } from '../Select';

type Props<T> = {
  baseUrl?: string;
  options: SelectOption<T>[];
  selectedId: string;
  onSelect?: (id: string) => void;
};
export function Menu<T>({
  baseUrl = '/',
  options,
  selectedId,
  onSelect,
}: Props<T>) {
  return (
    <ul className="flex flex-col items-stretch text-center list-style-none">
      {options.map(({ id, label, value }) => (
        <li
          className="flex flex-col border-b1 hover:bg-color-shade-10 transition-bg-color border-color-shade-20"
          key={id}
        >
          <Clickable
            data-cy={label?.toString().replaceAll(' ', '')}
            className={c(
              'detail color-shade-70 py1 px1_5 md:py1_25 md:px1',
              id === selectedId && 'bg-color-shade-10',
            )}
            to={onSelect ? undefined : `${baseUrl}${value}`}
            onClick={onSelect ? () => onSelect(id) : undefined}
          >
            {label}
          </Clickable>
        </li>
      ))}
    </ul>
  );
}
