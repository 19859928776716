import { createTheme } from '@material-ui/core';
import styles from './index.module.scss';

export const FONT_LATO = styles.latoFont;
export const COLOR_SHADE_60 = styles.colorShade60;

export const materialUITheme = createTheme({
  typography: {
    fontFamily: FONT_LATO,
    allVariants: {
      color: COLOR_SHADE_60,
    },
  },
});
