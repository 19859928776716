import { EventAnalytics } from '@eventmanager/types';

import { formatDateShort } from './date';
import { kebabify } from './misc';

export function getTimeScaleFromAnalytics(
  analytics: EventAnalytics,
  startTime: number,
  stopTime: number,
) {
  const timeStep =
    (stopTime - startTime) / (analytics.OccupancyData.length - 1);
  return analytics.OccupancyData.map(value =>
    formatDateShort(startTime + timeStep * value, false),
  );
}

export function getFloorLabelsFromAnalytics(
  analytics: EventAnalytics,
): string[] {
  return analytics.OccupancyDataByFloor.map((_, index) => `Floor ${index + 1}`);
}

export function downloadAsCSV(title: string, data: Array<any>) {
  let exportData = 'data:text/csv;charset=utf-8,';
  const fields = data.length !== 0 ? Object.keys(data[0]) : [];

  let value;
  const entriesArray = data.map(item =>
    fields.map(field => {
      value = item[field] || '';

      if (typeof value !== 'string') {
        return value;
      }
      return value.includes(',') ? `"${value}"` : value;
    }),
  );

  const csvString = [
    fields.join(','),
    ...entriesArray.map(row => row.join(',')),
  ].join('\n');

  /* Fron BetterDMV */
  // This is done because we are only making a CSV Of the attendees that are
  // searched, so using react-csv would recreate a CSV every time a new
  // filter is added. Thus, we avoid any methods that require the url upfront.
  exportData += csvString;
  const link = document.createElement('a');
  link.download = `${kebabify(title)}.csv`;
  link.href = encodeURI(exportData);
  link.click();
}
