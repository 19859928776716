import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useTimeout } from 'react-use';

import { SECOND } from '../../config';
import { c } from '../../utils';
import classes from './Image.module.scss';

type Props = {
  alt: string;
  children?: ReactNode;
  className?: string;
  isBackground?: boolean;
  onLoad?: () => void;
  onError?: () => void;
  skipPreload?: boolean;
  src: string;
};
export function Image({
  alt,
  children,
  className,
  isBackground,
  onError,
  onLoad,
  src,
  skipPreload = false,
}: Props) {
  const [isLoaded, setIsLoaded] = useState(false);

  const didLoad = useCallback(() => {
    setIsLoaded(true);
    if (onLoad) onLoad();
  }, [onLoad]);

  const didError = useCallback(() => {
    setIsLoaded(true);
    console.error(`Failed to load image ${src}`);
    if (onError) onError();
  }, [onError]);

  useEffect(() => {
    if (skipPreload) {
      didLoad();
      return;
    }
    const loader = new window.Image();
    loader.src = '';
    loader.onload = () => {
      didLoad();
    };
    loader.onerror = didError;
    loader.src = src;

    return () => {
      didLoad();
    };
  }, [src, skipPreload]);

  const [didExceedLoadingTimer] = useTimeout(2 * SECOND);

  if (isBackground) {
    return (
      <div
        className={c(
          className,
          classes.Image,
          classes.background,
          isLoaded && classes.loaded,
        )}
        style={{ backgroundImage: `url(${src})` }}
      >
        <span className="sr-only">Background image: {alt}</span>
        {children}
      </div>
    );
  } else {
    return (
      <img
        className={c(
          className,
          classes.Image,
          (isLoaded || didExceedLoadingTimer) && classes.loaded,
        )}
        src={src}
        alt={alt}
      />
    );
  }
}
