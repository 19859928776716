import { classnames } from '@gatherly/lib';
import { Bar } from 'react-chartjs-2';

import { LOGO_BLUE, LOGO_GREEN } from '../../config';

type Props = {
  className?: string;
  data: number[];
  height?: number;
  label: string;
  title?: string;
  xAxisLabels: string[];
};

export function BarChart({
  className,
  data,
  height,
  label,
  title,
  xAxisLabels,
}: Props) {
  return (
    <div className={classnames(className, 'flex flex-1 flex-col gap-1')}>
      {title && <p className="subheading">{title}</p>}
      <Bar
        type="bar"
        height={height}
        data={{
          labels: xAxisLabels,
          datasets: [
            {
              label,
              backgroundColor: LOGO_BLUE,
              borderColor: LOGO_BLUE,
              borderWidth: 1,
              hoverBackgroundColor: LOGO_GREEN,
              hoverBorderColor: LOGO_GREEN,
              data,
            },
          ],
        }}
        options={{
          legend: {
            display: false,
          },
          scales: {
            // @ts-ignore
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
      />
    </div>
  );
}
