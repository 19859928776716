import { UpdateEventParams } from '@eventmanager/types';
import { useCallback } from 'react';
import { updateEvent } from '../../actions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { eventById } from '../../selectors';
import { Clickable, ClickableVariant } from '../Clickable';

type Props = {
  eventId: string;
};

export function StopEvent({ eventId }: Props) {
  const dispatch = useAppDispatch();
  const event = useAppSelector(eventById(eventId));
  if (!event) {
    return null;
  }

  const handleStopEvent = useCallback(() => {
    const updatedEvent: UpdateEventParams = {
      ...event,
      stopTime: Date.now(),
    };
    updateEvent(dispatch, eventId, updatedEvent);
  }, [dispatch, eventId, event]);
  return (
    <Clickable
      variant={ClickableVariant.BUTTON_TERTIARY}
      onClick={handleStopEvent}
    >
      🛑 Stop
    </Clickable>
  );
}

export default StopEvent;
