import { classnames } from '@gatherly/lib';
import { useCallback, useMemo } from 'react';
import { useLocation, useRoute } from 'wouter';
import { useAppSelector } from '../../hooks';
import { eventAnalytics, eventBooths } from '../../selectors';
import { FieldWrapper } from '../FieldWrapper';
import { Menu } from '../Menu';
import { MessageWithIllustration } from '../MessageWithIllustration';
import { Select, SelectOption } from '../Select';
import { BoothAnalytics } from './BoothAnalytics';

import classes from './BoothReport.module.scss';

type Props = {
  eventId: string;
};

export const BoothReport = ({ eventId }: Props) => {
  const booths = useAppSelector(eventBooths(eventId));
  const analytics = useAppSelector(eventAnalytics(eventId));
  const noBooths = booths.length === 0;
  const [, setLocation] = useLocation();
  const [, params] = useRoute('/events/:id/:section/:tab/:boothId');
  const boothId = useMemo(() => {
    const id = params?.boothId || '';
    return booths.map(booth => booth.id).includes(id)
      ? id
      : booths.length > 0
      ? booths[0].id
      : '';
  }, [params?.boothId, booths]);

  const boothAnalytics = useMemo(() => {
    let boothData;

    if (analytics.Booths) {
      boothData = analytics.Booths[boothId];
    }

    return (
      boothData ?? {
        avgTimeSpent: 0,
        numConversations: 0,
        totalTimeSpent: 0,
        totalVisitors: 0,
        users: {},
      }
    );
  }, [analytics, boothId]);

  const internalNavOptions = useMemo((): SelectOption<string>[] => {
    return booths.map(({ displayName, id }) => {
      return { id, label: displayName, value: id };
    });
  }, [booths]);

  const onSelect = useCallback(
    evt => {
      setLocation(
        `/events/${eventId}/analytics/booth-data/${evt.target.value}`,
      );
    },
    [eventId],
  );

  if (noBooths) {
    return (
      <MessageWithIllustration
        heading={'No Booths'}
        message={'No booths were set up for this event'}
      />
    );
  }

  return (
    <div
      className={classnames(classes.BoothReport, 'flex flex-col md:flex-row')}
    >
      <div className="md:none">
        <FieldWrapper
          className="flex border-b1 border-color-shade-20 pb1_5"
          title="Booth analytics..."
        >
          <Select
            className="flex-1"
            name="booth"
            value={boothId}
            label="Booth"
            options={internalNavOptions}
            onChange={onSelect}
          />
        </FieldWrapper>
      </div>
      <div className={classnames(classes.internalNav, 'none md:flex flex-col')}>
        <Menu
          baseUrl={`/events/${eventId}/analytics/booth-data/`}
          options={internalNavOptions}
          selectedId={boothId}
        />
      </div>
      <div className={classnames(classes.wrapper, 'flex flex-col flex-1')}>
        <div className="flex flex-1 flex-col md:px4">
          <BoothAnalytics analytics={boothAnalytics} eventId={eventId} />
        </div>
      </div>
    </div>
  );
};
