import { OverlayType, clearOverlay } from '../../actions';
import React, { Suspense, lazy, useCallback } from 'react';
import { additionalOverlaysList, currentOverlay } from '../../selectors';
import { useAppDispatch, useAppSelector } from '../../hooks';

import { c } from '../../utils';
import classes from './Overlay.module.scss';

type OverlayContentProps = {
  overlayId: string;
  overlayType: OverlayType | undefined;
};

const BannerPickerDialog = lazy(() => import('../dialogs/BannerPickerDialog'));
const BroadcastRecordingPlayerDialog = lazy(
  () => import('../dialogs/BroadcastRecordingPlayerDialog'),
);
const BackgroundPickerDialog = lazy(
  () => import('../dialogs/BackgroundPickerDialog'),
);
const MeetingAreaEditorDialog = lazy(
  () => import('../dialogs/MeetingAreaEditorDialog'),
);
const DeleteEventDialog = lazy(() => import('../dialogs/DeleteEventDialog'));
const DeleteFloorDialog = lazy(() => import('../dialogs/DeleteFloorDialog'));
const DeleteLinkDialog = lazy(() => import('../dialogs/DeleteLinkDialog'));
const EditFloorDialog = lazy(() => import('../dialogs/EditFloorDialog'));
const EditLinkDialog = lazy(() => import('../dialogs/EditLinkDialog'));
const MapPickerDialog = lazy(() => import('../dialogs/MapPickerDialog'));
const NewEventDialog = lazy(() => import('../dialogs/NewEventDialog'));
const NewFloorDialog = lazy(() => import('../dialogs/NewFloorDialog'));
const NewLinkDialog = lazy(() => import('../dialogs/NewLinkDialog'));
const NotificationDialog = lazy(() => import('../dialogs/NotificationDialog'));
const OnboardingDialog = lazy(() => import('../dialogs/OnboardingDialog'));
const PlansDialog = lazy(() => import('../dialogs/PlansDialog'));
const PreviewEventDialog = lazy(() => import('../dialogs/PreviewEventDialog'));
const PurchaseTicketsDialog = lazy(
  () => import('../dialogs/PurchaseTicketsDialog'),
);
const RemoveLogosDialog = lazy(() => import('../dialogs/RemoveLogosDialog'));
const ShareLinkDialog = lazy(() => import('../dialogs/ShareLinkDialog'));
const UserDetailsDialog = lazy(() => import('../dialogs/UserDetailsDialog'));
const AIEventCreatedConfirmationDialog = lazy(
  () => import('../dialogs/AIEventCreatedConfirmationDialog'),
);
const AddSessionDialog = lazy(() => import('../dialogs/AddSessionDialog'));
const EditBoothDialog = lazy(() => import('../dialogs/EditBoothDialog'));
const ConfirmSwitchTabDialog = lazy(
  () => import('../dialogs/ConfirmSwitchTabDialog'),
);

function OverlayContent({ overlayId, overlayType }: OverlayContentProps) {
  if (!overlayId.trim()) {
    return null;
  }
  switch (overlayType) {
    case OverlayType.BANNER_PICKER: {
      return <BannerPickerDialog overlayId={overlayId} />;
    }
    case OverlayType.BROADCAST_RECORDING_PLAYER: {
      return <BroadcastRecordingPlayerDialog overlayId={overlayId} />;
    }
    case OverlayType.BACKGROUND_PICKER: {
      return <BackgroundPickerDialog overlayId={overlayId} />;
    }
    case OverlayType.MEETING_AREA_EDITOR: {
      return <MeetingAreaEditorDialog overlayId={overlayId} />;
    }
    case OverlayType.DELETE_EVENT: {
      return <DeleteEventDialog overlayId={overlayId} />;
    }
    case OverlayType.DELETE_FLOOR: {
      return <DeleteFloorDialog overlayId={overlayId} />;
    }
    case OverlayType.DELETE_LINK: {
      return <DeleteLinkDialog overlayId={overlayId} />;
    }
    case OverlayType.EDIT_FLOOR: {
      return <EditFloorDialog overlayId={overlayId} />;
    }
    case OverlayType.EDIT_LINK: {
      return <EditLinkDialog overlayId={overlayId} />;
    }
    case OverlayType.MAP_PICKER: {
      return <MapPickerDialog overlayId={overlayId} />;
    }
    case OverlayType.NEW_EVENT: {
      return <NewEventDialog overlayId={overlayId} />;
    }
    case OverlayType.NEW_FLOOR:
    case OverlayType.DUPLICATE_FLOOR: {
      return <NewFloorDialog overlayId={overlayId} />;
    }
    case OverlayType.NEW_LINK: {
      return <NewLinkDialog overlayId={overlayId} />;
    }
    case OverlayType.NOTIFICATION: {
      return <NotificationDialog overlayId={overlayId} />;
    }
    case OverlayType.PREVIEW_EVENT: {
      return <PreviewEventDialog overlayId={overlayId} />;
    }
    case OverlayType.REMOVE_LOGOS: {
      return <RemoveLogosDialog overlayId={overlayId} />;
    }
    case OverlayType.SHARE_LINK: {
      return <ShareLinkDialog overlayId={overlayId} />;
    }
    case OverlayType.PURCHASE_TICKETS: {
      return <PurchaseTicketsDialog overlayId={overlayId} />;
    }
    case OverlayType.ONBOARDING: {
      return <OnboardingDialog overlayId={overlayId} />;
    }
    case OverlayType.BROWSE_PLANS: {
      return <PlansDialog overlayId={overlayId} />;
    }
    case OverlayType.USER_DETAILS: {
      return <UserDetailsDialog overlayId={overlayId} />;
    }
    case OverlayType.AI_EVENT_CREATED_CONFIRMATION: {
      return <AIEventCreatedConfirmationDialog overlayId={overlayId} />;
    }
    case OverlayType.ADD_SESSION: {
      return <AddSessionDialog overlayId={overlayId} />;
    }
    case OverlayType.EDIT_BOOTH: {
      return <EditBoothDialog overlayId={overlayId} />;
    }
    case OverlayType.CONFIRM_SWITCH_TAB: {
      return <ConfirmSwitchTabDialog overlayId={overlayId} />;
    }
    default:
      if (overlayType) console.warn(`Unknown overlay type ${overlayType}`);
      return null;
  }
}

export function Overlay() {
  const dispatch = useAppDispatch();
  const overlay = useAppSelector(currentOverlay);
  const additionalOverlays = useAppSelector(additionalOverlaysList);

  const onClickBackdrop = useCallback(() => {
    if (!overlay) return;
    clearOverlay(dispatch, overlay.id, overlay?.data?.allowDismiss);
  }, [overlay?.id]);

  const zBase = 3;
  const zMax = zBase + additionalOverlays.length + 1;

  return (
    <div
      className={c(
        classes.Overlay,
        'fixed t0 b0 r0 l0 z-3',
        'div flex flex-col',
        overlay ? classes.withContent : 'events-none',
      )}
    >
      <div
        className={c(
          'absolute t0 r0 b0 l0',
          overlay ? 'bg-color-fog animation-fade-in' : 'events-none',
        )}
        style={{ zIndex: zMax - 1 }}
        onClick={onClickBackdrop}
      />

      <div className="flex-1 relative">
        <div
          className={c(classes.content, 'absolute')}
          style={{ zIndex: zMax }}
        >
          <Suspense fallback={null}>
            <OverlayContent
              overlayId={overlay?.id || ''}
              overlayType={overlay?.overlayType}
            />
          </Suspense>
        </div>

        {additionalOverlays.map((overlay, index) => {
          return (
            <div
              key={overlay.id}
              className={c(classes.content, 'absolute')}
              style={{
                zIndex: zBase + index,
              }}
            >
              <Suspense fallback={null}>
                <OverlayContent
                  overlayId={overlay.id}
                  overlayType={overlay.overlayType}
                />
              </Suspense>
            </div>
          );
        })}
      </div>
    </div>
  );
}
