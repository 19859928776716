import React, { useCallback, useState, SyntheticEvent } from 'react';

import { PromiseStatus } from '../../enums';
import { Clickable, ClickableVariant } from '../Clickable';
import { Input } from '../Input';
import { requestResetPassword } from '../../actions/accountActions';
import { setFulfilled } from '../../actions/statusActions';
import { useAppDispatch } from '../../hooks';
import {
  getFormField,
  getOnChangeFormField,
  isPending,
  isRejected,
  validateEmailAddress,
} from '../../utils';
import { Form } from './Form';

type Props = {
  status: PromiseStatus;
};

export function RequestResetPasswordForm({ status }: Props) {
  const dispatch = useAppDispatch();

  const isMakingRequest = isPending(status);
  const hasError = isRejected(status);
  const [formError, setFormError] = useState('');

  const [email, setEmail] = useState(getFormField(''));

  const onChangeEmail = useCallback(
    getOnChangeFormField(validateEmailAddress, setEmail),
    [],
  );

  async function onSubmit(evt: SyntheticEvent) {
    evt.preventDefault();
    if (!email.value) {
      setFormError('Enter email to continue');
    } else {
      setFormError('');
      requestResetPassword(dispatch, email.value);
    }
  }

  return (
    <Form className="flex flex-col gap-1" onSubmit={onSubmit}>
      <p className="detail color-shade-60 mb1">
        Enter the email address linked with your Gatherly account and we will
        send you instructions for resetting your password.{' '}
        <Clickable
          onClick={() => setFulfilled(dispatch, 'requestResetPassword')}
          variant={ClickableVariant.LINK}
        >
          I already have a code.
        </Clickable>
      </p>
      <Input
        autoComplete="email"
        label="Email Address"
        name="email"
        onChange={evt => onChangeEmail(evt.target.value)}
        value={email.value}
        error={email.error}
        isDisabled={isMakingRequest}
        isRequired
      />
      <Clickable
        isSubmit
        isDisabled={isMakingRequest}
        label={isMakingRequest ? 'SUBMITTING...' : 'SUBMIT'}
        variant={ClickableVariant.BUTTON_PRIMARY}
      />
      <div className="flex flex-1 flex-col gap-1 items-center pt1">
        {hasError && (
          <p className="color-red small text-center">
            Something went wrong, please check your email address and try again.
          </p>
        )}
        {formError && (
          <p className="color-red small text-center">{formError}</p>
        )}
      </div>
    </Form>
  );
}
