import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Auth as CognitoAuth } from 'aws-amplify';
import Cookies from 'js-cookie';

import { UserAuthState } from '../enums';

import { USER_SESSION_COOKIE_NAME } from '../config';

import { clearCookies, setCookies, SessionData } from './storage';
import { removeHttpAuthToken, setHttpAuthToken } from './api';
import {
  AccountsUser,
  DEFAULT_TICKETS,
  DEFAULT_AI_TOKENS,
} from '@eventmanager/types';
import { trackLoggedIn } from '../libs/trackingLib';

export const FEDERATED_SIGN_IN_MERGED_ERROR =
  'Already found an entry for username';

const EMPTY_SESSION: SessionData = {
  userId: '',
  userEmail: '',
  idToken: '',
};

export const saveSession = (sessionData: SessionData) => {
  const { idToken } = sessionData;
  setCookies({ sessionData, idToken });
  setHttpAuthToken(idToken);
};

export const getSession = (): string | null => {
  return Cookies.get(USER_SESSION_COOKIE_NAME) || null;
};

export async function getCurrentAuthState(): Promise<UserAuthState> {
  if (!getSession()) return UserAuthState.LOGGED_OUT;
  try {
    const user = await CognitoAuth.currentAuthenticatedUser();
    if (user.attributes.email_verified) return UserAuthState.LOGGED_IN;
    if (user.attributes.email) return UserAuthState.UNVERIFIED;
    return UserAuthState.LOGGED_OUT;
  } catch (e) {
    console.log(e);
    return UserAuthState.LOGGED_OUT;
  }
}

export const getSessionData = (): SessionData => {
  try {
    const session = getSession();
    if (session) {
      const sessionData = JSON.parse(session);
      if (sessionData) {
        return sessionData;
      }
    }
    return EMPTY_SESSION;
  } catch (error) {
    console.error('Error parsing sesssion', error);
    return EMPTY_SESSION;
  }
};

const deleteSession = () => {
  removeHttpAuthToken();
  clearCookies();
};

export type SignUpParams = {
  company?: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
};

export const signUp = async (params: SignUpParams): Promise<AccountsUser> => {
  const user = await CognitoAuth.signUp({
    password: params.password,
    username: params.email,
    attributes: {
      given_name: params.firstName,
      family_name: params.lastName,
      'custom:company': params.company,
    },
    autoSignIn: {
      enabled: true,
    },
  });

  return {
    ...params,
    company: params.company ?? '',
    role: 'free',
    reservedTickets: 0,
    tickets: DEFAULT_TICKETS,
    aiTokens: DEFAULT_AI_TOKENS,
    userId: user.userSub,
    permissionOverrides: {},
  };
};

export const signIn = async (email: string, password: string) => {
  const user = await CognitoAuth.signIn(email, password);
  if (user) {
    trackLoggedIn({
      userId: user.attributes.sub,
      logged_in_via: 'email',
    });
    saveSession({
      userId: user.attributes.sub,
      userEmail: user.attributes.email,
      idToken: user.signInUserSession.idToken.jwtToken,
    });
  }
  return user;
};

export const signInWithGoogle = async () => {
  CognitoAuth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Google,
  }).catch(reason => {
    console.log(reason);
  });
};

export const initiateSignIn = (errorMessage: string | undefined | null) => {
  if (
    errorMessage?.replaceAll('+', ' ').includes(FEDERATED_SIGN_IN_MERGED_ERROR)
  ) {
    // For now, Google is the only federated sign in. Update as necessary once more are added
    signInWithGoogle();
  }
};

export const setSignedInUserSession = async () => {
  try {
    const user = await CognitoAuth.currentAuthenticatedUser();

    if (user) {
      saveSession({
        userId: user.attributes.sub,
        userEmail: user.attributes.email,
        idToken: user.signInUserSession.idToken.jwtToken,
      });
      return true;
    }
  } catch (error) {
    console.log(error);
  }

  return false;
};

export async function signOut() {
  await CognitoAuth.signOut();
  clearCurrentSession();
}

export const clearCurrentSession = () => {
  deleteSession();
  document.location.reload();
};

export async function verifyAccount(email: string, code: string) {
  return CognitoAuth.confirmSignUp(email, code, {
    // Optional. Force user confirmation irrespective of existing alias.
    // By default set to True.
    forceAliasCreation: true,
  });
}

export async function resendVerificationCode(email: string) {
  return CognitoAuth.resendSignUp(email);
}

export async function requestResetPassword(email: string) {
  return CognitoAuth.forgotPassword(email);
}

export async function resetPassword(
  email: string,
  code: string,
  password: string,
) {
  return CognitoAuth.forgotPasswordSubmit(email, code, password);
}

export async function changePassword(oldPassword: string, newPassword: string) {
  const user = await CognitoAuth.currentAuthenticatedUser();
  return CognitoAuth.changePassword(user, oldPassword, newPassword);
}
