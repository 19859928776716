import React, { ReactNode } from 'react';
import { c } from '../../utils';
import classes from './FieldWrapper.module.scss';

type Props = {
  className?: string;
  children: ReactNode;
  helpText?: string | ReactNode;
  title?: string;
};

export function FieldWrapper({ children, className, helpText, title }: Props) {
  return (
    <div
      className={c(
        'flex flex-col items-start gap-_5',
        className,
        classes.FieldWrapper,
      )}
    >
      {title && (
        <p className={`body  color-shade-80 ${classes.fieldTitle}`}>{title}</p>
      )}
      {helpText && <p className={'subbody mb_5 color-shade-80'}>{helpText}</p>}
      {children}
    </div>
  );
}
