import React from 'react';

import { c } from '../../utils';

import { Clickable, ClickableVariant } from '../../components/Clickable';
import { Image } from '../../components/Image';
import classes from './ResourcesPage.module.scss';

export type ResourceCardProps = {
  type: string;
  title: string;
  image: string;
  description: string;
  ctaLabel: string;
  ctaLocation: string;
};

export function ResourceCard({
  type,
  title,
  image,
  description,
  ctaLabel,
  ctaLocation,
}: ResourceCardProps) {
  return (
    <div
      className={c(
        classes.ResourceCard,
        'flex flex-col md:flex-row md:items-center p1_5 md:px0 md:ml2',
        'bg-color-shade-10 br_25',
      )}
    >
      <small className="color-shade-60 small--spaced uppercase md:none mb1">
        {type}
      </small>
      <div
        className={c(
          classes.image,
          'br_5 bg-color-silver overflow-hidden shadow-md flex-none mb2 md:mb0',
        )}
      >
        <Image src={image} alt={title} />
      </div>
      <div className="flex flex-col md:items-start md:pr2 gap-1">
        <small className="color-shade-60 small--spaced uppercase none md:flex">
          {type}
        </small>
        <h3 className="color-shade-80 subheading bold">{title}</h3>
        <p className="color-shade-80 detail">{description}</p>
        <div className="flex mt1">
          <Clickable
            label={ctaLabel}
            variant={ClickableVariant.BUTTON_SECONDARY}
            to={ctaLocation}
          >
            <span className="color-white">{ctaLabel}</span>
          </Clickable>
        </div>
      </div>
    </div>
  );
}
