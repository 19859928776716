import { useCallback } from 'react';
import { AddRounded } from '@material-ui/icons';
import { Clickable, ClickableVariant } from '../../../Clickable';
import { setOverlay, OverlayType } from '../../../../actions';
import { useAppDispatch } from '../../../../hooks';
import { SALES_CONTACT_URL } from '../../../../config';

type EventFloorsFormFooterProps = {
  eventId: string;
  isDisabled: boolean;
  maxFloors: number;
};

type MaxFloorsMessageProps = {
  maxFloors: number;
};

function MaxFloorsMessage({ maxFloors }: MaxFloorsMessageProps) {
  const unlimitedMaxFloors = maxFloors === -1;
  const maxFloorsText = [
    `Max. floors ${unlimitedMaxFloors ? '∞' : maxFloors}. `,
    !unlimitedMaxFloors && (
      <>
        {'To upgrade, '}
        <Clickable variant={ClickableVariant.LINK} to={SALES_CONTACT_URL}>
          contact sales
        </Clickable>
      </>
    ),
  ].filter(Boolean);

  return (
    <div className="flex flex-col justify-end">
      <p className="color-shade-60 detail">
        {maxFloorsText.map((msg, index) => (
          <span key={`max-floor-msg-${index}`}>{msg}</span>
        ))}
      </p>
    </div>
  );
}

export function EventFloorsFormFooter({
  eventId,
  isDisabled,
  maxFloors,
}: EventFloorsFormFooterProps) {
  const dispatch = useAppDispatch();
  const onClickAddFloor = useCallback(async () => {
    setOverlay(dispatch, OverlayType.NEW_FLOOR, { eventId });
  }, [eventId]);

  return (
    <div className="flex gap-1 my1">
      <Clickable
        isDisabled={isDisabled}
        onClick={onClickAddFloor}
        variant={ClickableVariant.BUTTON_TERTIARY}
        data-cy="add-floor"
      >
        <AddRounded className="mr_5" />
        Add Floor
      </Clickable>
      <MaxFloorsMessage maxFloors={maxFloors} />
    </div>
  );
}
