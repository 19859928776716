import React, { useCallback, useEffect, useState, SyntheticEvent } from 'react';

import { Clickable, ClickableVariant } from '../Clickable';
import { Input } from '../Input';
import { resendVerificationCode, setIdle } from '../../actions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getStatusOfResendVerification, currentUser } from '../../selectors';
import {
  getFormField,
  getOnChangeFormField,
  isPending,
  isRejected,
  validateString,
} from '../../utils';
import { Form } from './Form';

export function ResendVerificationForm() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    setIdle(dispatch, 'resendVerification');
  }, []);

  const user = useAppSelector(currentUser);
  const status = useAppSelector(getStatusOfResendVerification);
  const isMakingRequest = isPending(status);
  const hasError = isRejected(status);

  const [email, setEmail] = useState(getFormField(user?.email || ''));

  const onChangeEmail = useCallback(
    getOnChangeFormField(value => validateString(value, 'Email'), setEmail),
    [],
  );

  const onSubmit = useCallback(
    async (evt: SyntheticEvent) => {
      evt.preventDefault();

      // validate
      const _email = onChangeEmail(email.value);

      if (_email.error) return;
      resendVerificationCode(dispatch, _email.value);
    },
    [email.value],
  );

  return (
    <Form className="flex flex-col gap-1" onSubmit={onSubmit}>
      <Input
        label="Email Address"
        name="email"
        onChange={evt => onChangeEmail(evt.target.value)}
        value={email.value}
        error={email.error}
        isDisabled={!!user?.email}
        isRequired
      />
      <div className="flex justify-center">
        <Clickable
          className="mt_75"
          isSubmit
          isDisabled={isMakingRequest}
          label={isMakingRequest ? 'RESENDING...' : 'RESEND CODE'}
          variant={ClickableVariant.BUTTON_PRIMARY}
        />
      </div>
      {hasError && (
        <p className="color-red small text-center">
          Unable to resend code. Please confirm email address and try again.
        </p>
      )}
      <Clickable variant={ClickableVariant.LINK} to="/sign-up">
        Don't have an account? Sign Up!
      </Clickable>
    </Form>
  );
}
