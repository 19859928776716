import React from 'react';

import { EditEvent } from '../../components/EditEvent';
import { EventMedia } from '../../components/EventMedia';
import { PostEventReport } from '../../components/PostEventReport';
import { TabConfig } from '../../components/Tabs';

type Props = {
  eventId: string;
  isDisabled: boolean;
  tabId: string;
};

export enum EventTab {
  EDIT = 'edit',
  ANALYTICS = 'analytics',
  MEDIA = 'media',
}

export const tabIds: EventTab[] = Object.values(EventTab);
export const getTabs = (eventId: string): TabConfig[] => [
  {
    id: EventTab.EDIT,
    label: 'Configuration',
    to: `/events/${eventId}/${EventTab.EDIT}`,
  },
  {
    id: EventTab.ANALYTICS,
    label: 'Analytics',
    to: `/events/${eventId}/${EventTab.ANALYTICS}`,
  },
  {
    id: EventTab.MEDIA,
    label: 'Media',
    to: `/events/${eventId}/${EventTab.MEDIA}`,
  },
];

export function EventDetailPageContent({ eventId, isDisabled, tabId }: Props) {
  switch (tabId) {
    case EventTab.ANALYTICS:
      return <PostEventReport eventId={eventId} />;
    case EventTab.MEDIA:
      return <EventMedia eventId={eventId} />;
    case EventTab.EDIT:
    default:
      return <EditEvent eventId={eventId} isDisabled={isDisabled} />;
  }
}
