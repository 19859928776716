import { generate as shortUuid } from 'short-uuid';

import {
  makeClearOverlayAction,
  makeSetBulkEditAction,
  makeSetOverlayAction,
  OverlayType,
} from '../store/uiReducer';

export { OverlayType } from '../store/uiReducer';

export function setOverlay(dispatch, overlayType: OverlayType, data?: any) {
  dispatch(makeSetOverlayAction({ overlayType, data, id: shortUuid() }));
}

export function clearOverlay(dispatch, id: string, canDismiss = true) {
  if (!canDismiss) return;
  dispatch(makeClearOverlayAction(id));
}

export function setBulkEdit(dispatch, isActive: boolean) {
  dispatch(makeSetBulkEditAction(isActive));
}
