import { EventAnalytics } from '@eventmanager/types';
import { useMemo } from 'react';
import { HOUR } from '../../config';
import {
  getFloorLabelsFromAnalytics,
  getTimeScaleFromAnalytics,
  getTimeZone,
  humanizeShortHand,
} from '../../utils';
import { BarChart, LineChart } from '../Chart';
import EventStatsCard from './EventStatsCard';
import classes from './AnalyticsOverview.module.scss';

type Props = {
  analytics: EventAnalytics;
  eventStartTime: number;
  eventStopTime: number;
};

const timeZone = getTimeZone();

export function AnalyticsOverview({
  analytics,
  eventStartTime,
  eventStopTime,
}: Props) {
  const eventDuration = useMemo(() => {
    return humanizeShortHand(analytics.EventDuration * HOUR);
  }, [analytics]);
  const occupancyDateTimeScale = useMemo(() => {
    return getTimeScaleFromAnalytics(analytics, eventStartTime, eventStopTime);
  }, [analytics, eventStartTime, eventStopTime]);
  const floorLabels = useMemo(() => {
    return getFloorLabelsFromAnalytics(analytics);
  }, [analytics]);

  return (
    <>
      <div className="heading bold">Overview</div>
      <div className="flex flex-col">
        <div className="flex flex-wrap flex-row gap-1">
          <EventStatsCard
            title={'Total Event Duration'}
            className={classes.card}
            body={
              <div
                data-cy={'event-duration'}
                className="heading bold color-shade-80"
              >
                {eventDuration}
              </div>
            }
          />
          <EventStatsCard
            title={'Total Attendees'}
            className={classes.card}
            body={
              <div
                data-cy={'total-attendees'}
                className="heading bold color-shade-80"
              >
                {analytics.NumPeople}
              </div>
            }
          />
        </div>

        <div className="flex flex-wrap flex-row gap-1">
          <EventStatsCard
            title={`Occupancy Over Time (${timeZone})`}
            className={classes.card}
            body={
              <LineChart
                data={analytics.OccupancyData}
                label="People"
                xAxisLabels={occupancyDateTimeScale}
              />
            }
          />
          <EventStatsCard
            title={'Occupancy by Floor'}
            className={classes.card}
            body={
              <BarChart
                data={analytics.NumPeopleByFloor}
                label="People"
                xAxisLabels={floorLabels}
              />
            }
          />
        </div>
      </div>
    </>
  );
}

AnalyticsOverview.displayName = 'AnalyticsOverview';
