import React, { ReactNode } from 'react';
import { useTitle } from 'react-use';

import { c } from '../../utils';
import classes from './Toolbar.module.scss';

declare interface Props {
  title?: string;
  children?: ReactNode;
  classNames?: string;
  noPadding?: boolean;
  withInternalNav?: boolean;
}

const BASE_PAGE_TITLE = 'Event Manager | Gatherly';
function makePageTitle(title: string) {
  return `${title} - ${BASE_PAGE_TITLE}`;
}

export function Toolbar({
  title = '',
  children,
  classNames,
  noPadding,
  withInternalNav,
}: Props) {
  useTitle(makePageTitle(title));

  return (
    <div
      className={c(
        classes.Toolbar,
        classNames,
        withInternalNav && classes.withInternalNav,
        'flex items-center justify-between',
        't0 r0 fixed md:gap-1 z-3',
        !noPadding && 'p2',
      )}
    >
      <h1 className="subheading bold color-shade-80 nowrap overflow-ellipsis pr1">
        {title}
      </h1>
      <div className="flex-1 flex flex-wrap md:nowrap md:gap-1">{children}</div>
    </div>
  );
}
