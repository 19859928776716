import { ReactNode, SyntheticEvent, useCallback } from 'react';
import { Facebook, LinkedIn, Twitter } from '@material-ui/icons';
import {
  classnames,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@gatherly/lib';
import { LinkConfig } from '@gatherly/types';
import { Clickable } from '../Clickable';
import classes from './EventLinkList.module.scss';

type Props = {
  linkConfig: LinkConfig;
  className?: string;
};

const socialLinks = [
  {
    href: `https://www.linkedin.com/sharing/share-offsite/?url=`,
    text: 'LinkedIn',
    icon: <LinkedIn />,
  },
  {
    href: `https://www.facebook.com/sharer/sharer.php?u=`,
    text: 'Facebook',
    icon: <Facebook />,
  },
  {
    href: `https://twitter.com/intent/tweet?url=`,
    text: 'Twitter',
    icon: <Twitter />,
  },
];

const shareWindowSize: number = 500;
const getWindowOptions = () => ({
  height: shareWindowSize,
  width: shareWindowSize,
  left: window.screen.width / 2 - 250,
  top: window.screen.height / 2 - 250,
  toolbar: 'no',
  location: 'no',
  status: 'no',
  menubar: 'no',
  scrollbars: 'no',
  resizable: 'no',
  directories: 'no',
  titlebar: 'no',
});

type SocialButtonProps = {
  href: string;
  text: string;
  icon?: ReactNode;
  onClick?: (e: SyntheticEvent) => void;
};

const SocialButton = ({ href, text, icon, onClick }: SocialButtonProps) => {
  const handleOpen = useCallback(
    (evt: SyntheticEvent) => {
      onClick?.(evt);
      const windowOptions = getWindowOptions();
      window.open(
        href,
        '_blank',
        Object.entries(windowOptions)
          .reduce((acc, [key, val]) => {
            acc.push(`${key}=${val}`);
            return acc;
          }, [] as string[])
          .join(','),
      );
    },
    [href, onClick],
  );

  return (
    <Clickable
      className={classnames(classes.shareButton, 'mb_5')}
      onClick={handleOpen}
    >
      {icon}
      <div>{text}</div>
    </Clickable>
  );
};

export const SharePopover = ({ linkConfig }: Props): JSX.Element => (
  <Popover>
    <PopoverTrigger className={classes.actionLink}>Share</PopoverTrigger>
    <PopoverContent
      side="top"
      shadow
      className={classnames(classes.shareButtons, 'px1')}
    >
      {socialLinks.map(({ href, text, icon }, index) => (
        <SocialButton
          key={`social-button-${index}`}
          href={href + window.encodeURIComponent(linkConfig.url as string)}
          text={text}
          icon={icon}
        />
      ))}
    </PopoverContent>
  </Popover>
);

export default SharePopover;
