// @ts-ignore - implicit any

import {
  BANNERS_BASEURL,
  BOOTH_BACKGROUND_BASEURL,
  GAME_BACKGROUND_BASEURL,
  GAME_LOGO_BASEURL,
  MAP_BASEURL,
  UGC_S3_BUCKET,
  UGC_S3_BUCKET_URL,
} from '../config';

import { GameType } from '@gatherly/types';
import isEqual from 'lodash.isequal';

type Falsy = false | 0 | '' | null | undefined;
export function c(...classNames: (string | Falsy)[]) {
  return classNames.filter(v => v).join(' ');
}

export function isExternalLink(str: string) {
  try {
    new URL(str);
    return true;
  } catch (e) {
    return false;
  }
}

export function reorder<T>(
  list: T[],
  startIndex: number,
  endIndex: number,
): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export const pluralize = (count: number, label: string) =>
  `${count} ${label}${count !== 1 ? 's' : ''}`;

export function lastItem<T>(arr: T[]): T | undefined {
  return arr[arr.length - 1];
}

export function difference<T extends object>(
  initial: T,
  updated: Partial<T>,
): Partial<T> {
  return Object.keys(updated).reduce((memo, key) => {
    // @ts-ignore - implicit any
    if (!isEqual(initial[key], updated[key])) {
      // @ts-ignore - implicit any
      memo[key] = updated[key];
    }
    return memo;
  }, {});
}
export function objectHasDifference<T extends object>(
  initial: T,
  updated: Partial<T>,
): boolean {
  const _difference = difference(initial, updated);
  return Object.keys(_difference).length > 0;
}

export function scrollTo(positionY: number, element?: Element) {
  if (element) {
    element.scrollTop = positionY;
    return;
  }
  document.body.scrollTop = positionY; // For Safari
  document.documentElement.scrollTop = positionY;
}

export function getByteLength(input: any) {
  if (input === null || input === undefined) return 0;
  if (typeof input.byteLength === 'number') {
    return input.byteLength;
  } else if (typeof input.length === 'number') {
    return input.length;
  } else if (typeof input.size === 'number') {
    return input.size;
  } else if (typeof input.path === 'string') {
    /* NodeJs Support
    return require('fs').lstatSync(input.path).size;
    */
  } else {
    throw new Error('Cannot determine length of ' + input);
  }
}

export const kebabify = (str: string) =>
  str.replace(/[^a-zA-Z\d-]+/g, '-').replace(/-+/g, '-');
export const getKeyForS3Item = (url: string) =>
  url.replace(UGC_S3_BUCKET_URL, '');
export const getS3UrlForFile = (fileName: string) =>
  `${UGC_S3_BUCKET_URL}${fileName}`;

export const getMapUrl = (fileName: string) => `${MAP_BASEURL}${fileName}`;
export const getBoothImageUrl = (fileName: string) =>
  `${BOOTH_BACKGROUND_BASEURL}${fileName}`;
export const getGameImageUrl = (fileName: string) =>
  `${GAME_BACKGROUND_BASEURL}${fileName}`;
export const getGameLogoUrl = (gameType: GameType) =>
  `${GAME_LOGO_BASEURL}${gameType.toLowerCase().replaceAll(' ', '')}.png`;

export const getBannerUrl = (fileName: string) =>
  `${BANNERS_BASEURL}${fileName}`;

export const isUserUploaded = (fileName: string) =>
  Array.isArray(fileName.match(UGC_S3_BUCKET));

export function orderObjectsBy<T>(
  elements: T[],
  orderKey: string,
  order: string[],
) {
  // @ts-ignore
  return order.map(orderVal =>
    elements.find(elem => elem[orderKey] === orderVal),
  );
}
export function toListMap<T extends Object>(
  elements: T[],
  key: string,
): Record<string, T> {
  return Object.values(elements).reduce((memo, item: T) => {
    if (!item.hasOwnProperty(key)) return memo;
    return {
      ...memo,
      // @ts-ignore
      [item[key]]: item,
    };
  }, {});
}

export function getVal(pathString: string | string[], obj: any): any {
  const path = Array.isArray(pathString)
    ? pathString
    : pathString
        .replace(/\[|\]/g, '')
        .replace(/\d/g, match => `.${match}`)
        .split('.');
  return path.reduce((acc, part) => {
    path.shift();
    if (Array.isArray(acc[part])) {
      const nextPart = path[0];
      const nextPartIndex = Number(path[0]);
      path.shift();
      if (nextPart) {
        if (isNaN(nextPartIndex)) {
          return getVal(
            path,
            acc[part].map((aObj: any) => aObj[nextPart]).flat(),
          );
        }

        return getVal(path, obj[part][nextPartIndex]);
      }

      return acc[part];
    }

    return getVal(path, acc[part]);
  }, obj);
}

export const noop = () => null;
export const asyncNoop = () => Promise.resolve();
