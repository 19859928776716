import React, { useState, SyntheticEvent, useCallback, useEffect } from 'react';
import isEqual from 'lodash.isequal';
import { UpdateUserParams } from '@eventmanager/types';

import { Input } from '../Input';

import { updateCurrentUser } from '../../actions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Clickable, ClickableVariant } from '../Clickable';
import { getStatusOfUpdateUser } from '../../selectors';
import {
  getFormField,
  isPending,
  validateString,
  toTitleCase,
  c,
} from '../../utils';
import { Form } from './Form';

declare interface Props {
  userId: string;
  firstName: string;
  lastName: string;
  company: string;
  role: string;
  email: string;
  className?: string;
}

export const requiredFields = ['firstName', 'lastName'];
const isRequiredFields = requiredFields.reduce(
  (acc, cur) => ({ [cur]: true }),
  {},
);

const EMPTY_USER_DETAILS: UpdateUserParams = {
  firstName: '',
  lastName: '',
  company: '',
};

function getUserDetailValues(userDetails: object): UpdateUserParams {
  return Object.entries(userDetails).reduce((acc, [key, [{ value }]]) => {
    acc[key] = value;
    return acc;
  }, EMPTY_USER_DETAILS);
}

export function UserDetailsForm({
  className,
  firstName,
  lastName,
  company,
  email,
}: Props) {
  const baseValues = { firstName, lastName, company };
  const dispatch = useAppDispatch();

  const userDetails = {
    firstName: useState(getFormField(firstName)),
    lastName: useState(getFormField(lastName)),
    company: useState(getFormField(company)),
  };

  const handleUserChange = useCallback(({ target: { name, value } }) => {
    const inputChangeHandler = userDetails[name][1];
    return inputChangeHandler(validateString(value, toTitleCase(name)));
  }, []);

  const handleSubmit = (evt: SyntheticEvent) => {
    evt.preventDefault();
    updateCurrentUser(dispatch, getUserDetailValues(userDetails));
  };

  const resetUserDetails = () => {
    Object.keys(userDetails).forEach(key => {
      userDetails[key][0] = getFormField(baseValues[key]);
      userDetails[key][1](userDetails[key][0]);
    });
  };

  const status = useAppSelector(getStatusOfUpdateUser);
  const isMakingRequest = isPending(status);
  const isDisabled =
    isMakingRequest || isEqual(baseValues, getUserDetailValues(userDetails));

  useEffect(() => {
    resetUserDetails();
  }, [firstName, lastName, company]);

  return (
    <Form
      className={c(className, 'flex flex-col flex-1 gap-2 br_25')}
      onSubmit={handleSubmit}
    >
      {Object.entries(userDetails).map(([name, [{ value, error }]]) => (
        <Input
          key={`user-detail-${name}`}
          label={toTitleCase(name)}
          name={name}
          onChange={handleUserChange}
          value={value}
          error={error}
          isRequired={!!isRequiredFields[name]}
        />
      ))}
      <Input
        key={`user-detail-email`}
        label={'Email'}
        name={'email'}
        value={email}
        isDisabled={true}
      />
      <div className="flex flex-row flex-1 justify-center items-center lg:justify-end gap-1">
        <Clickable
          isSubmit
          isDisabled={isDisabled}
          variant={ClickableVariant.BUTTON_SECONDARY}
          label={isMakingRequest ? 'Saving...' : 'Save'}
        />
        <Clickable
          isDisabled={isDisabled}
          onClick={resetUserDetails}
          variant={ClickableVariant.BUTTON_TERTIARY}
          label="Reset"
        />
      </div>
    </Form>
  );
}

export default UserDetailsForm;
