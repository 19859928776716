import { useMemo, useCallback, useState, useEffect } from 'react';
import LuxonUtils from '@date-io/luxon';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { getTimeZone } from '../../utils/date';
import { DateTime } from 'luxon';

const DATE_FORMAT = 'MM/dd/yyyy hh:mm a';
const DATE_MASK = '__/__/____ __:__ _M';

export type InputEvent = {
  target: {
    name: string;
    value: number;
  };
};
type Props = {
  durationError?: string;
  isDisabled?: boolean;
  onChange: (evt: InputEvent) => void;
  startValue: number | Date;
  stopValue: number | Date;
};

export function DateRangePicker({
  durationError,
  isDisabled,
  onChange,
  startValue: startValueProp,
  stopValue: stopValueProp,
}: Props) {
  const timeZone = useMemo(() => getTimeZone(), []);

  const handleChange = useCallback(
    (dateTime: MaterialUiPickersDate, name: string) => {
      const value = dateTime?.valueOf() || Date.now();
      const event = {
        target: {
          name,
          value,
        },
      };
      onChange(event);
    },
    [onChange],
  );

  const valueFromTimeProp = useCallback((value: number | Date) => {
    return typeof value === 'number'
      ? DateTime.fromMillis(value)
      : DateTime.fromMillis(new Date(value).getTime());
  }, []);

  const [startError, setStartError] = useState('');
  const [stopError, setStopError] = useState('');
  const [startValue, setStartValue] = useState<MaterialUiPickersDate>(
    valueFromTimeProp(startValueProp),
  );
  const [stopValue, setStopValue] = useState<MaterialUiPickersDate>(
    valueFromTimeProp(stopValueProp),
  );

  useEffect(() => {
    setStartValue(valueFromTimeProp(startValueProp));
  }, [startValueProp]);

  useEffect(() => {
    setStopValue(valueFromTimeProp(stopValueProp));
  }, [stopValueProp]);

  return (
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <div>
        <div className="flex flex-wrap items-center gap-1">
          <KeyboardDateTimePicker
            variant="inline"
            ampm={true}
            label={`Start Time (${timeZone})`}
            value={startValue}
            disabled={isDisabled}
            minutesStep={5}
            onChange={(dateTime: MaterialUiPickersDate) => {
              setStartValue(dateTime);
              if (!dateTime) return;

              if (dateTime?.invalidReason) {
                setStartError(
                  dateTime.invalidReason === 'unparsable'
                    ? 'Invalid date'
                    : dateTime.invalidReason,
                );
                return;
              }

              setStartError('');
              handleChange(dateTime, 'startTime');
            }}
            error={Boolean(startError || durationError)}
            helperText={startError || ' '}
            disablePast
            format={DATE_FORMAT}
            mask={DATE_MASK}
          />

          <p className="body">to</p>

          <KeyboardDateTimePicker
            variant="inline"
            ampm={true}
            label={`End Time (${timeZone})`}
            value={stopValue}
            disabled={isDisabled}
            minutesStep={5}
            onChange={(dateTime: MaterialUiPickersDate) => {
              setStopValue(dateTime);
              if (!dateTime) return;
              if (dateTime?.invalidReason) {
                setStopError(
                  dateTime.invalidReason === 'unparsable'
                    ? 'Invalid date'
                    : dateTime.invalidReason,
                );
                return;
              }

              setStopError('');
              handleChange(dateTime, 'stopTime');
            }}
            error={Boolean(stopError || durationError)}
            helperText={stopError || ' '}
            disablePast
            format={DATE_FORMAT}
            mask={DATE_MASK}
          />
        </div>
        {durationError && <div className="color-red">{durationError}</div>}
      </div>
    </MuiPickersUtilsProvider>
  );
}
