import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

import {
  API_BASEURL,
  API_TIMEOUT,
  USER_ID_TOKEN_COOKIE_NAME,
} from '../../config';
import { Auth } from '../../utils';

export const http = axios.create({
  baseURL: API_BASEURL,
  timeout: API_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
    authorization: Cookies.get(USER_ID_TOKEN_COOKIE_NAME) || '',
  },
});

http.interceptors.response.use(
  response => response,
  error => {
    // Force log-out and re-login on Unauthorized
    if (error?.response?.status === 401) {
      Auth.signOut();
      console.warn(error);
      return;
    }
    return Promise.reject(error);
  },
);

const requestInterceptors = (token: string) => [
  (config: AxiosRequestConfig) => {
    if (!config.headers) {
      config.headers = {};
    }
    config.headers.authorization = token;
    return config;
  },
  (error: AxiosRequestConfig) => Promise.reject(error),
];

export let requestAuthInterceptorId: number;

export function setHttpAuthToken(token: string = ''): void {
  requestAuthInterceptorId = http.interceptors.request.use(
    ...requestInterceptors(token),
  );
}

export function removeHttpAuthToken(): void {
  http.interceptors.request.eject(requestAuthInterceptorId);
}

export default http;
