import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'wouter';

import { setIdle, signOut } from '../../actions';
import { Clickable, ClickableVariant } from '../../components/Clickable';
import { Image } from '../../components/Image';
import { Footer } from '../../components/Footer';
import {
  RequestResetPasswordForm,
  ResetPasswordForm,
} from '../../components/forms';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Images } from '../../images';
import {
  getStatusOfRequestResetPassword,
  getStatusOfResetPassword,
} from '../../selectors';
import { c, isFulfilled } from '../../utils';
import classes from './ForgotPasswordPage.module.scss';

export function ForgotPasswordPage() {
  const [, setLocation] = useLocation();
  const dispatch = useAppDispatch();
  const requestResetStatus = useAppSelector(getStatusOfRequestResetPassword);
  const resetStatus = useAppSelector(getStatusOfResetPassword);

  useEffect(() => {
    setIdle(dispatch, 'requestResetPassword');
    setIdle(dispatch, 'resetPassword');
  }, []);

  const handleSignOut = useCallback(async () => {
    await signOut(dispatch);
    setLocation('/');
  }, []);

  return (
    <div className={c('page-inner', classes.ForgotPasswordPage)}>
      <Image
        src={Images.MAP_PARTY.src}
        alt={Images.MAP_PARTY.alt}
        className="flex flex-1 flex-col justify-center md:flex-row-reverse"
        isBackground
      >
        <div className="flex flex-col flex-1 md:col-6 p1 md:p0 ">
          <div className="flex flex-col flex-1 items-center justify-center">
            <div
              className={c(
                classes.formWrapper,
                'flex flex-col bg-color-white bo-1 border-color-shade-30 shadow-md pt1_25 pb2 px1_5',
              )}
            >
              <div className="flex justify-end">
                <Image
                  src={Images.GATHERLY_LOGO.src}
                  alt={Images.GATHERLY_LOGO.alt}
                  className={classes.logo}
                />
              </div>
              <h2 className="subtitle mt_5 mb1_5">Forgot Password?</h2>
              {isFulfilled(requestResetStatus) ? (
                <ResetPasswordForm status={resetStatus} />
              ) : (
                <RequestResetPasswordForm status={requestResetStatus} />
              )}
              <div className="flex flex-1 flex-col gap-1 items-center pt1">
                <Clickable variant={ClickableVariant.LINK} to="/sign-up">
                  Don't have an account? Sign Up!
                </Clickable>
                {isFulfilled(requestResetStatus) ? (
                  <Clickable
                    variant={ClickableVariant.LINK}
                    onClick={handleSignOut}
                  >
                    Back to Sign In
                  </Clickable>
                ) : (
                  <Clickable variant={ClickableVariant.LINK} to="/">
                    Back to Sign In
                  </Clickable>
                )}
              </div>
            </div>
          </div>
          <Footer className="color-white" />
        </div>
        <div className="flex flex-col justify-center md:col-6">
          <div className="hidden md:visible flex-1">
            <div className="flex flex-col">
              <span
                className={c(
                  classes.huddleFour,
                  'bg-color-leaf-green color-logo-green--dark border-color-logo-green--dark',
                )}
              >
                4
              </span>
            </div>
            <div className="flex flex-col">
              <span
                className={c(
                  classes.huddleTwo,
                  'bg-color-leaf-green color-logo-green--dark border-color-logo-green--dark',
                )}
              >
                2
              </span>
            </div>
            <div className="flex flex-col">
              <span
                className={c(
                  classes.huddleThree,
                  'bg-color-leaf-green color-logo-green--dark border-color-logo-green--dark',
                )}
              >
                3
              </span>
            </div>
          </div>
        </div>
      </Image>
    </div>
  );
}
