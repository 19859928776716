import React from 'react';
import { HelpRounded } from '@material-ui/icons';

import { MINUTE, SERVER_LAUNCH_WINDOW } from '../../config';
import { c } from '../../utils';
import { Tooltip } from '../Tooltip';

import classes from './EventCountdown.module.scss';

export function CountdownTooltip() {
  return (
    <div className="absolute px_5 py_25 t0 l0">
      <Tooltip
        content={
          <p>
            Edits can be made until <br />
            {SERVER_LAUNCH_WINDOW / MINUTE} min before start time
          </p>
        }
        className="small"
      >
        <HelpRounded className={c(classes.icon, 'color-shade-10')} />
        <span className="sr-only">?</span>
      </Tooltip>
    </div>
  );
}
