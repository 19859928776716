import React, { useEffect } from 'react';
import { useTimeout } from 'react-use';
import {
  CachedRounded as DownloadingIcon,
  GetAppRounded as DownloadIcon,
  ErrorRounded as ErrorIcon,
  CheckRounded as CheckIcon,
  PlayArrowRounded as PlayIcon,
} from '@material-ui/icons';

import { setIdle } from '../../actions';
import { Clickable } from '../../components/Clickable';
import { SECOND } from '../../config';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getStatusOfDownloadBroadcastRecording } from '../../selectors';
import {
  c,
  formatDate,
  getMillisAsTimestamp,
  isFulfilled,
  isPending,
  isRejected,
} from '../../utils';
import classes from './BroadcastRecordingsTable.module.scss';

export type TableRow = {
  recordingId: string;
  name: string;
  durationMillis: number;
  floor: string;
  startTime: number;
};
type Props = TableRow & {
  handleDownload: () => void;
  handlePlay: () => void;
  hasPermission: boolean;
  index: number;
};

export function BroadcastRecordingsTableRow({
  durationMillis,
  floor,
  handleDownload,
  handlePlay,
  hasPermission,
  index,
  name,
  recordingId,
  startTime,
}: Props) {
  const dispatch = useAppDispatch();
  const status = useAppSelector(
    getStatusOfDownloadBroadcastRecording(recordingId),
  );

  const [showEphemeralState, cancel, reset] = useTimeout(3 * SECOND);
  const isDownloading = isPending(status);
  const isDownloaded = !showEphemeralState() && isFulfilled(status);
  const isErrored = !showEphemeralState() && isRejected(status);

  useEffect(() => {
    setIdle(dispatch, 'downloadBroadcastRecording', recordingId);
  }, []);
  useEffect(() => {
    if (isRejected(status) || isFulfilled(status)) {
      reset();
    } else {
      cancel();
    }
  }, [status, reset, cancel]);

  return (
    <tr
      key={recordingId}
      className={c(
        index % 2 === 1 && 'bg-color-shade-10',
        'border-t1 border-color-shade-20',
        'hover:bg-color-blue-delight--light transition-bg-color',
      )}
    >
      <td>{name}</td>
      <td>{getMillisAsTimestamp(durationMillis)}</td>
      <td>{floor || <em>all floors</em>}</td>
      <td>{formatDate(startTime)}</td>
      <td className={classes.actionsColumn}>
        <Clickable
          className="color-shade-80 opacity-0_5 hover:opacity-1 transition-opacity"
          onClick={handlePlay}
          title="Play"
        >
          <div className={c(classes.icon, 'mr1')}>
            <PlayIcon />
          </div>
        </Clickable>
        <Clickable
          className={c(
            hasPermission &&
              'color-shade-80 hover:opacity-1 opacity-0_5 transition-opacity',
            !hasPermission && 'color-shade-30',
          )}
          onClick={handleDownload}
          isDisabled={!hasPermission || isDownloading}
          title={hasPermission ? 'Download' : 'Upgrade required'}
        >
          <div className={classes.icon}>
            {isDownloading ? (
              <DownloadingIcon />
            ) : isDownloaded ? (
              <CheckIcon />
            ) : isErrored ? (
              <ErrorIcon />
            ) : (
              <DownloadIcon />
            )}
          </div>
        </Clickable>
      </td>
    </tr>
  );
}
