import { ChangeEventHandler, ReactNode, useCallback, useMemo } from 'react';
import { Validations, ValidationsList } from '../Validations';

import { CharacterLimit } from './CharacterLimit';
import { InputLabelProps } from '@material-ui/core';
import MuiTextField from '@material-ui/core/TextField';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import { c } from '../../utils';

export type InputBaseProps = {
  autoComplete?: string;
  className?: string;
  endAdornment?: ReactNode;
  error?: string | ReactNode;
  isDisabled?: boolean;
  isMultiline?: boolean;
  isRequired?: boolean;
  label: string;
  maxLength?: number;
  name?: string;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  placeholder?: string;
  type?: 'password' | 'number' | 'text';
  validations?: ValidationsList;
  value: string | number;
  characterLimit?: number;
  InputProps?: Partial<OutlinedInputProps>;
  inputProps?: OutlinedInputProps['inputProps'];
  InputLabelProps?: Partial<InputLabelProps>;
};

type Props = InputBaseProps & {
  endAdornment?: ReactNode;
};

export function Input({
  autoComplete,
  className,
  endAdornment,
  error,
  inputProps = {},
  InputProps = {},
  InputLabelProps = {},
  isDisabled,
  isMultiline,
  isRequired,
  label,
  maxLength,
  name,
  onChange,
  placeholder,
  type,
  validations,
  value,
  characterLimit,
  ...props
}: Props) {
  const helperText = useMemo(() => {
    if (error && !validations) {
      return error;
    }

    if (characterLimit) {
      return (
        <CharacterLimit
          currentLength={value?.toString().length ?? 0}
          limit={characterLimit}
        />
      );
    }
  }, [value, characterLimit, error, validations]);

  const handleChange = useCallback(
    evt => {
      if (characterLimit && evt.target.value.length > characterLimit) {
        evt.target.value = evt.target.value.slice(0, characterLimit);
      }

      return onChange?.(evt);
    },
    [onChange, characterLimit],
  );

  const input = (
    <MuiTextField
      autoComplete={autoComplete}
      className={className}
      disabled={isDisabled}
      error={!!error}
      fullWidth
      helperText={helperText ?? null}
      InputProps={{ ...InputProps, endAdornment }}
      inputProps={{ ...inputProps, maxLength }}
      InputLabelProps={{ ...InputLabelProps }}
      label={label}
      margin="normal"
      multiline={isMultiline}
      name={name}
      onChange={handleChange}
      placeholder={placeholder}
      required={isRequired}
      type={type}
      value={value}
      variant="outlined"
      {...props}
    />
  );

  if (validations) {
    return (
      <div className={c('flex flex-1 flex-col gap-_5', className)}>
        {input}
        {!!error && (
          <Validations prefix="Must contain" validations={validations} />
        )}
      </div>
    );
  } else {
    return input;
  }
}
