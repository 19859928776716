import React from 'react';
import { Line } from 'react-chartjs-2';

import { LOGO_BLUE, LOGO_GREEN } from '../../config';
import { c } from '../../utils';

type Props = {
  className?: string;
  data: number[];
  height?: number;
  label: string;
  title?: string;
  xAxisLabels: string[];
};

export function LineChart({
  className,
  data,
  height,
  label,
  title,
  xAxisLabels,
}: Props) {
  return (
    <div className={c(className, 'flex flex-1 flex-col gap-1')}>
      {title && <p className="subheading">{title}</p>}
      <Line
        type="line"
        height={height}
        data={{
          labels: xAxisLabels,
          datasets: [
            {
              label,
              fill: true,
              backgroundColor: LOGO_BLUE,
              borderColor: LOGO_BLUE,
              pointBackgroundColor: LOGO_BLUE,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: LOGO_GREEN,
              pointHoverBorderColor: LOGO_GREEN,
              pointHoverBorderWidth: 1,
              pointRadius: 1,
              pointHitRadius: 10,
              data,
            },
          ],
        }}
        options={{
          legend: {
            display: false,
          },
          scales: {
            // @ts-ignore
            xAxes: [
              {
                ticks: {
                  maxRotation: 0,
                  minRotation: 0,
                  maxTicksLimit: 5,
                  minTicksLimit: 5,
                },
              },
            ],
            // @ts-ignore
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label: tooltipItem => {
                return title + tooltipItem.yLabel;
              },
            },
          },
        }}
      />
    </div>
  );
}
