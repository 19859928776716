import { IGatherlyEvent } from '@gatherly/types';

import { SERVER_LAUNCH_WINDOW } from '../config';
import { GatherlyEventServerStatus } from '../enums';

export function getEventServerStatus(
  startTime?: number,
  stopTime?: number,
): GatherlyEventServerStatus | undefined {
  if (!startTime || !stopTime) return undefined;

  const NOW = Date.now();
  if (NOW > stopTime) return GatherlyEventServerStatus.OVER;
  if (NOW > startTime) return GatherlyEventServerStatus.LIVE;
  const timeUntilStart = startTime - NOW;
  if (timeUntilStart <= SERVER_LAUNCH_WINDOW)
    return GatherlyEventServerStatus.LAUNCH;
  return GatherlyEventServerStatus.PRELAUNCH;
}

export function eventIsEditable(event?: IGatherlyEvent): boolean {
  return (
    getEventServerStatus(event?.startTime, event?.stopTime) ===
    GatherlyEventServerStatus.PRELAUNCH
  );
}
