import React, { useCallback, useState } from 'react';
import {
  ExitToAppRounded,
  HomeWorkRounded,
  ImportContactsRounded,
  PersonRounded,
  RateReview,
  CreditCard,
} from '@material-ui/icons';
import { useLocation, useRoute } from 'wouter';

import { Clickable, ClickableVariant } from '../Clickable';
import { Image } from '../Image';
import { signOut } from '../../actions';
import { useAppDispatch } from '../../hooks';
import { Images } from '../../images';
import { c } from '../../utils';
import classes from './Sidebar.module.scss';

export function Sidebar() {
  const [, setLocation] = useLocation();
  const [isEvents] = useRoute('/events/:rest*');
  const [isResources] = useRoute('/resources');
  const [isAccount] = useRoute('/account');
  const [isFeedback] = useRoute('https://www.gatherly.io/app-feedback');
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useAppDispatch();

  const handleSignOut = useCallback(async () => {
    await signOut(dispatch);
    setLocation('/');
  }, []);
  const toggleIsOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleUpgradeClick = useCallback(() => {
    setLocation('/pricing'); // Navigate to the pricing page
  }, [setLocation]);

  return (
    <div
      className={c(
        classes.SideBar,
        'flex flex-col bg-color-white border-r1 border-color-shade-20',
        'overflow-hidden fixed z-2 t0',
      )}
    >
      <Clickable
        className={c(
          classes.homeButton,
          'md:none border-b1 border-color-shade-20',
        )}
        label="Toggle menu"
        onClick={toggleIsOpen}
        variant={ClickableVariant.BUTTON_GRID}
      >
        <Image
          src={Images.GATHERLY_ICON.src}
          alt={Images.GATHERLY_ICON.alt}
          className="m_5 w100"
          skipPreload
        />
      </Clickable>
      <Clickable
        className={c(
          classes.homeButton,
          'none md:flex md:visible border-b1 border-color-shade-20',
        )}
        label="home"
        to="/"
        variant={ClickableVariant.BUTTON_GRID}
      >
        <Image
          src={Images.GATHERLY_ICON.src}
          alt={Images.GATHERLY_ICON.alt}
          className="m_5 w100"
          skipPreload
        />
      </Clickable>
      <ul className="flex-1 none md:flex flex-col">
        <li>
          <Clickable
            to="/events"
            label="Events"
            className={c(isEvents && 'bg-color-shade-10')}
            variant={ClickableVariant.BUTTON_GRID}
          >
            <HomeWorkRounded className="color-shade-70" />
            <span className="color-shade-70">Events</span>
          </Clickable>
        </li>
        <li className="border-t1 border-color-shade-20">
          <Clickable
            to="/resources"
            label="Resources"
            className={c(isResources && 'bg-color-shade-10')}
            variant={ClickableVariant.BUTTON_GRID}
          >
            <ImportContactsRounded className="color-shade-70" />
            <span className="color-shade-70">Resources</span>
          </Clickable>
        </li>
        <li className="border-t1 border-b1 border-color-shade-20">
          <Clickable
            to="/account"
            label="Account"
            className={c(isAccount && 'bg-color-shade-10')}
            variant={ClickableVariant.BUTTON_GRID}
          >
            <PersonRounded className="color-shade-70" />
            <span className="color-shade-70">Account</span>
          </Clickable>
        </li>
        {/* Upgrade button added here */}
        <Clickable
          onClick={handleUpgradeClick}
          label="Upgrade"
          className={c('none md:flex border-t1 border-color-shade-20')}
          variant={ClickableVariant.BUTTON_GRID}
        >
          <CreditCard className="color-shade-70" />
          <span className="color-shade-70">Upgrade</span>
        </Clickable>

        <li className="border-t1 border-b1 border-color-shade-20">
          <Clickable
            to="https://www.gatherly.io/app-feedback"
            label="Feedback"
            className={c(isFeedback && 'bg-color-shade-10')}
            variant={ClickableVariant.BUTTON_GRID}
          >
            <RateReview className="color-shade-70" />
            <span className="color-shade-70">Feedback</span>
          </Clickable>
        </li>
      </ul>

      <Clickable
        label="sign out"
        onClick={handleSignOut}
        className={c(classes.signutButton, 'none md:flex')}
        variant={ClickableVariant.BUTTON_GRID}
      >
        <ExitToAppRounded />
        <span>Sign out</span>
      </Clickable>

      {isOpen && (
        <div
          className={c(
            'flex flex-col fixed l0 r0 b0 md:none',
            'bg-color-white',
            classes.mobileMenu,
          )}
        >
          <ul className="flex pt4 flex-col items-center justify-center gap-2">
            <li>
              <Clickable
                className="heading"
                label="Events"
                onClick={toggleIsOpen}
                to="/events"
                variant={ClickableVariant.LINK}
              />
            </li>
            <li>
              <Clickable
                className="heading"
                to="/resources"
                label="Resources"
                onClick={toggleIsOpen}
                variant={ClickableVariant.LINK}
              />
            </li>
            <li>
              <Clickable
                className="heading"
                to="/account"
                label="Account"
                onClick={toggleIsOpen}
                variant={ClickableVariant.LINK}
              />
            </li>
            <li>
              <Clickable
                className="heading"
                label="Sign Out"
                onClick={handleSignOut}
                variant={ClickableVariant.LINK}
              />
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
