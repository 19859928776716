import React from 'react';
import { c } from '../../utils';

export type ValidationsList = Array<[string, boolean]>;

type Props = {
  className?: string;
  prefix: string;
  validations: ValidationsList;
};
export function Validations({ className, prefix, validations }: Props) {
  return (
    <p className={c('small color-red', className)}>
      {validations.map(([message, isFulfilled], index) => {
        return (
          <span
            className={c(isFulfilled && 'color-shade-80')}
            key={`validation-${index}`}
          >
            {index !== 0 ? ', ' : `${prefix} `}
            {message} {isFulfilled ? '✅' : '❌'}
          </span>
        );
      })}
    </p>
  );
}
