import { Clickable, ClickableVariant } from '../../Clickable';
import {
  CloseRounded as DeleteIcon,
  FilterNoneRounded as DuplicateIcon,
} from '@material-ui/icons';
import { OverlayType, setOverlay } from '../../../actions';
import React, { ReactChild, useCallback } from 'react';

import classes from './EventFormHeader.module.scss';
import { classnames } from '@gatherly/lib';
import { useAppDispatch } from '../../../hooks';

type Props = {
  children: ReactChild;
  eventId: string;
  isDisabled: boolean;
  title: string;
};

export function EventFormHeader({
  children,
  eventId,
  isDisabled,
  title,
}: Props) {
  const dispatch = useAppDispatch();

  const handleClickDelete = useCallback(() => {
    setOverlay(dispatch, OverlayType.DELETE_EVENT, { eventIds: [eventId] });
  }, []);

  const handleClickDuplicate = useCallback(() => {
    setOverlay(dispatch, OverlayType.NEW_EVENT, { eventId });
  }, []);

  return (
    <div
      className={classnames(classes.EventFormHeader, 'flex flex-col pb2 gap-1')}
    >
      <div className="flex flex-col gap-2">
        <div className="flex items-start justify-end gap-1">
          {isDisabled || (
            <Clickable
              data-cy="delete-event"
              className="small hover:color-red hover:border-color-red"
              onClick={handleClickDelete}
              variant={ClickableVariant.BUTTON_TERTIARY}
            >
              <span className="flex items-center gap-_5">
                Delete <DeleteIcon />
              </span>
            </Clickable>
          )}
          <Clickable
            className="small hover:color-blue-delight hover:border-color-blue-delight"
            onClick={handleClickDuplicate}
            variant={ClickableVariant.BUTTON_TERTIARY}
          >
            <span className="flex items-center gap-_5">
              Duplicate <DuplicateIcon />
            </span>
          </Clickable>
        </div>
        <h2 className="color-shade-80 heading flex-1 md:mt3 md:pt_5">
          {title}
        </h2>
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
}
