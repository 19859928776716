// from https://www.petermorlion.com/iterating-a-typescript-enum/
function enumKeys<O extends object, K extends keyof O = keyof O>(
  enumObject: O,
): K[] {
  return Object.keys(enumObject).filter(member => Number.isNaN(+member)) as K[];
}

export enum UserAuthState {
  UNKNOWN = 'UNKNOWN',
  UNREGISTERED = 'UNREGISTERED',
  UNVERIFIED = 'UNVERIFIED',
  LOGGED_IN = 'LOGGED_IN',
  LOGGED_OUT = 'LOGGED_OUT',
}

export enum PromiseStatus {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  REJECTED = 'REJECTED',
}
export enum GatherlyEventType {
  POSTER_FAIR = 'poster-fair',
  NETWORKING = 'networking',
  SOCIAL = 'social-hangout',
  COMPANY_ORG = 'company-org',
  RECRUITING = 'recruiting',
  OTHER = 'other',
}

export enum GatherlyEventServerStatus {
  PRELAUNCH = 'prelaunch',
  LAUNCH = 'launch',
  LIVE = 'live',
  OVER = 'over',
}

export const eventTypeLabels = {
  [GatherlyEventType.POSTER_FAIR]: 'Poster fair',
  [GatherlyEventType.NETWORKING]: 'Networking session',
  [GatherlyEventType.SOCIAL]: 'Social hangout',
  [GatherlyEventType.COMPANY_ORG]: 'Org meeting',
  [GatherlyEventType.RECRUITING]: 'Recruiting',
  [GatherlyEventType.OTHER]: 'Other',
};

export const eventTypeOptionsArray = enumKeys(GatherlyEventType).map(key => ({
  id: GatherlyEventType[key],
  value: GatherlyEventType[key],
  label: eventTypeLabels[GatherlyEventType[key]],
}));

export enum GatherlyEmailSetting {
  disabled = 'disabled',
  optional = 'optional',
  required = 'required',
}

const emailSettingLabels = {
  disabled: 'Do not collect attendee emails',
  optional: 'Collect attendee emails as an optional field',
  required: 'Collect attendee emails as a required field',
};

export const emailSettingOptionsArray = enumKeys(GatherlyEmailSetting).map(
  key => ({
    id: key,
    value: key,
    label: emailSettingLabels[key],
  }),
);

export enum MapCategory {
  ESSENTIALS = 'Essentials',
  NETWORKING = 'Networking',
  POSTER_FAIRS = 'Poster Fairs',
  COWORKING = 'Coworking',
  VENUES = 'Venues',
  OUTDOOR = 'Outdoor',
  SEASONAL = 'Seasonal',
  SPORTS = 'Sports',
  TRAVEL = 'Travel',
  ABSTRACT = 'Abstract',
}

export const mapCategoryOptionsArray = enumKeys(MapCategory).map(key => ({
  id: key,
  value: key,
  label: MapCategory[key],
}));
export const MAPS = [
  {
    imgName: '_Welcome+Lobby+A.jpeg',
    title: 'Welcome Lobby A',
    description: 'Friendly welcome lobby with a help desk.',
    category: MapCategory.ESSENTIALS,
  },
  {
    imgName: '_Welcome+Lobby+B.jpeg',
    title: 'Welcome Lobby B',
    description: 'Classic welcome lobby with a help desk.',
    category: MapCategory.ESSENTIALS,
  },
  {
    imgName: '_Welcome+Lobby+C.jpeg',
    title: 'Welcome Lobby C',
    description: 'Fancy welcome lobby with a help desk.',
    category: MapCategory.ESSENTIALS,
  },
  {
    imgName: '_Welcome+Lobby+D.png',
    title: 'Welcome Lobby D',
    description: 'Contemporary welcome lobby with a help desk.',
    category: MapCategory.ESSENTIALS,
  },
  {
    imgName: '_Welcome+Lobby+E.png',
    title: 'Welcome Lobby E',
    description: 'Office-style welcome lobby with a large help desk.',
    category: MapCategory.ESSENTIALS,
  },
  {
    imgName: '_Networking+A.jpeg',
    title: 'Networking Lounge A',
    description:
      'Classic lounge with various seating options and a refreshing water cooler.',
    category: MapCategory.NETWORKING,
  },
  {
    imgName: '_Networking+B.jpeg',
    title: 'Networking Lounge B',
    description: 'Chic lounge with modern seating and a grand piano.',
    category: MapCategory.NETWORKING,
  },
  {
    imgName: '_Networking+C.jpeg',
    title: 'Networking Lounge C',
    description: 'Comfy lounge with fun furniture and a delicious snack bar!',
    category: MapCategory.NETWORKING,
  },
  {
    imgName: '_Networking+D.png',
    title: 'Networking Lounge D',
    description: 'Casual lounge featuring a koi fish tank.',
    category: MapCategory.NETWORKING,
  },
  {
    imgName: '_Networking+E.png',
    title: 'Networking Lounge E',
    description: 'Dinner lounge featuring your favorite wines.',
    category: MapCategory.NETWORKING,
  },
  {
    imgName: '_Poster+Fair+A+6.jpeg',
    title: 'Poster Fair A 6',
    description: 'Dark blue poster fair layout with 6 poster booths',
    category: MapCategory.POSTER_FAIRS,
  },
  {
    imgName: '_Poster+Fair+A+8.jpeg',
    title: 'Poster Fair A 8',
    description: 'Dark blue poster fair layout with 8 poster booths',
    category: MapCategory.POSTER_FAIRS,
  },
  {
    imgName: '_Poster+Fair+B+6.jpeg',
    title: 'Poster Fair B 6',
    description: 'Isometric poster fair layout with 6 poster booths',
    category: MapCategory.POSTER_FAIRS,
  },
  {
    imgName: '_Poster+Fair+B+9.jpeg',
    title: 'Poster Fair B 9',
    description: 'Isometric poster fair layout with 9 poster booths',
    category: MapCategory.POSTER_FAIRS,
  },
  {
    imgName: '_Poster+Fair+C+6.jpeg',
    title: 'Poster Fair C 6',
    description: 'Light blue poster fair layout with 6 poster booths',
    category: MapCategory.POSTER_FAIRS,
  },
  {
    imgName: '_Poster+Fair+C+7.jpeg',
    title: 'Poster Fair C 7',
    description: 'Light blue poster fair layout with 7 poster booths',
    category: MapCategory.POSTER_FAIRS,
  },
  {
    imgName: '_Office+Space+A.jpeg',
    title: 'Office A',
    description: 'Open co-working space for meetings, work, and relaxation.',
    category: MapCategory.COWORKING,
  },
  {
    imgName: '_Office+Space+B.jpeg',
    title: 'Office B',
    description: 'Productive co-working space with different tables.',
    category: MapCategory.COWORKING,
  },
  {
    imgName: '_Office+Space+C.jpeg',
    title: 'Office C',
    description: 'Chill co-working space with different tables.',
    category: MapCategory.COWORKING,
  },
  {
    imgName: '_Office+D.png',
    title: 'Office D',
    description: 'Co-working space with a conference room and a bathroom.',
    category: MapCategory.COWORKING,
  },
  {
    imgName: '_Office+E.png',
    title: 'Office E',
    description: 'Focused office space with cubicles and a water cooler.',
    category: MapCategory.COWORKING,
  },
  {
    imgName: '_Ballroom+AL.jpeg',
    title: 'Ballroom A',
    description:
      'Fun ballroom with a dance floor, snack bar, and a classy piano.',
    category: MapCategory.VENUES,
  },
  {
    imgName: '_Ballroom+B+1L.jpeg',
    title: 'Ballroom B-1',
    description: 'Ballroom with a stage and 5 circular tables.',
    category: MapCategory.VENUES,
  },
  {
    imgName: '_Ballrooom+B+2L.jpeg',
    title: 'Ballroom B-2',
    description: 'Ballroom with a stage and 7 circular tables.',
    category: MapCategory.VENUES,
  },
  {
    imgName: '_Ballroom+C.png',
    title: 'Ballroom C',
    description:
      'Ballroom with 6 rectangular tables facing a main speaking area.',
    category: MapCategory.VENUES,
  },
  {
    imgName: '_Ballroom+D.png',
    title: 'Ballroom D',
    description:
      'Ballroom with 8 rectangular tables and a snack bar for mingling.',
    category: MapCategory.VENUES,
  },
  {
    imgName: '_Ballroom+E.png',
    title: 'Ballroom E',
    description: 'Ballroom with 7 modern couches facing a minimalist stage.',
    category: MapCategory.VENUES,
  },
  {
    imgName: '_Conference+Room+A.jpeg',
    title: 'Conference Room A',
    description:
      'Professional meeting room with a podium and plenty of seating.',
    category: MapCategory.VENUES,
  },
  {
    imgName: '_Conference+Room+B.jpeg',
    title: 'Conference Room B',
    description: 'Stage with seating and a lounge area in the back.',
    category: MapCategory.VENUES,
  },
  {
    imgName: '_Conference+Room+C.png',
    title: 'Conference Room C',
    description:
      'Professional conference room with executive chairs and a whiteboard.',
    category: MapCategory.VENUES,
  },
  {
    imgName: '_Conference+Room+D.png',
    title: 'Conference Room D',
    description:
      'Casual conference room with space for meetings and casual chats.',
    category: MapCategory.VENUES,
  },
  {
    imgName: '_Conference+Room+E.png',
    title: 'Conference Room E',
    description:
      'Conference room with a large table and a lounge area in the back.',
    category: MapCategory.VENUES,
  },
  {
    imgName: '_Garden+A.jpeg',
    title: 'Garden A',
    description:
      'Garden with outdoor couch seating, chairs, and a picnic table.',
    category: MapCategory.OUTDOOR,
  },
  {
    imgName: '_Garden+B.jpeg',
    title: 'Garden B',
    description: 'Garden with flower bed, fountain, tables, and a pond.',
    category: MapCategory.OUTDOOR,
  },
  {
    imgName: '_Garden+C.jpeg',
    title: 'Garden C',
    description:
      'Symmetric garden with a central fountain, benches, and nice landscaping.',
    category: MapCategory.OUTDOOR,
  },
  {
    imgName: '_Pool+A.jpeg',
    title: 'Pool A',
    description:
      'Rectangular pool with a diving board, lounge chairs, and a table.',
    category: MapCategory.OUTDOOR,
  },
  {
    imgName: '_Pool+B.jpeg',
    title: 'Pool B',
    description:
      'Pool area with lounge chairs, outdoor couches, and sunbathing towels.',
    category: MapCategory.OUTDOOR,
  },
  {
    imgName: '_Pool+C.jpeg',
    title: 'Pool C',
    description:
      'Pool with lounge chairs, a poolside bar, hot tub, and water slide.',
    category: MapCategory.OUTDOOR,
  },
  {
    imgName: '_Winter+A.png',
    title: 'Winter A',
    description: 'Snowy escape featuring a sled and a frosty friend.',
    category: MapCategory.SEASONAL,
  },
  {
    imgName: '_Winter+B.png',
    title: 'Winter B',
    description: 'A pristine ski resort!',
    category: MapCategory.SEASONAL,
  },
  {
    imgName: '_Spring+A.png',
    title: 'Spring A',
    description: 'A park with Japanese cherry blossoms and a koi pond.',
    category: MapCategory.SEASONAL,
  },
  {
    imgName: '_Spring+B.png',
    title: 'Spring B',
    description: 'No better way to spend a Sunday than gardening.',
    category: MapCategory.SEASONAL,
  },
  {
    imgName: '_Summer+A.png',
    title: 'Summer A',
    description: 'Relax on the beach! Watch out for the crab.',
    category: MapCategory.SEASONAL,
  },
  {
    imgName: '_Summer+B.png',
    title: 'Summer B',
    description: 'Casual picnic, complete with a grill and a cat.',
    category: MapCategory.SEASONAL,
  },
  {
    imgName: '_Fall+A.png',
    title: 'Fall A',
    description: 'Time to harvest some pumpkins!',
    category: MapCategory.SEASONAL,
  },
  {
    imgName: '_Fall+B.png',
    title: 'Fall B',
    description: 'Suburban neighborhood with fall colors.',
    category: MapCategory.SEASONAL,
  },
  {
    imgName: '_Soccer.png',
    title: 'Soccer',
    description: 'Or football, if you’re not from the USA.',
    category: MapCategory.SPORTS,
  },
  {
    imgName: '_Football.png',
    title: 'Football',
    description: 'An American football field.',
    category: MapCategory.SPORTS,
  },
  {
    imgName: '_Ice+Hockey.png',
    title: 'Ice Hockey',
    description: 'An ice hockey rink!',
    category: MapCategory.SPORTS,
  },
  {
    imgName: '_Tennis.png',
    title: 'Tennis',
    description: 'A few tennis courts to explore.',
    category: MapCategory.SPORTS,
  },
  {
    imgName: '_Ping+Pong.png',
    title: 'Ping Pong',
    description: 'Anyone up for a game of ping pong?',
    category: MapCategory.SPORTS,
  },
  {
    imgName: '_Basketball.png',
    title: 'Basketball',
    description: 'The perfect indoor basketball court!',
    category: MapCategory.SPORTS,
  },
  {
    imgName: '_Space.png',
    title: 'Space',
    description: 'Explore the galaxy. Perfect for captivating your audience.',
    category: MapCategory.TRAVEL,
  },
  {
    imgName: '_Port.png',
    title: 'Port',
    description: 'International shipping logistics, anyone?',
    category: MapCategory.TRAVEL,
  },
  {
    imgName: '_Volcano.png',
    title: 'Volcano',
    description: 'Getaway to a tropical island... with an active volcano.',
    category: MapCategory.TRAVEL,
  },
  {
    imgName: '_Global.png',
    title: 'Global',
    description: 'Travel the world with a click!',
    category: MapCategory.TRAVEL,
  },
  {
    imgName: '_Blank.jpeg',
    title: 'Blank',
    description: 'Blank cool grey map.',
    category: MapCategory.ABSTRACT,
  },
  {
    imgName: '_Help+Desk+Simple.jpeg',
    title: 'Help Desk',
    description: 'Simple welcome area with a help desk.',
    category: MapCategory.ABSTRACT,
  },
  {
    imgName: '_Poster+Fair+Simple+6.jpeg',
    title: 'Poster Fair Simple 6',
    description: 'Poster fair layout with 6 abstract poster booths.',
    category: MapCategory.ABSTRACT,
  },
  {
    imgName: '_Poster+Fair+Simple+9.jpeg',
    title: 'Poster Fair Simple 9',
    description: 'Poster fair layout with 9 abstract poster booths.',
    category: MapCategory.ABSTRACT,
  },
  {
    imgName: '_Poster+Fair+Simple+12.jpeg',
    title: 'Poster Fair Simple 12',
    description: 'Poster fair layout with 12 abstract poster booths.',
    category: MapCategory.ABSTRACT,
  },
  {
    imgName: '_Areas+1.jpeg',
    title: 'Areas 1',
    description: 'A colored space to mingle and network.',
    category: MapCategory.ABSTRACT,
  },
  {
    imgName: '_Areas+2.jpeg',
    title: 'Areas 2',
    description: '2 colored spaces to mingle and network.',
    category: MapCategory.ABSTRACT,
  },
  {
    imgName: '_Areas+3.jpeg',
    title: 'Areas 3',
    description: '3 colored spaces to mingle and network.',
    category: MapCategory.ABSTRACT,
  },
  {
    imgName: '_Areas+4.jpeg',
    title: 'Areas 4',
    description: '4 colored spaces to mingle and network.',
    category: MapCategory.ABSTRACT,
  },
  {
    imgName: '_Areas+5.jpeg',
    title: 'Areas 5',
    description: '5 colored spaces to mingle and network.',
    category: MapCategory.ABSTRACT,
  },
  {
    imgName: '_Areas+6.jpeg',
    title: 'Areas 6',
    description: '6 colored spaces to mingle and network.',
    category: MapCategory.ABSTRACT,
  },
  {
    imgName: '_Areas+7.jpeg',
    title: 'Areas 7',
    description: '7 colored spaces to mingle and network.',
    category: MapCategory.ABSTRACT,
  },
  {
    imgName: '_Areas+8.jpeg',
    title: 'Areas 8',
    description: '8 colored spaces to mingle and network.',
    category: MapCategory.ABSTRACT,
  },
];

export const BANNERS = [
  {
    imgName: 'Gradient+Banner.png',
    title: 'Gradient Banner',
    description: 'Default landing page banner.',
  },
  {
    imgName: 'People+Banner.png',
    title: 'People Banner',
    description: 'Great for social hangouts, org meetings or recruiting.',
  },
  {
    imgName: 'Networking+Banner.png',
    title: 'Networking Banner',
    description: 'Perfect for networking or poster fairs.',
  },
];

export const BOOTH_BACKGROUNDS = [
  {
    imgName: '1.png',
    title: 'Style 1',
  },
  {
    imgName: '2.png',
    title: 'Style 2',
  },
  {
    imgName: '3.png',
    title: 'Style 3',
  },
  {
    imgName: '4.png',
    title: 'Style 4',
  },
  {
    imgName: '5.png',
    title: 'Style 5',
  },
  {
    imgName: '6.png',
    title: 'Style 6',
  },
  {
    imgName: '7.png',
    title: 'Style 7',
  },
  {
    imgName: '8.png',
    title: 'Style 8',
  },
  {
    imgName: '9.png',
    title: 'Style 9',
  },
  {
    imgName: '10.png',
    title: 'Style 10',
  },
];

export const GAME_BACKGROUNDS = [
  {
    imgName: '_Soccer.png',
    title: 'Soccer',
  },
  {
    imgName: '_Football.png',
    title: 'Football',
  },
  {
    imgName: '_Ice+Hockey.png',
    title: 'Ice Hockey',
  },
  {
    imgName: '_Tennis.png',
    title: 'Tennis',
  },
  {
    imgName: '_Ping+Pong.png',
    title: 'Ping Pong',
  },
  {
    imgName: '_Basketball.png',
    title: 'Basketball',
  },
  {
    imgName: '_Space.png',
    title: 'Space',
  },
];
