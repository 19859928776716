import React, { MouseEventHandler, ReactNode, CSSProperties } from 'react';
import { Link, useLocation } from 'wouter';

import { c, isExternalLink } from '../../utils';
import classes from './Clickable.module.scss';

/* eslint-disable jsx-a11y/anchor-is-valid */

export enum ClickableVariant {
  BUTTON_PRIMARY = 'BUTTON_PRIMARY',
  BUTTON_SECONDARY = 'BUTTON_SECONDARY',
  BUTTON_TERTIARY = 'BUTTON_TERTIARY',
  BUTTON_QUATERNARY = 'BUTTON_QUATERNARY',
  BUTTON_SMALL = 'BUTTON_SMALL',
  BUTTON_GRID = 'BUTTON_GRID',
  BUTTON_GRID_SM = 'BUTTON_GRID_SM',
  BUTTON_GRID_INLINE = 'BUTTON_GRID_INLINE',
  LINK = 'LINK',
  NONE = 'NONE',
}

type Props = {
  children?: ReactNode;
  className?: string;
  disabledClassName?: string;
  downloadFilename?: string;
  isDisabled?: boolean;
  isSubmit?: boolean;
  label?: string;
  onClick?: MouseEventHandler;
  style?: CSSProperties;
  title?: string;
  to?: string;
  variant?: ClickableVariant;
  negative?: boolean;
};

export function Clickable({
  children,
  className,
  disabledClassName,
  downloadFilename,
  isDisabled,
  isSubmit,
  label,
  negative,
  onClick,
  style,
  title,
  to,
  variant = ClickableVariant.NONE,
  ...props
}: Props) {
  const classNames = c(
    isDisabled && disabledClassName,
    negative && classes.negative,
    classes.Clickable,
    variant === ClickableVariant.BUTTON_PRIMARY && classes.buttonPrimary,
    variant === ClickableVariant.BUTTON_SECONDARY && classes.buttonSecondary,
    variant === ClickableVariant.BUTTON_TERTIARY && classes.buttonTertiary,
    variant === ClickableVariant.BUTTON_QUATERNARY && classes.buttonQuaternary,
    variant === ClickableVariant.BUTTON_SMALL && classes.buttonSmall,
    variant === ClickableVariant.BUTTON_GRID && classes.buttonGrid,
    variant === ClickableVariant.BUTTON_GRID_SM && classes.buttonGridSmall,
    variant === ClickableVariant.LINK && classes.link,
    className,
  );
  const [, setLocation] = useLocation();
  if (to) {
    if (isExternalLink(to)) {
      return (
        <a
          aria-label={label}
          className={classNames}
          download={downloadFilename}
          href={isDisabled ? '#' : to}
          onClick={onClick}
          rel="noreferrer noopener"
          style={style}
          target={isDisabled ? '' : '_blank'}
          title={title}
        >
          {children || label}
        </a>
      );
    } else {
      /* Deal with an issue with Link */
      if (children && !Array.isArray(children)) {
        return (
          <button
            className={classNames}
            disabled={isDisabled}
            onClick={e => {
              setLocation(to);
              onClick && onClick(e);
            }}
            style={style}
            title={title}
            {...props}
          >
            {children || label}
          </button>
        );
      }
      return (
        <Link
          aria-label={label}
          className={classNames}
          onClick={onClick}
          style={style}
          title={title}
          to={isDisabled ? '' : to}
        >
          {children || label}
        </Link>
      );
    }
  }

  if (isSubmit) {
    return (
      <input
        className={classNames}
        disabled={isDisabled}
        style={style}
        title={title}
        type="submit"
        value={label}
        {...props}
      />
    );
  }

  return (
    <button
      className={classNames}
      disabled={isDisabled}
      onClick={onClick}
      style={style}
      title={title}
      type="button"
      {...props}
    >
      {children || label}
    </button>
  );
}
