import { PromiseStatus } from '../enums';
import { RootState } from '../store';

export function getStatusOfCreateEvent(state: RootState): PromiseStatus {
  return state.status.createEvent;
}

export function getStatusOfCreateFloor(state: RootState): PromiseStatus {
  return state.status.createFloor;
}

export function getStatusOfDeleteFloor(
  state: RootState,
): Record<string, PromiseStatus> {
  return state.status.deleteFloor;
}

export const getStatusOfDeleteFloorById =
  (floorId = '') =>
  (state: RootState): PromiseStatus => {
    return state.status.deleteFloor[floorId] || PromiseStatus.IDLE;
  };

export function getStatusOfEditEventLink(state: RootState): PromiseStatus {
  return state.status.editEventLink;
}

export function getStatusOfDeleteEvent(state: RootState): PromiseStatus {
  return state.status.deleteEvent;
}

export const getStatusOfGetEvent =
  (eventId = '') =>
  (state: RootState): PromiseStatus => {
    return state.status.getEvent[eventId] || PromiseStatus.IDLE;
  };

export const getStatusOfGetEventBroadcastRecordings =
  (eventId = '') =>
  (state: RootState): PromiseStatus => {
    return (
      state.status.getEventBroadcastRecordings[eventId] || PromiseStatus.IDLE
    );
  };
export const getStatusOfDownloadBroadcastRecording =
  (recordingId = '') =>
  (state: RootState): PromiseStatus => {
    return (
      state.status.downloadBroadcastRecording[recordingId] || PromiseStatus.IDLE
    );
  };

export const getStatusOfGetEventTracking =
  (eventId = '') =>
  (state: RootState): PromiseStatus => {
    return state.status.getEventTracking[eventId] || PromiseStatus.IDLE;
  };

export function getStatusOfGetPermissions(state: RootState): PromiseStatus {
  return state.status.getPermissions;
}

export function getStatusOfGetUpcomingEvents(state: RootState): PromiseStatus {
  return state.status.getUpcomingEvents;
}

export function getStatusOfGetUser(state: RootState): PromiseStatus {
  return state.status.getUser;
}

export function getStatusOfRequestResetPassword(
  state: RootState,
): PromiseStatus {
  return state.status.requestResetPassword;
}

export function getStatusOfResendVerification(state: RootState): PromiseStatus {
  return state.status.resendVerification;
}

export function getStatusOfResetPassword(state: RootState): PromiseStatus {
  return state.status.resetPassword;
}

export function getStatusOfSignIn(state: RootState): PromiseStatus {
  return state.status.signIn;
}

export function getStatusOfSignUp(state: RootState): PromiseStatus {
  return state.status.signUp;
}

export const getStatusOfUpdateEvent =
  (eventId = '') =>
  (state: RootState): PromiseStatus => {
    return state.status.updateEvent[eventId] || PromiseStatus.IDLE;
  };

export const getStatusOfUpdateFloor =
  (floorId = '') =>
  (state: RootState): PromiseStatus => {
    return state.status.updateFloor[floorId] || PromiseStatus.IDLE;
  };

export const getStatusOfUploadBanner = (state: RootState): PromiseStatus => {
  return state.status.uploadBanner || PromiseStatus.IDLE;
};

export const getStatusOfUploadBrochure =
  (floorId = '') =>
  (state: RootState): PromiseStatus => {
    return state.status.uploadBrochure[floorId] || PromiseStatus.IDLE;
  };

export const getStatusOfUploadMap =
  (floorId = '') =>
  (state: RootState): PromiseStatus => {
    return state.status.uploadMap[floorId] || PromiseStatus.IDLE;
  };

export const getStatusOfUploadLogo = (state: RootState): PromiseStatus => {
  return state.status.uploadLogo || PromiseStatus.IDLE;
};

export const getStatusOfUploadBoothLogo = (state: RootState): PromiseStatus => {
  return state.status.uploadBoothLogo || PromiseStatus.IDLE;
};

export function getStatusOfUpdateUser(state: RootState): PromiseStatus {
  return state.status.updateUser;
}

export function getStatusOfVerifyEmail(state: RootState): PromiseStatus {
  return state.status.verifyEmail;
}
