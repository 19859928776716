import { EventFormHeader } from '../EventFormHeader';

type EventFloorsFormHeaderProps = {
  eventId: string;
  isDisabled: boolean;
};

export function EventFloorsFormHeader({
  eventId,
  isDisabled,
}: EventFloorsFormHeaderProps) {
  return (
    <div className="md:pl2">
      <EventFormHeader
        eventId={eventId}
        isDisabled={isDisabled}
        title="Edit Floors"
      >
        <div className="flex flex-col gap-1">
          <p className="color-shade-80 body">
            {`Floors help organize your Gatherly event so attendees know where to go and who to huddle with.`}
            <br />
          </p>
        </div>
      </EventFormHeader>
    </div>
  );
}
