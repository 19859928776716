import { ChangeEvent, ReactNode } from 'react';
import { generate as shortUuid } from 'short-uuid';

import MuiFormControl from '@material-ui/core/FormControl';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiInputLabel from '@material-ui/core/InputLabel';
import MuiSelect from '@material-ui/core/Select';

import { c, toTitleCase } from '../../utils';
import classes from './Select.module.scss';

export type SelectOption<T> = {
  id: string;
  label?: string | ReactNode;
  value: T;
};

type Props<T> = {
  className?: string;
  error?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  label: string;
  name: string;
  onChange: (
    event: ChangeEvent<{ name?: string; value: T }>,
    child: ReactNode,
  ) => void;
  value: T;
  options: SelectOption<T>[];
};

export function Select<
  T extends string | number | readonly string[] | undefined,
>({
  className,
  error,
  isDisabled,
  isRequired,
  label,
  onChange,
  options,
  ...props
}: Props<T>) {
  const labelId = `${props.name || shortUuid()}__label`;
  return (
    <MuiFormControl
      className={c(classes.Select, error && classes.errored, className)}
    >
      <MuiSelect
        disabled={isDisabled}
        error={!!error}
        onChange={onChange as any}
        labelId={labelId}
        required={isRequired}
        placeholder={toTitleCase(props.name)}
        variant="outlined"
        {...props}
      >
        {options.map(option => (
          <MuiMenuItem
            data-cy="event-type-option"
            key={option.id}
            value={option.value}
          >
            {option.label || option.value}
          </MuiMenuItem>
        ))}
      </MuiSelect>
      <MuiInputLabel className={classes.label} id={labelId}>
        {label}
        {isRequired ? ' *' : ''}
      </MuiInputLabel>
      <span className={classes.errorMessage}>{error}</span>
    </MuiFormControl>
  );
}
