import { classnames } from '@gatherly/lib';
import { ReactNode } from 'react';

type EventStatsCardProps = {
  title: string;
  body: ReactNode;
  className?: string;
};

export const EventStatsCard = ({
  className,
  title,
  body,
}: EventStatsCardProps) => {
  return (
    <div
      className={classnames(
        className,
        'flex flex-1 gap-1 border-1 border-color-shade-20 p1 mb1',
      )}
    >
      <div className="flex flex-col flex-1 body color-shade-70">
        <p className="body mb1">{title}</p>
        {body}
      </div>
    </div>
  );
};
EventStatsCard.displayName = 'EventStatsCard';

export default EventStatsCard;
