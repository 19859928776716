import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  SyntheticEvent,
} from 'react';
import { IFloorConfig } from '@gatherly/types';
import { Checkbox, Switch, classnames } from '@gatherly/lib';

import {
  uploadBrochure,
  updateEventConfig,
  setOverlay,
  OverlayType,
  setIdle,
  updateEventFloor,
  uploadMap,
} from '../../../actions';

import { Clickable, ClickableVariant } from '../../Clickable';
import { DroppableUploader } from '../../DroppableUploader';
import { FieldWrapper } from '../../FieldWrapper';
import { Form } from '../Form';
import { Input } from '../../Input';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  eventById,
  getFloorById,
  getStatusOfUploadBrochure,
  getStatusOfUpdateEvent,
  getStatusOfUpdateFloor,
} from '../../../selectors';
import {
  c,
  difference,
  getFormField,
  getOnChangeFormField,
  hasValidationError,
  isPending,
  isValidUrl,
  validateString,
} from '../../../utils';

import classes from './EditFloorForm.module.scss';
import {
  addBooth,
  addGame,
  getBoothAreas,
  getGameAreas,
  FloorType,
  saveMeetingAreaMap,
} from '../../../utils/meetingArea';
import MeetingAreaSetup from './MeetingAreaSetup';
import MapSetup from './MapSetup';
import FloorTypeSwitch from './FloorTypeSwitch';
import BackgroundStyle from './BackgroundStyle';
import ReactQuill from 'react-quill';
import {
  trackBoothFloorSaved,
  trackBrochureAppliedToAllFloors,
  trackCustomMapUploaded,
  trackGameFloorSaved,
} from '../../../libs/trackingLib';

type Props = {
  eventId: string;
  floorId: string;
  handleClose: () => void;
};

function getChanges(floor: IFloorConfig, fields: Partial<IFloorConfig>) {
  const fieldsChanged = difference(floor, fields);

  const didUpdate = Object.keys(fieldsChanged).length > 0;
  return {
    fieldsChanged,
    didUpdate,
  };
}

export function EditFloorForm({ eventId, floorId, handleClose }: Props) {
  const dispatch = useAppDispatch();
  const event = useAppSelector(eventById(eventId));
  const floor = useAppSelector(getFloorById(eventId, floorId));
  const updateFloorStatus = useAppSelector(getStatusOfUpdateFloor(floorId));
  const updateEventStatus = useAppSelector(getStatusOfUpdateEvent(eventId));
  const brochureUploadStatus = useAppSelector(
    getStatusOfUploadBrochure(floorId),
  );
  const [updatingMap, setUpdatingMap] = useState(false);
  const isMakingRequest =
    isPending(updateFloorStatus) || isPending(updateEventStatus) || updatingMap;

  useEffect(() => {
    setIdle(dispatch, 'updateFloor', floorId);
  }, []);

  const [formError, setFormError] = useState('');

  const [name, setName] = useState(getFormField(floor?.name || ''));
  const [brochureLabel, setBrochureLabel] = useState(
    getFormField(floor?.brochureLabel || ''),
  );
  const [meetingAreas, setMeetingArea] = useState(
    floor?.meetingAreas ? [...floor.meetingAreas] : undefined,
  );
  const [mapUrl, setMapUrl] = useState(getFormField(floor?.mapUrl || ''));
  const [boothBackgroundUrl, setBoothBackgroundUrl] = useState(
    getFormField(floor?.boothBackgroundUrl || ''),
  );
  const [gameBackgroundUrl, setGameBackgroundUrl] = useState(
    getFormField(floor?.gameBackgroundUrl || ''),
  );

  const [brochureUrl, setBrochureUrl] = useState(
    getFormField(floor?.brochureUrl || ''),
  );
  const brochureIsAppliedToAllFloors =
    (!!event &&
      !!brochureUrl.value &&
      floor?.brochureUrl === brochureUrl.value &&
      event.config.floors.every(
        f =>
          f.brochureUrl === floor?.brochureUrl &&
          brochureLabel.value === f.brochureLabel,
      )) ??
    false;

  const [applyBrochureToAllFloors, setApplyBrochureToAllFloors] =
    useState(false);
  const [embeddedContent, setEmbeddedContent] = useState(
    getFormField(floor?.embeddedContentUrl || ''),
  );
  const embeddedContentIsAppliedToAllFloors =
    (!!event &&
      !!embeddedContent.value &&
      floor?.embeddedContentUrl === embeddedContent.value &&
      event.config.floors.every(
        f =>
          f.embeddedContentUrl === floor?.embeddedContentUrl &&
          brochureLabel.value === f.brochureLabel,
      )) ??
    false;
  const [brochureType, setBrochureType] = useState<'pdf' | 'embed'>(
    Boolean(floor?.embeddedContentUrl) ? 'embed' : 'pdf',
  );

  const [customButtonLabel, setCustomButtonLabel] = useState(
    getFormField(floor?.customButton?.label || ''),
  );
  const [customButtonLink, setCustomButtonLink] = useState(
    getFormField(floor?.customButton?.url || ''),
  );

  const hasChanges = useMemo(() => {
    if (!floor) return false;
    if (applyBrochureToAllFloors) return true;
    const { didUpdate } = getChanges(floor, {
      name: name.value,
      mapUrl: mapUrl.value,
      brochureUrl: brochureUrl.value,
      ...((floor.meetingAreas || meetingAreas) && {
        meetingAreas,
      }),
      ...((floor.boothBackgroundUrl || boothBackgroundUrl.value) && {
        boothBackgroundUrl: boothBackgroundUrl.value,
      }),
      ...((floor.gameBackgroundUrl || gameBackgroundUrl.value) && {
        gameBackgroundUrl: gameBackgroundUrl.value,
      }),
      ...((floor.embeddedContentUrl || embeddedContent.value) && {
        embeddedContentUrl: embeddedContent.value,
      }),
      ...((floor.brochureLabel || brochureLabel.value) && {
        brochureLabel: brochureLabel.value,
      }),
      ...((floor.customButton?.label || customButtonLabel.value) && {
        customButton: {
          label: customButtonLabel.value,
          url: customButtonLink.value,
        },
      }),
      ...((floor.customButton?.url || customButtonLink.value) && {
        customButton: {
          label: customButtonLabel.value,
          url: customButtonLink.value,
        },
      }),
    });
    return didUpdate;
  }, [
    floor,
    name.value,
    mapUrl.value,
    brochureUrl.value,
    brochureLabel.value,
    boothBackgroundUrl,
    gameBackgroundUrl,
    embeddedContent.value,
    customButtonLabel.value,
    customButtonLink.value,
    meetingAreas,
    applyBrochureToAllFloors,
  ]);

  useEffect(() => {
    setMeetingArea(floor?.meetingAreas ? [...floor?.meetingAreas] : undefined);
  }, [floor?.meetingAreas]);

  const savedBooths = useMemo(() => {
    return getBoothAreas(floor?.meetingAreas);
  }, [floor?.meetingAreas]);

  const savedGames = useMemo(() => {
    return getGameAreas(floor?.meetingAreas);
  }, [floor?.meetingAreas]);

  const boothMeetingAreas = useMemo(() => {
    return getBoothAreas(meetingAreas);
  }, [meetingAreas]);

  const gameMeetingArea = useMemo(() => {
    return getGameAreas(meetingAreas);
  }, [meetingAreas]);

  const hasBooths = boothMeetingAreas.length > 0;
  const hasGames = gameMeetingArea.length > 0;
  const floorType: FloorType = useMemo(() => {
    if (hasBooths) {
      return 'booth';
    }

    if (hasGames) {
      return 'game';
    }

    return 'normal';
  }, [hasBooths, hasGames]);

  const currentMapIsFloorsMap = useMemo(() => {
    if (savedGames.length > 0 && floorType === 'game') {
      return true;
    }

    if (savedBooths.length > 0 && floorType === 'booth') {
      return true;
    }

    if (
      savedBooths.length === 0 &&
      savedGames.length === 0 &&
      floorType === 'normal'
    ) {
      return true;
    }

    return false;
  }, [savedBooths, savedGames, floorType]);

  const onChangeName = useCallback(
    getOnChangeFormField(value => validateString(value, 'Floor name'), setName),
    [],
  );
  const onChangeBrochureLabel = useCallback(
    getOnChangeFormField(
      value => validateString(value, 'Brochure button label', false),
      setBrochureLabel,
    ),
    [],
  );
  const onChangeEmbeddedContent = useCallback(
    getOnChangeFormField(value => isValidUrl(value), setEmbeddedContent),
    [],
  );
  const onChangeCustomButtonLabel = useCallback(
    (value: string, customButtonLink: string) => {
      if (customButtonLink.trim() === '') {
        setCustomButtonLink({
          value: '',
          error:
            value.trim() !== ''
              ? 'Please enter a link for the custom button'
              : '',
        });
      }

      const field = validateString(
        value,
        'Custom button label',
        customButtonLink.trim().length > 0,
        25,
      );

      setCustomButtonLabel(field);

      return field;
    },
    [],
  );
  const onChangeCustomButtonLink = useCallback(
    (value: string, customButtonLabel: string) => {
      if (customButtonLabel.trim() === '') {
        setCustomButtonLabel({
          value: '',
          error:
            value.trim() !== ''
              ? 'Please enter a label for the custom button'
              : '',
        });
      }

      let field;

      if (value.trim() === '' && customButtonLabel.trim() !== '') {
        field = {
          value: '',
          error: 'Please enter a link for the custom button',
        };
      } else {
        field = isValidUrl(value);
      }

      setCustomButtonLink(field);

      return field;
    },

    [],
  );

  const onChangeBrochureUrl = useCallback(
    async acceptedFiles => {
      const file = acceptedFiles[0];
      const response = await uploadBrochure(dispatch, floorId, file);

      let error = '';
      if (response.hasOwnProperty('error')) {
        console.error(error);
        error = 'File upload error';
      }
      setEmbeddedContent({
        value: '',
        error: '',
      });
      setBrochureUrl({
        value: typeof response === 'string' ? response : '',
        error,
      });
    },
    [floorId],
  );

  const onChangeFloorType = useCallback(
    (type: FloorType) => {
      const floorCurrentlyHasBooths = savedBooths.length > 0;
      const floorCurrentlyHasGames = savedGames.length > 0;

      if (type === 'booth' || type === 'game') {
        setMeetingArea(currentValue => {
          if (type === 'booth') {
            return [
              ...(getBoothAreas(currentValue) ?? []),
              ...(floorCurrentlyHasBooths
                ? [...savedBooths]
                : [...addBooth([])]),
            ];
          }

          return [
            ...(getGameAreas(currentValue) ?? []),
            ...(floorCurrentlyHasGames ? [...savedGames] : [...addGame([])]),
          ];
        });
        setBoothBackgroundUrl({
          value: floor?.boothBackgroundUrl ?? '',
          error: '',
        });

        setGameBackgroundUrl({
          value: floor?.gameBackgroundUrl ?? '',
          error: '',
        });

        if (floorCurrentlyHasBooths || floorCurrentlyHasGames) {
          setMapUrl({ value: floor?.mapUrl ?? '', error: '' });
          return;
        }

        setMapUrl({ value: '', error: '' });
        return;
      }

      setMeetingArea(currentValue => {
        if (!currentValue) {
          return currentValue;
        }
        const meetingAreas = currentValue.filter(
          area => area.type !== 'Booth' && area.type !== 'Game',
        );

        if (meetingAreas?.length === 0) {
          return floor?.meetingAreas ? [] : undefined;
        }

        return meetingAreas;
      });

      setBoothBackgroundUrl({
        value: '',
        error: '',
      });
      setGameBackgroundUrl({
        value: '',
        error: '',
      });

      if (floorCurrentlyHasBooths || floorCurrentlyHasGames) {
        setMapUrl({ value: '', error: '' });
        return;
      }

      setMapUrl({ value: floor?.mapUrl ?? '', error: '' });
    },

    [setMeetingArea, setMapUrl, savedBooths, savedGames, floor],
  );

  const handleSubmit = useCallback(
    async (evt?: SyntheticEvent) => {
      evt?.preventDefault();
      const _name = onChangeName(name.value);
      const _customButtonLabel = onChangeCustomButtonLabel(
        customButtonLabel.value,
        customButtonLink.value,
      );
      const _customButtonLink = onChangeCustomButtonLink(
        customButtonLink.value,
        customButtonLabel.value,
      );
      const _embeddedContent = onChangeEmbeddedContent(embeddedContent.value);

      if (
        hasValidationError(
          _name,
          _customButtonLabel,
          _customButtonLink,
          _embeddedContent,
        )
      ) {
        setFormError('Please correct all form fields to continue');
      } else {
        setFormError('');
        if (!floor) {
          setFormError('Invalid floor');
          return;
        }

        const { fieldsChanged, didUpdate } = getChanges(floor, {
          name: _name.value,
          mapUrl: mapUrl.value,
          brochureUrl: brochureUrl.value,
          meetingAreas: meetingAreas ? [...meetingAreas] : [],
          boothBackgroundUrl: boothBackgroundUrl.value,
          gameBackgroundUrl: gameBackgroundUrl.value,
          embeddedContentUrl: embeddedContent.value,
          brochureLabel: brochureLabel.value,
          customButton:
            customButtonLabel.value.trim() !== '' &&
            customButtonLink.value.trim() !== ''
              ? {
                  label: customButtonLabel.value,
                  url: customButtonLink.value,
                }
              : (null as any),
        });

        if (applyBrochureToAllFloors) {
          if (!event) {
            setFormError('Invalid event');
            return;
          }
          const updatedFloors = event?.config.floors.map(floor => {
            if (floor.id === floorId) {
              return {
                ...floor,
                ...fieldsChanged,
              };
            } else {
              return {
                ...floor,
                brochureLabel: brochureLabel.value,
                ...(brochureType === 'pdf' && {
                  brochureUrl: brochureUrl.value,
                }),
                ...(brochureType === 'embed' && {
                  embeddedContentUrl: embeddedContent.value,
                }),
              };
            }
          });
          await updateEventConfig(dispatch, event, {
            floors: updatedFloors,
          }).then(() => {
            setApplyBrochureToAllFloors(false);
            trackBrochureAppliedToAllFloors();
          });
        } else if (didUpdate) {
          await updateEventFloor(
            dispatch,
            eventId,
            floorId,
            fieldsChanged,
          ).then(() => {
            if (
              fieldsChanged.meetingAreas &&
              fieldsChanged.meetingAreas.length > 0
            ) {
              if (fieldsChanged.meetingAreas[0].type === 'Booth') {
                trackBoothFloorSaved(fieldsChanged.meetingAreas.length);
              } else {
                trackGameFloorSaved(fieldsChanged.meetingAreas.length);
              }
            }
          });
        }
      }
    },
    [
      applyBrochureToAllFloors,
      eventId,
      floorId,
      event,
      floor,
      name.value,
      mapUrl.value,
      brochureUrl.value,
      brochureLabel.value,
      customButtonLabel.value,
      customButtonLink.value,
      embeddedContent.value,
      meetingAreas,
      boothBackgroundUrl,
      gameBackgroundUrl,
      onChangeName,
      dispatch,
    ],
  );

  const onMapUploadError = useCallback(() => {
    setMapUrl({
      value: '',
      error: 'Error updating map',
    });
  }, [setMapUrl]);

  const createMap = useCallback(
    async (floorType: FloorType) => {
      const success = await saveMeetingAreaMap({
        areas: floor?.meetingAreas ?? [],
        dispatch,
        eventId,
        floorId,
        floorType,
        backgroundUrl:
          floorType === 'booth'
            ? floor?.boothBackgroundUrl
            : floor?.gameBackgroundUrl,
        showAlert: false,
      });

      if (!success) onMapUploadError();
    },
    [
      floor?.boothBackgroundUrl,
      floor?.gameBackgroundUrl,
      floor?.meetingAreas,
      onMapUploadError,
      eventId,
      floorId,
      dispatch,
    ],
  );

  useEffect(() => {
    if (floor?.mapUrl) {
      setMapUrl({
        value: floor.mapUrl,
        error: '',
      });
    }
  }, [floor?.mapUrl]);

  useEffect(() => {
    if (!floor?.boothBackgroundUrl) return;

    setBoothBackgroundUrl({
      value: floor?.boothBackgroundUrl,
      error: '',
    });
  }, [floor?.boothBackgroundUrl]);

  useEffect(() => {
    if (!floor?.gameBackgroundUrl) return;

    setGameBackgroundUrl({
      value: floor?.gameBackgroundUrl,
      error: '',
    });
  }, [floor?.gameBackgroundUrl]);

  const onOpenMapPicker = useCallback(async () => {
    if (hasChanges) {
      await handleSubmit();
    }

    setOverlay(dispatch, OverlayType.MAP_PICKER, {
      eventId,
      floorId,
    });
  }, [eventId, floorId, hasChanges, handleSubmit]);

  const onBackgroundUploaded = useCallback(
    async (acceptedFiles: File[], floorType: FloorType) => {
      try {
        setUpdatingMap(true);
        const file = acceptedFiles[0];
        const response = await uploadMap(dispatch, floorId, file);

        if (response?.error) {
          if (floorType === 'booth') {
            setBoothBackgroundUrl(current => ({
              value: current.value,
              error: response.error!,
            }));
          }

          if (floorType === 'game') {
            setGameBackgroundUrl(current => ({
              value: current.value,
              error: response.error!,
            }));
          }

          return;
        }

        trackCustomMapUploaded({
          floorType,
          fileFormat: file?.type,
        });

        if (response.mapUrl) {
          const success = await saveMeetingAreaMap({
            areas: meetingAreas ?? [],
            dispatch,
            eventId,
            floorId,
            floorType,
            backgroundUrl: response.mapUrl,
            showAlert: false,
          });

          if (!success) onMapUploadError();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setUpdatingMap(false);
      }
    },
    [meetingAreas, onMapUploadError, eventId, floorId, dispatch, handleSubmit],
  );

  const onOpenBackgroundPicker = useCallback(async () => {
    if (hasChanges) {
      await handleSubmit();
    }

    try {
      if (meetingAreas?.length === 1) {
        setUpdatingMap(true);
        const success = await saveMeetingAreaMap({
          areas: meetingAreas ?? [],
          dispatch,
          eventId,
          floorId,
          floorType,
          backgroundUrl:
            floorType === 'booth'
              ? floor?.boothBackgroundUrl
              : floor?.gameBackgroundUrl,
          showAlert: false,
        });

        if (!success) onMapUploadError();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setUpdatingMap(false);
    }

    setOverlay(dispatch, OverlayType.BACKGROUND_PICKER, {
      eventId,
      floorId,
      floorType,
    });
  }, [
    eventId,
    floorId,
    hasChanges,
    meetingAreas,
    floor?.boothBackgroundUrl,
    floor?.gameBackgroundUrl,
    handleSubmit,
    floorType,
  ]);

  const onRemoveBackground = useCallback(async () => {
    if (hasChanges) {
      await handleSubmit();
    }

    try {
      setUpdatingMap(true);
      const success = await saveMeetingAreaMap({
        areas: meetingAreas ?? [],
        dispatch,
        eventId,
        floorId,
        floorType,
        showAlert: false,
      });

      if (success) {
        if (floorType === 'booth') {
          setBoothBackgroundUrl({
            value: '',
            error: '',
          });
        }

        if (floorType === 'game') {
          setGameBackgroundUrl({
            value: '',
            error: '',
          });
        }
      }

      if (!success) onMapUploadError();
    } catch (error) {
      console.log(error);
    } finally {
      setUpdatingMap(false);
    }
  }, [
    eventId,
    floorId,
    hasChanges,
    meetingAreas,
    floor?.boothBackgroundUrl,
    floor?.gameBackgroundUrl,
    handleSubmit,
    floorType,
  ]);

  const onOpenMeetingAreaEditor = useCallback(async () => {
    if (hasChanges) {
      await handleSubmit();
    }

    try {
      if (meetingAreas?.length === 1) {
        setUpdatingMap(true);
        const success = await saveMeetingAreaMap({
          areas: meetingAreas ?? [],
          dispatch,
          eventId,
          floorId,
          floorType,
          backgroundUrl:
            floorType === 'booth'
              ? floor?.boothBackgroundUrl
              : floor?.gameBackgroundUrl,
          showAlert: false,
        });

        if (!success) onMapUploadError();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setUpdatingMap(false);
    }

    setOverlay(dispatch, OverlayType.MEETING_AREA_EDITOR, {
      eventId,
      floorId,
      floorType,
    });
  }, [
    eventId,
    floorId,
    floor?.boothBackgroundUrl,
    floor?.gameBackgroundUrl,
    hasChanges,
    meetingAreas,
    handleSubmit,
    floorType,
  ]);

  return (
    <Form
      className={c(
        classes.EditFloorForm,
        'flex flex-1 flex-col pt1 px2 mb4 pb_5',
      )}
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col md:flex-row gap-2 mb1">
        <FieldWrapper title="Name" className="flex-1">
          <Input
            label="Floor Name"
            name="floorName"
            onChange={evt => onChangeName(evt.target.value)}
            InputProps={{
              onKeyDown: evt => {
                if (evt.key === 'Enter') {
                  evt.preventDefault();
                  evt.stopPropagation();
                }
              },
            }}
            value={name.value}
            error={name.error}
            isRequired
          />
          <div>
            Floors without an uploaded brochure will display the Event
            Description from the Landing Page instead. You can edit the Event
            Description{' '}
            <Clickable
              to={`/events/${eventId}/edit/landing/#event-description`}
              variant={ClickableVariant.LINK}
              onClick={handleClose}
            >
              here
            </Clickable>
            .
          </div>
        </FieldWrapper>
        <FieldWrapper
          title="Brochure PDF or Embedded Content"
          className="flex-1 items-stretch gap-1"
        >
          <div className="flex justify-end gap-1">
            <span>Add a PDF or embedded content to your floor. </span>
            <div>
              <Switch<'embed' | 'pdf'>
                onChange={value => {
                  setApplyBrochureToAllFloors(false);
                  setBrochureType(value);
                }}
                value={brochureType}
                options={[
                  {
                    id: 'pdf',
                    label: 'PDF',
                    value: 'pdf',
                  },
                  {
                    id: 'embed',
                    label: 'Embed',
                    value: 'embed',
                  },
                ]}
              />
            </div>
          </div>

          <Input
            label="Button label"
            name="brochureLabel"
            onChange={evt => onChangeBrochureLabel(evt.target.value)}
            InputProps={{
              onKeyDown: evt => {
                if (evt.key === 'Enter') {
                  evt.preventDefault();
                  evt.stopPropagation();
                }
              },
            }}
            value={brochureLabel.value}
            error={brochureLabel.error}
          />
          <div className="flex gap-1">
            {brochureType === 'pdf' ? (
              <DroppableUploader
                label="Brochure PDF"
                emptyTitle="Upload brochure"
                wrapperClassName={classes.brochureUploader}
                emptySubtitle={
                  <>
                    Drag and drop a PDF, or <u>Browse</u>
                  </>
                }
                mimeType={{ 'application/pdf': [] }}
                emptyClassName={c(classes.brochurePreview, 'absolute z1')}
                emptyPreview={
                  !floor?.brochureUrl &&
                  event?.config.landingPage.description ? (
                    <div
                      className={classnames(
                        'border-1 border-color-shade-20',
                        classes.eventDescriptionWrapper,
                      )}
                    >
                      <ReactQuill
                        className={classes.eventDescription}
                        value={event.config.landingPage.description}
                        readOnly={true}
                        modules={{
                          toolbar: false,
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      className={classnames(
                        classes.brochurePreview,
                        'border-1 border-color-shade-20',
                      )}
                    ></div>
                  )
                }
                previewClassName={c(classes.brochurePreview, 'bg-color-silver')}
                isLoading={isPending(brochureUploadStatus)}
                onDrop={onChangeBrochureUrl}
                value={brochureUrl.value}
                error={brochureUrl.error}
                renderPreview
              />
            ) : (
              <Input
                label="Embedded Link"
                name="embeddedContent"
                onChange={evt => onChangeEmbeddedContent(evt.target.value)}
                InputProps={{
                  onKeyDown: evt => {
                    if (evt.key === 'Enter') {
                      evt.preventDefault();
                      evt.stopPropagation();
                    }
                  },
                }}
                value={embeddedContent.value}
                error={embeddedContent.error}
              />
            )}
          </div>

          <div className="flex flex-col-reverse justify-start mt_25 gap-1">
            {brochureIsAppliedToAllFloors && brochureType === 'pdf' && (
              <div className="flex flex-col gap-1">
                <span className="detail">
                  This brochure is applied to all floors.
                </span>
              </div>
            )}
            {embeddedContentIsAppliedToAllFloors && brochureType === 'embed' && (
              <div className="flex flex-col gap-1">
                <span className="detail">
                  The embedded content is applied to all floors.
                </span>
              </div>
            )}
            {((brochureUrl.value &&
              !brochureIsAppliedToAllFloors &&
              brochureType === 'pdf') ||
              (embeddedContent.value &&
                !embeddedContentIsAppliedToAllFloors &&
                brochureType === 'embed')) && (
              <Checkbox
                key="checkbox"
                label={`Apply ${
                  brochureType === 'pdf' ? 'brochure' : 'embedded content'
                } to all floors`}
                onChange={setApplyBrochureToAllFloors}
                value={applyBrochureToAllFloors}
                size="sm"
              />
            )}
            {brochureUrl.value && brochureType === 'pdf' && (
              <div key="button">
                <Clickable
                  className="bg-color-blush"
                  onClick={() => {
                    setApplyBrochureToAllFloors(false);
                    setBrochureUrl({ value: '', error: '' });
                  }}
                  isDisabled={isPending(brochureUploadStatus)}
                  variant={ClickableVariant.BUTTON_SMALL}
                >
                  Remove Brochure
                </Clickable>
              </div>
            )}
          </div>
        </FieldWrapper>
      </div>
      <FloorTypeSwitch
        floorType={floorType}
        onChangeFloorType={onChangeFloorType}
      />
      {floorType === 'normal' && (
        <MapSetup
          floorId={floorId}
          eventId={eventId}
          mapUrl={
            currentMapIsFloorsMap
              ? mapUrl
              : {
                  ...mapUrl,
                  value: '',
                  error: '',
                }
          }
          setMapUrl={setMapUrl}
          onOpenMapPicker={onOpenMapPicker}
        />
      )}

      {floorType !== 'normal' && (
        <BackgroundStyle
          backgroundUrl={
            floorType === 'booth' ? boothBackgroundUrl : gameBackgroundUrl
          }
          isMakingRequest={isMakingRequest}
          onUploadBackground={onBackgroundUploaded}
          onRemoveBackground={onRemoveBackground}
          floorType={floorType}
          floorId={floorId}
          onOpenBackgroundPicker={onOpenBackgroundPicker}
        />
      )}

      {floorType !== 'normal' && (
        <MeetingAreaSetup
          floorId={floorId}
          mapUrl={
            currentMapIsFloorsMap
              ? mapUrl
              : {
                  ...mapUrl,
                  value:
                    (floorType === 'booth'
                      ? floor?.boothBackgroundUrl
                      : floor?.gameBackgroundUrl) ?? '',
                  error: '',
                }
          }
          isMakingRequest={isMakingRequest}
          createMap={() => {
            createMap(floorType);
          }}
          floorType={floorType}
          onOpenMeetingAreaEditor={onOpenMeetingAreaEditor}
        />
      )}

      <div className="flex flex-row mb2">
        <FieldWrapper className="w-full" title="Custom button (Optional)">
          <div className="flex-1">
            <p>This button will appear on the action bar of your floor.</p>
          </div>
          <div className="flex flex-col md:flex-row gap-2 w-full flex-1">
            <Input
              label="Label"
              name="customButtonLabel"
              onChange={evt =>
                onChangeCustomButtonLabel(
                  evt.target.value,
                  customButtonLink.value,
                )
              }
              InputProps={{
                onKeyDown: evt => {
                  if (evt.key === 'Enter') {
                    evt.preventDefault();
                    evt.stopPropagation();
                  }
                },
              }}
              value={customButtonLabel.value}
              error={customButtonLabel.error}
            />
            <Input
              label="Link"
              name="customButtonLink"
              onChange={evt =>
                onChangeCustomButtonLink(
                  evt.target.value,
                  customButtonLabel.value,
                )
              }
              InputProps={{
                onKeyDown: evt => {
                  if (evt.key === 'Enter') {
                    evt.preventDefault();
                    evt.stopPropagation();
                  }
                },
              }}
              value={customButtonLink.value}
              error={customButtonLink.error}
            />
          </div>
        </FieldWrapper>
      </div>

      <div className="flex border-t1 border-color-shade-20 absolute border b0 l0 z1 bg-color-white w-full pt_5 pb1 flex-row flex-1 justify-center items-center gap-1">
        <Clickable
          isSubmit
          isDisabled={isMakingRequest || !hasChanges}
          variant={ClickableVariant.BUTTON_SECONDARY}
          label={isMakingRequest ? 'Saving...' : 'Save'}
        />
        <Clickable
          data-cy="cancel-edit-floor"
          onClick={handleClose}
          variant={ClickableVariant.BUTTON_TERTIARY}
        >
          Cancel
        </Clickable>
      </div>
      {formError && <p className="detail text-center color-red">{formError}</p>}
    </Form>
  );
}
