import {
  AttendeeTrackingLog,
  BroadcastRecording,
  DeleteFloorResponse,
  EventAnalytics,
  UpdateFloorResponse,
  AugmentedGatherlyEvent,
} from '@eventmanager/types';
import {
  EventLinks,
  IGatherlyEvent,
  IGatherlyEventConfig,
  LinkConfig,
  ChatLog,
} from '@gatherly/types';

import { createReducer } from './createReducer';

interface EventsState {
  analytics: Record<string, EventAnalytics>;
  hasMore: boolean;
  ids: string[];
  list: Record<string, AugmentedGatherlyEvent>;
  broadcastRecordings: Record<string, Record<string, BroadcastRecording>>;
  tracking: Record<string, AttendeeTrackingLog[]>;
  chat: Record<string, ChatLog[]>;
}

enum EventsActionType {
  ADD_ANALYTICS = '@events/ADD_ANALYTICS',
  ADD_BROADCAST_RECORDINGS = '@events/ADD_BROADCAST_RECORDINGS',
  ADD_EVENT = '@events/ADD_EVENT',
  ADD_FLOOR = '@events/ADD_FLOOR',
  ADD_TRACKING = '@events/ADD_TRACKING',
  DELETE_EVENT = '@events/DELETE_EVENT',
  REMOVE_FLOOR = '@events  REMOVE_FLOOR',
  SET_UPCOMING_EVENTS = '@events/SET_UPCOMING_EVENTS',
}
type EventsAddEventAction = {
  type: EventsActionType.ADD_EVENT;
  payload: IGatherlyEvent;
};
type EventsAddFloorAction = {
  type: EventsActionType.ADD_FLOOR;
  payload: {
    eventId: string;
    floorId: string;
    data: Partial<UpdateFloorResponse>;
  };
};
type EventsRemoveFloorAction = {
  type: EventsActionType.REMOVE_FLOOR;
  payload: {
    eventId: string;
    floorId: string;
  };
};
type EventsDeleteEventAction = {
  type: EventsActionType.DELETE_EVENT;
  payload: string;
};
type EventsSetUpcomingEventsAction = {
  type: EventsActionType.SET_UPCOMING_EVENTS;
  payload: {
    ids: string[];
    list: Record<string, AugmentedGatherlyEvent>;
    hasMore: boolean;
  };
};
type EventsAddAnalyticsAction = {
  type: EventsActionType.ADD_ANALYTICS;
  payload: {
    eventId: string;
    data: EventAnalytics;
  };
};
type EventsAddBroadcastRecordingsAction = {
  type: EventsActionType.ADD_BROADCAST_RECORDINGS;
  payload: {
    eventId: string;
    data: Record<string, BroadcastRecording>;
  };
};
type EventsAddTrackingAction = {
  type: EventsActionType.ADD_TRACKING;
  payload: {
    eventId: string;
    data: {
      tracking: AttendeeTrackingLog[];
      chat: ChatLog[];
    };
  };
};

type EventsAction =
  | EventsAddBroadcastRecordingsAction
  | EventsAddEventAction
  | EventsAddFloorAction
  | EventsAddAnalyticsAction
  | EventsAddTrackingAction
  | EventsRemoveFloorAction
  | EventsSetUpcomingEventsAction;

const initialState: EventsState = {
  analytics: {},
  hasMore: true,
  ids: [],
  list: {},
  broadcastRecordings: {},
  tracking: {},
  chat: {},
};

function sortEventsListByStartTime(
  list: Record<string, IGatherlyEvent>,
): string[] {
  return Object.keys(list).sort((a, b) => {
    if (list[a].startTime === list[b].startTime) return 0;
    return list[a].startTime < list[b].startTime ? 1 : -1;
  });
}

export const eventsReducer = createReducer<
  EventsState,
  any, // TODO: fix type inference
  EventsActionType
>(initialState, {
  [EventsActionType.ADD_ANALYTICS]: (
    state: EventsState,
    action: EventsAddAnalyticsAction,
  ): EventsState => {
    return {
      ...state,
      analytics: {
        ...state.analytics,
        [action.payload.eventId]: action.payload.data,
      },
    };
  },
  [EventsActionType.ADD_EVENT]: (
    state: EventsState,
    action: EventsAddEventAction,
  ): EventsState => {
    const list = {
      ...state.list,
      [action.payload.eventId]: action.payload,
    };
    const ids = sortEventsListByStartTime(list);
    return {
      ...state,
      ids,
      list,
    };
  },
  [EventsActionType.ADD_FLOOR]: (
    state: EventsState,
    action: EventsAddFloorAction,
  ): EventsState => {
    const { eventId, floorId, data } = action.payload;
    const event = state.list[eventId];
    if (!event) {
      console.error(
        `[eventsReducer::${EventsActionType.ADD_FLOOR}] Floor ${floorId} missing from event ${eventId}`,
      );
      return state;
    }
    const floors = event.config.floors.map(floor => {
      if (floor.id === floorId) return { ...floor, ...data.data?.floor };
      return floor;
    });
    return {
      ...state,
      list: updateEventConfigInList(
        {
          ...state.list,
          [eventId]: {
            ...event,
            areaLinks: data.data?.areaLinks,
          },
        },
        eventId,
        { floors },
      ),
    };
  },
  [EventsActionType.REMOVE_FLOOR]: (
    state: EventsState,
    action: EventsRemoveFloorAction,
  ): EventsState => {
    const { eventId, floorId } = action.payload;
    const event = state.list[eventId];
    if (!event) {
      console.error(
        `[eventsReducer::${EventsActionType.REMOVE_FLOOR}] Floor ${floorId} missing from event ${eventId}`,
      );
      return state;
    }
    const floors = event.config.floors.filter(floor => floor.id !== floorId);
    const removedFloor = event.config.floors.find(
      floor => floor.id === floorId,
    );
    let updatedLinks: LinkConfig[] = [];

    if (removedFloor) {
      updatedLinks = Object.values(event.areaLinks ?? []).filter(
        link => !removedFloor.meetingAreas?.some(area => area.id === link.maIM),
      );
    }

    const areaLinks: EventLinks = {};
    updatedLinks.forEach(link => (areaLinks[link.name] = link));

    return {
      ...state,
      list: updateEventConfigInList(
        {
          ...state.list,
          [eventId]: {
            ...state.list[eventId],
            areaLinks,
          },
        },
        eventId,
        { floors },
      ),
    };
  },
  [EventsActionType.DELETE_EVENT]: (
    state: EventsState,
    { payload: eventId }: EventsDeleteEventAction,
  ): EventsState => {
    return {
      ...state,
      ...removeEventFromList(state.list, state.ids, eventId),
    };
  },
  [EventsActionType.SET_UPCOMING_EVENTS]: (
    state: EventsState,
    action: EventsSetUpcomingEventsAction,
  ): EventsState => {
    const list = {
      ...state.list,
      ...action.payload.list,
    };
    const ids = sortEventsListByStartTime(list);
    return {
      ...state,
      ids,
      list,
      hasMore: action.payload.hasMore,
    };
  },
  [EventsActionType.ADD_BROADCAST_RECORDINGS]: (
    state: EventsState,
    action: EventsAddBroadcastRecordingsAction,
  ): EventsState => {
    return {
      ...state,
      broadcastRecordings: {
        ...state.broadcastRecordings,
        [action.payload.eventId]: action.payload.data,
      },
    };
  },
  [EventsActionType.ADD_TRACKING]: (
    state: EventsState,
    action: EventsAddTrackingAction,
  ): EventsState => {
    return {
      ...state,
      tracking: {
        ...state.tracking,
        [action.payload.eventId]: action.payload.data.tracking,
      },
      chat: {
        ...state.chat,
        [action.payload.eventId]: action.payload.data.chat,
      },
    };
  },
});

// action creators
export function makeAddAnalyticsAction(
  eventId: string,
  data: EventAnalytics,
): EventsAddAnalyticsAction {
  return {
    type: EventsActionType.ADD_ANALYTICS,
    payload: {
      eventId,
      data,
    },
  };
}
export function makeAddBroadcastRecordingsAction(
  eventId: string,
  data: Record<string, BroadcastRecording>,
): EventsAddBroadcastRecordingsAction {
  return {
    type: EventsActionType.ADD_BROADCAST_RECORDINGS,
    payload: {
      eventId,
      data,
    },
  };
}
export function makeAddEventAction(
  payload: IGatherlyEvent,
): EventsAddEventAction {
  return {
    type: EventsActionType.ADD_EVENT,
    payload,
  };
}
export function makeAddFloorAction(
  payload: UpdateFloorResponse,
): EventsAddFloorAction {
  return {
    type: EventsActionType.ADD_FLOOR,
    payload: {
      ...payload,
      data: {
        ...payload,
      },
    },
  };
}
export function makeRemoveFloorAction(
  payload: DeleteFloorResponse,
): EventsRemoveFloorAction {
  return {
    type: EventsActionType.REMOVE_FLOOR,
    payload,
  };
}
export function makeAddTrackingAction(
  eventId: string,
  data: { tracking: AttendeeTrackingLog[]; chat: ChatLog[] },
): EventsAddTrackingAction {
  return {
    type: EventsActionType.ADD_TRACKING,
    payload: {
      eventId,
      data,
    },
  };
}
export function makeSetUpcomingEventsAction(payload: {
  ids: string[];
  list: Record<string, AugmentedGatherlyEvent>;
  hasMore: boolean;
}): EventsAction {
  return {
    type: EventsActionType.SET_UPCOMING_EVENTS,
    payload,
  };
}

export function makeDeleteEventAction(
  payload: string,
): EventsDeleteEventAction {
  return {
    type: EventsActionType.DELETE_EVENT,
    payload,
  };
}

function removeEventFromList(
  listState: Record<string, IGatherlyEvent>,
  idsState: string[],
  eventId: string,
): Partial<EventsState> {
  const { [eventId]: _deletedEvent, ...list } = listState;
  return { list, ids: idsState.filter(id => id !== eventId) };
}

function updateEventConfigInList(
  listState: Record<string, IGatherlyEvent>,
  eventId: string,
  data: Partial<IGatherlyEventConfig>,
): Record<string, IGatherlyEvent> {
  const event = listState[eventId];
  if (!event) {
    console.error(
      `[eventsReducer::updateEventConfigInList] Event missing from list ${eventId}`,
    );
    return listState;
  }
  return {
    ...listState,
    [eventId]: {
      ...event,
      config: {
        ...event.config,
        ...data,
      },
    },
  };
}
