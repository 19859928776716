import {
  AttendeeTrackingLog,
  BroadcastRecording,
  EventAnalytics,
  AugmentedGatherlyEvent,
} from '@eventmanager/types';
import {
  ChatLog,
  IBaseMeetingArea,
  IGatherlyEvent,
  LinkConfig,
} from '@gatherly/types';
import { GatherlyEventServerStatus } from '../enums';
import { RootState } from '../store';
import {
  getEventServerStatus,
  lastItem,
  isPending,
  isRejected,
} from '../utils';

export const eventById =
  (eventId = '') =>
  (state: RootState): AugmentedGatherlyEvent | undefined => {
    return state.events.list[eventId];
  };

export const eventsByIds =
  (eventIds: string[]) =>
  (state: RootState): IGatherlyEvent[] => {
    return eventIds.reduce((acc: IGatherlyEvent[], eventId: string) => {
      acc.push(state.events.list[eventId]);
      return acc;
    }, []);
  };

const emptyAnalytics: EventAnalytics = {
  AvgDuration: 0,
  AvgDurationByFloor: [],
  EndTime: '',
  EventDuration: 0,
  MaxOccupancy: 0,
  MaxOccupancyByFloor: [],
  NumFloors: 0,
  NumPeople: 0,
  NumPeopleByFloor: [],
  OccupancyData: [],
  OccupancyDataByFloor: [[]],
  PeopleHours: 0,
  PeopleHoursByFloor: [],
  NumHuddles: 0,
  NumBoothMeetings: 0,
  Location: {},
  PhotosTaken: {
    group: 0,
    selfie: 0,
    total: 0,
  },
  Server: '',
  StartTime: '',
  unixEndTime: 0,
  unixStartTime: 0,
};
export const eventAnalytics =
  (eventId = '') =>
  (state: RootState): EventAnalytics => {
    return state.events.analytics[eventId] || emptyAnalytics;
  };

const emptyBroadcastRecordings: Record<string, BroadcastRecording> = {};
export const eventBroadcastRecordings =
  (eventId = '') =>
  (state: RootState): Record<string, BroadcastRecording> => {
    return (
      state.events.broadcastRecordings[eventId] || emptyBroadcastRecordings
    );
  };
export const eventBroadcastRecording =
  (eventId = '', recordingId = '') =>
  (state: RootState): BroadcastRecording => {
    const broadcastRecordings =
      state.events.broadcastRecordings[eventId] || emptyBroadcastRecordings;
    return broadcastRecordings[recordingId];
  };

const emptyTracking: AttendeeTrackingLog[] = [];
export const eventTracking =
  (eventId = '') =>
  (state: RootState): AttendeeTrackingLog[] => {
    return state.events.tracking[eventId] || emptyTracking;
  };

export const eventChatLogs =
  (eventId = '') =>
  (state: RootState): ChatLog[] => {
    return state.events.chat[eventId] || [];
  };

export const eventLinks =
  (eventId = '') =>
  (
    state: RootState,
  ): {
    links: Record<string, LinkConfig>;
    areaLinks: Record<string, LinkConfig>;
  } => {
    return (
      {
        links: state.events.list[eventId]?.links,
        areaLinks: state.events.list[eventId]?.areaLinks || {},
      } || {}
    );
  };

export const eventServerStatus =
  (eventId = '') =>
  (state: RootState): GatherlyEventServerStatus | undefined => {
    const event = state.events.list[eventId];
    return getEventServerStatus(event?.startTime, event?.stopTime);
  };

export function allEvents(state: RootState): AugmentedGatherlyEvent[] {
  return state.events.ids.map(id => state.events.list[id]);
}

export const shouldLoadMoreEvents = (state: RootState): boolean => {
  const status = state.status.getUpcomingEvents;
  if (isPending(status) || isRejected(status)) return false;
  return state.events.hasMore;
};

export const lastEventLoaded = (
  state: RootState,
): IGatherlyEvent | undefined => {
  const eventId = lastItem(state.events.ids);
  if (eventId) return state.events.list[eventId];
};

export const eventBooths =
  (eventId = '') =>
  (state: RootState): IBaseMeetingArea[] => {
    return (
      state.events.list[eventId]?.config.floors
        .map(
          floor =>
            floor.meetingAreas?.filter(area => area.type === 'Booth') ?? [],
        )
        .reduce((prev, curr) => {
          return prev.concat(curr);
        }, []) ?? []
    );
  };
