import { SyntheticEvent, useCallback } from 'react';
import { LinkConfig } from '@gatherly/types';
import { classnames } from '@gatherly/lib';
import { Clickable, ClickableVariant } from '../Clickable';
import { setOverlay, OverlayType } from '../../actions';
import { Copyable } from '../Copyable';
import { useAppDispatch } from '../../hooks';
import SharePopover from './SharePopover';
import classes from './EventLinkList.module.scss';

type Props = {
  eventId: string;
  isEditable?: boolean;
  linkConfig: LinkConfig;
  className?: string;
};

export const ActionLinks = ({
  linkConfig,
  eventId,
  isEditable,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();

  const onClickComposeEmail = useCallback(
    (evt?: SyntheticEvent) => {
      if (evt) evt.preventDefault();
      setOverlay(dispatch, OverlayType.SHARE_LINK, { eventId, linkConfig });
    },
    [linkConfig, eventId, dispatch],
  );

  const handleEdit = useCallback(
    (evt?: SyntheticEvent) => {
      if (evt) evt.preventDefault();
      setOverlay(dispatch, OverlayType.EDIT_LINK, { eventId, linkConfig });
    },
    [linkConfig, eventId, dispatch],
  );

  const handleDelete = useCallback(
    (evt?: SyntheticEvent) => {
      if (evt) evt.preventDefault();
      setOverlay(dispatch, OverlayType.DELETE_LINK, {
        eventId,
        linkName: linkConfig.name,
      });
    },
    [linkConfig, eventId, dispatch],
  );

  return (
    <div
      className={classnames(
        classes.col,
        'flex justify-center items-start gap-_5',
      )}
    >
      {isEditable && (
        <>
          <Clickable className={classes.actionLink} onClick={handleEdit}>
            Edit
          </Clickable>
          ·
        </>
      )}
      <>
        <Copyable
          className={classes.actionLink}
          value={linkConfig.url}
          copiedContent="Copied!"
          variant={ClickableVariant.LINK}
        >
          Copy Link
        </Copyable>
        ·
      </>
      <>
        <Clickable className={classes.actionLink} onClick={onClickComposeEmail}>
          <div>Email a Copy</div>
        </Clickable>
        ·
      </>
      <SharePopover linkConfig={linkConfig} />
      {isEditable && (
        <>
          ·
          <Clickable className={classes.actionLink} onClick={handleDelete}>
            Delete
          </Clickable>
        </>
      )}
    </div>
  );
};
