import React, { useCallback, useState, SyntheticEvent } from 'react';

import { PromiseStatus } from '../../enums';
import { Clickable, ClickableVariant } from '../Clickable';
import { Input, Password } from '../Input';
import { resetPassword } from '../../actions/accountActions';
import { setIdle } from '../../actions/statusActions';
import { useAppDispatch } from '../../hooks';

import {
  getFormField,
  getOnChangeFormField,
  hasValidationError,
  isPending,
  isRejected,
  validateEmailAddress,
  validatePassword,
  validateString,
} from '../../utils';
import { Form } from './Form';

type Props = {
  status: PromiseStatus;
};

export function ResetPasswordForm({ status }: Props) {
  const dispatch = useAppDispatch();

  const isMakingRequest = isPending(status);
  const hasError = isRejected(status);
  const [formError, setFormError] = useState('');

  const [code, setCode] = useState(getFormField(''));
  const [email, setEmail] = useState(getFormField(''));
  const [password, setPassword] = useState(getFormField(''));

  const onChangeCode = useCallback(
    getOnChangeFormField(
      value => validateString(value, 'Verification code'),
      setCode,
    ),
    [],
  );
  const onChangeEmail = useCallback(
    getOnChangeFormField(validateEmailAddress, setEmail),
    [],
  );
  const onChangePassword = useCallback(
    getOnChangeFormField(validatePassword, setPassword),
    [],
  );

  async function onSubmit(evt: SyntheticEvent) {
    evt.preventDefault();
    const _code = onChangeCode(code.value);
    const _email = onChangeEmail(email.value);
    const _password = onChangePassword(password.value);

    if (hasValidationError(_code, _email, _password)) {
      setFormError('Please fix all errors to continue.');
      return;
    } else {
      setFormError('');
      const response = await resetPassword(
        dispatch,
        email.value,
        code.value,
        password.value,
      );
      if (response && response.hasOwnProperty('error')) {
        setFormError(response?.error || '');
      }
    }
  }

  return (
    <Form className="flex flex-col gap-1" onSubmit={onSubmit}>
      <p className="detail color-shade-60">
        Check your email for a verification code. No email? Check your spam
        folder, or{'  '}
        <Clickable
          onClick={() => setIdle(dispatch, 'requestResetPassword')}
          variant={ClickableVariant.LINK}
        >
          request another
        </Clickable>
        .
      </p>
      <Input
        label="Verification Code"
        name="code"
        onChange={evt => onChangeCode(evt.target.value)}
        value={code.value}
        error={code.error}
        isDisabled={isMakingRequest}
        isRequired
      />
      <Input
        autoComplete="email"
        label="Email Address"
        name="email"
        onChange={evt => onChangeEmail(evt.target.value)}
        value={email.value}
        error={email.error}
        isDisabled={isMakingRequest}
        isRequired
      />
      <Password
        autoComplete="off"
        label="New Password"
        maxLength={99}
        name="password"
        onChange={evt => onChangePassword(evt.target.value)}
        value={password.value}
        error={password.error}
        validations={password.validations}
        isDisabled={isMakingRequest}
        isRequired
      />
      <Clickable
        isSubmit
        isDisabled={isMakingRequest}
        label={isMakingRequest ? 'SUBMITTING...' : 'SUBMIT'}
        variant={ClickableVariant.BUTTON_PRIMARY}
      />
      <div className="flex flex-1 flex-col gap-1 items-center">
        {hasError && !formError && (
          <p className="color-red small text-center">
            Sorry, we were unable to reset your password.
          </p>
        )}
        {formError && (
          <p className="color-red small text-center">{formError}</p>
        )}
      </div>
    </Form>
  );
}
