import { EVENT_ALIAS_MAX_LENGTH } from '../config';

const toTitleCaseLowers =
  'a an and as at but by for for from in into near nor of on onto or the to with'.split(
    ' ',
  );

export function toTitleCase(str: string): string {
  const lowers = toTitleCaseLowers.map(lower => `\\s${lower}\\s`);
  const regex = new RegExp(`(?!${lowers.join('|')})\\w\\S*`, 'g');
  return str.replace(
    regex,
    txt =>
      txt.charAt(0).toUpperCase() +
      txt.substr(1).replace(/[A-Z]/g, word => ` ${word}`),
  );
}

export function toHyphenCase(str: string) {
  return str
    .replace('&', 'and')
    .replace(/[^\w]/g, '-')
    .replace(/-+/g, '-')
    .replace(/^-|-$/g, '')
    .toLowerCase();
}

export const makeEventAlias = (
  eventName: string | undefined,
  eventUrl: string | undefined,
) => {
  return (eventUrl || toHyphenCase(`${eventName}`)).substring(
    0,
    EVENT_ALIAS_MAX_LENGTH,
  );
};
