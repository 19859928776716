import { IBaseMeetingArea, IGatherlyEvent } from '@gatherly/types';

import {
  DEFAULT_MAP_URL,
  MAX_BROCHURE_SIZE,
  MAX_LOGO_SIZE,
  MAX_MAP_SIZE,
  SUPPORTED_BROCHURE_MIME_TYPES,
  SUPPORTED_IMAGE_MIME_TYPES,
} from '../config';
import { makeAddFloorAction } from '../store/eventsReducer';
import { Api, isUserUploaded, getKeyForS3Item, validateFile } from '../utils';
import { updateEventConfig } from './eventActions';
import { setPending, setFulfilled, setRejected } from './statusActions';
import { trackBrochureUploaded } from '../libs/trackingLib';

export async function uploadBanner(
  dispatch,
  event: IGatherlyEvent,
  file: File,
): Promise<boolean | { error: string }> {
  try {
    const error = validateFile(
      file,
      'Banner',
      SUPPORTED_IMAGE_MIME_TYPES,
      MAX_MAP_SIZE,
    );
    if (error) return { error };

    setPending(dispatch, 'uploadBanner');
    const bannerUrl = await Api.uploadFile(file, 'banner');

    const response = await updateEventConfig(dispatch, event, {
      landingPage: {
        ...event.config.landingPage,
        bannerUrl,
      },
    });
    setFulfilled(dispatch, 'uploadBanner');
    return response;
  } catch (err: any) {
    console.error(err?.response || err);
    setRejected(dispatch, 'updateEvent', event.eventId);
    setRejected(dispatch, 'uploadBanner');
    return { error: 'An unexpected error has occurred' };
  }
}

export async function uploadBrochure(
  dispatch,
  floorId: string,
  file: File,
): Promise<string | { error: string }> {
  try {
    const error = validateFile(
      file,
      'Brochure',
      SUPPORTED_BROCHURE_MIME_TYPES,
      MAX_BROCHURE_SIZE,
    );

    if (error) return { error };

    setPending(dispatch, 'uploadBrochure', floorId);
    const brochureUrl = await Api.uploadFile(file, 'brochure');
    if (!brochureUrl) throw new Error('Brochure file not uploaded');
    trackBrochureUploaded();
    setFulfilled(dispatch, 'uploadBrochure', floorId);
    return brochureUrl;
  } catch (err: any) {
    console.error(err?.response || err);
    setRejected(dispatch, 'uploadBrochure', floorId);
    return { error: 'Failed to upload brochure file' };
  }
}

export async function updateBrochure(
  dispatch,
  eventId: string,
  floorId: string,
  options: {
    brochureUrl?: string;
    file?: File;
  },
): Promise<boolean | { error: string }> {
  try {
    let { brochureUrl } = options;
    const { file } = options;

    const fileUploadResponse = file
      ? await uploadBrochure(dispatch, floorId, file)
      : '';

    if (typeof fileUploadResponse === 'object')
      throw new Error(fileUploadResponse.error);
    if (!fileUploadResponse) throw new Error('Uploaded brochure file is empty');

    brochureUrl = fileUploadResponse;
    const response = await Api.updateFloor(eventId, floorId, {
      brochureUrl,
    });
    dispatch(makeAddFloorAction(response));
    setFulfilled(dispatch, 'uploadBrochure', floorId);
    return !!brochureUrl && response?.data?.floor.brochureUrl === brochureUrl;
  } catch (err: any) {
    console.error(err?.response || err);
    setRejected(dispatch, 'uploadBrochure', floorId);
    return { error: 'An unexpected error has occurred' };
  }
}

interface UploadBoothLogoProps {
  dispatch: any;
  eventId: string;
  floorId: string;
  file: File;
  boothId: string;
  meetingAreas: IBaseMeetingArea[];
}

export async function uploadBoothLogo({
  dispatch,
  eventId,
  floorId,
  file,
  boothId,
  meetingAreas,
}: UploadBoothLogoProps): Promise<{ error?: string; logoUrl?: string }> {
  try {
    const error = validateFile(
      file,
      'Logo',
      SUPPORTED_IMAGE_MIME_TYPES,
      MAX_LOGO_SIZE,
    );
    if (error) return { error };

    setPending(dispatch, 'uploadBoothLogo');
    const logoUrl = await Api.uploadFile(file, 'boothLogo');

    const booths = meetingAreas.map(area => {
      return {
        ...area,
        ...(area.id === boothId && {
          companyLogo: logoUrl,
        }),
      };
    });

    const response = await Api.updateFloor(eventId, floorId, {
      meetingAreas: booths,
    });

    dispatch(makeAddFloorAction(response));
    setFulfilled(dispatch, 'uploadBoothLogo');
    return {
      logoUrl,
    };
  } catch (err: any) {
    console.error(err?.response || err);
    setRejected(dispatch, 'updateEvent', eventId);
    setRejected(dispatch, 'uploadBoothLogo');
    return { error: 'An unexpected error has occurred' };
  }
}

export async function updateLogos(
  dispatch,
  event: IGatherlyEvent,
  logo: { file: File; websiteLink?: string },
): Promise<{ error?: string; logoUrl?: string }> {
  try {
    const error = validateFile(
      logo.file,
      'Logo',
      SUPPORTED_IMAGE_MIME_TYPES,
      MAX_LOGO_SIZE,
    );
    if (error) return { error };

    setPending(dispatch, 'uploadLogo');
    const logoUrl = await Api.uploadFile(logo.file, 'logo');

    const response = await updateEventConfig(dispatch, event, {
      landingPage: {
        ...event.config.landingPage,
        logoUrls: event.config.landingPage.logoUrls.concat({
          imageUrl: logoUrl,
          imageLink: logo.websiteLink,
        }),
      },
    });

    if (!response) {
      return {
        error: 'Failed to update logo',
      };
    }

    setFulfilled(dispatch, 'uploadLogo');
    return {
      logoUrl,
    };
  } catch (err: any) {
    console.error(err?.response || err);
    setRejected(dispatch, 'updateEvent', event.eventId);
    setRejected(dispatch, 'uploadLogo');
    return { error: 'An unexpected error has occurred' };
  }
}

export async function uploadMap(
  dispatch,
  floorId: string,
  file: File,
): Promise<{ mapUrl?: string; error?: string }> {
  try {
    const error = validateFile(
      file,
      'Map',
      SUPPORTED_IMAGE_MIME_TYPES,
      MAX_MAP_SIZE,
    );
    if (error) return { error };

    setPending(dispatch, 'uploadMap', floorId);
    const mapUrl = await Api.uploadFile(file, 'map');
    if (!mapUrl) throw new Error('Map file not uploaded');
    setFulfilled(dispatch, 'uploadMap', floorId);
    return { mapUrl };
  } catch (err: any) {
    console.error(err?.response || err);
    setRejected(dispatch, 'uploadMap', floorId);
    return { error: 'Failed to upload map file' };
  }
}

export async function updateMap(
  dispatch,
  eventId: string,
  floorId: string,
  options: {
    mapUrl?: string;
    file?: File;
    isBooth: boolean;
  },
): Promise<boolean | { error: string }> {
  try {
    let { mapUrl } = options;
    const { file, isBooth } = options;

    if (file) {
      const fileUploadResponse = await uploadMap(dispatch, floorId, file);

      if (!fileUploadResponse?.mapUrl) {
        if (fileUploadResponse?.error)
          throw new Error(fileUploadResponse.error);
        throw new Error('Uploaded map file is empty');
      }

      mapUrl = fileUploadResponse.mapUrl;
    }

    const response = await Api.updateFloor(eventId, floorId, {
      mapUrl,
      ...(!isBooth && {
        boothBackgroundUrl: '',
        gameBackgroundUrl: '',
        meetingAreas: [],
      }),
    });
    dispatch(makeAddFloorAction(response));
    return !!mapUrl && response?.data?.floor.mapUrl === mapUrl;
  } catch (err: any) {
    console.error(err?.response || err);
    setRejected(dispatch, 'uploadMap', floorId);
    return { error: 'An unexpected error has occurred' };
  }
}

export async function removeBanner(
  dispatch,
  event: IGatherlyEvent,
): Promise<boolean | { error: string }> {
  try {
    const bannerUrl = event.config.landingPage.bannerUrl;
    setPending(dispatch, 'uploadBanner');
    if (isUserUploaded(bannerUrl)) {
      const s3Key = getKeyForS3Item(bannerUrl);
      await Api.deleteFile(s3Key);
    }

    const response = updateEventConfig(dispatch, event, {
      landingPage: {
        ...event.config.landingPage,
        bannerUrl: '',
      },
    });
    setFulfilled(dispatch, 'uploadBanner');
    return response;
  } catch (err: any) {
    console.error(err?.response || err);
    setRejected(dispatch, 'updateEvent');
    setRejected(dispatch, 'uploadBanner');
    return { error: 'An unexpected error has occurred' };
  }
}

export async function removeLandingPageLogos(
  dispatch,
  event: IGatherlyEvent,
  logoUrls: string[],
): Promise<boolean | { error: string }> {
  try {
    setPending(dispatch, 'uploadLogo');
    logoUrls.map(async logo => {
      const s3Key = getKeyForS3Item(logo);
      await Api.deleteFile(s3Key);
    });

    const updatedLogoUrls = event.config.landingPage.logoUrls.filter(value => {
      if (typeof value === 'string') {
        return !logoUrls.includes(value);
      }

      return !logoUrls.includes(value.imageUrl);
    });

    const response = updateEventConfig(dispatch, event, {
      landingPage: {
        ...event.config.landingPage,
        logoUrls: updatedLogoUrls,
      },
    });
    setFulfilled(dispatch, 'uploadLogo');
    return response;
  } catch (err: any) {
    console.error(err?.response || err);
    setRejected(dispatch, 'updateEvent');
    setRejected(dispatch, 'uploadLogo');
    return { error: 'An unexpected error has occurred' };
  }
}

export async function removeBrochure(
  dispatch,
  eventId: string,
  floorId: string,
  brochureUrl: string,
): Promise<boolean | { error: string }> {
  try {
    setPending(dispatch, 'uploadBrochure', floorId);
    const s3Key = getKeyForS3Item(brochureUrl);
    await Api.deleteFile(s3Key);

    return updateBrochure(dispatch, eventId, floorId, { brochureUrl: '' });
  } catch (err: any) {
    console.error(err?.response || err);
    setRejected(dispatch, 'uploadBrochure', floorId);
    return { error: 'An unexpected error has occurred' };
  }
}

export async function removeMap(
  dispatch,
  eventId: string,
  floorId: string,
  mapUrl: string,
): Promise<boolean | { error: string }> {
  try {
    if (isUserUploaded(mapUrl)) {
      const s3Key = getKeyForS3Item(mapUrl);
      await Api.deleteFile(s3Key);
    }

    return updateMap(dispatch, eventId, floorId, {
      mapUrl: DEFAULT_MAP_URL,
      isBooth: false,
    });
  } catch (err: any) {
    console.error(err?.response || err);
    setRejected(dispatch, 'uploadMap', floorId);
    return { error: 'An unexpected error has occurred' };
  }
}

export async function uploadLogo(
  dispatch,
  event: IGatherlyEvent,
  file: File,
): Promise<boolean | { error: string }> {
  try {
    const error = validateFile(
      file,
      'Banner',
      SUPPORTED_IMAGE_MIME_TYPES,
      MAX_MAP_SIZE,
    );
    if (error) return { error };

    const logoUrl = await Api.uploadFile(file, 'event-logo');

    const response = await updateEventConfig(dispatch, event, {
      logoUrl,
    });
    return response;
  } catch (err: any) {
    console.error(err?.response || err);
    return { error: 'An unexpected error has occurred' };
  }
}

export async function removeLogo(
  dispatch,
  event: IGatherlyEvent,
): Promise<boolean | { error: string }> {
  try {
    const {
      config: { logoUrl },
    } = event;
    await Api.deleteFile(getKeyForS3Item(logoUrl));

    const response = updateEventConfig(dispatch, event, {
      logoUrl: '',
    });
    return response;
  } catch (err: any) {
    console.error(err?.response || err);
    return { error: 'An unexpected error has occurred' };
  }
}

export function handleUploadError(response, type: string, action: string) {
  if (response?.hasOwnProperty('error')) {
    // @ts-ignore
    alert(`Failed to ${action} ${type} - ${response.error}!`);
  }
}
