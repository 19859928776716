import { Clickable, ClickableVariant } from '../Clickable';
import { DateRangePicker, InputEvent } from '../DateRangePicker';
import {
  EVENT_ALIAS_MAX_LENGTH,
  EVENT_ALIAS_SUFFIX,
  MIN_EVENT_CAPACITY,
  MIN_EVENT_DURATION,
} from '../../config';
import {
  FormField,
  c,
  getFormField,
  getOnChangeFormField,
  getTimeFromNow,
  hasValidationError,
  isPending,
  isRejected,
  makeEventAlias,
  validateCapacity,
  validateEventAliasPrefix,
  validateEventDuration,
  validateString,
  validateTimestamp,
} from '../../utils';
import { GatherlyEventType, eventTypeOptionsArray } from '../../enums';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  currentUser,
  getStatusOfCreateEvent,
  maxEventDurationHours,
} from '../../selectors';
import { useAppDispatch, useAppSelector } from '../../hooks';

import { DateTime } from 'luxon';
import { Form } from './Form';
import { Input } from '../Input';
import { NewAIEventProgressIndicator } from './NewAIEventProgressIndicator';
import { Select } from '../Select';
import classes from './NewEventForm.module.scss';
import { createEvent } from '../../actions/eventActions';
import { getEventById } from '../../utils/http/event';
import { setIdle } from '../../actions/statusActions';

type Props = {
  eventId?: string;
  //withAI: boolean;
  //isManualSetup: boolean;
  //setIsManualSetup: (isManualSetup: boolean) => void;
  onCancel: () => void;
  onSuccess: (eventId: string) => void;
  //onSuccess: (eventId: string, generatedWithAI: boolean) => void;
};

const getDefaultFutureTimeField = (value: number) =>
  getFormField(
    getTimeFromNow({ days: 1 }, DateTime.fromMillis(value)).toMillis(),
  );

export function NewEventForm({
  onCancel,
  onSuccess,
  eventId: sourceEventId,
}: //withAI,
//isManualSetup,
//setIsManualSetup,
Props) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    setIdle(dispatch, 'createEvent');
  }, []);

  const status = useAppSelector(getStatusOfCreateEvent);
  const isMakingRequest = isPending(status);
  const hasError = isRejected(status);

  const user = useAppSelector(currentUser);
  const maxEventDuration = useAppSelector(maxEventDurationHours);
  const [formError, setFormError] = useState('');
  const [durationError, setDurationError] = useState('');
  const [eventName, setEventName] = useState(getFormField(''));
  const [eventType, setEventType] = useState(getFormField(''));
  const [capacity, setCapacity] = useState(
    getFormField<string>(MIN_EVENT_CAPACITY.toString()),
  );
  const [eventUrl, setEventUrl] = useState(getFormField(''));
  //const [aiPrompt, setAiPrompt] = useState(getFormField(''));
  //const [creatingAIEventFailed, setCreatingAIEventFailed] = useState(false);
  const [eventTime, setEventTime] = useState<Record<string, FormField<number>>>(
    {
      startTime: getFormField(getTimeFromNow({ minutes: 30 }).toMillis()),
      stopTime: getFormField(
        getTimeFromNow({ minutes: 30 + MIN_EVENT_DURATION }).toMillis(),
      ),
    },
  );
  const { startTime, stopTime } = eventTime;

  const onChangeEventName = useCallback(
    getOnChangeFormField(
      value => validateString(value, 'Event name'),
      setEventName,
    ),
    [],
  );
  const onChangeEventType = useCallback(
    getOnChangeFormField(
      value => validateString(value, 'Event type'),
      setEventType,
    ),
    [],
  );
  const onChangeEventUrl = useCallback(
    getOnChangeFormField(validateEventAliasPrefix, setEventUrl),
    [],
  );
  const onChangeCapacity = useCallback(
    getOnChangeFormField(
      value => validateCapacity(value, user?.tickets || 0, 0),
      setCapacity,
    ),
    [],
  );
  /*
  const onChangeAiPrompt = useCallback(
    getOnChangeFormField(
      value => validateString(value, 'Prompt', true, MAX_AI_PROMPT_LENGTH),
      setAiPrompt,
    ),
    [],
  );
*/
  /*
  const onChangeSetupType = (manual: boolean) => {
    setIsManualSetup(manual);
  };
  */

  const onTimeChange = useCallback(
    (event: InputEvent) => {
      const {
        target: { name, value },
      } = event;
      const updatedEventTime = eventTime;

      if (name === 'startTime') {
        const duration =
          (eventTime.stopTime.value - eventTime.startTime.value) / 1000 / 60;
        updatedEventTime.stopTime = getFormField(
          getTimeFromNow(
            { minutes: duration },
            DateTime.fromMillis(value),
          ).toMillis(),
        );
      }

      setEventTime({ ...eventTime, [name]: validateTimestamp(value) });
    },
    [eventTime],
  );

  useEffect(() => {
    const _durationError = validateEventDuration(
      startTime.value,
      stopTime.value,
      maxEventDuration,
    );
    setDurationError(_durationError);
  }, [startTime.value, stopTime.value, maxEventDuration]);

  useEffect(() => {
    if (sourceEventId) {
      getEventById(sourceEventId).then(({ event }) => {
        setEventName(getFormField(event.name));
        setEventType(getFormField(event.eventType || ''));
        setCapacity(getFormField(event.capacity.toString()));
        setEventUrl(getFormField(event.alias.split('.').shift() || ''));
        setEventTime({
          startTime: getDefaultFutureTimeField(event.startTime),
          stopTime: getDefaultFutureTimeField(event.stopTime),
        });
      });
    }
  }, [sourceEventId]);

  const handleSubmit = useCallback(
    async (evt: SyntheticEvent) => {
      evt.preventDefault();
      const _eventName = onChangeEventName(eventName.value);
      const _eventType = onChangeEventType(eventType.value);
      const _eventUrl = onChangeEventUrl(
        makeEventAlias(eventName.value, eventUrl.value),
      );
      const _capacity = onChangeCapacity(capacity.value);
      const _durationError = validateEventDuration(
        startTime.value,
        stopTime.value,
        maxEventDuration,
      );

      if (
        _durationError ||
        hasValidationError(
          _eventName,
          // ...(isManualSetup ? [_eventType] : []),
          _eventUrl,
          _capacity,
        )
      ) {
        setFormError('Please correct all form fields to continue');
      } else {
        setFormError('');

        //const { error, eventId, isUrlTaken } = await createEvent(dispatch, {
        const { error, eventId } = await createEvent(dispatch, {
          alias: _eventUrl.value + EVENT_ALIAS_SUFFIX,
          capacity: parseInt(_capacity.value, 10),
          eventType: _eventType.value,
          name: _eventName.value.trim(),
          startTime: startTime.value,
          stopTime: stopTime.value,
          //prompt: aiPrompt.value,
          //withAI: !isManualSetup,
          sourceEventId,
        });

        if (error) {
          return setFormError(error);
        }

        if (eventId) {
          onSuccess(eventId);
        }

        /*
        if (!isManualSetup && !eventId && isUrlTaken !== true)
          setCreatingAIEventFailed(true);

        if (error) {
          return setFormError(error);
        }
        if (eventId) {
          onSuccess(eventId, !isManualSetup);
        }
        */
      }
    },
    [
      eventName.value,
      eventType.value,
      eventUrl.value,
      capacity.value,
      startTime.value,
      stopTime.value,
      maxEventDuration,
      //aiPrompt.value,
      // isManualSetup,
    ],
  );

  const eventUrlError = useMemo(() => {
    if (!eventUrl.value && eventName.value) {
      return undefined;
    }

    return eventUrl.error;
  }, [eventUrl.error, eventUrl.value, eventName.value]);

  //const aiPromptDisabled = isMakingRequest || isManualSetup;

  return (
    <Form
      className={c(
        classes.NewEventForm,
        'flex flex-col gap-1',
        isMakingRequest && classes.isMakingRequest,
      )}
      onSubmit={handleSubmit}
    >
      <p className="body">Let's create your new event:</p>
      <div className="flex flex-col md:flex-row gap-1">
        <Input
          data-cy="event-name"
          label="Event Name"
          name="eventName"
          onChange={evt => onChangeEventName(evt.target.value.slice(0, 75))}
          value={eventName.value}
          error={eventName.error}
          isDisabled={isMakingRequest}
          isRequired
          characterLimit={75}
        />
      </div>

      <div className="flex flex-col md:flex-row gap-1">
        <Select
          data-cy="event-type"
          className={classes.select}
          label="Event Type"
          name="eventType"
          onChange={evt =>
            onChangeEventType(evt.target.value as GatherlyEventType)
          }
          options={eventTypeOptionsArray}
          error={eventType.error}
          value={eventType.value}
          isDisabled={isMakingRequest}
          isRequired
        />
        <Input
          data-cy="ticket-count"
          label="Tickets (Capacity)"
          name="capacity"
          onChange={evt => onChangeCapacity(evt.target.value)}
          value={capacity.value}
          error={capacity.error}
          type="number"
          isDisabled={isMakingRequest}
          isRequired
        />
      </div>

      <DateRangePicker
        onChange={onTimeChange}
        startValue={startTime.value}
        stopValue={stopTime.value}
        durationError={durationError}
      />

      <p className="body">
        Choose a custom URL — this cannot be changed later!
      </p>
      <div className="flex items-center gap-_5">
        <Input
          data-cy="event-url"
          className="flex-2"
          label="Custom URL"
          name="eventUrl"
          onChange={evt => onChangeEventUrl(evt.target.value)}
          value={makeEventAlias(eventName.value, eventUrl.value)}
          error={eventUrlError}
          isDisabled={isMakingRequest}
          isRequired
          characterLimit={EVENT_ALIAS_MAX_LENGTH}
        />
        <span className="flex-1 nowrap body bold mr_5">
          {EVENT_ALIAS_SUFFIX}
        </span>
      </div>

      {(hasError || formError) && (
        <p className="detail text-center color-red">
          {formError ||
            `Sorry, we were unable to create your event. Please check all fields and try again.`}
        </p>
      )}

      <div className="flex flex-row flex-1 justify-center items-center gap-1 mt1 pb1">
        <Clickable
          data-cy="create-event-submit"
          isSubmit
          isDisabled={isMakingRequest}
          variant={ClickableVariant.BUTTON_SECONDARY}
          label={isMakingRequest ? 'Submitting...' : 'Submit'}
        />
        <Clickable
          data-cy="cancel"
          onClick={onCancel}
          variant={ClickableVariant.BUTTON_TERTIARY}
        >
          Cancel
        </Clickable>
      </div>

      <NewAIEventProgressIndicator
        isMakingRequest={isMakingRequest}
        isManualSetup={true} // Manual setup is always true
      />
    </Form>
  );
}

/*
    <Form
      className={c(
        classes.NewEventForm,
        'flex flex-col gap-1',
        !isManualSetup && isMakingRequest && classes.isMakingRequest,
      )}
      onSubmit={handleSubmit}
    >
      <p className="body">Let's create your new event:</p>
      <div className="flex flex-col md:flex-row gap-1">
        <Input
          data-cy="event-name"
          label="Event Name"
          name="eventName"
          onChange={evt => onChangeEventName(evt.target.value.slice(0, 75))}
          value={eventName.value}
          error={eventName.error}
          isDisabled={isMakingRequest}
          isRequired
          characterLimit={75}
        />
        {withAI && (
          <Input
            data-cy="ticket-count"
            label="Tickets (Capacity)"
            name="capacity"
            onChange={evt => onChangeCapacity(evt.target.value)}
            value={capacity.value}
            error={capacity.error}
            type="number"
            isDisabled={isMakingRequest}
            isRequired
          />
        )}
      </div>
      {isManualSetup && !withAI && (
        <div className="flex flex-col md:flex-row gap-1">
          <Select
            data-cy="event-type"
            className={classes.select}
            label="Event Type"
            name="eventType"
            onChange={evt =>
              onChangeEventType(evt.target.value as GatherlyEventType)
            }
            options={eventTypeOptionsArray}
            error={eventType.error}
            value={eventType.value}
            isDisabled={isMakingRequest}
            isRequired
          />
          <Input
            data-cy="ticket-count"
            label="Tickets (Capacity)"
            name="capacity"
            onChange={evt => onChangeCapacity(evt.target.value)}
            value={capacity.value}
            error={capacity.error}
            type="number"
            isDisabled={isMakingRequest}
            isRequired
          />
        </div>
      )}
      <DateRangePicker
        onChange={onTimeChange}
        startValue={startTime.value}
        stopValue={stopTime.value}
        durationError={durationError}
      />
      <p className="body">
        Choose a custom url — this cannot be changed later!
      </p>
      <div className="flex items-center gap-_5">
        <Input
          data-cy="event-url"
          className="flex-2"
          label="Custom Url"
          name="eventUrl"
          onChange={evt => onChangeEventUrl(evt.target.value)}
          value={makeEventAlias(eventName.value, eventUrl.value)}
          error={eventUrlError}
          isDisabled={isMakingRequest}
          isRequired
          characterLimit={EVENT_ALIAS_MAX_LENGTH}
        />
        <span className="flex-1 nowrap body bold mr_5">
          {EVENT_ALIAS_SUFFIX}
        </span>
      </div>
      {withAI && (
        <>
          <div
            className={classnames(
              'flex body',
              aiPromptDisabled && classes.disabled,
            )}
          >
            <Pill className="color-logo-blue bg-color-shade-10 mr_5">New</Pill>
            Describe your event and we’ll set it up for you with AI:
          </div>
          <div className="flex items-center gap-_5 mb_5">
            <Input
              className={classnames('flex-2', classes.aiPrompt)}
              placeholder="I want to host a football-themed team social with icebreakers..."
              name="aiPrompt"
              label="Prompt"
              onChange={evt =>
                onChangeAiPrompt(
                  evt.target.value.slice(0, MAX_AI_PROMPT_LENGTH),
                )
              }
              value={aiPrompt.value}
              InputProps={{
                inputComponent: TextareaAutosize,
                maxRows: 3,
                rows: 3,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              isMultiline
              isDisabled={aiPromptDisabled}
              isRequired
              characterLimit={MAX_AI_PROMPT_LENGTH}
            />
          </div>
        </>
      )}
      {withAI && (
        <div className="flex">
          <p className="body mr_5">
            Or, would you like to set your event up manually?
          </p>

          <Switch
            options={[
              {
                id: 'ai',
                label: 'Yes',
                value: true,
              },
              {
                id: 'no-ai',
                label: 'No',
                value: false,
              },
            ]}
            onChange={onChangeSetupType}
            value={isManualSetup}
          />
        </div>
      )}
      {withAI && isManualSetup && (
        <div className="flex">
          <Select
            data-cy="event-type"
            className={classnames(classes.select, 'flex-1')}
            label="Event Type"
            name="eventType"
            onChange={evt =>
              onChangeEventType(evt.target.value as GatherlyEventType)
            }
            options={eventTypeOptionsArray}
            error={eventType.error}
            value={eventType.value}
            isDisabled={isMakingRequest}
            isRequired
          />
        </div>
      )}
      {(hasError || formError) && (
        <p className="detail text-center color-red">
          {formError ||
            `Sorry, we were unable to create your event. Please check all fields and try again.`}
        </p>
      )}
      <div className="flex flex-row flex-1 justify-center items-center gap-1 mt1 pb1">
        <Clickable
          data-cy="create-event-submit"
          isSubmit
          isDisabled={isMakingRequest}
          variant={ClickableVariant.BUTTON_SECONDARY}
          label={isMakingRequest ? 'Submitting...' : 'Submit'}
        />
        <Clickable
          data-cy="cancel"
          onClick={onCancel}
          variant={ClickableVariant.BUTTON_TERTIARY}
        >
          Cancel
        </Clickable>
      </div>
      <NewAIEventProgressIndicator
        isMakingRequest={isMakingRequest}
        isManualSetup={isManualSetup}
      />
      {creatingAIEventFailed && (
        <ErrorCreatingAIEventDialog
          handleClose={() => setCreatingAIEventFailed(false)}
        />
      )}
    </Form>
  );
}
  */
