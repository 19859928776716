import {
  EventFloorsForm,
  EventInfoForm,
  EventLandingPageForm,
  EventLinksForm,
} from '../forms/EditEventForms';
import { Select, SelectOption } from '../Select';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useRoute } from 'wouter';

import { EventAgendaForm } from '../forms/EditEventForms/EventAgendaForm';
import { FieldWrapper } from '../FieldWrapper';
import { Menu } from '../Menu';
import { Status } from '../Status';
import { c } from '../../utils';
import classes from './EditEvent.module.scss';
import { eventById } from '../../selectors';
import { useAppSelector } from '../../hooks';
import { OverlayType, setOverlay } from '../../actions';
import { useDispatch } from 'react-redux';

export enum EditEventSection {
  DETAILS = 'details',
  FLOORS = 'floors',
  LINKS = 'links',
  LANDING = 'landing',
  AGENDA = 'agenda',
}
export const EditEventSections = {
  [EditEventSection.DETAILS]: {
    id: EditEventSection.DETAILS,
    title: 'Event Info',
  },
  [EditEventSection.LINKS]: {
    id: EditEventSection.LINKS,
    title: 'Access Links',
  },
  [EditEventSection.AGENDA]: {
    id: EditEventSection.AGENDA,
    title: 'Agenda',
  },
  [EditEventSection.FLOORS]: {
    id: EditEventSection.FLOORS,
    title: 'Floors',
  },
  [EditEventSection.LANDING]: {
    id: EditEventSection.LANDING,
    title: 'Landing Page',
  },
};
const sectionIds: EditEventSection[] = Object.values(EditEventSection);

type Props = {
  eventId: string;
  isDisabled: boolean;
};

export function EditEvent({ eventId, isDisabled }: Props) {
  const [, setLocation] = useLocation();
  const [, params] = useRoute('/events/:id/:tab*');
  const event = useAppSelector(eventById(eventId));
  const dispatch = useDispatch();
  const [hasChanges, setHasChanges] = useState(false);

  const sectionId = useMemo(() => {
    const [_tab, section] = (params?.tab || '').split('/');
    return sectionIds.includes(section as EditEventSection)
      ? (section as EditEventSection)
      : EditEventSection.DETAILS;
  }, [params?.tab]);

  useEffect(() => {
    setHasChanges(false);
  }, [sectionId]);

  const formComponent = useMemo(() => {
    if (!event) {
      return <Status message="Event not found" />;
    }
    switch (sectionId) {
      case EditEventSection.FLOORS:
        return (
          <EventFloorsForm
            setHasChanges={setHasChanges}
            isDisabled={isDisabled}
            event={event}
          />
        );
      case EditEventSection.LANDING:
        return (
          <EventLandingPageForm
            setHasChanges={setHasChanges}
            isDisabled={isDisabled}
            event={event}
          />
        );
      case EditEventSection.LINKS:
        return <EventLinksForm isDisabled={isDisabled} event={event} />;
      case EditEventSection.AGENDA:
        return (
          <EventAgendaForm
            setHasChanges={setHasChanges}
            isDisabled={isDisabled}
            event={event}
          />
        );
      case EditEventSection.DETAILS:
      default:
        return (
          <EventInfoForm
            setHasChanges={setHasChanges}
            isDisabled={isDisabled}
            event={event}
          />
        );
    }
  }, [sectionId, event, isDisabled]);

  const internalNavOptions = useMemo((): SelectOption<string>[] => {
    return Object.values(EditEventSections).map(({ id, title }) => ({
      id,
      label: title,
      value: id,
    }));
  }, []);
  const onSelect = useCallback(
    async evt => {
      if (hasChanges) {
        setOverlay(dispatch, OverlayType.CONFIRM_SWITCH_TAB, {
          location: `/events/${eventId}/edit/${evt.target.value}`,
        });
        return;
      }

      setLocation(`/events/${eventId}/edit/${evt.target.value}`);
    },
    [eventId, hasChanges, setLocation],
  );

  return (
    <div className={c(classes.EditEvent, 'flex flex-col md:flex-row')}>
      <div className="md:none p1">
        <FieldWrapper
          className="flex border-b1 border-color-shade-20 pb1_5"
          title="Now Editing..."
        >
          <Select
            className="flex-1"
            name="section"
            value={sectionId}
            label="Section"
            options={internalNavOptions}
            onChange={onSelect}
          />
        </FieldWrapper>
      </div>

      <div className={c(classes.internalNav, 'none md:flex flex-col mt6')}>
        <Menu
          baseUrl={`/events/${eventId}/edit/`}
          options={internalNavOptions}
          selectedId={sectionId}
          onSelect={id => onSelect({ target: { value: id } })}
        />
      </div>
      <div className="flex flex-1 flex-col p1 md:p2">{formComponent}</div>
    </div>
  );
}
