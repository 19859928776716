import React from 'react';

import { MIN_EVENT_CAPACITY_FOR_ANALYTICS } from '../../config';
import { MessageWithIllustration } from '../MessageWithIllustration';
import { INACTIVE_ANALYTICS as img } from '../../images';

export function TooSmallForAnalytics() {
  return (
    <MessageWithIllustration
      heading="Too Small for Analytics"
      imgAlt={img.alt}
      imgSrc={img.src}
      message={`Analytics are only available for events with a capacity of 
        ${MIN_EVENT_CAPACITY_FOR_ANALYTICS}+ attendees. Check back after
         hosting a larger event.`}
    />
  );
}
