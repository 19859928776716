// @ts-nocheck
import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  ReactNode,
  useRef,
} from 'react';
import { useTimeoutFn } from 'react-use';
import { generate as shortUuid } from 'short-uuid';
import {
  CloseRounded,
  ErrorRounded,
  CheckCircleRounded,
  InfoRounded,
} from '@material-ui/icons';

import { c, noop } from '../../utils';
import classes from './Toast.module.scss';

type ToastOptions = {
  timeout: number;
};

interface ContextType {
  addToast: (
    content: ReactNode,
    type: string,
    options?: ToastOptions,
  ) => string;
  removeToast: (toastId: string, cb?: () => void) => void;
}

export const ToastContext = React.createContext({} as ContextType);

type ToastProps = {
  id: string;
  type: 'info' | 'warn' | 'error' | 'success';
  children: ReactNode;
  timeout: number;
};

const ToastWrapper = ({ toasts }) => (
  <div
    className={c(classes.toastWrapper, 'absolute b0 r0 z1 p1 overflow-hidden')}
  >
    <div className="relative">
      {toasts.map((props: Toast) => (
        <Toast key={props.id} {...props} />
      ))}
    </div>
  </div>
);

const typeClasses = {
  error: 'red',
  success: 'logo-green',
  info: 'logo-blue',
};
const typeIcons = {
  error: ErrorRounded,
  success: CheckCircleRounded,
  info: InfoRounded,
};

const Toast = ({ children, id, type, timeout }: ToastProps) => {
  const { removeToast } = useToast();
  const toastRef = useRef<HTMLDivElement>(null);

  const clearToast = () => {
    toastRef.current?.classList.add('animation-toast-out');
    const timer = setTimeout(() => {
      removeToast(id);
      clearTimeout(timer);
    }, 300);
  };

  useTimeoutFn(() => !!timeout && clearToast(), timeout);
  useEffect(() => {
    toastRef.current?.classList.add('animation-toast-in');
  }, [id, removeToast]);

  const Icon = typeIcons[type];

  return (
    <div className="flex justify-end">
      <div
        className={c(
          classes.toast,
          (typeClasses[type] && `border-color-${typeClasses[type]}`) ||
            'border-color-shade-50',
          'relative p1 mt1 border-1 br_25',
          'inline-block bg-color-white',
        )}
        ref={toastRef}
      >
        <CloseRounded
          onClick={clearToast}
          className={c(
            classes.removeButton,
            'absolute r0 t0',
            'color-shade-70 hover:color-red',
          )}
        />
        <div className="flex flex-row items-center">
          {Icon && (
            <Icon
              className={c(
                (typeClasses[type] && `color-${typeClasses[type]}`) ||
                  'color-shade-50',
                'mr1',
              )}
            />
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

const typeTimeouts = { error: 0 };
const defaultTimeout = (type: string) =>
  typeTimeouts.hasOwnProperty(type) ? typeTimeouts[type] : 3300;

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState<Array<Toast>>([]);

  const addToast = useCallback(
    (
      children: ReactNode,
      type: string = 'info',
      { timeout = defaultTimeout(type), ...options } = {},
    ) => {
      const id = shortUuid();
      setToasts(toasts => [
        ...toasts,
        {
          id,
          children,
          type,
          timeout,
          ...options,
        },
      ]);
      return id;
    },
    [setToasts],
  );

  const removeToast = useCallback(
    (toastId, cb = noop) => {
      setToasts(toasts => toasts.filter(t => t.id !== toastId));
      cb();
    },
    [setToasts],
  );

  return (
    <ToastContext.Provider
      value={{
        addToast,
        removeToast,
      }}
    >
      <ToastWrapper toasts={toasts} />
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
