import EventStatsCard from './EventStatsCard';
import classes from './BoothAnalytics.module.scss';
import { humanizeShortHand } from '../../utils';
import { BoothAttendeesReport } from './BoothAttendeesReport';

type Props = {
  eventId: string;
  analytics: {
    avgTimeSpent: number;
    numConversations: number;
    totalTimeSpent: number;
    totalVisitors: number;
    users: {
      [id: string]: {
        timeSpent: number;
      };
    };
  };
};

export const BoothAnalytics = ({ analytics, eventId }: Props) => {
  const totalTimeSpent = humanizeShortHand(analytics.totalTimeSpent);
  const avgTimeSpent = humanizeShortHand(analytics.avgTimeSpent);

  return (
    <>
      <div className="heading bold">Aggregate Data</div>
      <div className="flex flex-wrap flex-row gap-1 pt2">
        <EventStatsCard
          title={'Number of Conversations'}
          className={classes.card}
          body={
            <div
              data-cy={'total-booth-conversations'}
              className="heading bold color-shade-80"
            >
              {analytics.numConversations}
            </div>
          }
        />
        <EventStatsCard
          title={'Total Booth Visitors'}
          className={classes.card}
          body={
            <div
              data-cy={'total-booth-visitors'}
              className="heading bold color-shade-80"
            >
              {analytics.totalVisitors}
            </div>
          }
        />
        <EventStatsCard
          title={'Total Hours'}
          className={classes.card}
          body={
            <div
              data-cy={'total-time-spent-at-booth'}
              className="heading bold color-shade-80"
            >
              {totalTimeSpent}
            </div>
          }
        />
        <EventStatsCard
          title={'Average Time Spent'}
          className={classes.card}
          body={
            <div
              data-cy={'avg-time-spent-at-booth'}
              className="heading bold color-shade-80"
            >
              {avgTimeSpent}
            </div>
          }
        />
      </div>
      <BoothAttendeesReport
        eventId={eventId}
        boothAttendees={Object.entries(analytics.users).map(
          ([id, { timeSpent }]) => ({
            id,
            timeSpent: humanizeShortHand(timeSpent),
          }),
        )}
      />
    </>
  );
};
