import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { NewFloorParams } from '@eventmanager/types';

import { DEFAULT_BROCHURE_URL, DEFAULT_MAP_URL } from '../../config';
import { useAppSelector } from '../../hooks';
import { generate as shortUuid } from 'short-uuid';
import { getStatusOfCreateFloor, getFloorById } from '../../selectors';
import {
  c,
  getFormField,
  getOnChangeFormField,
  validateString,
  hasValidationError,
  isPending,
  isFulfilled,
  isRejected,
} from '../../utils';
import { Clickable, ClickableVariant } from '../Clickable';
import { Image } from '../Image';
import { Input } from '../Input';
import { Form } from './Form';
import classes from './NewFloorForm.module.scss';

type Props = {
  onCancel: () => void;
  onSubmit: (data: NewFloorParams) => Promise<void | { error: string }>;
  eventId: string;
  sourceFloorId?: string;
};

export function NewFloorForm({
  onCancel,
  onSubmit,
  eventId,
  sourceFloorId,
}: Props) {
  const status = useAppSelector(getStatusOfCreateFloor);
  const isMakingRequest = isPending(status);
  const hasError = isRejected(status);
  const [formError, setFormError] = useState('');
  const sourceFloor = useAppSelector(getFloorById(eventId, sourceFloorId));

  useEffect(() => {
    if (isFulfilled(status)) onCancel();
  }, [status]);

  const [floorName, setFloorName] = useState(
    getFormField(sourceFloor?.name ? `Copy of ${sourceFloor?.name}` : ''),
  );

  const onChangeFloorName = useCallback(
    getOnChangeFormField(
      value => validateString(value, 'Floor name'),
      setFloorName,
    ),
    [],
  );

  const handleSubmit = useCallback(
    async (evt: SyntheticEvent) => {
      evt.preventDefault();
      const _floorName = onChangeFloorName(floorName.value);

      if (hasValidationError(_floorName)) {
        setFormError('Please check all form fields to continue');
      } else {
        setFormError('');

        const response = await onSubmit({
          floorName: _floorName.value.trim(),
          ...(sourceFloor
            ? {
                mapUrl: sourceFloor.mapUrl,
                brochureUrl: sourceFloor.brochureUrl,
                meetingAreas:
                  sourceFloor.meetingAreas?.map(area => ({
                    ...area,
                    id: shortUuid(),
                    displayName: `${area.displayName}${
                      area.type === 'Booth' ? ' Duplicate' : ''
                    }`,
                  })) ?? [],
                boothBackgroundUrl: sourceFloor.boothBackgroundUrl,
                gameBackgroundUrl: sourceFloor.gameBackgroundUrl,
              }
            : {}),
        });
        if (response && response.error) {
          setFormError(response.error);
        }
      }
    },
    [floorName.value, sourceFloor],
  );

  return (
    <Form
      className={c(classes.NewFloorForm, 'flex flex-col gap-1')}
      onSubmit={handleSubmit}
    >
      <Input
        data-cy="floor-name"
        label="Floor Name"
        name="floorName"
        onChange={evt => onChangeFloorName(evt.target.value)}
        value={floorName.value}
        error={floorName.error}
        isRequired
      />
      {sourceFloor && (
        <div className="flex flex-col gap-_5">
          <div className="flex gap-1">
            <div className="flex-1">
              <p className="detail color-shade-50">Map</p>
              <Image
                src={sourceFloor.mapUrl || DEFAULT_MAP_URL}
                alt={`Map for ${floorName.value || 'floor'}`}
              />
            </div>
            <div className="flex-1">
              <p className="detail color-shade-50">Brochure</p>
              <div
                className={c(
                  classes.brochurePreview,
                  'border-1 border-color-shade-20',
                )}
              >
                <iframe
                  title="Brochure preview"
                  src={`${
                    sourceFloor.brochureUrl || DEFAULT_BROCHURE_URL
                  }#toolbar=0&navpanes=0`}
                />
              </div>
            </div>
          </div>
          <p className="small italic color-shade-70">
            **You will be able to update map and brochure later.
          </p>
        </div>
      )}

      <div
        className={c(
          'flex flex-row flex-1 justify-center items-center gap-1',
          sourceFloor ? 'mt1' : 'mt2',
        )}
      >
        <Clickable
          data-cy="create-floor-submit"
          isSubmit
          isDisabled={isMakingRequest}
          variant={ClickableVariant.BUTTON_SECONDARY}
          label={isMakingRequest ? 'Saving...' : 'Create'}
        />
        <Clickable
          onClick={onCancel}
          variant={ClickableVariant.BUTTON_TERTIARY}
        >
          Cancel
        </Clickable>
      </div>
      {(hasError || formError) && (
        <p className="detail text-center color-red">
          {formError ||
            `Sorry, we were unable to create your floor. Please check all fields and try again.`}
        </p>
      )}
    </Form>
  );
}
