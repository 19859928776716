import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from '@material-ui/icons';

import { c } from '../../utils';
import { Clickable } from '../Clickable';
import classes from './PaginatedList.module.scss';

type Props<T extends Record<string, any>> = {
  columns: Array<{ key: keyof T; label: string }>;
  items: Array<T>;
  perPage: number;
};

export function PaginatedList<T>({ columns, items, perPage }: Props<T>) {
  const [page, setPage] = useState(1);
  const numberOfPages = useMemo(() => {
    return Math.ceil(items.length / perPage);
  }, [items.length, perPage]);
  const pageItems = useMemo(() => {
    const start = (page - 1) * perPage;
    const end = page * perPage;
    return items.slice(start, end);
  }, [items, page, perPage]);
  useEffect(() => {
    setPage(1);
  }, [items]);
  return (
    <div className={c(classes.PaginatedList, 'flex flex-col gap-2')}>
      <table className={c(classes.table, 'text-left detail')}>
        <thead>
          <tr className="color-white bg-color-logo-blue">
            {columns.map(({ key, label }) => (
              <th key={`paginated-th-${String(key)}`}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {pageItems.map((item, index) => (
            <tr
              key={`item-${index}`}
              className="transition-bg-color hover:bg-color-shade-10"
            >
              {columns.map(({ key }) => (
                <td key={`paginated-td-${String(key)}`}>
                  {
                    // @ts-ignore
                    item[key] as ReactNode
                  }
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-center gap-1 p1">
        <Clickable isDisabled={page <= 1} onClick={() => setPage(page - 1)}>
          <ArrowBackIosRounded />
        </Clickable>
        <div className="flex gap-_5">
          {page} <span>of</span> {numberOfPages}
        </div>
        <Clickable
          isDisabled={page >= numberOfPages}
          onClick={() => setPage(page + 1)}
        >
          <ArrowForwardIosRounded />
        </Clickable>
      </div>
    </div>
  );
}
