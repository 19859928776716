import React from 'react';

import { Image } from '../../components/Image';
import { Footer } from '../../components/Footer';
import { SignUpForm } from '../../components/forms';
import { Images } from '../../images';
import { c } from '../../utils';
import classes from './SignUpPage.module.scss';

export function SignUpPage() {
  return (
    <div className={c('page-inner', classes.SignUpPage)} data-cy="SignUpPage">
      <Image
        src={Images.MAP_POOL.src} // TODO: marketing content here?
        alt={Images.MAP_POOL.alt}
        className="flex flex-1 flex-col justify-center p1 bg-color-shade-10"
        isBackground
      >
        <div className="flex flex-col flex-1 items-center justify-center md:items-end">
          <div className="flex justify-center md:col-6">
            <div
              className={c(
                classes.formWrapper,
                'flex flex-col bg-color-white bo-1 border-color-shade-30 shadow-md pt1_25 pb1_5 px1_5',
              )}
            >
              <div className="flex justify-end">
                <Image
                  src={Images.GATHERLY_LOGO.src}
                  alt={Images.GATHERLY_LOGO.alt}
                  className={classes.logo}
                />
              </div>
              <h2 className="subtitle mt_5 mb1_5">Sign Up!</h2>
              <SignUpForm />
            </div>
          </div>
        </div>
        <Footer />
      </Image>
    </div>
  );
}
