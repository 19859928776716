import React, { useCallback, useState, SyntheticEvent } from 'react';
import { CheckCircleRounded } from '@material-ui/icons';

import { Image } from '../../components/Image';
import {
  VerificationForm,
  ResendVerificationForm,
} from '../../components/forms';
import { Clickable, ClickableVariant } from '../../components/Clickable';
import { Footer } from '../../components/Footer';
import { useAppSelector } from '../../hooks';
import { Images } from '../../images';
import { getStatusOfResendVerification } from '../../selectors';
import { c, isFulfilled } from '../../utils';
import classes from './VerificationPage.module.scss';

export function VerificationPage() {
  const [isResending, setIsResending] = useState(false);
  const toggleIsResending = useCallback(
    (evt?: SyntheticEvent) => {
      if (evt) evt.preventDefault();
      setIsResending(!isResending);
    },
    [isResending],
  );

  const statusOfResendVerification = useAppSelector(
    getStatusOfResendVerification,
  );

  return (
    <div className={c('page-inner', classes.VerificationPage)}>
      <Image
        src={Images.MAP_PARTY.src}
        alt={Images.MAP_PARTY.alt}
        className="flex flex-1 flex-col justify-center p1 bg-color-shade-10"
        isBackground
      >
        <div className="flex flex-col flex-1 items-center justify-center md:items-end">
          <div className="flex justify-center md:col-6">
            <div
              className={c(
                classes.formWrapper,
                'flex flex-col bg-color-white bo-1 border-color-shade-30 shadow-md pt1_25 pb1_5 px1_5',
              )}
            >
              <div className="flex justify-end">
                <Image
                  src={Images.GATHERLY_LOGO.src}
                  alt={Images.GATHERLY_LOGO.alt}
                  className={classes.logo}
                />
              </div>
              {isResending ? (
                <>
                  <h2 className="subtitle mt_5 mb1_5">Resend Code</h2>
                  {isFulfilled(statusOfResendVerification) ? (
                    <>
                      <p className="body mb1_5">
                        A new verification code was sent to the email provided.{' '}
                      </p>
                      <div className="flex flex-1 flex-col items-center mb1_5 gap-1">
                        <p className="subtitle flex items-center justify-center color-logo-green">
                          Sent! <CheckCircleRounded className="ml_5" />
                        </p>
                        <p className="small color-shade-70 italic">
                          It may take a few minutes for the email to arrive. If
                          you are having trouble finding it, please check your
                          email address and try again.
                        </p>
                      </div>
                      <Clickable
                        variant={ClickableVariant.LINK}
                        onClick={toggleIsResending}
                      >
                        Verify my account
                      </Clickable>
                    </>
                  ) : (
                    <>
                      <p className="body mb1_5">
                        A new verification code will be sent to the email{' '}
                        provided.
                        <Clickable
                          variant={ClickableVariant.LINK}
                          onClick={toggleIsResending}
                        >
                          Oops, I'm here by mistake! Back to Verify
                        </Clickable>
                      </p>
                      <ResendVerificationForm />
                    </>
                  )}
                </>
              ) : (
                <>
                  <h2 className="subtitle mt_5 mb1_5">Verify Email</h2>
                  <p className="body mb1_5">
                    A verification code was sent to the address linked with your
                    Gatherly account.{' '}
                    <Clickable
                      variant={ClickableVariant.LINK}
                      onClick={toggleIsResending}
                    >
                      Didn't get an email? Resend code
                    </Clickable>
                  </p>
                  <VerificationForm onClickResend={toggleIsResending} />
                </>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </Image>
    </div>
  );
}
