import React from 'react';

import { AclPermissionsRole } from '@eventmanager/types';

import { c } from '../../utils';
import classes from './AccountPlan.module.scss';
import { PlanDetails } from './PlanDetails';
import { PlanBadge } from '.';

interface Props {
  role?: AclPermissionsRole;
  detailFilter?: string[];
}

export function AccountPlan({ role, detailFilter }: Props) {
  if (!role) return null;

  return (
    <div className={c(classes.AccountPlan, 'py2 flex flex-col items-center')}>
      <PlanBadge planName={role} className="mb1" />
      <PlanDetails role={role} filter={detailFilter} />
    </div>
  );
}

export default AccountPlan;
