import { ILogo } from '@gatherly/types';
import { LogoDisplay } from '.';

export type Props = {
  logos: ILogo[];
  eventId: string;
  onUpdateWebsite: (logo: ILogo) => void;
  isDisabled: boolean;
};

export function SponsorLogos({
  logos,
  eventId,
  onUpdateWebsite,
  isDisabled,
}: Props) {
  return (
    <>
      {logos.map(logo => (
        <LogoDisplay
          label="Sponsor logo"
          onUpdateWebsite={onUpdateWebsite}
          logo={logo}
          eventId={eventId}
          isDisabled={isDisabled}
          key={logo.imageUrl}
        />
      ))}
    </>
  );
}
