import React, {
  useLayoutEffect,
  DetailedHTMLProps,
  FormHTMLAttributes,
  useRef,
} from 'react';

export function Form(
  props: DetailedHTMLProps<
    FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  >,
) {
  const formRef = useRef<HTMLFormElement>(null);
  useLayoutEffect(() => {
    if (formRef.current) {
      const firstInput = formRef.current.querySelector('input');
      if (firstInput) {
        firstInput.focus();
      }
    }
  }, []);

  return <form {...props} ref={formRef} />;
}
