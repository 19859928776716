import React, { ChangeEvent, ReactNode } from 'react';
import MuiFormControl from '@material-ui/core/FormControl';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiInput from '@material-ui/core/Input';
import MuiInputLabel from '@material-ui/core/InputLabel';
import MuiSelect from '@material-ui/core/Select';
import MuiChip from '@material-ui/core/Chip';

import { c } from '../../utils';

import classes from './Multiselect.module.scss';

export type MultiselectOption<T> = {
  id: string;
  label?: string;
  value: T;
};

type Props<T extends string | number> = {
  className?: string;
  error?: string;
  helpText?: string;
  isDisabled?: boolean;
  label?: string;
  optionLabels?: Record<T, string>;
  name: string;
  onChange?: (
    event: ChangeEvent<{ name?: string; value: unknown }>,
    child: ReactNode,
  ) => void;
  options: MultiselectOption<T>[];
  placeholder?: string;
  value: T[];
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function Multiselect<T extends string | number>({
  className,
  error,
  helpText,
  isDisabled,
  label,
  name,
  onChange,
  options,
  optionLabels,
  placeholder,
  value,
}: Props<T>) {
  return (
    <MuiFormControl
      className={c(classes.Select, error && classes.errored, className)}
    >
      {label && (
        <MuiInputLabel id={`${name}__label`}>
          {value.length ? label : placeholder ?? label}
        </MuiInputLabel>
      )}
      <MuiSelect
        labelId={`${name}__label`}
        id={`${name}__select`}
        disabled={isDisabled}
        multiple
        value={value}
        onChange={onChange}
        input={<MuiInput id={`${name}__input`} />}
        renderValue={(selected): ReactNode => (
          <div className={classes.chips}>
            {(selected as string[]).map(value => (
              <MuiChip
                key={value}
                label={optionLabels ? optionLabels[value] : value}
                className="mr_25"
              />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {options.map(option => (
          <MuiMenuItem key={option.id} value={option.value}>
            {option.label || option.value}
          </MuiMenuItem>
        ))}
      </MuiSelect>
      {helpText && (
        <p className="small italic mt_75 color-shade-70">{helpText}</p>
      )}
    </MuiFormControl>
  );
}
