import React from 'react';
import { useState, useEffect } from 'react';
import { Footer } from '../../components/Footer';
import { Image } from '../../components/Image';
import { SignInForm } from '../../components/forms';
import { MAP_BREAK_ROOM, MAP_PARTY, MAP_POOL, MAP_WELCOME } from '../../images';
import { c } from '../../utils';
import classes from './HomePage.module.scss';

export function HomePage() {
  // Array of images to cycle through
  const images = [MAP_WELCOME, MAP_POOL, MAP_BREAK_ROOM, MAP_PARTY];

  // State to track the current image index
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [nextImageIndex, setNextImageIndex] = useState(1); // Tracks the next image for smoother transition

  // Effect to cycle through images every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      // Switch to next image
      setCurrentImageIndex(_ => nextImageIndex);
      setNextImageIndex(_ => (nextImageIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [nextImageIndex, images.length]);

  return (
    <div className={c('page-inner', classes.HomePage)}>
      {/* Dynamically update the background image */}

      <Image
        src={images[currentImageIndex].src}
        alt={images[currentImageIndex].alt}
        className="flex flex-1 flex-col justify-center pb1"
        isBackground
      >
        <div className="flex flex-1 flex-col md:flex-row-reverse">
          <div className="flex flex-col flex-1 md:col-6 p1 md:p0">
            <div className="flex flex-col flex-1 items-center justify-center">
              <SignInForm />
            </div>
          </div>
          <div className="flex flex-col justify-center md:col-6">
            <div className="hidden md:visible flex-1">
              <div className="flex flex-col">
                <span
                  className={c(
                    classes.huddleSixteen,
                    'bg-color-logo-green--dark color-white',
                  )}
                >
                  16
                </span>
              </div>
              <div className="flex flex-col">
                <span
                  className={c(
                    classes.huddleTwo,
                    'bg-color-rose color-burgundy border-color-burgundy',
                  )}
                >
                  2
                </span>
              </div>
              <div className="flex flex-col">
                <span
                  className={c(
                    classes.huddleThree,
                    'bg-color-rose color-burgundy border-color-burgundy',
                  )}
                >
                  3
                </span>
              </div>
              <div className="flex flex-col">
                <span
                  className={c(
                    classes.huddleFive,
                    'bg-color-leaf-green color-logo-green--dark border-color-logo-green--dark',
                  )}
                >
                  5
                </span>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Image>
    </div>
  );
}
