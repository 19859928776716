import { classnames } from '@gatherly/lib';
import { useCallback, useMemo, useState } from 'react';
import { removeMap, updateMap } from '../../../actions';
import {
  MAP_TEMPLATE_URL,
  DEFAULT_MAP_URL,
  SALES_CONTACT_URL,
} from '../../../config';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getPermissionValue, getStatusOfUploadMap } from '../../../selectors';
import { FormField, isPending } from '../../../utils';
import { Clickable, ClickableVariant } from '../../Clickable';
import { DroppableUploader } from '../../DroppableUploader';
import { FieldWrapper } from '../../FieldWrapper';

import classes from './EditFloorForm.module.scss';
import { trackCustomMapUploaded } from '../../../libs/trackingLib';

interface Props {
  floorId: string;
  eventId: string;
  mapUrl: FormField<string>;
  onOpenMapPicker: () => Promise<void>;
  setMapUrl: (value: React.SetStateAction<FormField<string>>) => void;
}

const MapSetup = ({
  eventId,
  floorId,
  mapUrl,
  onOpenMapPicker,
  setMapUrl,
}: Props) => {
  const dispatch = useAppDispatch();
  const hasCustomMapsPermission = useAppSelector(
    getPermissionValue('customMaps'),
  );
  const mapUploadStatus = useAppSelector(getStatusOfUploadMap(floorId));
  const [isRemovingMap, setIsRemovingMap] = useState(false);
  const [removeMapError, setRemoveMapError] = useState('');
  const isDisabled = isPending(mapUploadStatus) || isRemovingMap;
  const mapIsEmpty = useMemo(() => {
    return !mapUrl.value || mapUrl.value === DEFAULT_MAP_URL;
  }, [mapUrl.value]);
  const onChangeMapUrl = useCallback(
    async acceptedFiles => {
      const file = acceptedFiles[0];
      const response = await updateMap(dispatch, eventId, floorId, {
        isBooth: false,
        file,
      });

      if (typeof response !== 'boolean') {
        setMapUrl(current => ({
          value: current.value,
          error: response.error,
        }));
      } else {
        trackCustomMapUploaded({
          floorType: 'normal',
          fileFormat: file?.type,
        });
      }
    },
    [floorId, eventId],
  );

  const onRemoveMap = useCallback(async () => {
    try {
      setIsRemovingMap(true);
      const response = await removeMap(
        dispatch,
        eventId,
        floorId,
        mapUrl.value,
      );

      setRemoveMapError(typeof response === 'boolean' ? '' : response.error);
    } catch (error) {
      console.log(error);
    } finally {
      setIsRemovingMap(false);
    }
  }, [dispatch, eventId, floorId, mapUrl.value]);

  return (
    <div data-cy="map-setup" className="flex flex-row">
      <div>
        <FieldWrapper title="Map">
          <div className="flex flex-col md:flex-row gap-2">
            <div>
              {hasCustomMapsPermission ? (
                <div className="flex flex-col flex-1 gap-1">
                  <div className="flex flex-col gap-_5">
                    <p>
                      Choose a map from the Map Gallery, or upload your own!
                    </p>
                    <p>
                      To create a custom map, copy the slides template by
                      clicking{' '}
                      <Clickable
                        to={MAP_TEMPLATE_URL}
                        variant={ClickableVariant.LINK}
                      >
                        here
                      </Clickable>
                      . It includes a video tutorial to help you out.
                    </p>
                    <p>
                      Attendees will be able to walk around and freely network
                      with one another on any of the maps that you choose.
                    </p>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col gap-_5">
                  <p>Choose a map from the Map Gallery.</p>
                  <p>
                    To unlock the custom maps permission,{' '}
                    <Clickable
                      variant={ClickableVariant.LINK}
                      to={SALES_CONTACT_URL}
                    >
                      contact sales
                    </Clickable>
                    .
                  </p>
                </div>
              )}
              <div className="flex flex-col gap-1 mt_5">
                <div className="flex flex-col md:flex-row gap-_5">
                  <Clickable
                    className="border-1 border-color-shade-30"
                    isDisabled={isDisabled}
                    onClick={onOpenMapPicker}
                    variant={ClickableVariant.BUTTON_SMALL}
                  >
                    Open Map Gallery
                  </Clickable>
                  {!mapIsEmpty && (
                    <Clickable
                      className="bg-color-blush"
                      isDisabled={isDisabled}
                      onClick={onRemoveMap}
                      variant={ClickableVariant.BUTTON_SMALL}
                    >
                      Remove Map
                    </Clickable>
                  )}
                </div>
                {removeMapError && (
                  <div className="color-red small pt_5">{removeMapError}</div>
                )}
              </div>
            </div>
            <div>
              <DroppableUploader
                label="Map"
                emptyTitle={'Upload new floor map'}
                onDrop={onChangeMapUrl}
                value={mapUrl.value || DEFAULT_MAP_URL}
                error={mapUrl.error}
                isLoading={isDisabled}
                isDisabled={!hasCustomMapsPermission}
                emptyClassName={classnames(
                  classes.mapEmpty,
                  'absolute-center z1',
                )}
                previewClassName={classnames(classes.mapPreview)}
                previewImageClassName="absolute-center"
                className={classes.mapUploader}
                wrapperClassName={classes.mapUploadWrapper}
                renderPreview
              />
            </div>
          </div>
        </FieldWrapper>
      </div>
    </div>
  );
};

export default MapSetup;
