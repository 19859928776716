import React from 'react';

import { parseTimeLeft } from './CountdownClock';

type Props = {
  millisLeft: number;
};

export function TimeLeft({ millisLeft }: Props) {
  const timeLeft = parseTimeLeft(millisLeft);
  return (
    <div className="flex flex-wrap items-center gap-_5">
      <p>starts in</p>
      {timeLeft.map(({ unit, value }) => (
        <span className="flex items-center gap-_25" key={unit}>
          <span>{value}</span>
          {unit}
        </span>
      ))}
      <span>{'✨'}</span>
    </div>
  );
}
