import React from 'react';

import { SERVER_LAUNCH_WINDOW } from '../../config';
import { humanize } from '../../utils';

type Props = {
  millisLeft: number;
};

export type TimeMeasurement = {
  unit: string;
  value: string;
};

export function parseTimeLeft(millis: number): TimeMeasurement[] {
  const humanized = humanize(millis);
  const unitValuePairs = humanized.split(',');
  return unitValuePairs.map(value => {
    const tuple = value.trim().split(/\s/g);
    return {
      unit: `${tuple[1]}`,
      value: `${tuple[0]}`,
    };
  });
}

export function CountdownClock({ millisLeft }: Props) {
  const timeLeft = parseTimeLeft(millisLeft);
  return (
    <div className="flex flex-wrap items-center gap-_5">
      <p>starts in</p>
      <div className="flex items-center gap-_25">
        {timeLeft.map(({ unit, value }, index) => (
          <span className="flex items-center gap-_25" key={unit}>
            {index !== 0 && ':'}
            <span className="color-shade-80 bg-color-shade-10 br_25 p_25">
              {value.padStart(2, '0')}
            </span>
          </span>
        ))}
        {timeLeft.length < 3 && (
          <span className="flex items-center gap-_25">
            <span>:</span>
            <span className="color-shade-80 bg-color-shade-10 br_25 p_25">
              00
            </span>
          </span>
        )}
      </div>
      <span>{millisLeft <= SERVER_LAUNCH_WINDOW ? '🔐' : '⏰'}</span>
    </div>
  );
}
