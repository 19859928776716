// @ts-nocheck

import 'react-quill/dist/quill.snow.css';

import { Clickable, ClickableVariant } from '../../Clickable';
import { GatherlyEvent, Logo } from '@eventmanager/types';
import {
  OverlayType,
  removeBanner,
  setOverlay,
  updateEventConfig,
  updateLogos,
  uploadBanner,
} from '../../../actions';
import ReactQuill, { Quill } from 'react-quill';
import { Switch, classnames } from '@gatherly/lib';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  c,
  difference,
  getBannerUrl,
  getFormField,
  getOnChangeFormField,
  hasValidationError,
  isPending,
  isValidUrl,
  validateEmailAddress,
  validateString,
} from '../../../utils';
import {
  getStatusOfUpdateEvent,
  getStatusOfUploadBanner,
  getStatusOfUploadLogo,
} from '../../../selectors';
import { useAppDispatch, useAppSelector } from '../../../hooks';

import { BANNERS } from '../../../enums';
import { DroppableUploader } from '../../DroppableUploader';
import { EventFormFooter } from './EventFormFooter';
import { EventFormHeader } from './EventFormHeader';
import { FieldWrapper } from '../../FieldWrapper';
import { Form } from '../Form';
import { Input } from '../../Input';
import { LANDING_PAGE_INSTRUCTIONS_URL } from '../../../config';
import MagicUrl from 'quill-magic-url';
import { SponsorLogos } from '../../Logo';
import classes from './EventLandingPageForm.module.scss';

Quill.register('modules/magicUrl', MagicUrl);
const MAX_LOGOS = 10;

type Props = {
  event: GatherlyEvent;
  isDisabled: boolean;
  setHasChanges: (hasChanges: boolean) => void;
};

const FALLBACK_BANNER_URL = getBannerUrl(BANNERS[0].imgName); // TODO: this depends on eventType

type LandingPageFields = {
  description: string;
  companyName: string;
  email: string;
  website: string;
  facebook: string;
  twitter: string;
  linkedIn: string;
  registrationLink: string;
  socialDisabled: boolean;
  logoUrls: Logo[];
  agendaDisabled: boolean;
};
function getChanges(
  event: GatherlyEvent,
  {
    description,
    socialDisabled,
    companyName,
    email,
    website,
    registrationLink,
    facebook,
    twitter,
    linkedIn,
    logoUrls,
    agendaDisabled,
  }: LandingPageFields,
) {
  const fieldsChanged = difference(event.config.landingPage, {
    socialDisabled,
    companyName,
    email,
    website,
    facebook,
    twitter,
    registrationLink,
    linkedIn,
    logoUrls,
    agendaDisabled,
  });

  const eventDescriptionChanged =
    event.config.landingPage.description !== description;

  if (eventDescriptionChanged) {
    fieldsChanged.description = description;
  }

  const fieldsDidUpdate = Object.keys(fieldsChanged).length > 0;
  return {
    fieldsChanged,
    fieldsDidUpdate,
  };
}

export function EventLandingPageForm({
  event,
  isDisabled,
  setHasChanges,
}: Props) {
  const { eventId } = event;
  const dispatch = useAppDispatch();
  const quillEditorRef = useRef();
  const eventDescriptionRef = useRef();
  const companyNameRef = useRef();

  const status = useAppSelector(getStatusOfUpdateEvent(eventId));
  const uploadBannerStatus = useAppSelector(getStatusOfUploadBanner);
  const uploadLogoStatus = useAppSelector(getStatusOfUploadLogo);
  const isMakingRequest =
    isPending(status) ||
    isPending(uploadBannerStatus) ||
    isPending(uploadLogoStatus);

  // FormFields
  const [description, setDescription] = useState(
    event.config.landingPage.description,
  );
  const [socialDisabled, setSocialDisabled] = useState(
    !!event.config.landingPage.socialDisabled,
  );
  const [agendaDisabled, setAgendaDisabled] = useState(
    !!event.config.landingPage.agendaDisabled,
  );
  const [registrationLink, setRegistrationLink] = useState(
    getFormField(event.config.landingPage.registrationLink),
  );
  const [companyName, setCompanyName] = useState(
    getFormField(event.config.landingPage.companyName || ''),
  );
  const [email, setEmail] = useState(
    getFormField(event.config.landingPage.email),
  );
  const [website, setWebsite] = useState(
    getFormField(event.config.landingPage.website),
  );
  const [facebook, setFacebook] = useState(
    getFormField(event.config.landingPage.facebook),
  );
  const [twitter, setTwitter] = useState(
    getFormField(event.config.landingPage.twitter),
  );
  const [linkedIn, setLinkedIn] = useState(
    getFormField(event.config.landingPage.linkedIn),
  );

  const [logos, setLogos] = useState(event.config.landingPage.logoUrls);

  useEffect(() => {
    if (event.config.landingPage.logoUrls.length < logos.length) {
      onLogosDeleted(
        event.config.landingPage.logoUrls.map(logo => logo.imageUrl),
      );
    }
  }, [event, logos]);

  useEffect(() => {
    if (window.location.hash === '#event-description') {
      companyNameRef.current?.blur();
      quillEditorRef.current?.focus();
      eventDescriptionRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [eventDescriptionRef.current, companyNameRef.current]);

  // onChange Handlers

  const onLogoWebsiteChanged = useCallback(
    (logo: Logo) => {
      const logosCopy = logos.map(l => ({ ...l }));

      const editedLogo = logosCopy.find(
        editedLogo => editedLogo.imageUrl === logo.imageUrl,
      );

      if (!editedLogo) {
        return;
      }

      editedLogo.imageLink = logo.imageLink;

      setLogos([...logosCopy]);
    },
    [logos],
  );

  const onLogosDeleted = useCallback(
    (logoUrls: string[]) => {
      const remainingLogos = logos.filter(currentLogos =>
        logoUrls.some(logoUrl => currentLogos.imageUrl === logoUrl),
      );
      setLogos([...remainingLogos]);
    },
    [logos],
  );

  const onDescriptionChanged = useCallback((value, _, source) => {
    if (source === 'api') return;

    const fieldIsEmpty = value === '<p><br></p>' || value === '<p></p>';

    setDescription(fieldIsEmpty ? '' : value);
  }, []);

  const onChangeCompanyName = useCallback(
    getOnChangeFormField(
      value => validateString(value, 'Company name', false, 50),
      setCompanyName,
    ),
    [],
  );

  const onChangeRegistrationLink = useCallback(
    getOnChangeFormField(value => isValidUrl(value), setRegistrationLink),
  );
  const onChangeEmail = useCallback(
    getOnChangeFormField(value => validateEmailAddress(value, false), setEmail),
    [],
  );
  const onChangeWebsite = useCallback(
    getOnChangeFormField(value => isValidUrl(value), setWebsite),
    [],
  );
  const onChangeFacebook = useCallback(
    getOnChangeFormField(value => isValidUrl(value), setFacebook),
    [],
  );
  const onChangeTwitter = useCallback(
    getOnChangeFormField(value => isValidUrl(value), setTwitter),
    [],
  );
  const onChangeLinkedIn = useCallback(
    getOnChangeFormField(value => isValidUrl(value), setLinkedIn),
    [],
  );
  const onChangeBanner = useCallback(
    async acceptedFiles => {
      const file = acceptedFiles[0];
      const response = await uploadBanner(dispatch, event, file);
      if (response.hasOwnProperty('error')) {
        // @ts-ignore
        alert(`Failed to upload banner - ${response.error}!`);
      }
    },
    [event],
  );

  const onChangeLogos = useCallback(
    async acceptedFiles => {
      const file = acceptedFiles[0];
      const response = await updateLogos(dispatch, event, {
        file: file,
        websiteLink: '',
      });
      if (response.error) {
        // @ts-ignore
        alert(`Failed to upload logo - ${response.error}!`);
        return;
      }

      setLogos([
        ...logos,
        {
          imageUrl: response.logoUrl!,
          imageLink: '',
        },
      ]);
    },
    [event, setLogos, logos],
  );

  const onRemoveBanner = useCallback(async () => {
    const response = await removeBanner(dispatch, event);
    if (response?.hasOwnProperty('error')) {
      // @ts-ignore
      alert(`Failed to delete banner - ${response.error}!`);
    }
  }, [event]);

  const hasChanges = useMemo(() => {
    const { fieldsDidUpdate } = getChanges(event, {
      description,
      socialDisabled,
      facebook: facebook.value,
      twitter: twitter.value,
      companyName: companyName.value,
      email: email.value,
      linkedIn: linkedIn.value,
      website: website.value,
      registrationLink: registrationLink.value,
      logoUrls: logos,
      agendaDisabled,
    });
    setHasChanges(fieldsDidUpdate);
    return fieldsDidUpdate;
  }, [
    event,
    description,
    email,
    twitter,
    facebook,
    companyName,
    website,
    linkedIn,
    socialDisabled,
    registrationLink,
    logos,
    agendaDisabled,
    setHasChanges,
  ]);

  // Click Handlers
  const onOpenBannerPicker = useCallback(
    (evt?: SyntheticEvent) => {
      if (evt) evt.preventDefault();
      setOverlay(dispatch, OverlayType.BANNER_PICKER, {
        eventId: event.eventId,
      });
    },
    [event.eventId],
  );

  const onSubmit = useCallback(
    async (evt?: SyntheticEvent) => {
      if (evt) evt.preventDefault();
      const _email = onChangeEmail(email.value);
      const _facebook = onChangeFacebook(facebook.value);
      const _twitter = onChangeTwitter(twitter.value);
      const _linkedIn = onChangeLinkedIn(linkedIn.value);
      const _companyName = onChangeCompanyName(companyName.value);
      const _website = onChangeWebsite(website.value);
      const _registrationLink = onChangeRegistrationLink(
        registrationLink.value,
      );

      const isInvalid =
        hasValidationError(
          _email,
          _website,
          _facebook,
          _twitter,
          _linkedIn,
          _companyName,
          _registrationLink,
        ) || logos.some(logo => isValidUrl(logo.imageLink).error);

      const { fieldsChanged } = getChanges(event, {
        description,
        email: _email.value,
        companyName: _companyName.value,
        facebook: _facebook.value,
        linkedIn: _linkedIn.value,
        twitter: _twitter.value,
        website: _website.value,
        registrationLink: _registrationLink.value,
        socialDisabled,
        logoUrls: logos,
        agendaDisabled,
      });
      const didUpdate = Object.keys(fieldsChanged).length > 0;

      if (!didUpdate) return;
      if (isInvalid) {
        alert('Please fix all errors to save this event');
        return;
      }
      await updateEventConfig(dispatch, event, {
        landingPage: {
          ...event.config.landingPage,
          ...fieldsChanged,
        },
      });
    },
    [
      description,
      email,
      companyName,
      website,
      facebook,
      twitter,
      linkedIn,
      socialDisabled,
      registrationLink,
      logos,
      agendaDisabled,
    ],
  );

  return (
    <Form
      className={c(classes.EventLandingPageForm, 'flex flex-col')}
      onSubmit={onSubmit}
    >
      <div className="md:pl2">
        <EventFormHeader
          eventId={event.eventId}
          isDisabled={isDisabled}
          title="Landing Page"
        >
          <p className="color-shade-80 body">
            {`Before your event starts, a landing page will be shown. Customize it with
            logos and helpful information to prepare visitors for your event.`}
            <br />
            <Clickable
              to={LANDING_PAGE_INSTRUCTIONS_URL}
              variant={ClickableVariant.LINK}
            >
              Watch the tutorial
            </Clickable>
          </p>
        </EventFormHeader>
      </div>

      <div className={c(classes.mainColumn, 'flex flex-col gap-2 md:px2')}>
        <FieldWrapper
          title="Contact Information"
          helpText={`Provide details on how attendees can contact you directly for any event related inquiries.`}
        >
          <Input
            data-cy="company-name"
            label="Company Name"
            name="companyName"
            onChange={evt => onChangeCompanyName(evt.target.value.slice(0, 50))}
            value={companyName.value}
            error={companyName.error}
            InputProps={{
              ref: companyNameRef,
            }}
            isDisabled={isDisabled}
            isMultiline={false}
            characterLimit={50}
            className={classes.landingPageInput}
          />

          <Input
            label="Email"
            name="email"
            data-cy="company-email"
            onChange={evt => onChangeEmail(evt.target.value)}
            value={email.value}
            error={email.error}
            isDisabled={isDisabled}
            isMultiline={false}
            className={classes.landingPageInput}
          />

          <Input
            label="Website"
            name="website"
            data-cy="company-website"
            onChange={evt => onChangeWebsite(evt.target.value)}
            value={website.value}
            error={website.error}
            isDisabled={isDisabled}
            isMultiline={false}
            className={classes.landingPageInput}
          />

          <Input
            label="Facebook"
            name="facebook"
            data-cy="company-facebook"
            onChange={evt => onChangeFacebook(evt.target.value)}
            value={facebook.value}
            error={facebook.error}
            isDisabled={isDisabled}
            isMultiline={false}
            className={classes.landingPageInput}
          />

          <Input
            label="Twitter"
            name="twitter"
            data-cy="company-twitter"
            onChange={evt => onChangeTwitter(evt.target.value)}
            value={twitter.value}
            error={twitter.error}
            isDisabled={isDisabled}
            isMultiline={false}
            className={classes.landingPageInput}
          />

          <Input
            label="LinkedIn"
            name="linkedIn"
            data-cy="company-linkedin"
            onChange={evt => onChangeLinkedIn(evt.target.value)}
            value={linkedIn.value}
            error={linkedIn.error}
            isDisabled={isDisabled}
            isMultiline={false}
          />
        </FieldWrapper>
        <FieldWrapper
          title="Registration"
          helpText={`Provide a link where attendees can register for this event.`}
        >
          <Input
            label="Registration Link"
            name="registration"
            data-cy="registration-link"
            onChange={evt => onChangeRegistrationLink(evt.target.value)}
            value={registrationLink.value}
            error={registrationLink.error}
            isDisabled={isDisabled}
            isMultiline={false}
            className={classes.landingPageInput}
          />
        </FieldWrapper>
        <FieldWrapper
          title="Event Banner"
          helpText={`A 2000px by 500px image that will display at the top of your landing page.`}
        >
          <DroppableUploader
            label="Banner"
            emptyClassName={c(classes.bannerPreview, 'absolute z1')}
            previewClassName={c(classes.bannerPreview, 'bg-color-silver')}
            isDisabled={isDisabled}
            isLoading={isPending(uploadBannerStatus)}
            onDrop={onChangeBanner}
            emptyTitle="Upload a new banner image"
            value={event.config.landingPage.bannerUrl || FALLBACK_BANNER_URL}
            renderPreview
          />
          <div className="flex gap-_5">
            <Clickable
              className="border-1 border-color-shade-30"
              isDisabled={isDisabled}
              onClick={onOpenBannerPicker}
              variant={ClickableVariant.BUTTON_SMALL}
            >
              Open Banner Gallery
            </Clickable>
            {event.config.landingPage.bannerUrl && (
              <Clickable
                className="bg-color-blush"
                isDisabled={isDisabled}
                onClick={onRemoveBanner}
                variant={ClickableVariant.BUTTON_SMALL}
              >
                Remove Banner
              </Clickable>
            )}
          </div>
        </FieldWrapper>

        <FieldWrapper title="Event Description">
          <div ref={eventDescriptionRef}></div>
          <ReactQuill
            data-cy="event-description"
            ref={quillEditorRef}
            className={classnames(
              classes.eventDescription,
              isDisabled && classes.disabled,
            )}
            value={description}
            onChange={onDescriptionChanged}
            readOnly={isDisabled}
            modules={{
              magicUrl: true,
            }}
          />
        </FieldWrapper>

        <FieldWrapper title="Social Media">
          <div className={classes.socialMediaSharing}>
            <p>Display social media sharing links on the landing page.</p>
            <Switch
              onChange={setSocialDisabled}
              value={socialDisabled}
              options={[
                {
                  id: 'social-enabled',
                  label: 'Yes',
                  value: false,
                  disabled: isDisabled,
                },
                {
                  id: 'social-disabled',
                  label: 'No',
                  value: true,
                  disabled: isDisabled,
                },
              ]}
            />
          </div>
        </FieldWrapper>

        <FieldWrapper title="Agenda">
          <div className={classes.agenda}>
            <p>Display agenda on the landing page.</p>
            <Switch
              onChange={setAgendaDisabled}
              value={agendaDisabled}
              options={[
                {
                  id: 'agenda-enabled',
                  label: 'Yes',
                  value: false,
                  disabled: isDisabled,
                },
                {
                  id: 'agenda-disabled',
                  label: 'No',
                  value: true,
                  disabled: isDisabled,
                },
              ]}
            />
          </div>
        </FieldWrapper>

        <FieldWrapper
          title="Sponsor Logos"
          helpText={
            <span className={classes.sponsorLogos}>
              <span className="bold">Recommended size: </span>
              Logos should be no larger than 160px wide, and 32px high. You can
              upload up to <span className="bold">10 sponsor logos.</span>
            </span>
          }
        >
          {event.config.landingPage.logoUrls.length < MAX_LOGOS && (
            <DroppableUploader
              label="Logo Urls"
              wrapperClassName={classes.logoUploadContainer}
              emptyClassName={c(classes.logoEmpty)}
              previewClassName={c(
                classes.logoUploadContainer,
                'flex flex-col justify-center',
              )}
              isDisabled={
                isPending(uploadLogoStatus) ||
                event.config.landingPage.logoUrls.length === MAX_LOGOS ||
                isDisabled
              }
              isLoading={isPending(uploadLogoStatus)}
              onDrop={onChangeLogos}
              value={''}
              maxFiles={MAX_LOGOS}
              renderPreview
            />
          )}

          <SponsorLogos
            data-cy="sponsor-logos"
            logos={event.config.landingPage.logoUrls}
            eventId={event.eventId}
            onUpdateWebsite={onLogoWebsiteChanged}
            isDisabled={isDisabled}
          />
        </FieldWrapper>
        <EventFormFooter
          eventId={eventId}
          isDisabled={isDisabled}
          hasChanges={hasChanges}
          isMakingRequest={isMakingRequest}
          onSubmit={onSubmit}
          previewType="landing-page"
        />
      </div>
    </Form>
  );
}
