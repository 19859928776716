import React from 'react';

import { AclPermissionsRole } from '@eventmanager/types';
import { Image } from '../Image';
import { c, getPlanImage, toTitleCase } from '../../utils';
import classes from './PlanBadge.module.scss';

const planColorMap = {
  free: 'bg-color-shade-30',
  tickets: 'bg-color-shade-30',
  starter: 'bg-color-logo-blue--lighter',
  pro: 'bg-color-violet--lighter',
  enterprise: 'bg-color-logo-green--lighter',
  custom: 'bg-color-logo-green--lighter',
};

interface Props {
  className?: string;
  planName: AclPermissionsRole | 'custom';
}

export function PlanBadge({ className, planName }: Props) {
  const image = getPlanImage(planName);

  return (
    <div
      className={c(
        classes.PlanBadge,
        className,
        'flex flex-col items-center gap-1 text-center',
      )}
    >
      <Image src={image.src} alt={image.alt} />
      <h4
        className={c(
          'px1 py_25 flex-1 br1 body bold nowrap',
          planColorMap[planName],
        )}
      >
        {toTitleCase(planName)} Plan
      </h4>
    </div>
  );
}

export default PlanBadge;
