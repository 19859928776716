import { IAgendaSession, LinkConfig } from '@gatherly/types';
import {
  IS_SEGMENT_ENABLED,
  IS_SMARTLOOK_ENABLED,
  SEGMENT_PROJECT_ID,
  SMARTLOOK_PROJECT_ID,
} from '../config';

import { AccountsUser } from '@eventmanager/types';
import { FloorType } from '../utils';
import { PaymentIntent } from '@stripe/stripe-js';
import segmentClient from './segmentClient';
import smartlookClient from 'smartlook-client';

declare global {
  interface Window {
    analytics: any;
  }
}

let identifier = '';

interface AccountAnalytics {
  method: 'email' | 'google';
}

export function initTracker() {
  console.debug(
    `[trackingLib:init] segment: ${IS_SEGMENT_ENABLED}, smartlook: ${IS_SMARTLOOK_ENABLED}`,
  );
  // Segment is required for Intercom and GA
  if (IS_SEGMENT_ENABLED) {
    if (!SEGMENT_PROJECT_ID) console.warn('SEGMENT_PROJECT_ID missing');
    segmentClient.init(SEGMENT_PROJECT_ID);
  }

  // Only initalize Smartlook for prod environments for $$ and data purposes
  if (IS_SMARTLOOK_ENABLED) {
    if (!SMARTLOOK_PROJECT_ID) console.warn('SMARTLOOK_PROJECT_ID missing');
    smartlookClient.init(SMARTLOOK_PROJECT_ID);
  }
}

export function identifyUser({
  userId,
  firstName,
  lastName,
  company,
  email,
}: AccountsUser) {
  const userDetails = { firstName, lastName, company, email };
  console.debug(
    `[trackingLib:identifyUser] segment: ${IS_SEGMENT_ENABLED}, smartlook: ${IS_SMARTLOOK_ENABLED}`,
  );
  identifier = userId;
  if (IS_SMARTLOOK_ENABLED) smartlookClient.identify(userId, userDetails);
  if (IS_SEGMENT_ENABLED) window.analytics.identify(userId, userDetails);
}

export function trackContactSales() {
  console.debug(
    `[trackContactSales] segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED && window)
    window.analytics.track('Upgrade', {
      category: 'Contact',
      label: 'Open update subscription form',
      ...(identifier && { userId: identifier }),
    });
}

export function trackEventCreated(eventId: string, withAI: boolean) {
  console.debug(
    `[trackEventCreated segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED && window)
    window.analytics.track('Event Created', {
      category: 'Event',
      eventId,
      withAI: withAI ? 'true' : 'false',
      label: 'An event was created',
      ...(identifier && { userId: identifier }),
    });
}

export function trackEventsDeleted(data: { numEvents: number }) {
  console.debug(
    `[trackEventsDeleted] segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED)
    window.analytics.track('Events Deleted', {
      ...data,
      category: 'Event',
      label: 'Events deleted',
      ...(identifier && { userId: identifier }),
    });
}

export function trackEventDuplicated() {
  console.debug(
    `[trackEventDuplicated] segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED)
    window.analytics.track('Event Duplicated', {
      category: 'Event',
      label: 'Event duplicated',
      ...(identifier && { userId: identifier }),
    });
}

export function trackGalleryMapSelected(data: {
  floorType: FloorType;
  title: string;
}) {
  console.debug(
    `[trackGalleryMapSelected] segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED)
    window.analytics.track('Gallery Map Selected', {
      ...data,
      category: 'Event',
      label: 'Gallery map selected',
      ...(identifier && { userId: identifier }),
    });
}

export function trackCustomMapUploaded(data: {
  floorType: FloorType;
  fileFormat: string;
}) {
  console.debug(
    `[trackCustomMapUploaded] segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED) {
    let fileFormat = 'unknown';
    if (data.fileFormat.includes('png')) fileFormat = 'png';
    else if (data.fileFormat.includes('svg')) fileFormat = 'svg';
    else if (data.fileFormat.includes('jpeg')) fileFormat = 'jpeg';

    window.analytics.track('Custom Map Uploaded', {
      ...data,
      fileFormat,
      category: 'Event',
      label: 'Custom map uploaded',
      ...(identifier && { userId: identifier }),
    });
  }
}

export function trackBrochureUploaded() {
  console.debug(
    `[trackBrochureUploaded] segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED)
    window.analytics.track('Brochure Uploaded', {
      category: 'Event',
      label: 'Brochure uploaded',
      ...(identifier && { userId: identifier }),
    });
}

export function trackBrochureAppliedToAllFloors() {
  console.debug(
    `[trackBrochureAppliedToAllFloors] segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED)
    window.analytics.track('Brochure Applied To All Floors', {
      category: 'Event',
      label: 'Brochure applied to all floors',
      ...(identifier && { userId: identifier }),
    });
}

export function trackFloorCreated(numFloorsAfter: number) {
  console.debug(
    `[trackFloorCreated] segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED)
    window.analytics.track('Floor Created', {
      numFloorsAfter,
      category: 'Event',
      label: 'Floor created',
      ...(identifier && { userId: identifier }),
    });
}

export function trackFloorDuplicated(numFloorsAfter: number) {
  console.debug(
    `[trackFloorDuplicated] segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED)
    window.analytics.track('Floor Duplicated', {
      numFloorsAfter,
      category: 'Event',
      label: 'Floor duplicated',
      ...(identifier && { userId: identifier }),
    });
}

export function trackNewSession(
  session: Omit<IAgendaSession, 'id'>,
  duplicated: boolean,
) {
  console.debug(
    `${
      duplicated ? '[trackSessionDuplicated]' : '[trackSessionCreated]'
    } segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED)
    window.analytics.track(
      duplicated ? 'Session Duplicated' : 'Session Created',
      {
        length: session.length,
        mode: session.mode?.type ?? 'none',
        ...(session.mode && {
          config: {
            ...session.mode,
          },
        }),
        category: 'Agenda',
        label: duplicated ? 'Session duplicated' : 'Session created',
        ...(identifier && { userId: identifier }),
      },
    );
}

export function trackSessionDeleted(sessionRemaining: number) {
  console.debug(
    `[trackSessionDeleted] segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED)
    window.analytics.track('Session Deleted', {
      sessionRemaining,
      category: 'Agenda',
      label: 'Session deleted',
      ...(identifier && { userId: identifier }),
    });
}

export function trackSessionUpdated(
  oldSession: IAgendaSession,
  newSession: Omit<IAgendaSession, 'id'>,
) {
  const changedFields = Object.keys(newSession).filter(key => {
    if (key === 'mode') {
      return (
        JSON.stringify(oldSession[key]) !== JSON.stringify(newSession[key])
      );
    }

    return oldSession[key] !== newSession[key];
  });

  if (changedFields.length === 0) return;

  const changes = changedFields.map(field => {
    return {
      field,
      oldValue: oldSession[field],
      newValue: newSession[field],
    };
  });

  console.debug(
    `[trackSessionDuplicated] segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED)
    window.analytics.track('Session Duplicated', {
      changedFields,
      changes,
      category: 'Agenda',
      label: 'Session updated',
      ...(identifier && { userId: identifier }),
    });
}

export function trackFloorDeleted(numFloorsAfter: number) {
  console.debug(
    `[trackFloorDeleted] segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED)
    window.analytics.track('Floor Deleted', {
      numFloorsAfter,
      category: 'Event',
      label: 'Floor deleted',
      ...(identifier && { userId: identifier }),
    });
}

interface LinkAnalytics {
  color: string;
  admin: boolean;
  customStartingFloor?: number;
  customAllowedFloors?: number[];
}

export function trackLinkCreated(data: LinkAnalytics) {
  console.debug(
    `[trackLinkCreated] segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED)
    window.analytics.track('Link Created', {
      ...data,
      category: 'Event',
      label: 'Link created',
      ...(identifier && { userId: identifier }),
    });
}

export function trackLinkDeleted(data: LinkAnalytics) {
  console.debug(
    `[trackLinkDeleted] segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED)
    window.analytics.track('Link Deleted', {
      ...data,
      category: 'Event',
      label: 'Link deleted',
      ...(identifier && { userId: identifier }),
    });
}

export function trackTemplateCopied(linkConfig?: LinkConfig) {
  console.debug(
    `[trackTemplateCopied] segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED) {
    let linkType = 'general';
    if (linkConfig?.broadcast) linkType = 'admin';
    if (
      linkConfig?.allowedFloors ||
      linkConfig?.spawnFloor !== 0 ||
      linkConfig?.color
    )
      linkType = 'custom';
    if (linkConfig?.maIM) linkType = 'booth';

    window.analytics.track('Template Copied', {
      linkType,
      category: 'Event',
      label: 'Template copied',
      ...(identifier && { userId: identifier }),
    });
  }
}

export function trackGameFloorSaved(numGames: number) {
  console.debug(
    `[trackGameFloorSaved] segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED)
    window.analytics.track('Game Floor Saved', {
      numGames,
      category: 'Event',
      label: 'Game floor saved',
      ...(identifier && { userId: identifier }),
    });
}

export function trackBoothFloorSaved(numBooths: number) {
  console.debug(
    `[trackBoothFloorSaved] segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED)
    window.analytics.track('Booth Floor Saved', {
      numBooths,
      category: 'Event',
      label: 'Booth floor saved',
      ...(identifier && { userId: identifier }),
    });
}

export function trackPreviewEvent(uponSave: boolean) {
  console.debug(
    `[trackPreviewEvent] segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED)
    window.analytics.track('Preview Event', {
      uponSave,
      category: 'Event',
      label: 'Preview event',
      ...(identifier && { userId: identifier }),
    });
}

export function trackPreviewLandingPage(uponSave: boolean) {
  console.debug(
    `[trackPreviewLandingPage] segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED)
    window.analytics.track('Preview Landing Page', {
      uponSave,
      category: 'Event',
      label: 'Preview landing page',
      ...(identifier && { userId: identifier }),
    });
}

export function trackCardPayment(
  userId: string,
  {
    amount,
    currency,
    id,
    payment_method,
    receipt_email,
    status,
  }: PaymentIntent,
) {
  const paymentDetails = {
    amount,
    currency,
    paymentIntentId: id,
    paymentMethod: `${payment_method}`,
    receiptEmail: `${receipt_email}`,
    status,
  };
  console.debug(
    `[trackCardPayment] segment: ${IS_SEGMENT_ENABLED}, smartlook: ${IS_SMARTLOOK_ENABLED}`,
  );
  if (IS_SMARTLOOK_ENABLED)
    smartlookClient.track('card_payment', paymentDetails);
  if (IS_SEGMENT_ENABLED) {
    smartlookClient.track('card_payment', paymentDetails);
    window.analytics.track('card_payment', {
      userId, // used to merge identified user profile // https://segment.com/docs/connections/spec/best-practices-identify/
      ...paymentDetails,
    });
  }
}

export function trackAccountCreated({ method }: AccountAnalytics) {
  console.debug(
    `[trackAccountCreated] segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED)
    window.analytics.track('Create Account', {
      currency: 'USD',
      value: 0.0,
      method,
      category: 'Account',
      label: 'Finish account creation',
      ...(identifier && { userId: identifier }),
    });
}

export function trackAccountVerified({ method }: AccountAnalytics) {
  console.debug(
    `[trackAccountVerified] segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED)
    window.analytics.track('Verify Account', {
      currency: 'USD',
      value: 0.0,
      method,
      category: 'Account',
      label: 'Verify created account',
      ...(identifier && { userId: identifier }),
    });
}

export function trackPage() {
  console.debug(
    `[trackPage] segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED) window.analytics.page();
}

export function trackPurchaseSubscription(monthlyFee: number) {
  console.debug(
    `[trackPurchaseSubscription] segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED)
    window.analytics.track('Start Subscription', {
      currency: 'USD',
      value: monthlyFee,
      category: 'Purchase',
      label: `$${monthlyFee} plan`,
      ...(identifier && { userId: identifier }),
    });
}

export function trackPurchaseTickets(ticketCost: number, tickets: number) {
  console.debug(
    `[trackPurchaseTickets] segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  if (IS_SEGMENT_ENABLED)
    window.analytics.track('Purchase Tickets', {
      currency: 'USD',
      value: ticketCost,
      num_items: tickets,
      content_name: 'tickets',
      category: 'Purchase',
      label: `${tickets} tickets`,
      ...(identifier && { userId: identifier }),
    });
}

interface LoggedInAnalytics {
  userId: string;
  company?: string;
  logged_in_via: 'email' | 'google';
}

export function trackLoggedIn(data: LoggedInAnalytics) {
  console.debug(
    `[tackLoggedIn] segment: ${IS_SEGMENT_ENABLED} userId: ${identifier}`,
  );
  identifier = data.userId;
  if (IS_SEGMENT_ENABLED)
    window.analytics.track('Logged In', {
      ...data,
      category: 'Account',
      label: 'User logged in',
    });
}
