import { DateTime, Duration, Interval } from 'luxon';
// @ts-ignore - implicit any
import humanizeDuration from 'humanize-duration';

import { SECOND, MINUTE, DAY, HOUR, MONTH, YEAR } from '../config';
import { pluralize } from './misc';

const DATETIME_ERROR = 'DATETIME_ERROR';
// https://github.com/moment/luxon/blob/master/docs/formatting.md#table-of-tokens
/* e.g. Mon 29 Mar, 11:45 AM */
export function formatDate(value: number): string {
  if (!value) return DATETIME_ERROR;
  try {
    const date = DateTime.fromMillis(value);
    const NOW = DateTime.now();

    const isThisYear = date.year === NOW.year;
    const isToday = date.day === NOW.day;

    return date.toFormat(
      `${isToday ? `'Today,'` : `ccc d LLL,${isThisYear ? '' : ' y'}`} h:mm a`,
    );
  } catch (e) {
    console.error(e);
    return DATETIME_ERROR;
  }
}

/* e.g. Saturday 18 December, 12:00 AM CST */
export function formatDateVerbose(value: number, withTimezone = true): string {
  if (!value) return DATETIME_ERROR;
  try {
    const date = DateTime.fromMillis(value);
    const NOW = DateTime.now();

    const isThisYear = date.year === NOW.year;
    const isThisMonth = date.month === NOW.month;
    const isToday = isThisMonth && date.day === NOW.day;

    return date.toFormat(
      `${
        isToday ? `'Today at'` : `cccc d LLLL${isThisYear ? '' : ', y'} ','`
      } h:mm a${withTimezone ? ' ZZZZ' : ''}`,
    );
  } catch (e) {
    console.error(e);
    return DATETIME_ERROR;
  }
}

/* e.g. 5:14 PM 06/15 */
export function formatDateShort(value: number, withTimezone = true): string {
  if (!value) return DATETIME_ERROR;
  try {
    const date = DateTime.fromMillis(value);
    const NOW = DateTime.now();

    const isThisYear = date.year === NOW.year;

    return date.toFormat(
      `h:mm a MM/dd${isThisYear ? '' : '/yy'}${withTimezone ? ' ZZZZ' : ''}`,
    );
  } catch (e) {
    console.error(e);
    return DATETIME_ERROR;
  }
}

/* e.g. Saturday 18 December, 12:00 AM - 12:30 AM CST */
export function formatRange(start: number, stop: number): string {
  try {
    const startDate = DateTime.fromMillis(start);
    const stopDate = DateTime.fromMillis(stop);
    const NOW = DateTime.now();

    const startsThisYear = startDate.year === NOW.year;
    const startsThisMonth = startDate.month === NOW.month;
    const stopsThisYear = stopDate.year === NOW.year;
    const stopsThisMonth = stopDate.month === NOW.month;
    const stopsSameYear = startDate.year === stopDate.year;
    const stopsSameMonth = startDate.month === stopDate.month;

    const startsToday =
      startsThisYear && startsThisMonth && startDate.day === NOW.day;
    const stopsToday =
      stopsThisYear && stopsThisMonth && stopDate.day === NOW.day;
    const stopsSameDay =
      stopsSameYear && stopsSameMonth && startDate.day === stopDate.day;

    const formattedStart = startDate.toFormat(
      `${startsToday ? `'Today'` : 'cccc d LLLL'}${
        startsThisYear || stopsSameYear ? '' : `',' y`
      }',' h:mm a`,
    );

    const stopDayFormatter = stopsSameDay
      ? ''
      : `${stopsToday ? `'Today'` : 'cccc d LLLL'}`;

    const stopYearFormatter = stopsSameYear ? '' : `',' y`;

    const formattedStop = stopDate.toFormat(
      `${stopDayFormatter}${stopYearFormatter} ${
        stopsSameDay ? '' : `','`
      } h:mm a ZZZZ`,
    );
    return `${formattedStart} - ${formattedStop}`;
  } catch (e) {
    console.error(e);
    return '';
  }
}

export function getTimeZone(): string {
  try {
    return DateTime.now().toFormat('ZZZZ');
  } catch (e) {
    console.error(e);
    return '';
  }
}

export function parseJSDate(date: Date): DateTime | null {
  try {
    return DateTime.fromJSDate(date);
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function parseDatePickerDateAsJs(timestamp: number): Date | null {
  try {
    return DateTime.fromMillis(timestamp).toJSDate();
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function getDurationMillis(start: DateTime, stop: DateTime): number {
  try {
    return stop.toMillis() - start.toMillis();
  } catch (e) {
    console.error(e);
    return -1;
  }
}
export function getDurationMinutes(start: DateTime, stop: DateTime): number {
  return Math.ceil(getDurationMillis(start, stop) / MINUTE);
}

export function getDurationHumanReadable(start: number, stop: number): string {
  const startDate = DateTime.fromMillis(start);
  const stopDate = DateTime.fromMillis(stop);
  const minutes = getDurationMinutes(startDate, stopDate);
  const hours = minutes / 60;
  if (hours < 1) return `${minutes} minutes`;
  const days = hours / 24;
  if (days < 2) {
    const approximateHours = Math.round(hours);
    return pluralize(approximateHours, 'hour');
  }
  const approximateDays = Math.round(days);
  return pluralize(approximateDays, 'day');
}

// 01:22:12
export function getDurationAsTimestamp(
  start = 0,
  stop = 0,
  format = 'hh:mm:ss',
): string {
  if (!start || !stop) return '';
  const startDate = DateTime.fromMillis(start);
  const stopDate = DateTime.fromMillis(stop);
  const millis = getDurationMillis(startDate, stopDate);
  if (millis < 0) return '';
  const duration = Duration.fromMillis(millis);
  return duration.toFormat(format);
}
export function getMillisAsTimestamp(
  millis: number,
  format = 'hh:mm:ss',
): string {
  if (millis < 0) return '';
  const duration = Duration.fromMillis(millis);
  return duration.toFormat(format);
}

type LuxonTimeOffset = {
  minutes?: number;
  hours?: number;
  days?: number;
};

export function getTimeFromNow(
  offset: LuxonTimeOffset,
  currentTime: DateTime = DateTime.now(),
): DateTime {
  try {
    return currentTime.plus(offset);
  } catch (e) {
    console.error(e);
    return currentTime;
  }
}

export function getMillisLeft(timestamp: number): number {
  try {
    if (!timestamp) return 0;
    const dateTime = DateTime.fromMillis(timestamp);
    const interval = Interval.fromDateTimes(DateTime.now(), dateTime);
    return interval.length('milliseconds');
  } catch (e) {
    console.error(e);
    return 0;
  }
}

export function humanizeShortHand(millis: number, spacer = ' ') {
  return humanize(millis, false, spacer, ' ')
    .replace('0 hours, ', '')
    .replace('minute', 'min')
    .replace('hour', 'hr');
}

export function humanize(
  millis: number,
  showSeconds = true,
  spacer = ' ',
  delimiter = ', ',
) {
  try {
    if (millis > YEAR) {
      return humanizeDuration(millis, {
        round: true,
        units: ['y'],
        delimiter,
        spacer,
      });
    } else if (millis > MONTH) {
      return humanizeDuration(millis, {
        round: true,
        units: ['mo'],
        delimiter,
        spacer,
      });
    } else if (millis > DAY) {
      return humanizeDuration(millis, {
        round: true,
        units: ['d', 'h'],
        delimiter,
        spacer,
      });
    } else if (millis > HOUR) {
      return humanizeDuration(millis, {
        round: true,
        units: showSeconds ? ['h', 'm', 's'] : ['h', 'm'],
        delimiter,
        spacer,
      });
    } else {
      return `0 hours, ${humanizeDuration(millis, {
        round: true,
        units: showSeconds ? ['h', 'm', 's'] : ['h', 'm'],
        delimiter,
        spacer,
      })}`;
    }
  } catch (e) {
    return 'invalid date';
  }
}

export function isOnTheMinute(millisLeft: number) {
  const seconds = Math.ceil(millisLeft / SECOND) % 60;
  return !seconds;
}
