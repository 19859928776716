import { c } from '../../utils';
import classes from './EventCountdown.module.scss';

type Props = {
  className?: string;
};

export function InProgress({ className }: Props) {
  return (
    <div
      className={c(
        className,
        classes.EventCountdown,
        'flex items-center color-shade-80 bg-color-butter body p1 br_25',
      )}
    >
      🌎 Now live
    </div>
  );
}
