import {
  AccountsUser,
  AclPermissions,
  AclPermissionsRole,
} from '@eventmanager/types';

import { UserAuthState } from '../enums';
import { createReducer } from './createReducer';

interface AccountState {
  user: AccountsUser | null;
  authState: UserAuthState;
  permissions: Record<AclPermissionsRole, AclPermissions>;
}

enum AccountActionType {
  CLEAR_USER = '@account/CLEAR_USER',
  SET_PERMISSIONS = '@account/SET_PERMISSIONS',
  SET_USER = '@account/SET_USER',
  SET_USER_AUTH_STATE = '@account/SET_USER_AUTH_STATE',
}
type AccountClearUserAction = {
  type: AccountActionType.CLEAR_USER;
};
type AccountSetPermissionsAction = {
  type: AccountActionType.SET_PERMISSIONS;
  payload: Record<AclPermissionsRole, AclPermissions>;
};
type AccountSetUserAction = {
  type: AccountActionType.SET_USER;
  payload: AccountsUser | null;
};
type AccountSetUserAuthStateAction = {
  type: AccountActionType.SET_USER_AUTH_STATE;
  payload: UserAuthState;
};

type AccountAction =
  | AccountSetUserAction
  | AccountSetUserAuthStateAction
  | AccountClearUserAction
  | AccountSetPermissionsAction;

const initialState: AccountState = {
  user: null,
  authState: UserAuthState.UNKNOWN,
  permissions: {} as Record<AclPermissionsRole, AclPermissions>,
};

export const accountReducer = createReducer<
  AccountState,
  any, // TODO: fix type inference
  AccountActionType
>(initialState, {
  [AccountActionType.SET_USER]: (
    state: AccountState,
    action: AccountSetUserAction,
  ) => {
    return {
      ...state,
      user: action.payload,
    };
  },
  [AccountActionType.SET_USER_AUTH_STATE]: (
    state: AccountState,
    action: AccountSetUserAuthStateAction,
  ) => {
    return {
      ...state,
      authState: action.payload,
    };
  },
  [AccountActionType.CLEAR_USER]: (state: AccountState) => {
    return {
      ...state,
      user: null,
      authState: UserAuthState.LOGGED_OUT,
    };
  },
  [AccountActionType.SET_PERMISSIONS]: (
    state: AccountState,
    action: AccountSetPermissionsAction,
  ) => {
    return {
      ...state,
      permissions: action.payload,
    };
  },
});

// action creators
export function makeSetUserAction(
  userData: AccountsUser | null,
): AccountAction {
  const payload = userData
    ? {
        ...userData,
        fullName: [userData.firstName, userData.lastName]
          .filter(Boolean)
          .join(' '),
      }
    : userData;
  return {
    type: AccountActionType.SET_USER,
    payload,
  };
}
export function makeSetUserAuthStateAction(
  payload: UserAuthState,
): AccountAction {
  return {
    type: AccountActionType.SET_USER_AUTH_STATE,
    payload,
  };
}
export function makeClearUserAction(): AccountAction {
  return {
    type: AccountActionType.CLEAR_USER,
  };
}
export function makeSetPermissionsAction(
  payload: Record<AclPermissionsRole, AclPermissions>,
): AccountAction {
  return {
    type: AccountActionType.SET_PERMISSIONS,
    payload,
  };
}
