import {
  AccountsUser,
  AclPermissionsRole,
  UpdateUserParams,
  DEFAULT_AI_TOKENS,
  DEFAULT_TICKETS,
} from '@eventmanager/types';
import { UserAuthState } from '../enums';
import {
  identifyUser,
  trackAccountCreated,
  trackAccountVerified,
  trackPage,
} from '../libs/trackingLib';
import {
  makeSetPermissionsAction,
  makeSetUserAction,
  makeSetUserAuthStateAction,
  makeClearUserAction,
} from '../store/accountReducer';
import { OverlayType } from '../store/uiReducer';
import { Api, Auth } from '../utils';
import { SignUpParams } from '../utils/auth';
import { nameFromEmail } from '../utils/user';

import {
  setIdle,
  setPending,
  setFulfilled,
  setRejected,
} from './statusActions';
import { setOverlay } from './uiActions';

export const EMPTY_USER: AccountsUser = {
  company: '',
  email: '',
  firstName: '',
  lastName: '',
  reservedTickets: 0,
  role: 'free',
  tickets: DEFAULT_TICKETS,
  userId: '',
  aiTokens: DEFAULT_AI_TOKENS,
  permissionOverrides: {},
};

async function createUser(dispatch) {
  const { userId, userEmail } = Auth.getSessionData();
  console.warn('Account is missing a user, creating a blank one', userId);
  const userNames = nameFromEmail(userEmail);
  const user = await Api.createUser({
    userId,
    ...userNames,
    email: userEmail,
    company: ' ',
  });

  // segment analytics events
  identifyUser(user);
  trackPage();
  trackAccountCreated({ method: 'email' });

  dispatch(makeSetUserAction(user));
}

export async function findOrCreateUser(dispatch) {
  setPending(dispatch, 'getUser');
  const authState = await Auth.getCurrentAuthState();
  dispatch(makeSetUserAuthStateAction(authState));

  try {
    const existingUser = await Api.getCurrentUser();
    if (existingUser) {
      const { firstName, lastName } = existingUser;
      if ([firstName, lastName].some(name => !name.trim())) {
        setOverlay(dispatch, OverlayType.USER_DETAILS, {
          user: existingUser,
          allowDismiss: false,
        });
      }
      dispatch(makeSetUserAction(existingUser));
      setFulfilled(dispatch, 'getUser');
      identifyUser(existingUser);
      return;
    } else {
      createUser(dispatch);
    }

    setFulfilled(dispatch, 'getUser');
  } catch (err: any) {
    console.error(err);
    setRejected(dispatch, 'getUser');
  }
}

function refetchUser(dispatch, timeoutDuration = 5000) {
  setPending(dispatch, 'getUser');
  const timeout = setTimeout(async () => {
    try {
      const validatedUser = await Api.getCurrentUser();
      dispatch(makeSetUserAction(validatedUser));
      setFulfilled(dispatch, 'getUser');
    } catch (err: any) {
      console.error(err);
      setRejected(dispatch, 'getUser');
    }

    clearTimeout(timeout);
  }, timeoutDuration);
}

export function setUserRole(
  dispatch,
  user: AccountsUser,
  role: AclPermissionsRole,
) {
  dispatch(makeSetUserAction({ ...user, role }));

  if (role === 'free') {
    updateCurrentUser(dispatch, { role });
  }

  refetchUser(dispatch);

  return;
}

export function addUserTickets(
  dispatch,
  user: AccountsUser,
  ticketCount: number,
) {
  dispatch(makeSetUserAction({ ...user, tickets: user.tickets + ticketCount }));

  refetchUser(dispatch);

  return;
}

export async function updateCurrentUser(dispatch, userData: UpdateUserParams) {
  setPending(dispatch, 'updateUser');
  try {
    const user = await Api.updateCurrentUser(userData);
    dispatch(makeSetUserAction(user));
    setFulfilled(dispatch, 'updateUser');
    identifyUser(user);
  } catch (err: any) {
    setRejected(dispatch, 'updateUser');
  }
}

export async function fetchPermissions(dispatch) {
  try {
    setPending(dispatch, 'getPermissions');
    const permissions = await Api.getPermissions();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(makeSetPermissionsAction(permissions));
    setFulfilled(dispatch, 'getPermissions');
  } catch (err: any) {
    console.error(err);
    setRejected(dispatch, 'getPermissions');
  }
}

export async function requestResetPassword(dispatch, email: string) {
  try {
    setPending(dispatch, 'requestResetPassword');
    await Auth.requestResetPassword(email);
    setFulfilled(dispatch, 'requestResetPassword');
  } catch (err: any) {
    console.error(err);
    setRejected(dispatch, 'requestResetPassword');
  }
}

export async function resetPassword(
  dispatch,
  email: string,
  code: string,
  password: string,
) {
  try {
    setPending(dispatch, 'resetPassword');
    await Auth.resetPassword(email, code, password);
    setFulfilled(dispatch, 'resetPassword');
    return signIn(dispatch, email, password);
  } catch (err: any) {
    console.error(err);
    switch (err?.code) {
      case 'ExpiredCodeException':
        setRejected(dispatch, 'resetPassword');
        return {
          error: err.message,
        };
      default:
        setRejected(dispatch, 'resetPassword');
        break;
    }
  }
}

export async function signIn(dispatch, email: string, password: string) {
  try {
    setPending(dispatch, 'signIn');
    await Auth.signIn(email, password);
    const authState = await Auth.getCurrentAuthState();
    dispatch(makeSetUserAuthStateAction(authState));
    setFulfilled(dispatch, 'signIn');
  } catch (err: any) {
    console.error(err);
    switch (err?.code) {
      case 'UserNotConfirmedException':
        dispatch(makeSetUserAuthStateAction(UserAuthState.UNVERIFIED));
        dispatch(
          makeSetUserAction({
            ...EMPTY_USER,
            email,
          }),
        );
        setIdle(dispatch, 'signIn');
        break;
      case 'NotAuthorizedException':
        dispatch(makeSetUserAuthStateAction(UserAuthState.LOGGED_OUT));
        setRejected(dispatch, 'signIn');
        break;
      case 'UserNotFoundException':
        dispatch(makeSetUserAuthStateAction(UserAuthState.UNREGISTERED));
        setRejected(dispatch, 'signIn');
        break;
      default:
        dispatch(makeSetUserAuthStateAction(UserAuthState.UNKNOWN));
        setRejected(dispatch, 'signIn');
        break;
    }
  }
}

export async function signOut(dispatch) {
  await Auth.signOut();
  console.log('logged out');
  dispatch(makeClearUserAction());
  console.log('cleared current user');
}

export async function signUp(dispatch, params: SignUpParams) {
  setPending(dispatch, 'signUp');
  try {
    // DB user created in lambda pre-sign up trigger
    const user = await Auth.signUp(params);
    if (!user) throw new Error('Failed to create user');

    // segment analytics events
    identifyUser(user);
    trackPage();
    trackAccountCreated({ method: 'email' });

    dispatch(makeSetUserAction(user));
    dispatch(makeSetUserAuthStateAction(UserAuthState.UNVERIFIED));
    setFulfilled(dispatch, 'signUp');
  } catch (err: any) {
    let errorMessage = err.message;
    console.error(err);
    if (err.code === 'UsernameExistsException') {
      errorMessage = 'An account already exists for that email address';
    } else if (err.code === 'InvalidPasswordException') {
      errorMessage =
        'Password must contain at least 8 letters, numbers or special characters';
    }
    setRejected(dispatch, 'signUp');
    return { errorMessage };
  }
}

export async function syncAuthState(dispatch) {
  const authState = await Auth.getCurrentAuthState();
  dispatch(makeSetUserAuthStateAction(authState));
}

export async function verifyUserEmail(
  dispatch,
  email: string,
  verificationCode: string,
): Promise<void | { error: string }> {
  try {
    setPending(dispatch, 'verifyEmail');
    await Auth.verifyAccount(email, verificationCode);

    // segment analytics event
    trackAccountVerified({ method: 'email' });

    setFulfilled(dispatch, 'verifyEmail');
  } catch (err: any) {
    console.error(err);
    switch (err?.code) {
      case 'NotAuthorizedException':
        // Already confirmed, allow user to sign in
        setFulfilled(dispatch, 'verifyEmail');
        return;
      default:
        console.log(err.code);
        setRejected(dispatch, 'verifyEmail');
        // surface errors from AWS
        if (err?.code && err?.message) return { error: err.message };
    }
  }
}

export async function resendVerificationCode(dispatch, email: string) {
  try {
    setPending(dispatch, 'resendVerification');
    await Auth.resendVerificationCode(email);
    setFulfilled(dispatch, 'resendVerification');
  } catch (err: any) {
    console.error(err);
    setRejected(dispatch, 'resendVerification');
  }
}
