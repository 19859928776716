import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer, toast } from 'react-toastify';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initSentry } from './libs/errorLib';
import AppProvider from './AppProvider';
import { isDev } from './config';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { NewVersionToast } from './components/toasts';
import 'react-toastify/dist/ReactToastify.css';
import './styles/root.scss';

initSentry();

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <App />
      <ToastContainer />
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

if (isDev) {
  serviceWorkerRegistration.unregister();
} else {
  serviceWorkerRegistration.register({
    onUpdate: ({ waiting }) =>
      toast(<NewVersionToast waiting={waiting} />, {
        position: 'bottom-right',
        hideProgressBar: true,
        closeOnClick: false,
        autoClose: false,
        pauseOnHover: true,
      }),
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
