import { configureStore } from '@reduxjs/toolkit';

import { accountReducer } from './accountReducer';
import { eventsReducer } from './eventsReducer';
import { statusReducer } from './statusReducer';
import { uiReducer } from './uiReducer';

export const store = configureStore({
  reducer: {
    account: accountReducer,
    events: eventsReducer,
    status: statusReducer,
    ui: uiReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
// should ensure middleware and slice state types are passed down
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {account: AccountState, status: StatusState }
export type AppDispatch = typeof store.dispatch;
