import { useEffect, useMemo } from 'react';
import { c } from '../../utils';

type CharacterLimitProps = {
  limit: number;
  currentLength: number;
  onChange?: (isMaxLength: boolean) => void;
};

export const CharacterLimit = ({
  currentLength,
  limit = 0,
  onChange,
}: CharacterLimitProps) => {
  const isMaxLengthExceeded = useMemo(
    () => currentLength >= limit,
    [currentLength, limit],
  );

  useEffect(() => {
    onChange?.(isMaxLengthExceeded);
  }, [isMaxLengthExceeded]);

  return (
    <span
      data-cy="character-limit"
      className={c('flex justify-end', isMaxLengthExceeded && 'color-red')}
    >
      <span className={c('mr_25')}>
        {currentLength}/{limit}
      </span>
      character limit
    </span>
  );
};
