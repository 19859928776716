import React, { ReactNode } from 'react';

import { c } from '../../utils';
import classes from './Pill.module.scss';

type Props = {
  className?: string;
  children: ReactNode | string;
};
export function Pill({ className, children }: Props) {
  return (
    <div
      className={c(
        className,
        classes.Pill,
        'flex items-center justify-center p1',
      )}
    >
      {children}
    </div>
  );
}
