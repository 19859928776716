import { ReactNode, SyntheticEvent, useState } from 'react';
import { IconButton, InputAdornment } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { Input, InputBaseProps } from './Input';

export function Password({ name, ...props }: InputBaseProps) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (evt: SyntheticEvent) => {
    evt.preventDefault();
  };

  const endAdornment: ReactNode = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        data-cy="toggle-password-visibility"
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <Input
      {...props}
      name={name || 'password'}
      type={showPassword ? 'text' : 'password'}
      endAdornment={endAdornment}
    />
  );
}
