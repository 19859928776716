import React, { ReactNode } from 'react';

import { c } from '../../utils';

import classes from './DroppableUploader.module.scss';

type Props = {
  error?: string;
  className?: string;
  isDisabled?: boolean;
  isDragActive: boolean;
  isLoading?: boolean;
  title?: string;
  subtitle?: string | ReactNode;
};
export function EmptyState({
  className,
  error,
  isDisabled,
  isDragActive,
  isLoading,
  title,
  subtitle,
}: Props) {
  return (
    <div
      className={c(
        className,
        isLoading && classes.isLoading,
        'flex flex-col justify-center items-center text-center',
        'p1 border-color-shade-30 br_25',
        isDisabled
          ? ''
          : 'cursor-pointer transition-opacity--long hover:opacity-0_5',
      )}
    >
      {!isDisabled && (
        <div
          className={c(
            classes.emptyStateContent,
            'bg-color-silver br1 p1',
            'flex flex-col items-center justify-center',
          )}
        >
          <p className="color-shade-70 bold subbody">{title}</p>
          <p className="color-shade-70 detail">
            {isDragActive && 'Drop the file to upload...'}
            {!isDragActive && (
              <>
                {subtitle ?? (
                  <>
                    {' '}
                    Drag and drop an image, or <u>Browse</u>{' '}
                  </>
                )}
              </>
            )}
          </p>
        </div>
      )}
      {error && <p className="italic color-red small pt_75">{error}</p>}
    </div>
  );
}
