import 'react-quill/dist/quill.snow.css';

import { Switch, classnames } from '@gatherly/lib';
import ReactQuill, { Quill } from 'react-quill';
import { OverlayType, setOverlay, updateEvent } from '../../../actions';
import { Clickable, ClickableVariant } from '../../Clickable';
import { Input, Password } from '../../Input';

import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  emailSettingOptionsArray,
  eventTypeOptionsArray,
} from '../../../enums';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  currentUser,
  getStatusOfUpdateEvent,
  maxEventDurationHours,
} from '../../../selectors';
import {
  c,
  getTimeFromNow,
  isPending,
  validateCapacity,
  validateEventDuration,
  validatePassword,
  validateString,
  validateTimestamp,
} from '../../../utils';
import { getChanges, getFormFields } from './eventFormHelpers';

import { AugmentedGatherlyEvent, UpdateEventParams } from '@eventmanager/types';
import { EventEmailSetting, SessionModeType } from '@gatherly/types';
import { Input as MuiInput } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { HelpRounded, InfoRounded } from '@material-ui/icons';
import { DateTime } from 'luxon';
import MagicUrl from 'quill-magic-url';
import { DateRangePicker } from '../../DateRangePicker';
import { FieldWrapper } from '../../FieldWrapper';
import { Select } from '../../Select';
import { Tooltip } from '../../Tooltip';
import { Form } from '../Form';
import { EventFormFooter } from './EventFormFooter';
import { EventFormHeader } from './EventFormHeader';
import classes from './EventInfoForm.module.scss';
import { EventLogoForm } from './EventLogoForm';

Quill.register('modules/magicUrl', MagicUrl);

type Props = {
  event: AugmentedGatherlyEvent;
  isDisabled: boolean;
  setHasChanges: (hasChanges: boolean) => void;
};

type EventErrors = {
  name?: string;
  capacity?: string;
  password?: string;
  startTime?: string;
  stopTime?: string;
  emailSettings?: EventEmailSetting;
  duration?: string;
  description?: string;
  waitingRoomCapacity?: string;
};

export function EventInfoForm({
  event: eventData,
  isDisabled,
  setHasChanges,
}: Props) {
  const { eventId, aiPrompt } = eventData;
  const dispatch = useAppDispatch();

  const user = useAppSelector(currentUser);
  const maxEventDuration = useAppSelector(maxEventDurationHours);
  const status = useAppSelector(getStatusOfUpdateEvent(eventData.eventId));
  const [description, setDescription] = useState(
    eventData.config.landingPage.description,
  );
  // Click Handlers
  const onClickBuyTickets = useCallback((evt?: SyntheticEvent) => {
    if (evt) evt.preventDefault();
    setOverlay(dispatch, OverlayType.PURCHASE_TICKETS);
  }, []);

  // FormFields
  const [event, setEvent] = useState(getFormFields(eventData));
  const [errors, setErrors] = useState<EventErrors>({});
  const {
    name,
    eventType,
    startTime,
    stopTime,
    capacity,
    password,
    emailSettings,
    elevatorEnabled,
    waitingRoomOption,
    photoBoothOption,
    startMode,
  } = event;
  const [waitingRoomLimit, setWaitingRoomLimit] = useState<number>(
    typeof waitingRoomOption === 'number' ? waitingRoomOption : capacity,
  );

  const validators = useMemo(
    () => ({
      name: (value: string) => validateString(value, 'Event name', true, 75),
      capacity: (value: string) =>
        validateCapacity(value, user?.tickets || 0, eventData.capacity),
      password: (value: string) => validatePassword(value, false),
      startTime: (value: number) => validateTimestamp(value),
      stopTime: (value: number) => validateTimestamp(value),
      duration: () =>
        validateEventDuration(startTime, stopTime, maxEventDuration),
    }),
    [],
  );

  const onTimeChange = useCallback(
    evt => {
      const {
        target: { name, value },
      } = evt;
      setEvent(event => {
        let updatedEvent = { ...event, [name]: value };
        let error: any = undefined;

        if (validators[name]) {
          error = validators[name](value)?.error || undefined;
          setErrors(errors => {
            if (!error) {
              delete errors[name];
            }

            return { ...errors, ...(error && { [name]: error }) };
          });
        }

        if (name === 'startTime' && !error) {
          const duration = (event.stopTime - event.startTime) / 1000 / 60;
          const updatedStopTime = getTimeFromNow(
            { minutes: duration },
            DateTime.fromMillis(value),
          ).toMillis();
          updatedEvent = { ...updatedEvent, stopTime: updatedStopTime };
        }

        return updatedEvent;
      });
    },
    [setEvent, setErrors, validators],
  );

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      if (validators[name]) {
        const { error } = validators[name](value);
        setErrors(errors => {
          if (!error) {
            delete errors[name];
          }

          return { ...errors, ...(error && { [name]: error }) };
        });
      }
      setEvent({ ...event, [name]: value });
    },
    [event, setEvent, setErrors],
  );

  const onDescriptionChanged = useCallback((value, _, source) => {
    if (source === 'api') return;

    const fieldIsEmpty = value === '<p><br></p>' || value === '<p></p>';

    setDescription(fieldIsEmpty ? '' : value);
  }, []);

  const hasChanges = useMemo(() => {
    const {
      eventFieldsDidUpdate,
      configFieldsDidUpdate,
      eventDescriptionDidUpdate,
    } = getChanges(eventData, {
      ...event,
      description,
    });

    const hasChanges =
      eventFieldsDidUpdate ||
      configFieldsDidUpdate ||
      eventDescriptionDidUpdate;

    setHasChanges(hasChanges);

    return hasChanges;
  }, [eventData, setHasChanges, description, event]);

  // Effects
  useEffect(() => {
    if (isDisabled) return;
    const _durationError = validateEventDuration(
      startTime,
      stopTime,
      maxEventDuration,
    );

    setErrors(errors => {
      if (!_durationError) {
        delete errors.duration;
      }

      return { ...errors, ...(_durationError && { duration: _durationError }) };
    });
  }, [startTime, stopTime, maxEventDuration]);

  useEffect(() => {
    if (isDisabled) return;

    if (typeof waitingRoomOption !== 'number') {
      setErrors(errors => {
        delete errors.waitingRoomCapacity;
        return errors;
      });
      return;
    }

    let error;

    if (waitingRoomLimit < 1) {
      error = 'Limit must be greater than 0';
    } else if (waitingRoomLimit > capacity) {
      error = `Limit cannot exceed capacity (${capacity})`;
    } else if (isNaN(waitingRoomLimit)) {
      error = 'Limit cannot be empty';
    }

    setErrors(errors => {
      if (!error) {
        delete errors.waitingRoomCapacity;
      }

      return { ...errors, ...(error && { waitingRoomCapacity: error }) };
    });
  }, [waitingRoomLimit, waitingRoomOption, capacity]);

  const hasErrors = useMemo(() => !!Object.values(errors).length, [errors]);

  const onSubmit = useCallback(
    async (evt?: SyntheticEvent) => {
      if (evt) evt.preventDefault();

      const { fieldsChanged, configFieldsChanged } = getChanges(eventData, {
        ...event,
        description,
      });

      if (hasErrors) {
        alert('Please fix all errors to save this event'); // TODO: No window.alert
        return;
      }

      const params: UpdateEventParams = {
        ...fieldsChanged,
        config: {
          ...eventData.config,
          ...configFieldsChanged,
        },
      };

      updateEvent(dispatch, eventData.eventId, params);
    },
    [eventData, event, description, hasErrors],
  );

  const logoUrl = useMemo(() => eventData.config?.logoUrl, [eventData]);

  return (
    <Form
      className={c(classes.EventInfoForm, 'flex flex-1 flex-col gap-1')}
      onSubmit={onSubmit}
      autoComplete="off"
    >
      <div className="md:pl2">
        <EventFormHeader
          eventId={eventId}
          isDisabled={isDisabled}
          title="Event Info"
        >
          <p className="color-shade-80 body">
            Ready to have an awesome event? Let's start with the basics.
            <br />
            <Clickable
              to={`https://www.youtube.com/watch?v=R4kXJ6SXG7A&list=PLRL7DgvBi2Ulg5F9Y-VEklKQssdWq7Rmh&index=3`}
              variant={ClickableVariant.LINK}
            >
              View Guides
            </Clickable>
          </p>
        </EventFormHeader>
      </div>
      <div className={c(classes.mainColumn, 'flex flex-col gap-1 md:pl2')}>
        <p className="color-shade-80 body bold">Basic Info</p>
        <div className="flex flex-col md:flex-row gap-1">
          <Input
            data-cy="event-name"
            label="Event Name"
            name="name"
            onChange={e =>
              handleChange({
                target: {
                  name: e.target.name,
                  value: e.target.value.slice(0, 75),
                },
              })
            }
            value={name}
            error={errors.name}
            isDisabled={isDisabled}
            isRequired
            characterLimit={75}
          />
        </div>
        <div className="flex flex-col md:flex-row gap-1">
          <Select
            className="col-6"
            label="Event Type"
            name="eventType"
            // @ts-ignore
            onChange={handleChange}
            options={eventTypeOptionsArray}
            value={eventType}
            isDisabled={isDisabled}
            isRequired
          />
        </div>
        <div className="flex flex-col md:flex-row gap-1">
          <Input
            className="md:col-6"
            label="Tickets (Capacity)"
            name="capacity"
            onChange={handleChange}
            value={capacity}
            error={errors.capacity}
            type="number"
            isDisabled={isDisabled}
            isRequired
          />
          <div className="flex items-start">
            {isDisabled || (
              <Clickable
                onClick={onClickBuyTickets}
                className="py1"
                variant={ClickableVariant.BUTTON_SECONDARY}
              >
                Buy Tickets
              </Clickable>
            )}
          </div>
        </div>
        <DateRangePicker
          // @ts-ignore
          onChange={onTimeChange}
          startValue={startTime}
          stopValue={stopTime}
          durationError={errors.duration}
          isDisabled={isDisabled}
        />

        <FieldWrapper title="Advanced Admin Controls" className="mb2">
          <div
            className={classnames(
              'flex justify-between mb_5',
              classes.adminControlsWrapper,
            )}
          >
            <div className="flex items-center justify-center">
              <span className={classnames(classes.label, 'mr_25')}>
                Elevators enabled{' '}
              </span>
              <Tooltip
                className={classes.tooltipWrapper}
                content={
                  <div>
                    <p>If enabled, elevators will be available in the map.</p>
                    <br />
                    <p>If disabled, elevators will be hidden.</p>
                  </div>
                }
              >
                <HelpRounded className={classes.icon} />
              </Tooltip>
            </div>
            <Switch
              options={[
                {
                  label: 'True',
                  value: true,
                  id: 'elevator-enabled',
                  disabled: isDisabled,
                },
                {
                  label: 'False',
                  value: false,
                  id: 'elevator-disabled',
                  disabled: isDisabled,
                },
              ]}
              onChange={enabled =>
                handleChange({
                  target: { name: 'elevatorEnabled', value: enabled },
                })
              }
              value={elevatorEnabled ?? true}
            />
          </div>
          <div
            className={classnames(
              'flex justify-between mb_5',
              classes.adminControlsWrapper,
            )}
          >
            <div className="flex items-center justify-center">
              <span className={classnames(classes.label, 'mr_25')}>
                Photo booth{' '}
              </span>
              <Tooltip
                className={classes.tooltipWrapper}
                content={
                  <div>
                    <p>
                      <strong>Hidden</strong>: The photo booth option is not
                      shown
                    </p>
                    <br />
                    <p>
                      <strong>Selfies only</strong>: The photo booth is shown,
                      but only selfies can be taken
                    </p>
                    <br />
                    <p>
                      <strong>Selfies and group photos</strong>: The photo booth
                      is shown, and both selfies and group photos can be taken
                    </p>
                  </div>
                }
              >
                <HelpRounded className={classes.icon} />
              </Tooltip>
            </div>
            <Switch
              options={[
                {
                  label: 'Hidden',
                  value: 'hidden',
                  id: 'hidden',
                  disabled: isDisabled,
                },
                {
                  label: 'Selfies only',
                  value: 'selfies',
                  id: 'selfies',
                  disabled: isDisabled,
                },
                {
                  label: 'Selfies and group photos',
                  value: 'selfies-and-group',
                  id: 'selfies-and-group',
                  disabled: isDisabled,
                },
              ]}
              onChange={value =>
                handleChange({
                  target: { name: 'photoBoothOption', value },
                })
              }
              value={photoBoothOption || 'selfies-and-group'}
            />
          </div>
          <div
            className={classnames(
              'flex justify-between',
              classes.adminControlsWrapper,
            )}
          >
            <div className="flex items-center">
              <span className={classnames(classes.label, 'mr_25')}>
                Waiting room{' '}
              </span>
              <Tooltip
                className={classes.tooltipWrapper}
                content={
                  <div>
                    <p>
                      <strong>Everyone</strong> means that all attendees who
                      join the event will first be placed into the waiting room
                      until an admin lets them in.
                    </p>
                    <br />
                    <p>
                      <strong>Limit</strong> means that attendees will
                      automatically join the event until the limit you set.
                      Afterwards, new attendees will be placed in the waiting
                      room until they are let in.
                    </p>
                    <br />
                    <p>
                      <strong>Auto-join</strong> means that attendees will
                      always automatically join the event, regardless of the
                      capacity of the event.
                    </p>
                  </div>
                }
              >
                <HelpRounded className={classes.icon} />
              </Tooltip>
            </div>
            <Switch
              options={[
                {
                  label: 'Everyone',
                  value: 'everyone',
                  id: 'everyone',
                  disabled: isDisabled,
                },
                {
                  label: (
                    <>
                      <MuiInput
                        data-lpignore="true"
                        name="lastpass-disable-search"
                        readOnly
                        onFocus={e => e.target.removeAttribute('readonly')}
                        onBlur={e =>
                          e.target.setAttribute('readonly', 'readonly')
                        }
                        onChange={e => {
                          const value = parseInt(e.target.value);
                          setWaitingRoomLimit(parseInt(e.target.value));
                          setEvent(event => ({
                            ...event,
                            waitingRoomOption: value,
                          }));
                        }}
                        type="number"
                        value={waitingRoomLimit}
                        inputProps={{
                          min: 1,
                          max: capacity,
                          variant: 'standard',
                        }}
                        autoComplete="false"
                        className={classes.numberInput}
                      />
                      &nbsp; limit
                    </>
                  ),
                  value: 'limit',
                  id: 'limit',
                  disabled: isDisabled,
                },
                {
                  label: 'Auto-join',
                  value: 'auto-join',
                  id: 'auto-join',
                  disabled: isDisabled,
                },
              ]}
              onChange={(value: 'limit' | 'auto-join' | 'everyone') => {
                if (value === 'limit') {
                  setEvent(event => ({
                    ...event,
                    waitingRoomOption: waitingRoomLimit,
                  }));
                  return;
                }

                handleChange({
                  target: { name: 'waitingRoomOption', value },
                });
              }}
              value={
                typeof waitingRoomOption === 'number'
                  ? 'limit'
                  : waitingRoomOption || 'auto-join'
              }
            />
          </div>
          {errors.waitingRoomCapacity && (
            <div className={classes.error}>{errors.waitingRoomCapacity}</div>
          )}
          <div
            className={classnames(
              'flex justify-between mt_5',
              classes.adminControlsWrapper,
            )}
          >
            <div className="flex items-center">
              <span className={classnames(classes.label, 'mr_25')}>
                Starting mode{' '}
              </span>
              <Tooltip
                className={classes.tooltipWrapper}
                content={
                  <div>
                    <p>
                      Choose the mode Gatherly will be in when your event
                      starts. You can always change this during the event.
                    </p>
                    <br />
                    <p>
                      <strong>Map</strong> mode will place attendees in a 2D
                      virtual room that they can walk around and network in,
                      just like real life. Great for interactive sessions.
                    </p>
                    <br />
                    <p>
                      <strong>Broadcast</strong> mode will place attendees in
                      the audience of a stream that admins control. Admins can
                      bring people up on stage with them or remove them; used
                      for webinars, keynotes, and panels.
                    </p>
                    <br />
                    <p>
                      <strong>Group</strong> mode will place attendees in a
                      group call with a maximum capacity of 100. Great for team
                      meetings.
                    </p>
                  </div>
                }
              >
                <HelpRounded className={classes.icon} />
              </Tooltip>
            </div>
            <Switch
              options={[
                {
                  label: 'Map',
                  value: 'map',
                  id: 'map',
                  disabled: isDisabled,
                },
                {
                  label: 'Broadcast',
                  value: 'broadcast',
                  id: 'broadcast',
                  disabled: isDisabled,
                },
                {
                  id: 'group',
                  label: 'Group',
                  value: 'group',
                  disabled: isDisabled,
                },
              ]}
              onChange={(value: SessionModeType) => {
                setEvent(event => ({
                  ...event,
                  startMode: value,
                }));
              }}
              value={startMode ?? 'map'}
            />
          </div>
          {startMode === 'group' && typeof waitingRoomOption === 'number' && (
            <div className="mt1 flex">
              <InfoRounded className={classnames(classes.icon, ' mr_5')} />
              <p>
                The waiting room limit will be overridden to the group call
                capacity (100) while the group call is active.
              </p>
            </div>
          )}
        </FieldWrapper>

        {aiPrompt && (
          <FieldWrapper title="AI Prompt" className="mb1">
            <Input
              className={classnames('flex-2', classes.aiPrompt)}
              placeholder="I want to host a football-themed team social with icebreakers..."
              name="aiPrompt"
              label="Prompt"
              value={aiPrompt}
              InputProps={{
                inputComponent: TextareaAutosize,
                maxRows: 3,
                rows: 3,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              isMultiline
              isDisabled={true}
            />
          </FieldWrapper>
        )}

        <FieldWrapper title="Event Description" className="mb2">
          <ReactQuill
            data-cy="event-description"
            className={classnames(
              classes.eventDescription,
              isDisabled && classes.disabled,
            )}
            value={description}
            onChange={onDescriptionChanged}
            readOnly={isDisabled}
            modules={{
              magicUrl: true,
            }}
          />
        </FieldWrapper>

        <p className="color-shade-80 body bold">Privacy</p>
        <div className="flex flex-col md:flex-row gap-1">
          <div className="flex md:col-6 flex-col gap-_5">
            <p className="detail bold">Collect attendee emails?</p>
            <Select
              label="Email Settings"
              name="emailSettings"
              // @ts-ignore
              onChange={handleChange}
              options={emailSettingOptionsArray}
              value={emailSettings}
              isDisabled={isDisabled}
              isRequired
            />
          </div>
          <div className="flex flex-1 flex-col gap-_5">
            <p className="detail bold">Protect your event with a password?</p>
            <Password
              autoComplete="new-password"
              label="Password"
              onChange={handleChange}
              value={password}
              error={errors.password}
              isDisabled={isDisabled}
              inputProps={{ autoComplete: 'new-password' }}
            />
          </div>
        </div>
        <EventLogoForm
          event={eventData}
          logoUrl={logoUrl}
          isDisabled={isDisabled}
        />
        <EventFormFooter
          eventId={eventId}
          isDisabled={isDisabled}
          hasChanges={hasChanges}
          isMakingRequest={isPending(status)}
          onSubmit={onSubmit}
        />
      </div>
    </Form>
  );
}
