import { GetTrackingResponse } from '@eventmanager/types';
import { ChatLog } from '@gatherly/types';

import { apiRoutes } from '../../config';
import { humanizeShortHand } from '../date';
import http from './client';

export async function getTrackingByEventId(
  id: string,
): Promise<GetTrackingResponse> {
  const response = await http.get(`${apiRoutes.tracking}/${id}`);
  return transformTrackingData(response.data);
}

export function transformTrackingData(data: any): GetTrackingResponse {
  const tracking =
    data.tracking?.map(attendee => {
      return {
        ...attendee,
        ...(attendee.timeSpent && typeof attendee.timeSpent === 'number'
          ? {
              timeSpent: humanizeShortHand(attendee.timeSpent, ''),
            }
          : {}),
      };
    }) ?? [];

  const chat: ChatLog[] =
    data.chat?.map(log => ({
      name: log.Name,
      message: log.Message,
      type: log.Type,
      time: log.Time,
      userId: log.UserID,
    })) ?? [];

  return {
    tracking,
    chat,
    IS_DEV: false,
  };
}
