import { RootState } from '../store';

export function currentOverlay(state: RootState) {
  return state.ui.overlays[state.ui.overlays.length - 1];
}

export function additionalOverlaysList(state: RootState) {
  return state.ui.overlays.slice(0, state.ui.overlays.length - 1);
}

export const overlayById = (id: string) => (state: RootState) => {
  return state.ui.overlays.find(overlay => overlay.id === id);
};

export function isBulkEditing(state: RootState) {
  return state.ui.isActiveBulkEdit;
}
