import * as AWS from '@aws-sdk/client-lambda';

import { AWS_REGION, STAGE } from '../config';
import {
  DuplicateFloorParams,
  NewEventParams,
  NewFloorParams,
  UpdateEventParams,
} from '@eventmanager/types';
import {
  IFloorConfig,
  IGatherlyEvent,
  IGatherlyEventConfig,
  LinkConfig,
} from '@gatherly/types';
import {
  makeAddAnalyticsAction,
  makeAddBroadcastRecordingsAction,
  makeAddEventAction,
  makeAddFloorAction,
  makeAddTrackingAction,
  makeDeleteEventAction,
  makeRemoveFloorAction,
  makeSetUpcomingEventsAction,
} from '../store/eventsReducer';
import { setFulfilled, setPending, setRejected } from './statusActions';
import {
  trackEventsDeleted,
  trackFloorCreated,
  trackFloorDeleted,
  trackFloorDuplicated,
} from '../libs/trackingLib';

import { Api } from '../utils';
import { Auth } from 'aws-amplify';
import { makeSetUserAction } from '../store/accountReducer';

window.Buffer = window.Buffer || require('buffer').Buffer;

type CreateEventResponse = {
  eventId?: string;
  error?: string;
  isUrlTaken?: boolean;
};
export async function createEvent(
  dispatch,
  data: NewEventParams,
): Promise<CreateEventResponse> {
  setPending(dispatch, 'createEvent');
  try {
    let response: any = undefined;
    if (data.withAI && data.withAI === true) {
      response = await createEventWithAI(data);
    } else {
      response = await Api.createEvent(data);
    }

    if (!response.event || !response.user)
      throw new Error('Failed to create event');

    dispatch(makeAddEventAction(response.event));
    dispatch(makeSetUserAction(response.user));
    setFulfilled(dispatch, 'createEvent');
    return { eventId: response.event.eventId };
  } catch (err: any) {
    console.error(err?.response || err);
    setRejected(dispatch, 'createEvent');

    if (err?.response?.status === 409) {
      return {
        error:
          'Sorry, this event URL is already taken. Please choose another and try again.',
        isUrlTaken: true,
      };
    }

    return { error: 'Failed to create event' };
  }
}

async function createEventWithAI(data: NewEventParams) {
  const credentials = await Auth.currentCredentials();
  const currentSession = await Auth.currentSession();
  const client = new AWS.LambdaClient({
    region: AWS_REGION,
    credentials,
  });

  const params = {
    FunctionName:
      STAGE === 'prod' || STAGE === 'beta'
        ? `event-manager-api-${STAGE}-newEvent`
        : `em-api-${STAGE}-newEvent`,
    InvocationType: 'RequestResponse',
    LogType: 'Tail',
    Payload: Buffer.from(
      JSON.stringify({
        headers: {
          authorization: currentSession.getIdToken().getJwtToken(),
        },
        body: JSON.stringify(data),
      }),
      'utf-8',
    ),
  };
  const command = new AWS.InvokeCommand(params);
  const response = await client.send(command);

  if (response.StatusCode !== 200 || !response.Payload) {
    throw new Error('Failed to create event');
  }

  const result = JSON.parse(Buffer.from(response.Payload.buffer).toString());

  if (result.statusCode === 409) {
    // eslint-disable-next-line no-throw-literal
    throw {
      response: {
        status: 409,
      },
    };
  }

  if (result.statusCode !== 201) {
    throw new Error('Failed to create event');
  }

  return JSON.parse(result.body);
}

export async function updateEvent(
  dispatch,
  eventId: string,
  data: UpdateEventParams,
): Promise<boolean> {
  setPending(dispatch, 'updateEvent', eventId);
  try {
    const { event, user } = await Api.updateEvent(eventId, data);
    dispatch(makeAddEventAction(event));
    dispatch(makeSetUserAction(user));
    setFulfilled(dispatch, 'updateEvent', eventId);
    return true;
  } catch (err: any) {
    setRejected(dispatch, 'updateEvent', eventId);
    return false;
  }
}

export async function updateEventFloor(
  dispatch,
  eventId: string,
  floorId: string,
  data: Partial<IFloorConfig>,
): Promise<boolean> {
  try {
    setPending(dispatch, 'updateFloor', floorId);
    const response = await Api.updateFloor(eventId, floorId, data);
    dispatch(makeAddFloorAction(response));
    setFulfilled(dispatch, 'updateFloor', floorId);
    return true;
  } catch (err: any) {
    console.error(err);
    setRejected(dispatch, 'updateFloor', floorId);
    return false;
  }
}

export async function updateEventConfig(
  dispatch,
  event: IGatherlyEvent,
  data: Partial<IGatherlyEventConfig>,
): Promise<boolean> {
  return updateEvent(dispatch, event.eventId, {
    config: {
      ...event.config,
      ...data,
    },
  });
}

export async function createFloor(
  dispatch,
  eventId: string,
  data: NewFloorParams | DuplicateFloorParams,
): Promise<void | { error: string }> {
  setPending(dispatch, 'createFloor');
  try {
    const { event } = await Api.createFloor(eventId, data);
    dispatch(makeAddEventAction(event));

    if (data.hasOwnProperty('mapUrl')) {
      trackFloorDuplicated(event.config.floors.length);
    } else {
      trackFloorCreated(event.config.floors.length);
    }

    setFulfilled(dispatch, 'createFloor');
  } catch (err: any) {
    console.error(err);
    setRejected(dispatch, 'createFloor');
  }
}

export async function deleteFloor(
  dispatch,
  event: IGatherlyEvent,
  floorId: string,
  numFloors: number,
): Promise<boolean> {
  setPending(dispatch, 'deleteFloor', floorId);
  try {
    const floors: IFloorConfig[] = [];
    event.config.floors.forEach(floor => {
      if (floor.id === floorId) {
        return;
      }

      floors.push({
        ...floor,
      });
    });

    const agenda =
      event.config.agenda?.map(session => {
        if (session.mode?.type === 'map' && session.mode.floor === floorId) {
          return {
            ...session,
            mode: {
              ...session.mode,
              floor: undefined,
            },
          };
        }

        if (
          session.mode?.type === 'group' &&
          session.mode.floorId === floorId
        ) {
          return {
            ...session,
            mode: {
              ...session.mode,
              floorId: floors[0]?.id,
            },
          };
        }

        return {
          ...session,
        };
      }) ?? [];

    const updated = await updateEventConfig(dispatch, event, {
      agenda,
      floors,
    });

    if (!updated) {
      throw new Error('Failed to delete floor');
    }

    trackFloorDeleted(numFloors - 1);
    dispatch(makeRemoveFloorAction({ eventId: event.eventId, floorId }));
    setFulfilled(dispatch, 'deleteFloor', floorId);
    return true;
  } catch (err: any) {
    console.error(err);
    setRejected(dispatch, 'deleteFloor', floorId);
    return false;
  }
}

export async function deleteEvent(
  dispatch,
  eventId: string,
): Promise<void | { error: string }> {
  setPending(dispatch, 'deleteEvent', eventId);
  try {
    const { user } = await Api.deleteEvent(eventId);
    trackEventsDeleted({ numEvents: 1 });
    dispatch(makeSetUserAction(user));
    dispatch(makeDeleteEventAction(eventId));
    setFulfilled(dispatch, 'deleteEvent', eventId);
  } catch (err: any) {
    console.error(err);
    setRejected(dispatch, 'deleteEvent', eventId);
  }
}

export async function deleteEvents(
  dispatch,
  eventIds: string[],
): Promise<void | { error: string }> {
  setPending(dispatch, 'deleteEvent');
  try {
    const promises = eventIds.map(eventId => Api.deleteEvent(eventId));
    await Promise.all(promises);
    trackEventsDeleted({ numEvents: eventIds.length });
    eventIds.forEach(eventId => dispatch(makeDeleteEventAction(eventId)));
    setFulfilled(dispatch, 'deleteEvent');
  } catch (err: any) {
    console.error(err?.response || err);
    setRejected(dispatch, 'deleteEvent');
  }
}

export async function updateEventLinks(
  dispatch,
  eventId: string,
  links: Record<string, LinkConfig>,
): Promise<void | { error: string }> {
  setPending(dispatch, 'editEventLink');
  try {
    const { event } = await Api.updateEvent(eventId, { links });
    dispatch(makeAddEventAction(event));
    setFulfilled(dispatch, 'editEventLink');
  } catch (err: any) {
    console.error(err);
    setRejected(dispatch, 'editEventLink');
  }
}

export async function createEventLink(
  dispatch,
  event: IGatherlyEvent,
  link: LinkConfig,
): Promise<void | { error: string }> {
  const links = {
    ...event.links,
    [link.name]: link,
  };
  return updateEventLinks(dispatch, event.eventId, links);
}

export async function updateEventLink(
  dispatch,
  event: IGatherlyEvent,
  initialLinkName: string,
  link: LinkConfig,
): Promise<void | { error: string }> {
  const links = {
    ...event.links,
    [link.name]: link,
  };
  if (link.name !== initialLinkName) delete links[initialLinkName];
  return updateEventLinks(dispatch, event.eventId, links);
}

export async function deleteEventLink(
  dispatch,
  linkName: string,
  event?: IGatherlyEvent,
): Promise<void | { error: string }> {
  if (!event) {
    alert('Event not found. Please refresh and try again');
    return;
  }
  const links = { ...event.links };
  delete links[linkName];
  return updateEventLinks(dispatch, event.eventId, links);
}

export async function getEvent(dispatch, eventId: string) {
  setPending(dispatch, 'getEvent', eventId);
  try {
    const payload = await Api.getEventById(eventId);
    dispatch(makeAddEventAction(payload.event));
    if (payload.analytics)
      dispatch(makeAddAnalyticsAction(eventId, payload.analytics));
    setFulfilled(dispatch, 'getEvent', eventId);
  } catch (err: any) {
    console.error(err);
    setRejected(dispatch, 'getEvent', eventId);
  }
}

export async function getEventBroadcastRecordings(dispatch, eventId: string) {
  setPending(dispatch, 'getEventBroadcastRecordings', eventId);
  try {
    const payload = await Api.getBroadcastRecordingsByEventId(eventId);
    dispatch(
      makeAddBroadcastRecordingsAction(eventId, payload.broadcastRecordings),
    );
    setFulfilled(dispatch, 'getEventBroadcastRecordings', eventId);
  } catch (err: any) {
    console.error(err);
    setRejected(dispatch, 'getEventBroadcastRecordings', eventId);
  }
}

export async function downloadBroadcastRecording(
  dispatch,
  recordingId: string,
  url: string,
) {
  setPending(dispatch, 'downloadBroadcastRecording', recordingId);
  try {
    await Api.downloadFile(url, `${recordingId}.mp4`);
    setFulfilled(dispatch, 'downloadBroadcastRecording', recordingId);
  } catch (err: any) {
    setRejected(dispatch, 'downloadBroadcastRecording', recordingId);
  }
}

export async function getEventTracking(dispatch, eventId: string) {
  setPending(dispatch, 'getEventTracking', eventId);
  try {
    const payload = await Api.getTrackingByEventId(eventId);
    dispatch(makeAddTrackingAction(eventId, payload));
    setFulfilled(dispatch, 'getEventTracking', eventId);
  } catch (err: any) {
    setRejected(dispatch, 'getEventTracking', eventId);
    console.error(err);
  }
}

export async function fetchUpcomingEvents(
  dispatch,
  lastEvent?: IGatherlyEvent,
) {
  setPending(dispatch, 'getUpcomingEvents');
  try {
    const payload = await Api.getUpcomingEvents(
      lastEvent?.eventId,
      lastEvent?.startTime,
    );
    dispatch(makeSetUpcomingEventsAction(payload));
    setFulfilled(dispatch, 'getUpcomingEvents');
  } catch (err: any) {
    console.error(err);
    setRejected(dispatch, 'getUpcomingEvents');
  }
}
