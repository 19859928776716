import React, { useCallback, ReactElement } from 'react';
import {
  DragDropContext as BeautifulDragDropContext,
  Droppable as BeautifulDroppable,
  Draggable as BeautifulDraggable,
  DropResult,
} from 'react-beautiful-dnd';

import { reorder } from '../../utils';

type Props<T> = {
  className?: string;
  id: string;
  isDisabled?: boolean;
  items: DraggableItem<T>[];
  onReorder: (reorderedItems: DraggableItem<T>[]) => void;
  renderItem: (provided, snapshot, content: T, index: number) => ReactElement;
};

export type DraggableItem<T> = { id: string; content: T };

/* 
Sample Usage in parent component:

// 
const dummyData = {
  redFish: {
    id: '1abc1abc',
    size: 2,
    name: 'Polly Pocket',
  },
  blueFish: {
    id: '2def2def',
    size: 16,
    name: 'Waldo Odlaw',
  },
};

const [items, setItems] = useState(getDraggableItems(Object.values(dummyData), 'id', 'id'));

const onReorder = (reorderedItems) = {
  // custom logic, go crazy
  // make sure to update the items tho!
  setItems(reorderedItems);
}

const renderItem = (provided, snapshot, content, index) => {
    const data = dummyData[content];
    return (
      <div
        className="pb1"
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <ItemComponent data={data} index={index} />
      </div>
    );
  }

  <Draggable id="example" items={items} renderItem={renderItem} onReorder={onReorder} />
*/

export function getDraggableItems<T>(
  data: T[],
  idKey?: keyof T,
  contentKey?: keyof T,
) {
  if (idKey && contentKey) {
    return data.map(item => ({
      id: `item-${item[idKey]}`,
      content: item[contentKey],
    }));
  }

  return data.map(item => ({
    id: item,
    content: item,
  }));
}

export function DraggableList<T>({
  className,
  id,
  isDisabled,
  items,
  renderItem,
  onReorder,
}: Props<T>) {
  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (isDisabled) return;
      // dropped outside the list
      if (!result.destination) return;
      const _items = reorder(
        items,
        result.source.index,
        result.destination.index,
      );
      onReorder(_items);
    },
    [isDisabled, items, onReorder],
  );
  return (
    <BeautifulDragDropContext onDragEnd={onDragEnd} key={id}>
      <BeautifulDroppable droppableId={id}>
        {(provided /* snapshot */) => {
          return (
            <div
              className={className}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {items.map((item, index) => {
                return (
                  <BeautifulDraggable
                    isDragDisabled={isDisabled}
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                  >
                    {(provided, snapshot) =>
                      renderItem(provided, snapshot, item.content, index)
                    }
                  </BeautifulDraggable>
                );
              })}
              {provided.placeholder}
            </div>
          );
        }}
      </BeautifulDroppable>
    </BeautifulDragDropContext>
  );
}
