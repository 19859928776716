import {
  DEFAULT_BOOTH_BACKGROUND_URL,
  DEFAULT_GAME_BACKGROUND_URL,
  MAX_BOOTHS_PER_FLOOR,
  MAX_GAMES_PER_FLOOR,
} from '../config';
import {
  GameType,
  IBaseMeetingArea,
  IBoothMeetingArea,
  IGameMeetingArea,
  IPoint,
} from '@gatherly/types';
import { getBoothImageUrl, getGameLogoUrl } from './misc';
import { updateEventFloor, uploadMap } from '../actions';

import Resizer from 'react-image-file-resizer';
import { generate as shortUuid } from 'short-uuid';

export type FloorType = 'normal' | 'booth' | 'game';

const IMAGE_WIDTH = 788;
const IMAGE_HEIGHT = 492.5;
const TABLE_WIDTH = 217;
const TABLE_HEIGHT = 77;
const SEAT_OFFSET = 28;
const MAX_LOGO_WIDTH = 140;
const MAX_LOGO_HEIGHT = 40;
const MAP_WIDTH = 440;
const SCALE_UP = IMAGE_WIDTH / MAP_WIDTH;
const SCALE_DOWN = 1 / SCALE_UP;

export const resizeLogo = (file: File) =>
  new Promise<File>(resolve => {
    Resizer.imageFileResizer(
      file,
      MAX_LOGO_WIDTH,
      MAX_LOGO_HEIGHT,
      'PNG',
      100,
      0,
      uri => {
        resolve(uri as File);
      },
      'file',
    );
  });

export interface HelpDeskConfig {
  center: IPoint;
  radius: number;
  color: {
    r: number;
    g: number;
    b: number;
  };
}

interface SaveHelpDeskMapProps {
  backgroundUrl: string;
  config: HelpDeskConfig;
  eventId: string;
  floorId: string;
  dispatch: any;
  showAlert?: boolean;
}

export const saveHelpDeskMap = async ({
  backgroundUrl,
  config,
  dispatch,
  eventId,
  floorId,
  showAlert = true,
}: SaveHelpDeskMapProps) => {
  try {
    const canvas = document.createElement('CANVAS') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');

    if (!ctx) throw new Error('ctx cannot be empty');

    canvas.width = IMAGE_WIDTH;
    canvas.height = IMAGE_HEIGHT;

    const backgroundImage = await loadImage(backgroundUrl, true);

    ctx.drawImage(backgroundImage, 0, 0);
    ctx.beginPath();
    ctx.fillStyle = `rgba(${config.color.r}, ${config.color.g}, ${config.color.b}, 0.8)`;
    ctx.arc(
      config.center.x * SCALE_UP,
      config.center.y * SCALE_UP,
      config.radius * SCALE_UP,
      0,
      2 * Math.PI,
    );
    ctx.fill();

    ctx.strokeStyle = 'rgba(200, 201, 210, 1)';
    ctx.arc(
      config.center.x * SCALE_UP,
      config.center.y * SCALE_UP,
      config.radius * SCALE_UP,
      0,
      2 * Math.PI,
    );
    ctx.lineWidth = 2.8;
    ctx.stroke();
    const helpText = await loadImage(getBoothImageUrl('help.png'), true);
    ctx.drawImage(
      helpText,
      config.center.x * SCALE_UP - 25,
      config.center.y * SCALE_UP - 25,
    );

    const blob = await new Promise<Blob | null>(resolve =>
      canvas.toBlob(resolve),
    );

    if (!blob) {
      throw new Error('Error generating booth map');
    }

    const mapFile = new File([blob], 'boothMap.png', { type: 'image/png' });
    const { error: mapUploadError, mapUrl } = await uploadMap(
      dispatch,
      floorId,
      mapFile,
    );

    if (mapUploadError) {
      throw new Error(mapUploadError);
    }

    const success = await updateEventFloor(dispatch, eventId, floorId, {
      boothBackgroundUrl: '',
      gameBackgroundUrl: '',
      meetingAreas: [
        {
          id: shortUuid(),
          type: 'Help Desk',
          avatarPlaceHolder: {
            ...config.center,
          },
          displayName: 'Help Desk',
          shape: {
            center: config.center,
            radius: config.radius,
          },
          areaShape: 'Circle',
        },
      ],
      mapUrl,
    });

    if (!success) {
      if (showAlert) {
        alert('Error updating event floor, try again');
      }

      return false;
    }

    return true;
  } catch (error) {
    console.error(error);
    if (showAlert) {
      alert('Error updating event floor, try again');
    }
    return false;
  }
};

export interface CreateAreaMapProps {
  areas: IBoothMeetingArea[] | IGameMeetingArea[];
  floorType: FloorType;
  backgroundImageUrl: string;
}

const createAreaMap = async ({
  backgroundImageUrl,
  floorType,
  areas,
}: CreateAreaMapProps) => {
  const canvas = document.createElement('CANVAS') as HTMLCanvasElement;
  canvas.width = IMAGE_WIDTH;
  canvas.height = IMAGE_HEIGHT;

  const ctx = canvas.getContext('2d');

  if (!ctx) throw new Error('ctx cannot be empty');

  const defaultUrl =
    floorType === 'booth'
      ? DEFAULT_BOOTH_BACKGROUND_URL
      : DEFAULT_GAME_BACKGROUND_URL;

  const backgroundImage = await loadImage(
    backgroundImageUrl !== '' ? backgroundImageUrl : defaultUrl,
    true,
  );
  const boothImage = await loadImage(
    getBoothImageUrl(areas.length > 4 ? 'table.png' : 'booth.png'),
    true,
  );
  const avatarPlaceHolderImage = await loadImage(
    getBoothImageUrl('placeholder.png'),
    true,
  );
  const ratio = backgroundImage.width / backgroundImage.height;
  let newWidth = canvas.width;
  let newHeight = newWidth / ratio;
  if (newHeight < canvas.height) {
    newHeight = canvas.height;
    newWidth = newHeight * ratio;
  }
  const xOffset = newWidth > canvas.width ? (canvas.width - newWidth) / 2 : 0;
  const yOffset =
    newHeight > canvas.height ? (canvas.height - newHeight) / 2 : 0;
  ctx.drawImage(backgroundImage, xOffset, yOffset, newWidth, newHeight);

  for (const area of areas) {
    const scaledX = Math.floor(area.shape[0].x * SCALE_UP);
    const scaledY = Math.floor(area.shape[0].y * SCALE_UP);

    ctx.drawImage(
      boothImage,
      scaledX,
      scaledY - (areas.length > 4 ? 0 : SEAT_OFFSET),
    );

    if (
      (area.type === 'Booth' && (area as IBoothMeetingArea).companyLogo) ||
      area.type === 'Game'
    ) {
      const logoUrl =
        area.type === 'Booth'
          ? (area as IBoothMeetingArea).companyLogo
          : (area as IGameMeetingArea).gameLogo;

      const logo = await loadImage(logoUrl ?? '', true);

      ctx.drawImage(
        logo,
        scaledX +
          Math.round(
            ((area.avatarPlaceHolder.x + 2 - area.shape[0].x) * SCALE_UP -
              logo.width) /
              2,
          ),
        scaledY + Math.round((TABLE_HEIGHT - logo.height) / 2),
      );
    } else {
      ctx.font = '24px Lato';
      ctx.fillText(
        area.displayName.substring(0, 11),
        scaledX + 10,
        scaledY + 49,
        MAX_LOGO_WIDTH,
      );
    }

    ctx.drawImage(
      avatarPlaceHolderImage,
      Math.floor(area.avatarPlaceHolder.x * SCALE_UP),
      Math.floor(area.avatarPlaceHolder.y * SCALE_UP),
    );
  }

  const blob = await new Promise<Blob | null>(resolve =>
    canvas.toBlob(resolve),
  );

  if (!blob) {
    throw new Error('Error generating booth map');
  }

  return new File([blob], 'boothMap.png', { type: 'image/png' });
};

export function loadImage(image: string, cross = false) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      resolve(img);
    };

    img.onerror = (e: string | Event) => {
      console.error('photo:loadImage', e);

      reject('error while loading image');
    };

    /*
      HMTL Canvas requires all externally loaded images for be downloaded
      via a valid CORS policy for it to permit that image to be exportable
      as a PNG. Adding this query string to the image url prevents the browse
      from trying to use a cached version of the image which may have been
      downloaded under less strict conditions
    */
    img.crossOrigin = cross ? 'crossorigin' : 'anonymous';
    img.src = `${image}${cross ? `?${shortUuid()}` : ''}`;
  });
}

const getBoothConfig = (leftCorner: IPoint) => {
  const x = leftCorner.x;
  const y = leftCorner.y;

  return {
    avatarPlaceHolder: {
      x: x + 85,
      y: y + 9,
    },
    shape: [
      {
        x,
        y,
      },
      {
        x,
        y: y + Math.floor(TABLE_HEIGHT * SCALE_DOWN),
      },
      {
        x: x + Math.floor(TABLE_WIDTH * SCALE_DOWN),
        y: y + Math.floor(TABLE_HEIGHT * SCALE_DOWN),
      },
      {
        x: x + Math.floor(TABLE_WIDTH * SCALE_DOWN),
        y,
      },
      {
        x,
        y,
      },
    ],
  };
};

export const addBooth = (meetingAreas: IBoothMeetingArea[], title?: string) => {
  if (meetingAreas.length >= MAX_BOOTHS_PER_FLOOR) {
    return meetingAreas;
  }

  const areas: IBoothMeetingArea[] = [
    ...meetingAreas,
    {
      id: shortUuid(),
      displayName: title ?? `Booth ${meetingAreas.length + 1}`,
      type: 'Booth',
      companyLogo: '',
      avatarPlaceHolder: {
        x: 1,
        y: 1,
      },
      shape: [],
    },
  ];

  return updateAreaConfigs(areas);
};

export const addGame = (
  meetingAreas: IGameMeetingArea[],
  gameType: GameType = GameType.TicTacToe,
) => {
  if (meetingAreas.length >= MAX_GAMES_PER_FLOOR) {
    return meetingAreas;
  }

  const areas: IGameMeetingArea[] = [
    ...meetingAreas,
    {
      id: shortUuid(),
      displayName: GAME_DISPLAY_NAMES[gameType],
      type: 'Game',
      gameType: gameType,
      gameLogo: getGameLogoUrl(gameType),
      avatarPlaceHolder: {
        x: 1,
        y: 1,
      },
      shape: [],
    },
  ];

  return updateAreaConfigs(areas);
};

export const removeArea = (
  meetingAreas: IBoothMeetingArea[],
  areaId: string,
) => {
  meetingAreas = meetingAreas.filter(area => area.id !== areaId);
  return updateAreaConfigs(meetingAreas);
};

export const shiftArea = (
  meetingAreas: IBoothMeetingArea[],
  areaId: string,
  direction: 'up' | 'down',
) => {
  const areas = meetingAreas.map(area => {
    return {
      ...area,
    };
  });

  const areaIndex = areas.findIndex(area => area.id === areaId);

  if (areaIndex === -1) {
    return areas;
  }

  if (direction === 'up' && areaIndex > 0) {
    const temp = areas[areaIndex];
    areas[areaIndex] = areas[areaIndex - 1];
    areas[areaIndex - 1] = temp;
  }

  if (direction === 'down' && areaIndex < areas.length - 1) {
    const temp = areas[areaIndex];
    areas[areaIndex] = areas[areaIndex + 1];
    areas[areaIndex + 1] = temp;
  }

  return updateAreaConfigs(areas);
};

const updateAreaConfigs = (
  meetingAreas: IBoothMeetingArea[] | IGameMeetingArea[],
) => {
  const boothConfigs = BOOTH_CONFIGS[meetingAreas.length] as {
    avatarPlaceHolder: IPoint;
    shape: IPoint[];
  }[];

  return meetingAreas.map((area, index) => {
    return {
      ...area,
      avatarPlaceHolder: boothConfigs[index].avatarPlaceHolder,
      shape: boothConfigs[index].shape,
    };
  });
};

export const getBoothAreas = (areas?: IBaseMeetingArea[]) => {
  if (!areas) {
    return [];
  }

  return [...areas].filter(
    area => area.type === 'Booth',
  ) as IBoothMeetingArea[];
};

export const getGameAreas = (areas?: IBaseMeetingArea[]) => {
  if (!areas) {
    return [];
  }

  return [...areas].filter(area => area.type === 'Game') as IGameMeetingArea[];
};

interface SaveAreaMapProps {
  backgroundUrl?: string;
  areas: IBaseMeetingArea[];
  eventId: string;
  floorId: string;
  dispatch: any;
  floorType: FloorType;
  showAlert?: boolean;
}

export const saveMeetingAreaMap = async ({
  areas,
  eventId,
  floorId,
  backgroundUrl = '',
  floorType,
  dispatch,
  showAlert = true,
}: SaveAreaMapProps) => {
  try {
    const mapFile = await createAreaMap({
      backgroundImageUrl: backgroundUrl,
      floorType,
      areas: floorType === 'booth' ? getBoothAreas(areas) : getGameAreas(areas),
    });

    const { error, mapUrl } = await uploadMap(dispatch, floorId, mapFile);

    if (error) {
      if (showAlert) {
        alert(`Error saving ${floorType} map, try again`);
      }

      return false;
    }

    const success = await updateEventFloor(dispatch, eventId, floorId, {
      boothBackgroundUrl: floorType === 'booth' ? backgroundUrl : '',
      gameBackgroundUrl: floorType === 'game' ? backgroundUrl : '',
      meetingAreas: areas,
      mapUrl,
    });

    if (!success) {
      if (showAlert) {
        alert('Error updating event floor, try again');
      }

      return false;
    }

    return true;
  } catch (error) {
    if (showAlert) {
      alert('Error updating event floor, try again');
    }

    return false;
  }
};

const BOOTH_CONFIGS = {
  1: [
    getBoothConfig({
      x: 159,
      y: 100,
    }),
  ],
  2: [
    getBoothConfig({
      x: 54,
      y: 100,
    }),
    getBoothConfig({
      x: 273,
      y: 100,
    }),
  ],
  3: [
    getBoothConfig({
      x: 50,
      y: 61,
    }),
    getBoothConfig({
      x: 269,
      y: 61,
    }),
    getBoothConfig({
      x: 160,
      y: 163,
    }),
  ],
  4: [
    getBoothConfig({
      x: 50,
      y: 61,
    }),
    getBoothConfig({
      x: 269,
      y: 61,
    }),
    getBoothConfig({
      x: 50,
      y: 163,
    }),
    getBoothConfig({
      x: 269,
      y: 163,
    }),
  ],
  5: [
    getBoothConfig({
      x: 50,
      y: 35,
    }),
    getBoothConfig({
      x: 269,
      y: 35,
    }),
    getBoothConfig({
      x: 159.5,
      y: 98,
    }),
    getBoothConfig({
      x: 50,
      y: 161,
    }),
    getBoothConfig({
      x: 269,
      y: 161,
    }),
  ],
  6: [
    getBoothConfig({
      x: 50,
      y: 35,
    }),
    getBoothConfig({
      x: 269,
      y: 35,
    }),
    getBoothConfig({
      x: 50,
      y: 98,
    }),
    getBoothConfig({
      x: 269,
      y: 98,
    }),
    getBoothConfig({
      x: 50,
      y: 161,
    }),
    getBoothConfig({
      x: 269,
      y: 161,
    }),
  ],
  7: [
    getBoothConfig({
      x: 50,
      y: 20,
    }),
    getBoothConfig({
      x: 269,
      y: 20,
    }),
    getBoothConfig({
      x: 50,
      y: 73,
    }),
    getBoothConfig({
      x: 269,
      y: 73,
    }),
    getBoothConfig({
      x: 50,
      y: 126,
    }),
    getBoothConfig({
      x: 269,
      y: 126,
    }),
    getBoothConfig({
      x: 269,
      y: 179,
    }),
  ],
  8: [
    getBoothConfig({
      x: 50,
      y: 20,
    }),
    getBoothConfig({
      x: 269,
      y: 20,
    }),
    getBoothConfig({
      x: 50,
      y: 73,
    }),
    getBoothConfig({
      x: 269,
      y: 73,
    }),
    getBoothConfig({
      x: 50,
      y: 126,
    }),
    getBoothConfig({
      x: 269,
      y: 126,
    }),
    getBoothConfig({
      x: 50,
      y: 179,
    }),
    getBoothConfig({
      x: 269,
      y: 179,
    }),
  ],
};

export const GAME_DISPLAY_NAMES: Record<GameType, string> = {
  ConnectFour: 'Connect 4',
  Snake: 'Snake',
  TicTacToe: 'Tic Tac Toe',
  TwentyFortyEight: '2048',
};
