import { useCallback, useMemo } from 'react';
import { useLocation, useRoute } from 'wouter';

import {
  ANALYTICS_PROCESSING_TIME,
  MIN_EVENT_CAPACITY_FOR_ANALYTICS,
} from '../../config';
import { GatherlyEventServerStatus } from '../../enums';
import { useAppSelector } from '../../hooks';
import {
  currentUserPermissions,
  eventById,
  eventServerStatus,
} from '../../selectors';
import { c } from '../../utils';

import { FieldWrapper } from '../FieldWrapper';
import { Menu } from '../Menu';
import { Select, SelectOption } from '../Select';
import { Status } from '../Status';
import { AnalyticsReport } from './AnalyticsReport';
import { TrackingReport } from './TrackingReport';
import { ReportNotEnabled } from './ReportNotEnabled';
import { ReportNotReady } from './ReportNotReady';
import { TooSmallForAnalytics } from './TooSmallForAnalytics';

import classes from './PostEventReport.module.scss';
import { Pill } from '../Pill';
import { BoothReport } from './BoothReport';
import { classnames } from '@gatherly/lib';

type Props = {
  eventId: string;
};

export enum PostEventReportSection {
  ANALYTICS = 'event-analytics',
  TRACKING = 'tracking',
  BOOTH = 'booth-data',
}
export const PostEventReportSections = {
  [PostEventReportSection.ANALYTICS]: {
    id: PostEventReportSection.ANALYTICS,
    title: 'Event Analytics',
  },
  [PostEventReportSection.TRACKING]: {
    id: PostEventReportSection.TRACKING,
    title: 'Attendee Tracking',
  },
  [PostEventReportSection.BOOTH]: {
    id: PostEventReportSection.BOOTH,
    title: 'Booth Data',
  },
};
const sectionIds: PostEventReportSection[] = Object.values(
  PostEventReportSection,
);

export function PostEventReport({ eventId }: Props) {
  const { permissions } = useAppSelector(currentUserPermissions);
  const event = useAppSelector(eventById(eventId));
  if (!event) return null;
  const eventServerLaunchStatus = useAppSelector(eventServerStatus(eventId));

  const isEnabled = permissions.attendeeTracking || permissions.dataAnalytics;
  const isOver = eventServerLaunchStatus === GatherlyEventServerStatus.OVER;
  const reportReadyTime = event.stopTime + ANALYTICS_PROCESSING_TIME;
  const isStillProcessing = Date.now() < reportReadyTime;
  const isTooSmall = event.capacity <= MIN_EVENT_CAPACITY_FOR_ANALYTICS;

  const [, setLocation] = useLocation();
  const [, params] = useRoute('/events/:id/:tab/:section*');
  const sectionId = useMemo(() => {
    const [section, _] = (params?.section || '').split('/');
    return sectionIds.includes(section as PostEventReportSection)
      ? (section as PostEventReportSection)
      : PostEventReportSection.ANALYTICS;
  }, [params?.section]);

  const content = useMemo(() => {
    if (!isEnabled) return <ReportNotEnabled />;
    if (!isOver || isStillProcessing)
      return (
        <ReportNotReady inProgress={!isOver} readyTime={reportReadyTime} />
      );
    if (isTooSmall) return <TooSmallForAnalytics />;
    switch (sectionId) {
      case PostEventReportSection.TRACKING:
        return <TrackingReport eventId={eventId} />;
      case PostEventReportSection.ANALYTICS:
        return <AnalyticsReport eventId={eventId} />;
      case PostEventReportSection.BOOTH:
        return <BoothReport eventId={eventId} />;
      default:
        return <AnalyticsReport eventId={eventId} />;
    }
  }, [
    isEnabled,
    isOver,
    isTooSmall,
    sectionId,
    eventId,
    permissions?.attendeeTracking,
    permissions?.dataAnalytics,
  ]);

  const internalNavOptions = useMemo((): SelectOption<string>[] => {
    return Object.values(PostEventReportSections).map(({ id, title }) => {
      if (id === PostEventReportSection.BOOTH) {
        return {
          id,
          label: (
            <div className="flex flex-row items-center">
              <div className="flex-1">{title}</div>
              <Pill className="color-logo-blue bg-color-shade-10 ml_5">
                New
              </Pill>
            </div>
          ),
          value: id,
        };
      }

      return { id, label: title, value: id };
    });
  }, []);
  const onSelect = useCallback(
    evt => {
      setLocation(`/events/${eventId}/analytics/${evt.target.value}`);
    },
    [eventId],
  );

  return (
    <div
      className={classnames(
        classes.PostEventReport,
        'flex flex-col md:flex-row',
      )}
    >
      <div className="md:none p1">
        <FieldWrapper
          className="flex border-b1 border-color-shade-20 pb1_5"
          title="View analytics..."
        >
          <Select
            className="flex-1"
            name="section"
            value={sectionId}
            label="Section"
            options={internalNavOptions}
            onChange={onSelect}
          />
        </FieldWrapper>
      </div>
      <div className={c(classes.internalNav, 'none md:flex flex-col mt6')}>
        <Menu
          baseUrl={`/events/${eventId}/analytics/`}
          options={internalNavOptions}
          selectedId={sectionId}
        />
      </div>
      <div className={c(classes.wrapper, 'flex flex-col flex-1')}>
        {permissions ? (
          <div className="flex flex-1 flex-col p2 md:px4 md:pt6">{content}</div>
        ) : (
          <Status message="Loading..." />
        )}
      </div>
    </div>
  );
}
