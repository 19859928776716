import { EventAnalytics } from '@eventmanager/types';
import { useMemo } from 'react';
import { HOUR } from '../../config';
import { humanizeShortHand } from '../../utils';
import { PieChart } from '../Chart';
import { ChoroplethMap } from '../Chart/ChoroplethMap';
import EventStatsCard from './EventStatsCard';
import classes from './AnalyticsEngagement.module.scss';
import { PublicChatReport } from './PublicChatReport';

type Props = {
  analytics: EventAnalytics;
  eventName: string;
  eventId: string;
};

export function AnalyticsEngagement({ analytics, eventName, eventId }: Props) {
  const avgDuration = useMemo(() => {
    return humanizeShortHand(analytics.AvgDuration * HOUR);
  }, [analytics]);
  const photosTaken = useMemo(() => {
    if (!analytics.PhotosTaken) {
      return [0, 0];
    }

    return [analytics.PhotosTaken.selfie, analytics.PhotosTaken.group];
  }, [analytics]);

  const totalConversations =
    (analytics.NumBoothMeetings ?? 0) + (analytics.NumHuddles ?? 0);

  return (
    <>
      <div className="heading bold">Engagement</div>
      <div data-cy={AnalyticsEngagement.displayName} className="flex flex-col">
        <div className="flex flex-wrap flex-row gap-1">
          <EventStatsCard
            title={'Max Concurrent Attendees'}
            className={classes.smallCard}
            body={
              <div
                data-cy={'max-attendees'}
                className="heading bold color-shade-80"
              >
                {analytics.MaxOccupancy}
              </div>
            }
          />
          <EventStatsCard
            title={'Average Attendance Duration'}
            className={classes.smallCard}
            body={
              <div
                data-cy={'average-duration'}
                className="heading bold color-shade-80"
              >
                {avgDuration}
              </div>
            }
          />
          <EventStatsCard
            title={'Total Conversations'}
            className={classes.smallCard}
            body={
              <div
                data-cy={'total-conversations'}
                className="heading bold color-shade-80"
              >
                {totalConversations}
              </div>
            }
          />
        </div>

        <div className="flex flex-wrap flex-row gap-1">
          <EventStatsCard
            title={'Attendee Location'}
            className={classes.map}
            body={<ChoroplethMap data={analytics.Location ?? {}} />}
          />
          <EventStatsCard
            title={'Photo Booth'}
            className={classes.photoBooth}
            body={
              <div className={classes.pieChartWrapper}>
                <PieChart
                  className={classes.pieChart}
                  data={photosTaken}
                  labels={['Selfie', 'Group']}
                />
              </div>
            }
          />
        </div>

        <div className="flex flex-wrap flex-row gap-1">
          <EventStatsCard
            title={'Total public chat messages'}
            className={classes.map}
            body={
              <div data-cy={'total-public-chat-messages'}>
                <PublicChatReport eventId={eventId} eventName={eventName} />
              </div>
            }
          />
        </div>
      </div>
    </>
  );
}

AnalyticsEngagement.displayName = 'AnalyticsEngagement';
