import React, { useCallback, useMemo } from 'react';
import { useLocation, useRoute } from 'wouter';

import { BroadcastRecordings } from '../../components/BroadcastRecordings';
import { FieldWrapper } from '../../components/FieldWrapper';
import { Menu } from '../../components/Menu';
import { Select, SelectOption } from '../../components/Select';
import { c } from '../../utils';
import classes from './EventMedia.module.scss';

type Props = {
  eventId: string;
};

export enum EventMediaSection {
  BROADCAST_RECORDINGS = 'broadcast-recordings',
}
export const EventMediaSections = {
  [EventMediaSection.BROADCAST_RECORDINGS]: {
    id: EventMediaSection.BROADCAST_RECORDINGS,
    title: 'Broadcast Recordings',
  },
};
const sectionIds: EventMediaSection[] = Object.values(EventMediaSection);

export function EventMedia({ eventId }: Props) {
  const [, setLocation] = useLocation();
  const [, params] = useRoute('/events/:id/:tab*');

  const sectionId = useMemo(() => {
    const [_, section] = (params?.tab || '').split('/');
    return sectionIds.includes(section as EventMediaSection)
      ? (section as EventMediaSection)
      : EventMediaSection.BROADCAST_RECORDINGS;
  }, [params?.tab]);

  const internalNavOptions = useMemo((): SelectOption<string>[] => {
    return Object.values(EventMediaSections).map(({ id, title }) => ({
      id,
      label: title,
      value: id,
    }));
  }, []);
  const onSelect = useCallback(
    evt => {
      setLocation(`/events/${eventId}/media/${evt.target.value}`);
    },
    [eventId],
  );

  const content = useMemo(() => {
    switch (sectionId) {
      case EventMediaSection.BROADCAST_RECORDINGS:
      default:
        return <BroadcastRecordings eventId={eventId} />;
    }
  }, [sectionId]);

  return (
    <div className={c(classes.EventMedia, 'flex flex-col md:flex-row')}>
      <div className="md:none p1">
        <FieldWrapper
          className="flex border-b1 border-color-shade-20 pb1_5"
          title="View media..."
        >
          <Select
            className="flex-1"
            name="section"
            value={sectionId}
            label="Section"
            options={internalNavOptions}
            onChange={onSelect}
          />
        </FieldWrapper>
      </div>
      <div className={c(classes.internalNav, 'none md:flex flex-col mt6')}>
        <Menu
          baseUrl={`/events/${eventId}/media/`}
          options={internalNavOptions}
          selectedId={sectionId}
        />
      </div>
      <div className={c(classes.wrapper, 'flex flex-col flex-1')}>
        <div className="flex flex-1 flex-col p2 md:px4 md:pt6">{content}</div>
      </div>
    </div>
  );
}
