const STAGE = process.env.REACT_APP_STAGE;
export const ADMIN_FEATURES_LIST_URL =
  'https://help.gatherly.io/en/articles/6289159-what-can-admins-on-gatherly-do';
export const BROADCAST_RECORDINGS_HELP_URL =
  'https://help.gatherly.io/en/articles/4995484-how-can-i-record-a-broadcast-on-gatherly';
export const DEFAULT_BROCHURE_URL =
  'https://poormanvr.s3.us-east-2.amazonaws.com/brochure-pdf/default-brochure.pdf';
export const DEFAULT_MAP_URL =
  'https://poormanvr.s3.us-east-2.amazonaws.com/betterDMV-maps/_Blank.jpeg';
export const LANDING_PAGE_INSTRUCTIONS_URL =
  'https://help.gatherly.io/en/articles/4822406-customizing-your-gatherly-event-landing-page';
export const MAP_TEMPLATE_URL =
  'https://docs.google.com/presentation/u/3/d/11c209mkLwMSKfW-OOQ6JpY7YuIjn0HQmUynEnNRSSOQ/copy#slide=id.g9072c99268_6_0';
export const SALES_CONTACT_URL = 'https://www.gatherly.io/pricing';

export const SALES_PLAN_UPGRADE_URL = 'https://www.gatherly.io/subscribe';

const defaultBucket =
  STAGE === 'prod' ? 's3betterdmv53458-production' : 's3betterdmv235349-dev';
export const UGC_S3_BUCKET = process.env.UGC_S3_BUCKET || defaultBucket;
export const UGC_S3_BUCKET_URL = `https://${UGC_S3_BUCKET}.s3.us-east-2.amazonaws.com/`;

export const BANNERS_BASEURL =
  'https://poormanvr.s3.us-east-2.amazonaws.com/betterDMV-banners/';
export const MAP_BASEURL =
  'https://poormanvr.s3.us-east-2.amazonaws.com/betterDMV-maps/';
export const CUSTOMER_SERVICES_AGREEMENT_URL =
  'https://www.gatherly.io/legal/customer-services-agreement';
export const WORLD_MAP_URL =
  'https://poormanvr.s3.us-east-2.amazonaws.com/location/world-countries.json';
export const BOOTHS_HELP_URL =
  'https://help.gatherly.io/en/articles/6520575-how-to-set-up-booths-at-your-gatherly-event';
export const DEFAULT_BOOTH_BACKGROUND_URL =
  'https://poormanvr.s3.us-east-2.amazonaws.com/betterDMV-booth-backgrounds/1.png';
export const DEFAULT_GAME_BACKGROUND_URL =
  'https://poormanvr.s3.us-east-2.amazonaws.com/betterDMV-game-backgrounds/_Ping+Pong.png';
export const BOOTH_BACKGROUND_BASEURL =
  'https://poormanvr.s3.us-east-2.amazonaws.com/betterDMV-booth-backgrounds/';
export const GAME_BACKGROUND_BASEURL =
  'https://poormanvr.s3.us-east-2.amazonaws.com/betterDMV-game-backgrounds/';
export const GAME_LOGO_BASEURL =
  'https://poormanvr.s3.us-east-2.amazonaws.com/betterDMV-game-logos/';
