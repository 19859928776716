import ReactQuill from 'react-quill';
import { useMeasure } from 'react-use';
import { DragIndicator } from '@material-ui/icons';
import { IFloorConfig } from '@gatherly/types';
import { Clickable, ClickableVariant } from '../../../components/Clickable';
import { Image } from '../../../components/Image';
import { DEFAULT_MAP_URL } from '../../../config';
import { c, FormField } from '../../../utils';
import classes from './FloorCard.module.scss';
import { classnames } from '@gatherly/lib';

export type FloorField = {
  name: FormField<string>;
};

type Props = {
  index: number;
  isDisabled: boolean;
  canDelete: boolean;
  floor: IFloorConfig | null;
  eventDescription?: string;
  onDelete: () => void;
  onDuplicate: () => void;
  onEdit: () => void;
};

export function FloorCard({
  floor,
  index,
  eventDescription,
  isDisabled,
  onDelete,
  onDuplicate,
  canDelete,
  onEdit,
}: Props) {
  const [ref, { height: mapPreviewHeight }] = useMeasure();

  return (
    <div
      data-cy="floor-card"
      className={c(
        classes.FloorCard,
        'flex flex-col border-1 border-color-shade-20 br_25 bg-color-white',
        'shadow-sm hover:shadow-md transition-shadow overflow-hidden',
      )}
      key={floor?.id}
    >
      <div className="flex items-start gap-1 md:mb_5">
        <div className="flex gap-_5 m_5">
          <span
            className={c(
              classes.number,
              'flex flex-col items-center justify-center',
              'color-shade-40 bold detail',
              'border-1 border-color-shade-40 bg-color-butter circle',
            )}
          >
            {index + 1}
          </span>
        </div>

        <div className="flex-1 flex m_5 gap-_5 justify-end">
          <Clickable
            className="flex items-center justify-center bg-color-shade-10"
            variant={ClickableVariant.BUTTON_SMALL}
            onClick={onEdit}
            isDisabled={isDisabled}
            data-cy="edit-floor"
            label="Edit"
          />
          <Clickable
            className="flex items-center justify-center bg-color-shade-10"
            variant={ClickableVariant.BUTTON_SMALL}
            onClick={onDuplicate}
            isDisabled={isDisabled}
            label="Duplicate"
          />
          <Clickable
            className="flex items-center justify-center bg-color-blush"
            variant={ClickableVariant.BUTTON_SMALL}
            onClick={onDelete}
            data-cy="delete-floor"
            isDisabled={isDisabled || !canDelete}
            label="Delete"
          />
          <DragIndicator />
        </div>
      </div>

      <div className="flex flex-col md:flex-row px1 md:px2 pb1 md:pb2 gap-1">
        <div
          ref={ref as any}
          className={classnames(
            'flex flex-col gap-_5 flex-2',
            classes.mapPreview,
          )}
        >
          <div className="flex-col gap-_5">
            <p className="detail color-shade-50">Name</p>
            <h4 className="heading bold">{floor?.name}</h4>
          </div>
          <p className="detail color-shade-50">Map</p>
          <div
            className={c(
              classes.mapPreviewWrapper,
              classes.mapPreviewWrapperOverlay,
              'overflow-hidden',
            )}
          >
            <div
              className={c(
                classes.mapPreviewContent,
                'bg-color-shade-10 flex flex-col',
              )}
            >
              <div className="relative flex-1">
                <Image
                  src={floor?.mapUrl || DEFAULT_MAP_URL}
                  alt={`Map for ${floor?.name || 'floor'}`}
                  className="absolute-center"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ maxHeight: mapPreviewHeight }}
          className="flex-1 flex flex-col gap-1 md:pl1"
        >
          <div className="flex flex-1 flex-col items-start gap-_5">
            <p className="detail color-shade-50">
              {floor?.embeddedContentUrl ? ' Embedded Content ' : 'Brochure'}
            </p>
            <div
              className={c(
                classes.brochurePreview,
                'border-1 border-color-shade-20',
              )}
            >
              {!floor?.brochureUrl &&
                !floor?.embeddedContentUrl &&
                eventDescription && (
                  <ReactQuill
                    className={classes.eventDescription}
                    style={{ maxHeight: mapPreviewHeight - 22 - 8 }}
                    value={eventDescription}
                    readOnly={true}
                    modules={{
                      toolbar: false,
                    }}
                  />
                )}
              {floor?.brochureUrl && !floor.embeddedContentUrl && (
                <iframe
                  title="brochure"
                  data-cy="floor-brochure"
                  src={`${floor?.brochureUrl}#toolbar=0&navpanes=0`}
                />
              )}
              {floor?.embeddedContentUrl && (
                <iframe
                  title="embeddedContent"
                  data-cy="floor-embedded-content"
                  src={floor.embeddedContentUrl}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
