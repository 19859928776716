import React, { useCallback, useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import { BroadcastRecording } from '@eventmanager/types';

import {
  downloadBroadcastRecording,
  setOverlay,
  OverlayType,
} from '../../actions';
import { Image } from '../../components/Image';
import { useAppDispatch } from '../../hooks';
import { ICON_ARROW_DOWN, ICON_ARROW_UP } from '../../images';
import { c } from '../../utils';
import {
  BroadcastRecordingsTableRow,
  TableRow,
} from './BroadcastRecordingsTableRow';
import classes from './BroadcastRecordingsTable.module.scss';
import { IFloorConfig } from '@gatherly/types';

type Props = {
  broadcastRecordingsMap: Record<string, BroadcastRecording>;
  eventId: string;
  floorsMap: Record<string, IFloorConfig>;
  hasPermission: boolean;
};

export function BroadcastRecordingsTable({
  broadcastRecordingsMap,
  eventId,
  floorsMap,
  hasPermission,
}: Props) {
  const dispatch = useAppDispatch();
  const handlePlay = useCallback(recordingId => {
    setOverlay(dispatch, OverlayType.BROADCAST_RECORDING_PLAYER, {
      eventId,
      recordingId,
    });
  }, []);
  const handleDownload = useCallback(
    recordingId => {
      if (!hasPermission) return;
      const url = broadcastRecordingsMap[recordingId].url;
      downloadBroadcastRecording(dispatch, recordingId, url);
    },
    [broadcastRecordingsMap, hasPermission, dispatch],
  );

  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Duration',
        accessor: 'durationMillis',
      },
      {
        Header: 'Floor',
        accessor: 'floor',
      },
      {
        Header: 'Time',
        accessor: 'startTime',
      },
      {
        Header: '',
        accessor: 'recordingId',
        canSort: false,
      },
    ];
  }, []);

  const data = useMemo(() => {
    return Object.values(broadcastRecordingsMap)
      .reduce(
        (
          memo,
          { name, floorId = '', startTime = 0, stopTime = 0, recordingId },
        ) => {
          const row: TableRow = {
            name,
            durationMillis: stopTime ? stopTime - startTime : 0,
            floor: floorsMap[floorId]?.name || '',
            startTime,
            recordingId,
          };

          return memo.concat(row);
        },
        [] as TableRow[],
      )
      .sort(({ startTime: startTimeA }, { startTime: startTimeB }) => {
        return startTimeA > startTimeB ? -1 : 1;
      });
  }, [broadcastRecordingsMap, floorsMap]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <div
      className={c(
        classes.BroadcastRecordingsTable,
        'flex flex-col border-1 border-color-shade-20 px1_25 scroll-y',
      )}
    >
      <table
        {...getTableProps()}
        className={c(
          classes.table,
          'text-left body color-shade-80 overflow-ellipsis',
        )}
      >
        <thead className="bold">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                return (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="flex gap-_25 items-center">
                      {column.Header}
                      {column.id !== 'recordingId' && (
                        <div className={classes.icon}>
                          {column.isSorted && (
                            <Image
                              src={
                                column.isSortedDesc
                                  ? ICON_ARROW_DOWN.src
                                  : ICON_ARROW_UP.src
                              }
                              alt={
                                column.isSortedDesc
                                  ? 'sort ascending'
                                  : 'sort descending'
                              }
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.length === 0 && (
            <tr className="border-t1 border-color-shade-20">
              <td className="text-center color-shade-60" colSpan={5}>
                No recordings yet.
              </td>
            </tr>
          )}
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <BroadcastRecordingsTableRow
                durationMillis={row.values.durationMillis}
                floor={row.values.floor}
                handleDownload={() => handleDownload(row.values.recordingId)}
                handlePlay={() => handlePlay(row.values.recordingId)}
                hasPermission={hasPermission}
                index={index}
                key={row.values.recordingId}
                name={row.values.name}
                recordingId={row.values.recordingId}
                startTime={row.values.startTime}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
