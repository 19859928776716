import icon from './assets/icon.png';
import availableTickets from './assets/available-tickets.svg';
import reservedTickets from './assets/reserved-tickets.svg';
import attendee from './assets/attendee.png';
import emailSales from './assets/email-sales.svg';
import fourAttendees from './assets/four-attendees.svg';
import iconArrowDown from './assets/icon-arrow-down.svg';
import iconArrowUp from './assets/icon-arrow-up.svg';
import inactiveAnalytics from './assets/inactive-analytics.svg';
import resources from './assets/resources.svg';
import tickets from './assets/tickets.svg';
import planFree from './assets/free-plan.svg';
import planPro from './assets/pro-plan.svg';
import planEnterprise from './assets/enterprise-plan.svg';
import planStaff from './assets/staff-plan.svg';
import planStarter from './assets/starter-plan.svg';
import planTickets from './assets/tickets-plan.svg';
import logo from './assets/logo.svg';
import logoTooltip from './assets/logo-tooltip.png';
import defaultAvatar from './assets/avatar.svg';
import availableAiTokens from './assets/available-ai-tokens.svg';
import logoSparkle from './assets/logo_sparkle.gif';

import { SALES_EMAIL } from './config';

export type ImageData = {
  src: string;
  alt: string;
};

export const AVAILABLE_AI_TOKENS = {
  src: availableAiTokens,
  alt: 'Available AI Tokens',
};

export const AVAILABLE_TICKETS = {
  src: availableTickets,
  alt: 'Available Tickets',
};
export const ATTENDEE = {
  src: attendee,
  alt: 'Attendee',
};
export const EMAIL_SALES = {
  src: emailSales,
  alt: `Email Sales: ${SALES_EMAIL}`,
};
export const FOUR_ATTENDEES = {
  src: fourAttendees,
  alt: 'An illustration showing four people on a Gatherly video call',
};
export const GATHERLY_LOGO = {
  src: logo,
  alt: 'Gatherly logo',
};
export const GATHERLY_LOGO_SPARKLE = {
  src: logoSparkle,
  alt: 'Gatherly logo sparkle',
};
export const GATHERLY_ICON = {
  src: icon,
  alt: 'Gatherly Icon',
};
export const ICON_ARROW_DOWN = {
  src: iconArrowDown,
  alt: 'arrow pointing down',
};
export const ICON_ARROW_UP = {
  src: iconArrowUp,
  alt: 'arrow pointing up',
};
export const INACTIVE_ANALYTICS = {
  src: inactiveAnalytics,
  alt: 'A grayed out illustration of some graphs',
};
export const LOGO_TOOLTIP = {
  src: logoTooltip,
  alt: 'Logo Tooltip',
};
export const MAP_BREAK_ROOM = {
  src: 'https://s3betterdmv53458-production.s3.us-east-2.amazonaws.com/public/map-break-room.svg',
  alt: 'An aerial map of an office break room with tables, couches, plants and a water cooler',
};
export const MAP_PARTY = {
  src: 'https://s3betterdmv53458-production.s3.us-east-2.amazonaws.com/public/map-party.svg',
  alt: 'An aerial map of an office break room with tables, couches, plants and a water cooler',
};
export const MAP_POOL = {
  src: 'https://s3betterdmv53458-production.s3.us-east-2.amazonaws.com/public/map-pool.svg',
  alt: 'An aerial map of an outdoor pool area with lounge chairs, floaties and sparkly blue water',
};
export const MAP_WELCOME = {
  src: 'https://s3betterdmv53458-production.s3.us-east-2.amazonaws.com/public/map-welcome.svg',
  alt: 'An aerial map with a help desk and large yellow rug with the word "Welcome!"',
};
export const PLAN_FREE = {
  src: planFree,
  alt: 'Free Plan',
};
export const PLAN_TICKETS = {
  src: planTickets,
  alt: 'Tickets Plan',
};
export const PLAN_STARTER = {
  src: planStarter,
  alt: 'Starter Plan',
};
export const PLAN_PRO = {
  src: planPro,
  alt: 'Pro Plan',
};
export const PLAN_ENTERPRISE = {
  src: planEnterprise,
  alt: 'Enterprise Plan',
};
export const PLAN_STAFF = {
  src: planStaff,
  alt: 'Staff Plan',
};
export const RESERVED_TICKETS = {
  src: reservedTickets,
  alt: 'Reserved Tickets',
};
export const RESOURCES = {
  src: resources,
  alt: 'Resources',
};
export const TICKETS = {
  src: tickets,
  alt: 'Tickets',
};
export const DEFAULT_AVATAR = {
  src: defaultAvatar,
  alt: 'Default Avatar',
};

export const Images = {
  ATTENDEE,
  AVAILABLE_TICKETS,
  DEFAULT_AVATAR,
  EMAIL_SALES,
  FOUR_ATTENDEES,
  GATHERLY_ICON,
  GATHERLY_LOGO,
  ICON_ARROW_DOWN,
  ICON_ARROW_UP,
  INACTIVE_ANALYTICS,
  LOGO_TOOLTIP,
  MAP_BREAK_ROOM,
  MAP_PARTY,
  MAP_POOL,
  MAP_WELCOME,
  PLAN_ENTERPRISE,
  PLAN_FREE,
  PLAN_PRO,
  PLAN_STAFF,
  PLAN_STARTER,
  PLAN_TICKETS,
  RESERVED_TICKETS,
  RESOURCES,
  TICKETS,
};
