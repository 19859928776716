import { useEffect, useMemo } from 'react';
import { getEventTracking } from '../../actions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  eventById,
  eventTracking,
  getStatusOfGetEventTracking,
} from '../../selectors';
import { isFulfilled, isPending, isRejected } from '../../utils';

import { TrackingTable } from './TrackingTable';

type Props = {
  eventId: string;
  boothAttendees: {
    timeSpent: number;
    id: string;
  }[];
};

const BASE_COLUMNS = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'timeSpent',
    label: 'Time at Booth',
  },
  {
    key: 'title',
    label: 'Job Title',
  },
  {
    key: 'link',
    label: 'Link',
  },
];

const EMAIL_ENABLED_COLUMNS = BASE_COLUMNS.slice(0, 2)
  .concat({
    key: 'email',
    label: 'Email',
  })
  .concat(BASE_COLUMNS.slice(2, BASE_COLUMNS.length));

export const BoothAttendeesReport = ({ boothAttendees, eventId }: Props) => {
  const event = useAppSelector(eventById(eventId));
  const tracking = useAppSelector(eventTracking(eventId));
  const status = useAppSelector(getStatusOfGetEventTracking(eventId));

  const dispatch = useAppDispatch();
  useEffect(() => {
    getEventTracking(dispatch, eventId);
  }, [eventId]);

  const emailEnabled = event?.config.emailSettings !== 'disabled';

  const data = useMemo(() => {
    return boothAttendees.map(attendee => {
      const attendeeTracking = tracking.find(
        user => user['id'] === attendee.id,
      );
      return {
        ...(attendeeTracking && {
          name: attendeeTracking.name,
          ...(emailEnabled && { email: attendeeTracking.email }),
          title: attendeeTracking.title,
          link: attendeeTracking.link,
        }),
        timeSpent: attendee.timeSpent,
      };
    });
  }, [boothAttendees, tracking]);

  return (
    <TrackingTable
      columns={emailEnabled ? EMAIL_ENABLED_COLUMNS : BASE_COLUMNS}
      failedToLoad={!tracking.length && isRejected(status)}
      filterField={'name'}
      loaded={isFulfilled(status)}
      eventName={event?.name}
      loading={!tracking.length && isPending(status)}
      data={data}
      title={'Booth Attendees'}
    />
  );
};
