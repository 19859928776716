import {
  AccountsUser,
  AclPermissionsRole,
  NewUserParams,
  NewUserResponse,
  UpdateUserParams,
} from '@eventmanager/types';

import { apiRoutes } from '../../config';
import http from './client';

/** Still needed for user-less accounts https://github.com/Gatherly/event-manager/pull/96  */
export async function createUser(
  data: NewUserParams,
): Promise<NewUserResponse> {
  const response = await http.post(apiRoutes.users, data);
  return response.data || null;
}
export async function getUserById(id: string): Promise<AccountsUser | null> {
  const response = await http.get(`${apiRoutes.users}/${id}`);
  return response.data || null;
}
export async function getCurrentUser(): Promise<AccountsUser | null> {
  const response = await http.get(`${apiRoutes.users}/current`);
  return response.data || null;
}
export async function updateCurrentUser(
  data: UpdateUserParams,
): Promise<AccountsUser> {
  const response = await http.put(`${apiRoutes.users}/current`, data);
  return response.data || null;
}
export async function downGradeUserRole(
  role: AclPermissionsRole,
): Promise<AccountsUser> {
  const response = await http.put(
    `${apiRoutes.users}/current/downgrade-user-role`,
    { role },
  );
  return response.data || null;
}
