import React, { useCallback, useState, SyntheticEvent } from 'react';

import { Images } from '../../images';
import { Clickable, ClickableVariant } from '../Clickable';
import { Image } from '../Image';
import { Input, Password } from '../Input';
import { signIn } from '../../actions/accountActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getStatusOfSignIn } from '../../selectors';
import {
  c,
  getFormField,
  getOnChangeFormField,
  isPending,
  isRejected,
  validateString,
  validateEmailAddress,
} from '../../utils';
import classes from './SignInForm.module.scss';
import { Form } from './Form';
import { GoogleSignIn } from '../GoogleSignIn/GoogleSignIn';
import { Divider } from '@material-ui/core';

export function SignInForm() {
  const dispatch = useAppDispatch();

  const status = useAppSelector(getStatusOfSignIn);
  const isMakingRequest = isPending(status);
  const hasError = isRejected(status);
  const [formError, setFormError] = useState('');

  const [email, setEmail] = useState(getFormField(''));
  const [password, setPassword] = useState(getFormField(''));

  const onChangeEmail = useCallback(
    getOnChangeFormField(validateEmailAddress, setEmail),
    [],
  );
  const onChangePassword = useCallback(
    getOnChangeFormField(
      value => validateString(value, 'Password'),
      setPassword,
    ),
    [],
  );

  async function onSubmit(evt: SyntheticEvent) {
    evt.preventDefault();
    if (!email.value || !password.value) {
      setFormError('Enter email and password continue');
    } else {
      setFormError('');
      signIn(dispatch, email.value, password.value);
    }
  }

  return (
    <div
      className={c(
        classes.SignInForm,
        'flex flex-col bg-color-white bo-1 border-color-shade-30 shadow-md pt1_25 pb2 px1_5',
      )}
    >
      <div className="flex justify-end">
        <Image
          src={Images.GATHERLY_LOGO.src}
          alt={Images.GATHERLY_LOGO.alt}
          className={classes.logo}
        />
      </div>
      <h2 className="subtitle mt_5 mb1_5">Sign in</h2>
      <div className="flex flex-col mb1 align-center justify-center">
        <GoogleSignIn className="mb1" />
        <Divider />
      </div>
      <Form className="flex flex-col gap-1" onSubmit={onSubmit}>
        <Input
          data-cy="email"
          autoComplete="email"
          label="Email Address"
          name="email"
          onChange={evt => onChangeEmail(evt.target.value)}
          value={email.value}
          error={email.error}
          isDisabled={isMakingRequest}
          isRequired
        />
        <Password
          data-cy="password"
          autoComplete="current-password"
          label="Password"
          name="password"
          onChange={evt => onChangePassword(evt.target.value)}
          value={password.value}
          error={password.error}
          isDisabled={isMakingRequest}
          isRequired
        />
        <Clickable
          data-cy="sign-in-submit"
          className="mt_75"
          isSubmit
          isDisabled={isMakingRequest}
          label={isMakingRequest ? 'SIGNING IN...' : 'SUBMIT'}
          variant={ClickableVariant.BUTTON_PRIMARY}
        />
      </Form>
      <div className="flex flex-1 flex-col gap-1 items-center pt1">
        {hasError && (
          <p className="color-red small text-center">
            Unable to log in. Please check your <br /> username &amp; password
            and try again.
          </p>
        )}
        {formError && (
          <p className="color-red small text-center">{formError}</p>
        )}
        <Clickable variant={ClickableVariant.LINK} to="/sign-up">
          Don't have an account? Sign Up!
        </Clickable>
        <Clickable variant={ClickableVariant.LINK} to="/forgot-password">
          Forgot Password?
        </Clickable>
      </div>
    </div>
  );
}
