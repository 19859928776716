import { ILogo } from '@gatherly/types';
import { Delete } from '@material-ui/icons';
import { SyntheticEvent, useCallback, useState } from 'react';
import { c, getFormField, getOnChangeFormField, isValidUrl } from '../../utils';
import { Image } from '../Image';
import { Input } from '../Input';
import { OverlayType, setOverlay } from '../../actions/uiActions';
import { useAppDispatch } from '../../hooks';
import classes from './Logo.module.scss';

type Props = {
  logo: ILogo;
  label: string;
  eventId: string;
  onUpdateWebsite: (logo: ILogo) => void;
  isDisabled: boolean;
};

export function LogoDisplay({
  logo,
  label,
  eventId,
  onUpdateWebsite,
  isDisabled,
}: Props) {
  const [website, setWebsite] = useState(getFormField(logo.imageLink ?? ''));

  const onChange = (value: string) => {
    onUpdateWebsite({
      ...logo,
      imageLink: value,
    });
    return onWebsiteChanged(value);
  };

  const onWebsiteChanged = useCallback(
    getOnChangeFormField(value => isValidUrl(value), setWebsite),
    [],
  );
  const dispatch = useAppDispatch();

  const onOpenLogoRemover = useCallback(
    (evt?: SyntheticEvent) => {
      if (evt) evt.preventDefault();
      if (isDisabled) {
        return;
      }

      setOverlay(dispatch, OverlayType.REMOVE_LOGOS, {
        eventId: eventId,
        logo: logo.imageUrl,
      });
    },
    [eventId, logo],
  );

  return (
    <div className={c(classes.Logo, 'flex row')}>
      <Image
        src={logo.imageUrl}
        alt={label}
        skipPreload={true}
        className={classes.logoDisplay}
      />
      <Input
        data-cy="logo-link"
        name="website"
        className={c(classes.form, website.error ? classes.fieldError : '')}
        label="Website"
        value={website.value}
        error={website.error}
        isMultiline={false}
        isDisabled={isDisabled}
        onChange={e => onChange(e.target.value)}
      />

      <div className={classes.iconContainer}>
        <Delete className={classes.icon} onClick={onOpenLogoRemover} />
      </div>
    </div>
  );
}
