import { useEffect, useRef, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import * as ChartGeo from 'chartjs-chart-geo';
import { WORLD_MAP_URL } from '../../config';
import { LocationAnalytics } from '../../../types';

type Props = {
  data: LocationAnalytics;
};

export function ChoroplethMap({ data }: Props) {
  const chartRef = useRef();
  const [worldMapData, setWorldMapData] = useState<any>([]);

  useEffect(() => {
    fetch(WORLD_MAP_URL)
      .then(response => response.json())
      .then(value => {
        setWorldMapData(
          ChartGeo.topojson.feature(
            value,
            value.objects.countries1,
            //@ts-ignore
          ).features,
        );
      });
  }, []);

  return (
    <Chart
      ref={chartRef}
      type="choropleth"
      data={{
        labels: worldMapData.map((d: any) => d.properties.name),
        datasets: [
          {
            outline: worldMapData,
            label: 'Countries',
            data: worldMapData.map((d: any) => ({
              feature: d,
              value: data[d.properties['Alpha-2']]?.total ?? 0,
            })),
          },
        ],
      }}
      options={{
        showOutline: true,
        showGraticule: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          xy: {
            projection: 'equalEarth',
          },
          // Hide color scale
          color: {
            display: false,
          },
        },
      }}
    />
  );
}
