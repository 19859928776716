import {
  ImageData,
  PLAN_ENTERPRISE,
  PLAN_FREE,
  PLAN_PRO,
  PLAN_STAFF,
  PLAN_STARTER,
  PLAN_TICKETS,
} from '../images';
import { toTitleCase } from '.';
import { AclPermissionsRole } from '@eventmanager/types';

type UserName = {
  firstName: string;
  lastName: string;
};

export function nameFromEmail(email: string): UserName {
  const username = email.substring(0, email.indexOf('@')).split(/\.|_|-/);
  const [firstName, lastName] = username.map(name => toTitleCase(name));

  return { firstName, lastName };
}

const planImageMap = {
  free: PLAN_FREE,
  pro: PLAN_PRO,
  staff: PLAN_STAFF,
  starter: PLAN_STARTER,
  enterprise: PLAN_ENTERPRISE,
  tickets: PLAN_TICKETS,
  custom: PLAN_ENTERPRISE,
};

export function getPlanImage(role: string): ImageData {
  return planImageMap[role as AclPermissionsRole] || planImageMap.pro;
}
