import pkg from '../../package.json';

export * as apiRoutes from './apiRoutes';
export * from './environment';
export * from './urls';

export const makeCookieName = (cookieName: string): string =>
  `gatherlyEventMgr_${cookieName}__${process.env.REACT_APP_NODE_ENV}`;
export const USER_SESSION_COOKIE_NAME: string = makeCookieName('sessionData');
export const USER_ID_TOKEN_COOKIE_NAME: string = makeCookieName('idToken');
export const COOKIE_NAMES: string[] = [
  USER_SESSION_COOKIE_NAME,
  USER_ID_TOKEN_COOKIE_NAME,
];

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const MONTH = 30 * DAY;
export const YEAR = 365 * DAY;
export const MEGABYTE = 1024 * 1024;

export const APP_VERSION = pkg.version;
export const API_TIMEOUT = 10 * SECOND;

export const DEFAULT_ACL_ROLE = 'free';
export const EVENT_ALIAS_SUFFIX = '.event.gatherly.io';
export const MIN_EVENT_CAPACITY = 5;
export const MIN_EVENT_CAPACITY_FOR_ANALYTICS = 10;
export const MAX_AI_PROMPT_LENGTH = 250;
export const MAX_EVENT_CAPACITY =
  process.env.NODE_ENV === 'development' ? 100 : 3500; // TODO: confirm this is accurate
export const MIN_EVENT_DURATION = 30;
export const SERVER_LAUNCH_MINUTES = 5;
export const SERVER_LAUNCH_WINDOW = SERVER_LAUNCH_MINUTES * MINUTE;
export const SUPPORTED_BROCHURE_MIME_TYPES = ['application/pdf'];
export const SUPPORTED_IMAGE_MIME_TYPES = ['image/png', 'image/jpeg'];
export const SVG_MIME_TYPE = 'image/svg+xml';
export const SUPPORTED_LOGO_MIME_TYPES = [
  SVG_MIME_TYPE,
  ...SUPPORTED_IMAGE_MIME_TYPES,
];
export const MAX_BROCHURE_SIZE = 10 * MEGABYTE;
export const MAX_LOGO_SIZE = 1 * MEGABYTE;
export const MAX_BOOTHS_PER_FLOOR = 8;
export const MAX_GAMES_PER_FLOOR = 8;
export const MAX_MAP_SIZE = 3 * MEGABYTE;
export const DEFAULT_LINK_NAME = 'General Link';
export const ADMIN_LINK_NAME = 'Admin Link';
export const DEFAULT_LINK_NAMES = [DEFAULT_LINK_NAME, ADMIN_LINK_NAME];
export const DEFAULT_AVATAR_COLOR = '#4b4d58';
export const LOGO_BLUE = '#00a9f2';
export const LOGO_GREEN = '#03caa5';
export const AvatarColors = [
  {
    id: 'DEFAULT',
    label: 'Default',
    value: DEFAULT_AVATAR_COLOR,
  },
  {
    id: 'RED',
    label: 'Red',
    value: '#971414',
  },
  {
    id: 'BROWN',
    label: 'Brown',
    value: '#702800',
  },
  {
    id: 'DARK_GREEN',
    label: 'Dark Green',
    value: '#0E584E',
  },
  {
    id: 'PURPLE',
    label: 'Purple',
    value: '#5E1DC8',
  },
  {
    id: 'DARK_PINK',
    label: 'Dark Pink',
    value: '#8E117A',
  },
];

export const SALES_EMAIL = 'sales@gatherly.io';
export const CONTACT_EMAIL = 'contact@gatherly.io';

export const PLAN_LEVELS = {
  free: 0,
  tickets: 1,
  starter: 2,
  pro: 3,
  enterprise: 4,
};
export const ACTIVE_ROLES = Object.keys(PLAN_LEVELS).sort((a, b) => {
  // @ts-ignore
  return PLAN_LEVELS[a] > PLAN_LEVELS[b] ? 1 : -1;
});

export const ANALYTICS_PROCESSING_TIME = 20 * MINUTE;

export const EVENT_ALIAS_MAX_LENGTH = 63;
