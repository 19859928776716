import { classnames } from '@gatherly/lib';
import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
import { useSearchParam } from 'react-use';
import { useLocation } from 'wouter';
import { saveSession, initiateSignIn } from '../../utils/auth';
import classes from './CallbackPage.module.scss';
import { trackLoggedIn } from '../../libs/trackingLib';

export const CallbackPage = () => {
  const loginError = useSearchParam('error_description');
  const [_, setLocation] = useLocation();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        trackLoggedIn({
          userId: user.attributes.sub,
          logged_in_via: 'google',
        });
        saveSession({
          userId: user.attributes.sub,
          userEmail: user.attributes.email,
          idToken: user.signInUserSession.idToken.jwtToken,
        });
        setLocation('/events');
      })
      .catch(() => {
        if (!loginError) {
          setLocation('/');
        }

        initiateSignIn(loginError);
      });
  }, [loginError]);

  return (
    <div
      className={classnames(
        'flex flex-1 bg-color-shade-10 items-center justify-center',
        classes.CallbackPage,
      )}
    >
      <div className={classes.loadingIcon} />
    </div>
  );
};
