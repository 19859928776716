import React, { useCallback, useEffect, useState, SyntheticEvent } from 'react';

import { Clickable, ClickableVariant } from '../Clickable';
import { Input, Password } from '../Input';
import { signUp, setIdle } from '../../actions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getStatusOfSignUp } from '../../selectors';
import {
  getFormField,
  getOnChangeFormField,
  hasValidationError,
  isPending,
  isRejected,
  validateEmailAddress,
  validatePassword,
  validateString,
} from '../../utils';
import { Form } from './Form';
import { GoogleSignIn } from '../GoogleSignIn/GoogleSignIn';
import { Divider } from '@material-ui/core';

export function SignUpForm() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    setIdle(dispatch, 'signUp');
  }, []);

  const status = useAppSelector(getStatusOfSignUp);
  const isMakingRequest = isPending(status);
  const hasError = isRejected(status);
  const [formError, setFormError] = useState('');

  const [firstName, setFirstName] = useState(getFormField(''));
  const [lastName, setLastName] = useState(getFormField(''));
  const [company, setCompany] = useState(getFormField(''));
  const [email, setEmail] = useState(getFormField(''));
  const [password, setPassword] = useState(getFormField(''));

  const onChangeFirstName = useCallback(
    getOnChangeFormField(
      value => validateString(value, 'First name'),
      setFirstName,
    ),
    [],
  );
  const onChangeLastName = useCallback(
    getOnChangeFormField(
      value => validateString(value, 'Last name'),
      setLastName,
    ),
    [],
  );
  const onChangeCompany = useCallback(
    getOnChangeFormField(value => ({ value, error: '' }), setCompany),
    [],
  );
  const onChangeEmail = useCallback(
    getOnChangeFormField(validateEmailAddress, setEmail),
    [],
  );
  const onChangePassword = useCallback(
    getOnChangeFormField(validatePassword, setPassword),
    [],
  );

  const onSubmit = useCallback(
    async (evt: SyntheticEvent) => {
      evt.preventDefault();

      // validate
      const _firstName = onChangeFirstName(firstName.value);
      const _lastName = onChangeLastName(lastName.value);
      const _company = onChangeCompany(company.value);
      const _email = onChangeEmail(email.value);
      const _password = onChangePassword(password.value);

      if (hasValidationError(_firstName, _lastName, _email, _password)) {
        setFormError('Please check all form fields to continue');
      } else {
        setFormError('');

        const response = await signUp(dispatch, {
          firstName: _firstName.value,
          lastName: _lastName.value,
          company: _company.value,
          email: _email.value,
          password: _password.value,
        });

        setFormError(response ? response.errorMessage : '');
      }
    },
    [
      firstName.value,
      lastName.value,
      company.value,
      email.value,
      password.value,
    ],
  );

  return (
    <Form className="flex flex-col gap-1" autoComplete="on" onSubmit={onSubmit}>
      <div className="flex flex-col mb1 align-center justify-center">
        <GoogleSignIn className="mb1" label="Sign up with Google" />
        <Divider />
      </div>
      <div className="flex gap-1">
        <Input
          label="Email"
          name="email"
          onChange={evt => onChangeEmail(evt.target.value)}
          value={email.value}
          error={email.error}
          isDisabled={isMakingRequest}
          isRequired
        />
      </div>
      <div className="flex gap-1">
        <Input
          label="First Name"
          name="firstName"
          onChange={evt => onChangeFirstName(evt.target.value)}
          value={firstName.value}
          error={firstName.error}
          isDisabled={isMakingRequest}
          isRequired
        />
        <Input
          label="Last Name"
          name="lastName"
          onChange={evt => onChangeLastName(evt.target.value)}
          value={lastName.value}
          error={lastName.error}
          isDisabled={isMakingRequest}
          isRequired
        />
      </div>
      <Input
        label="Company"
        name="company"
        onChange={evt => onChangeCompany(evt.target.value)}
        value={company.value}
        error={company.error}
        isDisabled={isMakingRequest}
      />
      <Password
        label="Password"
        name="password"
        onChange={evt => onChangePassword(evt.target.value)}
        value={password.value}
        error={password.error}
        maxLength={99}
        validations={password.validations}
        isDisabled={isMakingRequest}
        isRequired
      />
      {hasError && (
        <p className="color-red small mt1 text-center">
          Sorry, we were unable to create your account. Please check <br />
          your username &amp; password and try again.
        </p>
      )}
      <div className="flex justify-center">
        <Clickable
          isSubmit
          isDisabled={isMakingRequest}
          label={isMakingRequest ? 'SIGNING YOU UP...' : 'SIGN UP'}
          variant={ClickableVariant.BUTTON_PRIMARY}
        />
      </div>
      <div className="flex flex-1 flex-col items-center">
        {!formError && (
          <p className="color-red small text-center">{formError}</p>
        )}
        <Clickable variant={ClickableVariant.LINK} className="mt_5" to="/">
          Already have an account? Sign in.
        </Clickable>
      </div>
    </Form>
  );
}
