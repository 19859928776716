import { Checkbox } from '@gatherly/lib';
import { SyntheticEvent, useState } from 'react';
import { PromiseStatus } from '../../enums';
import { c, isPending } from '../../utils';
import { Clickable, ClickableVariant } from '../Clickable';
import { Image } from '../Image';
import { Form } from './Form';
import classes from './RemoveLogosForm.module.scss';

type Props = {
  logoUrls: string[];
  initialLogosToRemove: string[];
  onCancel: () => void;
  onSubmit: (event: SyntheticEvent, logosToRemove: string[]) => void;
  status: PromiseStatus;
};

export function RemoveLogosForm({
  logoUrls,
  initialLogosToRemove,
  onCancel,
  onSubmit,
  status,
}: Props) {
  const isMakingRequest = isPending(status);
  const [logosToRemove, setLogosToRemove] = useState(initialLogosToRemove);
  const isChecked = logoUrl => logosToRemove.includes(logoUrl);

  const onCheckChanged = (checked: boolean, url: string) => {
    if (checked) {
      addToLogosToRemove(url);
      return;
    }

    removeFromLogosToRemove(url);
  };

  const addToLogosToRemove = (url: string) => {
    setLogosToRemove([...logosToRemove, url]);
  };

  const removeFromLogosToRemove = (url: string) => {
    const updatedLogosToRemove = [...logosToRemove];
    const index = logosToRemove.indexOf(url);
    if (!!~index) {
      updatedLogosToRemove.splice(index, 1);
    }

    setLogosToRemove(updatedLogosToRemove);
  };

  const checkImage = url => {
    return (
      <div key={url} className={c(classes.checkImage, 'flex flex-row')}>
        <Checkbox
          value={isChecked(url)}
          label=""
          key={`check${url}`}
          onChange={value => onCheckChanged(value, url)}
        />
        <Image
          src={url}
          key={`logo-${url}`}
          alt={'Sponsor logo'}
          skipPreload={true}
          className={classes.logoDisplay}
        />
      </div>
    );
  };

  return (
    <Form
      className={c(classes.RemoveLogoForm, 'flex flex-col md:px4')}
      onSubmit={event => onSubmit(event, logosToRemove)}
    >
      {logoUrls.map(url => checkImage(url))}
      <div className="flex flex-1 justify-center items-center gap-1 mt2">
        <Clickable
          onClick={onCancel}
          variant={ClickableVariant.BUTTON_TERTIARY}
        >
          Cancel
        </Clickable>
        <Clickable
          isSubmit
          isDisabled={isMakingRequest}
          className={classes.removeButton}
          variant={ClickableVariant.BUTTON_SECONDARY}
          label={isMakingRequest ? 'Removing...' : 'Remove'}
        />
      </div>
    </Form>
  );
}
