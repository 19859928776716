import React from 'react';

import { Clickable } from '../Clickable';
import { c } from '../../utils';

import classes from './Tabs.module.scss';

export type TabConfig = {
  id: string;
  label: string;
  onClick?: () => void;
  to?: string;
};
type Props = {
  activeTabId: string;
  tabs: TabConfig[];
};

export function Tabs({ activeTabId, tabs }: Props) {
  return (
    <ul className={c(classes.Tabs, 'flex flex-wrap')}>
      {tabs.map(({ id, label, onClick, to }) => {
        const isActive = id === activeTabId;
        return (
          <Clickable
            key={id}
            className={c(
              classes.Tab,
              'px1_5 py_75',
              'bg-color-white',
              'border-1 border-color-shade-20',
              isActive ? 'color-shade-80' : 'color-shade-20',
              id === activeTabId && classes.active,
            )}
            label={label}
            onClick={onClick}
            to={to}
          >
            <span
              className={c(
                'detail',
                id === activeTabId ? 'color-shade-80' : 'color-shade-50',
              )}
            >
              {label}
            </span>
          </Clickable>
        );
      })}
    </ul>
  );
}
