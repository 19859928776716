import { classnames } from '@gatherly/lib';
import { Help as HelpIcon } from '@material-ui/icons';
import { ADMIN_FEATURES_LIST_URL } from '../../config';
import { Clickable, ClickableVariant } from '../Clickable';
import { Tooltip } from '../Tooltip';
import classes from './EventLinkList.module.scss';

export const AdminTooltip = (): JSX.Element => (
  <div>
    <p>
      Admins have the ability to broadcast, ban users, send announcements and
      more.
    </p>
    <Clickable to={ADMIN_FEATURES_LIST_URL} variant={ClickableVariant.LINK}>
      See the full list.
    </Clickable>
  </div>
);

const AdminHelp = (): JSX.Element => {
  return (
    <Tooltip
      className={classnames('small', 'flex justify-center items-center ml_25')}
      content={<AdminTooltip />}
    >
      <HelpIcon className={classnames(classes.helpIcon, 'ml_5')} />
    </Tooltip>
  );
};

export default AdminHelp;
