import React from 'react';

import { c } from '../../utils';
import classes from './EventCountdown.module.scss';

type Props = {
  className?: string;
};

export function EventOver({ className }: Props) {
  return (
    <div
      className={c(
        className,
        classes.EventCountdown,
        'flex items-center br_25 color-shade-80 bg-color-shade-10 gap-_5 px1 body relative',
      )}
    >
      <span>This event is over</span>
      <span>{'🚪'}</span>
    </div>
  );
}
