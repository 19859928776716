import React from 'react';

import { classnames } from '@gatherly/lib';
import { Clickable, ClickableVariant } from '../Clickable';
import { DEFAULT_LINK_NAMES } from '../../config';
import { useAppSelector } from '../../hooks';
import { eventLinks } from '../../selectors';
import { c } from '../../utils';

import classes from './EventLinkList.module.scss';
import { EventLinkListItem } from './EventLinkListItem';

type Props = {
  eventId: string;
  isDisabled: boolean;
  onClickNewLink: () => void;
};

export function EventLinkList({ eventId, isDisabled, onClickNewLink }: Props) {
  const { links, areaLinks } = useAppSelector(eventLinks(eventId));
  const defaultLinks = Object.values(links).filter(linkConfig =>
    DEFAULT_LINK_NAMES.includes(linkConfig.name),
  );
  const customLinks = Object.values(links).filter(
    linkConfig => !DEFAULT_LINK_NAMES.includes(linkConfig.name),
  );
  const meetingAreaLinks = Object.values(areaLinks);
  return (
    <div className={c(classes.EventLinkList, 'flex flex-col gap-1')}>
      <p className="color-shade-80 body bold">Default Links</p>
      <ul
        className={classnames(
          classes.accessLinks,
          'flex flex-col gap-2',
          'list-style-none ',
        )}
      >
        {defaultLinks.map((linkConfig, index) => (
          <EventLinkListItem
            key={`default-link-${index}`}
            eventId={eventId}
            linkConfig={linkConfig}
            isEditable={false}
            className={classes.accessLink}
            isDefault={true}
          />
        ))}
      </ul>
      {meetingAreaLinks.length > 0 && (
        <>
          <p className="color-shade-80 body bold">Booth Representative Links</p>
          <ul
            className={classnames(
              classes.accessLinks,
              'flex flex-col gap-2',
              'list-style-none ',
            )}
          >
            {meetingAreaLinks.map((linkConfig, index) => (
              <EventLinkListItem
                key={`default-link-${index}`}
                eventId={eventId}
                linkConfig={linkConfig}
                isEditable={false}
                className={classes.accessLink}
                isDefault={true}
              />
            ))}
          </ul>
        </>
      )}

      <div className={classnames('mt2 flex justify-between items-center')}>
        <h3 className="color-shade-80 body bold">Custom Links</h3>
        <Clickable
          onClick={onClickNewLink}
          variant={ClickableVariant.BUTTON_TERTIARY}
          className="detail"
        >
          Create new link
        </Clickable>
      </div>

      <ul
        className={classnames(
          classes.accessLinks,
          'flex flex-col gap-2',
          'list-style-none ',
        )}
      >
        <li className={classnames(classes.accessLinksHead, 'flex pb_25')}>
          <span className={classes.col}>Name</span>
          <span className={classes.col}>Avatar Border and Label Color</span>
          <span className={classes.col}>Actions</span>
        </li>
        {customLinks.map((linkConfig, index) => (
          <EventLinkListItem
            key={`custom-link-${index}`}
            eventId={eventId}
            linkConfig={linkConfig}
            isEditable={
              !isDisabled && !DEFAULT_LINK_NAMES.includes(linkConfig.name)
            }
            className={classes.accessLink}
          />
        ))}
      </ul>
    </div>
  );
}
